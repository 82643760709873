import React, {useEffect, useState} from 'react';
import market from "./img/Market.svg";
import short from "./img/short.svg";
import long from "./img/long.svg";
import posts1 from "./img/posts1.svg";
import posts2 from "./img/posts2.svg";
import OrdersTable from "../ordersTable";
import axios from "axios";
import OrdersList from "./ordersList";
import {useTranslation} from "react-i18next";

const Orders = () => {
    const {t} = useTranslation()
    const token = localStorage.getItem('token');

    const [userOrdersClose, setUserOrdersClose] = useState([]);
    const [userOrdersCloseAmount, setUserOrdersCloseAmount] = useState([]);
    const [isLoadedOrdersTable, setIsLoadedOrdersTable] = useState(false);
    const [userBalance, setUserBalance] = useState("");

    const requireUserBalance = () => {}


    const [page2, setPage2] = useState(1)
    const requireUserDataClosed = () => axios.get(
        `https://api.dune-ex.net/orders/api/order/close?size=7&page=${page2}`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserOrdersClose(res.data.items.reverse());
            setUserOrdersCloseAmount(res.data.total);
            setIsLoadedOrdersTable(true)
        })
    useEffect(() => {
        requireUserDataClosed();
    }, []);



    const [counter2, setCounter2] = useState(0)
    const upcount2 = () => {
        setCounter2(Math.random)
        setTimeout(upcount2, 3000)
    }
    useEffect(() => {
        requireUserDataClosed()
    }, [counter2])
    useEffect(() => {
        upcount2()
    }, [])
    const newPageHandler2 = (pg) => {
        setPage2(pg);
    }
    useEffect(() => {
        requireUserDataClosed()
    }, [page2])

    return (
        <div className="tabs__body ">
            <div className="account__box">
                <div className="kyc__box">
                    <div className="account__balans">{t("order_history_page.order_history")}</div>
                    <div className="story-orders__box">
                        <table className="story-orders__table">
                            <thead>
                            <tr className="story-orders__top-container">
                                <th className="story-orders__top-item">{t("order_history_page.ticker")}</th>
                                <th className="story-orders__top-item">{t("order_history_page.position")}</th>
                                <th className="story-orders__top-item">{t("order_history_page.size")}</th>
                                <th className="story-orders__top-item ti2">{t("order_history_page.entry_price")}</th>
                                <th className="story-orders__top-item">{t("order_history_page.margin")}</th>
                                <th className="story-orders__top-item">{t("order_history_page.liquidation")}</th>
                                <th className="story-orders__top-item">PNL %</th>
                                <th className="story-orders__top-item">{t("order_history_page.take_profit")}</th>
                                <th className="story-orders__top-item">{t("order_history_page.stop_loss")}</th>
                            </tr>
                            </thead>
                            <tbody className="story-orders__orders-body">
                            {isLoadedOrdersTable ? (
                                userOrdersCloseAmount > 0 ? (
                                    userOrdersClose.map((ordersTable) => {
                                        return (
                                            <OrdersList
                                                action={ordersTable.action}
                                                coin={ordersTable.coin_to}
                                                coinfrom={ordersTable.coin_from}
                                                price={ordersTable.price}
                                                amount={ordersTable.amount}
                                                total={ordersTable.total}
                                                leverage={ordersTable.leverage}
                                                takeProfit={ordersTable.take_profit}
                                                stopLoss={ordersTable.stop_loss}
                                                isLong={ordersTable.short_long}

                                                /*date={ordersTable.time}*/
                                                marzha={ordersTable.margin}
                                                filled={ordersTable.filled}
                                                id={ordersTable.id}
                                                pnl={ordersTable.pnl}
                                                pnl_percent={ordersTable.pnl_percent}
                                                longCheck={true}
                                                requireUserData={requireUserDataClosed}
                                            />
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td className="AlertOrders" colSpan="9">
                                            У вас нету ордеров
                                        </td>
                                    </tr>
                                )
                            ) : (
                                ""
                            )}
                            </tbody>
                        </table>
                        <div className="account__posts">
                            <button onClick={() => {page2 === 1 ? setPage2(1) : newPageHandler2(page2 - 1)}} className="account__posts-btn">
                                <img src={posts1} alt="" />
                            </button>
                            <div className="account__posts-nav">

                                { page2 === 1 ? "" : <button onClick={() => newPageHandler2(page2 - 1)}>{page2 - 1}</button>}
                                <button className="primary">
                                    {page2}
                                </button>
                                <button onClick={() => newPageHandler2(page2 + 1)}>{page2 + 1}</button>
                                <button onClick={() => newPageHandler2(page2 + 2)}>{page2 + 2}</button>
                            </div>
                            <button onClick={() => newPageHandler2(page2 + 1)} className="account__posts-btn">
                                <img src={posts2} alt="" />
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Orders;