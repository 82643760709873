import React, {useEffect, useState} from 'react';
import './css/portfolio.css'
import bronzebr from  './img/bronzebr.png'
import silverbr from  './img/silverbr.png'
import goldbr from  './img/goldbr.png'

import bronzecase from  './img/brcase.png'
import silvercase from  './img/svcase.png'
import goldcase from  './img/gdcase.png'
import axios from "axios";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
const Portfolio = () => {
    const {t} = useTranslation()
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );
    const [userPortfolioData, setUserPortfolioData] = useState()
    const [userPortfolioDataReserved, setUserPortfolioDataReserved] = useState()
    const [portfoliosData, setPortfoliosData] = useState()
    const [portfoliosDataUpgrade, setPortfoliosDataUpgrade] = useState()
    const [ILuserPortfolioData, setILUserPortfolioData] = useState(false)
    const [ILPortfolioData, setILPortfolioData] = useState(false)
    const [ILPortfolioDataUP, setILPortfolioDataUP] = useState(false)

    const [switcher, setSwitch] = useState(0)
    const [switcher2, setSwitch2] = useState(0)

    const caseSwitcher = (x) => {
        let cs1 = portfoliosData[0] ? portfoliosData[0].id : "dfnkjsdhfkjsdfkj"
        let cs2 = portfoliosData[1] ? portfoliosData[1].id : "dlkfjlsdf"
        let cs3 = portfoliosData[2] ? portfoliosData[2].id : "dflkvndfkjv"

        let case1 = document.getElementById(`${cs1}`)
        let case2 = document.getElementById(`${cs2}`)
        let case3 = document.getElementById(`${cs3}`)

        let caseb1 = document.getElementById(`${cs1}b`)
        let caseb2 = document.getElementById(`${cs2}b`)
        let caseb3 = document.getElementById(`${cs3}b`)



        switch (x) {
            case 0:
                setSwitch(0)
                case1.classList.add("hidden")
                caseb1.classList.add("hidden")
                case2.classList.add("hidden")
                caseb2.classList.add("hidden")
                case3.classList.add("hidden")
                caseb3.classList.add("hidden")
                break
            case cs1:
                setSwitch(cs1)
                case1.classList.remove("hidden")
                caseb1.classList.add("hidden")
                case2.classList.add("hidden")
                caseb2.classList.add("hidden")
                case3.classList.add("hidden")
                caseb3.classList.add("hidden")
                break
            case cs2:
                setSwitch(cs2)
                case1.classList.add("hidden")
                caseb1.classList.add("hidden")
                case2.classList.remove("hidden")
                caseb2.classList.add("hidden")
                case3.classList.add("hidden")
                caseb3.classList.add("hidden")
                break
            case cs3:
                setSwitch(cs3)
                case1.classList.add("hidden")
                caseb1.classList.add("hidden")
                case2.classList.add("hidden")
                caseb2.classList.add("hidden")
                case3.classList.remove("hidden")
                caseb3.classList.add("hidden")
                break

        }
    }

    const caseBuySwitcher = (x) => {

        let cs1 = portfoliosData[0] ? portfoliosData[0].id : "dfnkjsdhfkjsdfkj"
        let cs2 = portfoliosData[1] ? portfoliosData[1].id : "dlkfjlsdf"
        let cs3 = portfoliosData[2] ? portfoliosData[2].id : "dflkvndfkjv"

        let case1 = document.getElementById(`${cs1}b`)
        let case2 = document.getElementById(`${cs2}b`)
        let case3 = document.getElementById(`${cs3}b`)
        switch (x) {
            case 0:
                setSwitch2(0)
                case1.classList.add("hidden")
                case2.classList.add("hidden")
                case3.classList.add("hidden")
                break
            case cs1:
                setSwitch2(1)
                case1.classList.remove("hidden")
                case2.classList.add("hidden")
                case3.classList.add("hidden")
                break
            case cs2:
                setSwitch2(2)
                case1.classList.add("hidden")
                case2.classList.remove("hidden")
                case3.classList.add("hidden")
                break
            case cs3:
                setSwitch2(3)
                case1.classList.add("hidden")
                case2.classList.add("hidden")
                case3.classList.remove("hidden")
                break

        }
    }

    const caseSwitcherUP = (x) => {
        let cs1 = portfoliosDataUpgrade[0] ? portfoliosDataUpgrade[0].id : "dfnkjsdhfkjsdfkj"
        let cs2 = portfoliosDataUpgrade[1] ? portfoliosDataUpgrade[1].id : "dlkfjlsdf"
        let cs3 = portfoliosDataUpgrade[2] ? portfoliosDataUpgrade[2].id : "dflkvndfkjv"

        let case1 = document.getElementById(`${cs1}`)
        let case2 = document.getElementById(`${cs2}`)
        let case3 = document.getElementById(`${cs3}`)

        let caseb1 = document.getElementById(`${cs1}b`)
        let caseb2 = document.getElementById(`${cs2}b`)
        let caseb3 = document.getElementById(`${cs3}b`)



        switch (x) {
            case 0:
                setSwitch(0)
                case1.classList.add("hidden")
                caseb1.classList.add("hidden")
                case2.classList.add("hidden")
                caseb2.classList.add("hidden")
                case3.classList.add("hidden")
                caseb3.classList.add("hidden")
                break
            case cs1:
                setSwitch(cs1)
                case1.classList.remove("hidden")
                caseb1.classList.add("hidden")
                case2.classList.add("hidden")
                caseb2.classList.add("hidden")
                case3.classList.add("hidden")
                caseb3.classList.add("hidden")
                break
            case cs2:
                setSwitch(cs2)
                case1.classList.add("hidden")
                caseb1.classList.add("hidden")
                case2.classList.remove("hidden")
                caseb2.classList.add("hidden")
                case3.classList.add("hidden")
                caseb3.classList.add("hidden")
                break
            case cs3:
                setSwitch(cs3)
                case1.classList.add("hidden")
                caseb1.classList.add("hidden")
                case2.classList.add("hidden")
                caseb2.classList.add("hidden")
                case3.classList.remove("hidden")
                caseb3.classList.add("hidden")
                break

        }
    }

    const caseBuySwitcherUP = (x) => {

        let cs1 = portfoliosDataUpgrade[0] ? portfoliosDataUpgrade[0].id : "dfnkjsdhfkjsdfkj"
        let cs2 = portfoliosDataUpgrade[1] ? portfoliosDataUpgrade[1].id : "dlkfjlsdf"
        let cs3 = portfoliosDataUpgrade[2] ? portfoliosDataUpgrade[2].id : "dflkvndfkjv"

        let case1 = document.getElementById(`${cs1}b`)
        let case2 = document.getElementById(`${cs2}b`)
        let case3 = document.getElementById(`${cs3}b`)
        switch (x) {
            case 0:
                setSwitch2(0)
                case1.classList.add("hidden")
                case2.classList.add("hidden")
                case3.classList.add("hidden")
                break
            case cs1:
                setSwitch2(1)
                case1.classList.remove("hidden")
                case2.classList.add("hidden")
                case3.classList.add("hidden")
                break
            case cs2:
                setSwitch2(2)
                case1.classList.add("hidden")
                case2.classList.remove("hidden")
                case3.classList.add("hidden")
                break
            case cs3:
                setSwitch2(3)
                case1.classList.add("hidden")
                case2.classList.add("hidden")
                case3.classList.remove("hidden")
                break

        }
    }

    const token = localStorage.getItem('token');


    const getUserPortfolioData = () => {
        axios.get("https://api.dune-ex.net/api/api/user/portfolio", {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setUserPortfolioData(res.data)

                setILUserPortfolioData(true)
                     if (res.data.available == true) {
                    axios.get("https://api.dune-ex.net/api/api/user/portfolio/available", {headers: {"Authorization": `Bearer ${token}`}})
                            .then(res => {
                                setPortfoliosData(res.data.items)
                                setILPortfolioData(true)
                            })
                    }
                     if (res.data.active == true) {
                    axios.get("https://api.dune-ex.net/api/api/portfolio/upgrade", {headers: {"Authorization": `Bearer ${token}`}})
                            .then(res => {
                                setPortfoliosDataUpgrade(res.data)
                                setILPortfolioDataUP(true)
                            })
                    }
            })
    }
const [RPL, setRPL] = useState(false)
    const getUserPortfolioDataReserved = () => {
        axios.get("https://api.dune-ex.net/api/api/user/portfolio/reserved", {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setUserPortfolioDataReserved(res.data)
                if (res.data === null) {
                    setRPL(false)
                }
                else{
                    setRPL(true)
                }
            })
    }

    const buyPorfolio = (id, reserved) => {
        axios.post("https://api.dune-ex.net/api/api/portfolio", {id, reserved}, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setTimeout(() => document.location.reload(), 700)
            })
            .catch( error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail[0].msg}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    }
    const handleBuyRvd = (id, reserved) => {
        axios.post("https://api.dune-ex.net/api/api/portfolio", {id, reserved}, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setTimeout(() => document.location.reload(), 700)
            })
            .catch( error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail[0].msg}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    }

    const upgradePorfolio = (upgrade_id, reserved) => {
        axios.post("https://api.dune-ex.net/api/api/portfolio/upgrade", {"id": upgrade_id, reserved}, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setTimeout(() => document.location.reload(), 700)
            })
            .catch( error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail[0].msg}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    }
    const reservePorfolio = (id) => {
        axios.post("https://api.dune-ex.net/api/api/portfolio/reserved", {id}, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setTimeout(() => document.location.reload(), 700)
            })
            .catch( error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail[0].msg}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    }


    useEffect(() => {
        getUserPortfolioData()
        getUserPortfolioDataReserved()
    }, [])

    return (


        <div className="tabs__body">

            <div className="account__box">
                <div className="deposits__body">
                    <div className="account__balans">{t("portfolio_page.portfolio")}</div>

                    {ILuserPortfolioData && userPortfolioData.available ?

                        userPortfolioData.active ?
                            <>
                                <div className="not ">
                                    <div className="portfolio__attention2">
                                        <h1 className="portfolio__active-title">{t("portfolio_page.your_active_portfolio")}</h1>
                                        <h1 className={`portfolio__active-title2 ${userPortfolioData.category}t`}>{userPortfolioData.name} {userPortfolioData.subcategory ?
                                            <span className="subcat">{userPortfolioData.subcategory}</span> : ""}</h1>
                                    </div>

                                    <div className="portfolio__continer">
                                        <div className={`portfolio__desc-item ${userPortfolioData.category}`}>
                                            <div className="portfolio__desc-sum">
                                                <h3 className="portfolio__type">{t("portfolio_page.amount_of_contribution")}</h3>
                                                <h1 className={`portfolio__price ${userPortfolioData.category}t`}>${userPortfolioData.value}</h1>
                                            </div>
                                            <div className="portfolio__desc-sum">
                                                <h3 className="portfolio__type">{t("portfolio_page.term_of_validity")}</h3>
                                                <h1 className={`portfolio__price2 ${userPortfolioData.category}t`}>до {userPortfolioData.end[5]}{userPortfolioData.end[6]}.{userPortfolioData.end[8]}{userPortfolioData.end[9]}</h1>
                                            </div>
                                            <div
                                                className="portfolio__desc-description">{userPortfolioData.description}</div>
                                            <img
                                                src={userPortfolioData.category == "bronze" ? bronzecase : userPortfolioData.category == "silver" ? silvercase : goldcase}
                                                alt="" className="portfolio__desc-img"/>
                                        </div>
                                    </div>
                                </div>

                                {RPL ?
                                    userPortfolioDataReserved.reserved ?
                                        <>
                                            <div className="not ">
                                                <div className="portfolio__attention2">
                                                    <h1 className="portfolio__active-title">{t("portfolio_page.your_reserved_portfolio")}</h1>
                                                    <h1 className={`portfolio__active-title2 ${userPortfolioDataReserved.category}t`}>{userPortfolioDataReserved.name} {userPortfolioDataReserved.subcategory ?
                                                        <span
                                                            className="subcat">{userPortfolioDataReserved.subcategory}</span> : ""}</h1>
                                                </div>

                                                <div className="portfolio__continer">
                                                    <div
                                                        className={`portfolio__desc-item ${userPortfolioDataReserved.category}`}>
                                                        <div className="portfolio__desc-sum">
                                                            <h3 className="portfolio__type">{t("portfolio_page.amount_of_contribution")}</h3>
                                                            <h1 className={`portfolio__price ${userPortfolioDataReserved.category}t`}>${userPortfolioDataReserved.value}</h1>
                                                        </div>
                                                        <div className="portfolio__desc-sum">
                                                            <h3 className="portfolio__type">{t("portfolio_page.reservation_period")}</h3>
                                                            <h1 className={`portfolio__price2 ${userPortfolioDataReserved.category}t`}>до {userPortfolioDataReserved.end[5]}{userPortfolioDataReserved.end[6]}.{userPortfolioDataReserved.end[8]}{userPortfolioDataReserved.end[9]}</h1>
                                                        </div>
                                                        <div
                                                            className="portfolio__desc-description">{userPortfolioDataReserved.description}</div>
                                                        <img
                                                            src={userPortfolioDataReserved.category == "bronze" ? bronzecase : userPortfolioDataReserved.category == "silver" ? silvercase : goldcase}
                                                            alt="" className="portfolio__desc-img"/>
                                                    </div>
                                                </div>
                                                <button className="activate_reserved"
                                                        onClick={() => handleBuyRvd(userPortfolioDataReserved.id, userPortfolioDataReserved.reserved)}>{t("portfolio_page.activate")}
                                                </button>
                                            </div>
                                        </>
                                        : ""
                                    : <>

                                        <div className="portfolio__attention2">
                                            <h1 className="portfolio__active-title">{t("portfolio_page.upgrade_plan")}</h1>
                                        </div>
                                        <div className="portfolio__continer ">
                                            {ILPortfolioDataUP ?
                                                portfoliosDataUpgrade.map(data =>

                                                    <div onClick={
                                                        () => {
                                                            if (switcher === data.id) {
                                                                caseSwitcherUP(0)
                                                            } else {
                                                                caseSwitcherUP(data.id)
                                                            }
                                                        }} className={`portfolio__item ${data.category}`}>
                                                        <div className="portfolio__item-tc">
                                                            <h3 className={`portfolio__type ${data.category}t`}>{data.name}
                                                                <span
                                                                    className="subcat">{data.subcategory}</span></h3>
                                                            <h1 className="portfolio__price">${data.value}</h1>
                                                        </div>
                                                        <img
                                                            src={data.category == "bronze" ? bronzecase : data.category == "silver" ? silvercase : goldcase}
                                                            alt="" className="portfolio__case"/>
                                                    </div>
                                                )
                                                : ""}
                                        </div>

                                        <div className="portfolio__continer ">
                                            {ILPortfolioDataUP ?
                                                portfoliosDataUpgrade.map(data =>
                                                    <div className="dflp">
                                                        <div onClick={
                                                            () => {
                                                                if (switcher2 === data.id) {
                                                                    caseBuySwitcherUP(0)
                                                                } else {
                                                                    caseBuySwitcherUP(data.id)
                                                                }
                                                            }} className={`portfolio__item2 hidden ${data.category}`}
                                                             id={`${data.id}`}>
                                                            <h5 className={`portfolio__desc-title ${data.category}t`}>{data.name}</h5>
                                                            <h5 className="portfolio__desc-text">{data.description}</h5>
                                                            <h5 className="portfolio__desc-price-title goldt">{t("portfolio_page.entry_amount")}</h5>
                                                            <h5 className="portfolio__desc-price goldt">${data.value}</h5>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                        </div>

                                        <div className="portfolio__continer ">
                                            {ILPortfolioDataUP ? portfoliosDataUpgrade.map(data =>
                                                <div className="dflp">
                                                    <div className={`portfolio__item3 ${data.category} hidden`}
                                                         id={`${data.id}b`}>
                                                        <button className="portfolio__button"
                                                                onClick={() => upgradePorfolio(data.id, data.reserved)}>{t("portfolio_page.buy")}
                                                        </button>
                                                        <button className="portfolio__button"
                                                                onClick={() => reservePorfolio(data.id)}>{t("portfolio_page.reserve")}
                                                        </button>
                                                    </div>
                                                </div>) : ""}
                                        </div>
                                    </>}
                            </>
                            :
                            userPortfolioData.reserved ?
                                <>
                                    <div className="not ">
                                        <div className="portfolio__attention2">
                                            <h1 className="portfolio__active-title">{t("portfolio_page.your_reserved_portfolio")}</h1>
                                            <h1 className={`portfolio__active-title2 ${userPortfolioData.category}t`}>{userPortfolioData.name} {userPortfolioData.subcategory ?
                                                <span
                                                    className="subcat">{userPortfolioData.subcategory}</span> : ""}</h1>
                                        </div>

                                        <div className="portfolio__continer">
                                            <div className={`portfolio__desc-item ${userPortfolioData.category}`}>
                                                <div className="portfolio__desc-sum">
                                                    <h3 className="portfolio__type">{t("portfolio_page.amount_of_contribution")}</h3>
                                                    <h1 className={`portfolio__price ${userPortfolioData.category}t`}>${userPortfolioData.value}</h1>
                                                </div>
                                                <div className="portfolio__desc-sum">
                                                    <h3 className="portfolio__type">{t("portfolio_page.reservation_period")}</h3>
                                                    <h1 className={`portfolio__price2 ${userPortfolioData.category}t`}>до {userPortfolioData.end[5]}{userPortfolioData.end[6]}.{userPortfolioData.end[8]}{userPortfolioData.end[9]}</h1>
                                                </div>
                                                <div
                                                    className="portfolio__desc-description">{userPortfolioData.description}</div>
                                                <img
                                                    src={userPortfolioData.category == "bronze" ? bronzecase : userPortfolioData.category == "silver" ? silvercase : goldcase}
                                                    alt="" className="portfolio__desc-img"/>
                                            </div>
                                        </div>
                                        <button className="activate_reserved"
                                                onClick={() => handleBuyRvd(userPortfolioData.id, userPortfolioData.reserved)}>{t("portfolio_page.activate")}</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="portfolio__continer ">
                                        {ILPortfolioData ?
                                            portfoliosData.map(data =>
                                                <div onClick={
                                                    () => {
                                                        if (switcher === data.id) {
                                                            caseSwitcher(0)
                                                        } else {
                                                            caseSwitcher(data.id)
                                                        }
                                                    }} className={`portfolio__item ${data.category}`}>
                                                    <div className="portfolio__item-tc">
                                                        <h3 className={`portfolio__type ${data.category}t`}>{data.name}</h3>
                                                        <h1 className="portfolio__price">${data.value}</h1>
                                                    </div>
                                                    <img
                                                        src={data.category == "bronze" ? bronzecase : data.category == "silver" ? silvercase : goldcase}
                                                        alt="" className="portfolio__case"/>
                                                </div>
                                            )
                                            : ""}
                                    </div>

                                    <div className="portfolio__continer ">
                                        {ILPortfolioData ?
                                            portfoliosData.map(data =>
                                                <div className="dflp">
                                                    <div onClick={
                                                        () => {
                                                            if (switcher2 === data.id) {
                                                                caseBuySwitcher(0)
                                                            } else {
                                                                caseBuySwitcher(data.id)
                                                            }
                                                        }}
                                                         className={`portfolio__item2 hidden ${data.category}`}
                                                         id={`${data.id}`}>
                                                        <h5 className={`portfolio__desc-title ${data.category}t`}>{data.name}</h5>
                                                        <h5 className="portfolio__desc-text">{data.description}</h5>
                                                        <h5 className="portfolio__desc-price-title goldt">{t("portfolio_page.entry_amount")}</h5>
                                                        <h5 className="portfolio__desc-price goldt">${data.value}</h5>
                                                    </div>
                                                </div>
                                            ) : ""}
                                    </div>

                                    <div className="portfolio__continer ">
                                        {ILPortfolioData ? portfoliosData.map(data =>
                                            <div className="dflp">
                                                <div className={`portfolio__item3 ${data.category} hidden`}
                                                     id={`${data.id}b`}>
                                                    <button className="portfolio__button"
                                                            onClick={() => buyPorfolio(data.id, data.reserved)}>{t("portfolio_page.buy")}</button>
                                                    <button className="portfolio__button"
                                                            onClick={() => reservePorfolio(data.id)}>{t("portfolio_page.reserve")}</button>
                                                </div>
                                            </div>) : ""}
                                    </div>
                                </>
                        :
                        <div className="not ">
                            <div className="portfolio__attention">
                                <h1 className="portfolio__attention-title">{t("portfolio_page.attention")}</h1>
                                <p className="portfolio__attention-text">{t("portfolio_page.no_access_to_portfolios")}</p>
                            </div>
                            <div className="portfolio__continer">
                                <img src={bronzebr} alt="" className="portfolio__blur-item bronze"/>
                                <img src={silverbr} alt="" className="portfolio__blur-item silver"/>
                                <img src={goldbr} alt="" className="portfolio__blur-item gold"/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>


    );
};

export default Portfolio;
