import React from 'react';
import './comps.css'
import Bar from "./bar";
import img1 from "../../images/specials1.png";
import {useTranslation} from "react-i18next";
const Privacy = () => {
    const {i18n} = useTranslation()
    let currentLanguage = i18n.language
    return (
        <div className="foot_container">
        <div className="comps__body">
            <Bar active={3}/>
            {currentLanguage === "ru" ? <div className="comps__content">
                <p className="footer-title-comp" style={{ color: "#FFFFFF" }}>УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</p>
                <p>
                <br />
                </p>
                <p>Последнее обновление: 11 февраля 2024 г.</p>
                <p>
                <br />
                </p>
                <p>
                Настоящие Условия представляют собой юридически обязывающее соглашение между
                вами («вы», «ваш») и DuneEX («DuneEX», «мы», «наш», «нам»). Настоящие
                Условия регулируют использование вами Услуг DuneEX, которые доступны вам
                на Платформе или иным образом. Услуги DuneEX могут предоставляться DuneEX  или, если это указано в настоящих Условиях, каких-либо Условиях продукта
                или каких-либо дополнительных условиях, любым Партнером DuneEX.
                </p>
                <p>
                <br />
                </p>
                <p>
                Регистрируя Аккаунт DuneEX, получая доступ к Платформе и/или используя
                Услуги DuneEX, вы подтверждаете, что прочитали, поняли и приняли
                настоящие Условия, а также любые дополнительные документы или условия,
                упомянутые в настоящих Условиях. Вы подтверждаете и принимаете, что
                обязуетесь соблюдать настоящие Условия, которые периодически будут
                обновляться и изменяться.
                </p>
                <p>
                <br />
                </p>
                <p>
                Если вы не понимаете и не принимаете настоящие Условия в полном объеме, вам
                не следует регистрировать Аккаунт DuneEX, получать доступ к Платформе или
                использовать любые Услуги DuneEX.
                </p>
                <p>
                <br />
                </p>
                <p>Предупреждение о риске</p>
                <p>
                <br />
                </p>
                <p>
                Стоимость Цифровых активов может значительно измениться, что приведет к
                риску экономических потерь, если вы покупаете, продаете, держите или
                инвестируете в Цифровые активы. Поэтому вам необходимо оценить, подходит ли
                торговля или владение Цифровыми активами вам с учетом вашего финансового
                состояния.
                </p>
                <p>
                <br />
                </p>
                <p>
                Вы должны полностью понимать все риски, прежде чем принять решение об
                использовании Услуг DuneEX.
                </p>
                <p>
                <br />
                </p>
                <p>
                Мы не являемся вашим брокером, посредником, агентом или консультантом, и у
                нас нет фидуциарных отношений или обязательств перед вами в связи с любыми
                Транзакциями или другими действиями, которые вы совершаете при использовании
                Услуг DuneEX. Мы не предоставляем инвестиционных или консультационных
                советов любого рода, и никакая информация или сообщения, которые мы
                предоставляем вам, не представляют собой и не должны рассматриваться как
                советы любого рода.&nbsp;
                </p>
                <p>
                <br />
                </p>
                <p>
                Вы единолично определяете, соответствуют ли какие-либо инвестиции,
                инвестиционные стратегии или связанные с ними транзакции вашим личным
                инвестиционным целям, финансовому положению и допустимому риску, и вы несете
                ответственность за любые связанные с этим убытки или обязательства. Мы не
                рекомендуем вам покупать, зарабатывать, продавать или удерживать какой-либо
                Цифровой актив. Перед принятием решения о покупке, продаже или удержании
                любого Цифрового актива вы должны провести собственное исследование и
                проконсультироваться с финансовым специалистом. Мы не несем ответственности
                за ваши решения о покупке, заработке, продаже или удержании Цифровых активов
                на основе предоставленной нами информации, включая любые убытки, которые вы
                понесли в результате таких решений.
                </p>
                <p>
                <br />
                </p>
                <p>ИНФОРМАЦИЯ О НАШЕМ СОГЛАШЕНИИ С ВАМИ</p>
                <p>
                <br />
                </p>
                <p>1. &nbsp; &nbsp;Введение</p>
                <p>
                <br />
                </p>
                <p>
                1.1. &nbsp; &nbsp;О нас. Группа DuneEX — это экосистема, в центре которой
                находится онлайн-биржа для торговли Цифровыми активами. Группа DuneEX
                предоставляет пользователям торговую платформу для покупки и продажи
                Цифровых активов, интегрированное кастодиальное решение, позволяющее
                пользователям хранить свои Цифровые активы, и другие услуги, связанные с
                Цифровыми активами.
                </p>
                <p>
                <br />
                </p>
                <p>
                1.2. &nbsp; &nbsp;Настоящие Условия. Регистрируясь для создания Аккаунта
                DuneEX, вы заключаете с нами юридически обязывающее соглашение. Настоящие
                Условия будут регулировать использование вами Услуг DuneEX и расскажут
                вам, кто мы, как мы будем предоставлять вам Услуги DuneEX, как эти
                Условия могут быть изменены или прекращены, что делать в случае
                возникновения проблем, а также другую важную информацию.
                </p>
                <p>
                <br />
                </p>
                <p>
                Вы должны внимательно прочитать настоящие Условия, а также документы,
                упомянутые в Условиях, и сообщить нам, если вам что-то непонятно.
                </p>
                <p>
                <br />
                </p>
                <p>
                Если к использованию вами Услуг DuneEX применяются какие-либо Местные
                условия, такие Местные условия имеют преимущественную силу над настоящими
                Условиями.
                </p>
                <p>
                <br />
                </p>
                <p>
                1.3. &nbsp; &nbsp;Дополнительные документы. Настоящие Условия ссылаются на
                ряд дополнительных документов, которые также применяются к использованию
                вами Услуг DuneEX. К ним относятся:
                </p>
                <p>
                <br />
                </p>
                <p>
                Пользуясь Услугами DuneEX, вы даете согласие на обработку персональных
                данных и гарантируете, что все предоставляемые вами данные достоверные и
                актуальные.&nbsp;
                </p>
                <p>
                <br />
                </p>
                <p>
                b. &nbsp; &nbsp;Наше Предупреждение о рисках, излагающее важную информацию о
                рисках, которые могут возникнуть при покупке, продаже, удержании или
                инвестициях в Цифровые активы.&nbsp;
                </p>
                <p>
                <br />
                </p>
                <p>
                d. &nbsp; &nbsp;Условия продукта, излагающие дополнительные положения и
                условия, которые будут применяться к использованию вами конкретных Услуг
                DuneEX.
                </p>
                <p>
                <br />
                </p>
                <p>
                Вы принимаете, что обязуетесь соблюдать любые соответствующие дополнительные
                условия и положения, которые применяются к использованию вами Услуг DuneEX .
                </p>
                <p>
                <br />
                </p>
                <p>2. &nbsp; &nbsp;Доступность</p>
                <p>
                <br />
                </p>
                <p>
                2.1. &nbsp; &nbsp;Критерии доступности. Чтобы иметь право зарегистрировать
                Аккаунт DuneEX и использовать Услуги DuneEX, вы должны соблюдать
                следующие критерии:
                </p>
                <p>
                <br />
                </p>
                <p>
                a. &nbsp; &nbsp;быть физическим лицом, корпорацией, юридическим лицом,
                компанией или иной организацией, у которой есть все полномочия, права и
                возможности для (1) доступа и использования Услуг DuneEX; и (2)
                заключения и выполнения своих обязательств по настоящим Условиям;
                </p>
                <p>
                <br />
                </p>
                <p>
                b. &nbsp; &nbsp;если вы являетесь физическим лицом, быть не моложе 18 лет;
                </p>
                <p>
                <br />
                </p>
                <p>
                c. &nbsp; &nbsp;если вы действуете как сотрудник или агент юридического лица
                и заключаете настоящие Условия от его имени, вы должны быть должным образом
                уполномочены действовать от имени такого юридического лица и связывать его
                обязательствами в целях заключения настоящих Условий;
                </p>
                <p>
                <br />
                </p>
                <p>
                d. &nbsp; &nbsp;вам ранее не приостанавливали или не прекращали
                предоставление Услуг DuneEX;
                </p>
                <p>
                <br />
                </p>
                <p>e. &nbsp; &nbsp;вы не являетесь Лицом с ограниченным доступом;&nbsp;</p>
                <p>
                <br />
                </p>
                <p>f. &nbsp; &nbsp;у вас нет действующего Аккаунта DuneEX;&nbsp;</p>
                <p>
                <br />
                </p>
                <p>
                g. &nbsp; &nbsp;вы не расположены, зарегистрированы или иным образом
                учреждены в стране, не являетесь гражданином или резидентом страны, не
                ведете коммерческую деятельность в стране, которая является:
                </p>
                <p>
                <br />
                </p>
                <p>
                i. &nbsp; &nbsp;юрисдикцией, в которой, согласно Применимому
                законодательству, доступ или использование вами Услуг DuneEX является
                незаконным или приведет к нарушению нами или любой третьей стороной любого
                Применимого законодательства; или
                </p>
                <p>
                <br />
                </p>
                <p>
                2.2. &nbsp; &nbsp;Изменение наших критериев доступности. Мы можем изменить
                наши критерии доступности в любое время по своему усмотрению. По возможности
                мы заранее уведомим вас об изменении. Однако иногда нам может потребоваться
                внести изменения без предварительного уведомления. Это может произойти в
                следующих случаях:
                </p>
                <p>
                <br />
                </p>
                <p>
                a. &nbsp; &nbsp;мы вносим изменения в результате законодательных и/или
                нормативных изменений;
                </p>
                <p>
                <br />
                </p>
                <p>b. &nbsp; &nbsp;вносимые изменения отвечают вашим интересам; и/или</p>
                <p>
                <br />
                </p>
                <p>
                c. &nbsp; &nbsp;существует любая другая уважительная причина, в рамках
                которой у нас нет времени на уведомление.&nbsp;
                </p>
                <p>
                <br />
                </p>
                <p>
                Если мы не можем уведомить вас заранее, мы сообщим вам об изменениях как
                можно скорее после их внесения.
                </p>
                <p>
                <br />
                </p>
                <p>3. &nbsp; &nbsp;Способы связи</p>
                <p>
                <br />
                </p>
                <p>
                3.1. &nbsp; &nbsp;Как вы можете связаться с нами. Для получения
                дополнительной информации о DuneEX вы можете ознакомиться с информацией
                на нашем Сайте. Если у вас есть вопросы, обратная связь или жалобы, вы
                можете связаться с нами через нашу Службу поддержки по ссылке{" "}
            <a data-fr-linked="true" href="https://dune-ex.net/contacts">
                https://dune-ex.net/contacts
            </a>
            . В настоящих Условиях могут быть предоставлены контактные данные для
            отдельных видов сообщений. Мы не принимаем такие сообщения по выше
            указанному адресу.
        </p>
            <p>
                <br />
            </p>
            <p>
                3.2. &nbsp; &nbsp;Как мы будем связываться с вами. Мы будем связываться с
                вами, используя предоставленные вами данные. Способы связи могут включать
                электронную почту, SMS или телефон. Убедитесь, что указали верные и
                действующие контактные данные. Если ваши контактные данные изменились, вы
                должны немедленно сообщить нам об этом. Если вы этого не сделаете, мы не
                будем нести ответственность, если вы не получите от нас информацию,
                уведомления или другую важную информацию.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>4. &nbsp; &nbsp;Услуги DuneEX</p>
            <p>
                <br />
            </p>
            <p>
                4.1. &nbsp; &nbsp;Условия конкретного продукта. После создания Аккаунта
                DuneEX вы сможете пользоваться Услугами DuneEX в соответствии с
                настоящими Условиями и Условиями продукта, которые регулируют использование
                вами каждой конкретной Услуги DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                4.2. &nbsp; &nbsp;Внутригрупповые услуги. Вы подтверждаете и принимаете, что
                некоторые Услуги DuneEX могут предоставляться Партнерами DuneEX.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>5. &nbsp; &nbsp;Услуга чата</p>
            <p>
                <br />
            </p>
            <p>
                5.1. &nbsp; &nbsp;Доступность Услуги чата. Мы можем в любое время сделать
                доступной для вас нашу интерактивную услугу онлайн-чата («Услуга чата») в
                связи с использованием вами любой из Услуг DuneEX. Используя Услугу чата,
                вы можете взаимодействовать с ботом, чат-ботом или иной функцией, не
                являющейся человеком. Мы будем раскрывать информацию об использовании
                чат-бота или иной функции, не являющейся человеком, в той степени, в которой
                это требуется в соответствии с Применимым законодательством. Взаимодействуя
                с нами посредством использования Услуги чата, вы уполномочиваете нас
                отслеживать и сохранять ваши чаты.
            </p>
            <p>
                <br />
            </p>
            <p>
                5.2. &nbsp; &nbsp;Важная информация. Услуга чата предоставляется для
                удобства, часто для того, чтобы вам было проще разобраться в Услугах DuneEX . Наша Услуга чата приложит разумные усилия, чтобы предоставить вам точную
                и актуальную информацию в соответствии с вашим вопросом или потребностью.
                Ничто из того, что мы сообщаем в Услуге чата, не может рассматриваться как
                юридическое соглашение, заверение или гарантия в отношении Услуг DuneEX,
                процессов, решений или времени ответа. На любые персональные данные,
                предоставленные нам при использовании Услуги чата, распространяются
                применимые политики и уведомления, связанные с конфиденциальностью и
                описанные в нашем Уведомлении о конфиденциальности.
            </p>
            <p>
                <br />
            </p>
            <p>
                5.3. &nbsp; &nbsp;Чаты пользователей. Кроме того, мы можем сделать
                доступными для вас чаты, которые позволят вам напрямую взаимодействовать с
                другими пользователями Платформы («Чат пользователей»).
            </p>
            <p>
                <br />
            </p>
            <p>
                5.4. &nbsp; &nbsp;Запрещенные действия. Запрещается использовать Услугу чата
                или любой Чат пользователей для отправки оскорбительных, клеветнических,
                недобросовестных или непристойных сообщений; сообщений, направленных на
                манипулирование рынком или распространение ложной или вводящей в заблуждение
                информации; сообщений, которые иным образом противоречат Применимому
                законодательству. Такие действия могут привести к прекращению сеанса Услуги
                чата и ограничению вашего доступа к Услугам DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>6. &nbsp; &nbsp;Комиссия</p>
            <p>
                <br />
            </p>
            <p>
                6.1. &nbsp; &nbsp;Оплата комиссий. Вы соглашаетесь оплачивать все применимые
                комиссии в связи с использованием вами Услуг DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы уполномочиваете нас вычитать все применимые комиссии, сборы, проценты,
                платежи и другие суммы, которые вы должны, из вашего Аккаунта DuneEX в
                соответствии с настоящими Условиями или любыми Условиями продукта, используя
                метод расчета. Если вы должны нам сумму в одном Цифровом активе, но у вас
                недостаточно средств в этом Цифровом активе, мы можем вычесть сумму
                задолженности в другом Цифровом активе для осуществления платежа (в этом
                случае мы конвертируем имеющийся у вас Цифровой актив в причитающийся нам
                Цифровой актив (или эквивалент в Фиатной валюте) по актуальному курсу на
                Платформе или по другому коммерчески обоснованному курсу по нашему
                усмотрению). Если в вашем Аккаунте DuneEX недостаточно Цифровых активов,
                вы принимаете, что любая сумма, которую вы должны выплатить в соответствии с
                настоящим пунктом, является долгом, и вы обязуетесь немедленно выплатить его
                нам коммерчески обоснованным образом в размере и форме (в виде Цифровых
                активов или иных активов), определяемых по нашему усмотрению.
            </p>
            <p>
                <br />
            </p>
            <p>
                6.2. &nbsp; &nbsp;Изменение наших комиссий. Мы можем периодически
                корректировать наши комиссии в соответствии с п. ‎18.3 настоящих Условий.
            </p>
            <p>
                <br />
            </p>
            <p>ИНФОРМАЦИЯ О ВАШЕМ АККАУНТЕ DuneEX</p>
            <p>
                <br />
            </p>
            <p>7. &nbsp; &nbsp;Создание Аккаунта DuneEX</p>
            <p>
                <br />
            </p>
            <p>
                7.1. &nbsp; &nbsp;Создание Аккаунта. Для получения доступа к Услугам DuneEX  и Платформы вы должны создать и сохранять Аккаунт DuneEX. Это может
                быть Аккаунт DuneEX для физического лица или Корпоративный аккаунт DuneEX  для корпорации, юридического лица или иной организации.
            </p>
            <p>
                <br />
            </p>
            <p>
                Все Аккаунты DuneEX предоставляются по нашему абсолютному усмотрению. Мы
                оставляем за собой право без причины отклонить любую заявку на создание
                Аккаунта DuneEX или ограничить количество Аккаунтов DuneEX, которое вы
                можете иметь.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                7.2. &nbsp; &nbsp;Один бенефициар. Создавая Аккаунт DuneEX, вы признаете,
                что:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;если вы являетесь индивидуальным пользователем, вы будете
                использовать свой Аккаунт DuneEX только для себя, а не от имени третьих
                лиц, если только вы не получили наше предварительное письменное
                согласие;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;если вы являетесь корпоративным пользователем, ваши
                Разрешенные пользователи будут использовать Корпоративный аккаунт DuneEX
                только в ваших интересах, а не от имени третьих лиц, если только вы не
                получили наше предварительное письменное согласие.
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы несете полную ответственность за все действия, которые происходят в вашем
                Аккаунте DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                7.3. &nbsp; &nbsp;Верификация личности. Прежде чем вы получите разрешение создать Аккаунт DuneEX, получить доступ к Услугам DuneEX и пользоваться ими, вы должны будете пройти процедуру верификации личности, предоставив нам определенную информацию о себе и, в соответствующих случаях, о своих Разрешенных пользователях. Вся предоставленная вами информация должна быть полной, точной и правдивой. Если эта информация изменится, вы должны будете незамедлительно ее обновить. При отсутствии верификации счета, могу применяться ограничения
                (лимит вывода средств), для вас будет направлено письмо с разъяснением.
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы уполномочиваете нас делать запросы, напрямую или через третьих лиц,
                которые мы считаем необходимыми для верификации вашей личности и личности
                любых Разрешенных пользователей или для защиты вас и/или нас от
                мошенничества, отмывания денег, финансирования терроризма или других
                финансовых преступлений, а также предпринимать любые действия, которые мы
                сочтем необходимыми на основании результатов таких запросов.
            </p>
            <p>
                <br />
            </p>
            <p>
                Когда мы делаем запросы, вы подтверждаете и принимаете, что ваши
                персональные данные и персональные данные любых Разрешенных пользователей
                могут быть раскрыты агентствам по верификации личности, регистрации данных о
                соответствии, кредитным историям, предотвращению мошенничества или борьбе с
                финансовыми преступлениями, и что эти агентства могут отвечать на наши
                запросы в полном объеме.
            </p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                7.4. &nbsp; &nbsp;Расширенная проверка. Мы также можем потребовать от вас
                прохождения расширенной проверки путем указания дополнительной информации о
                вас, вашем бизнесе или ваших Разрешенных пользователях, предоставления
                дополнительных записей или документов или проведения личных встреч с
                представителями DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                7.5. &nbsp; &nbsp;Записи. Мы храним ваши персональные данные для того, чтобы
                вы могли продолжать пользоваться Услугами DuneEX. Данные хранятся до тех
                пор, пока они необходимы для выполнения соответствующих целей, описанных в
                настоящем Уведомлении о конфиденциальности, а также в соответствии с
                требованиями закона, например для целей налогообложения и бухгалтерского
                учета, соблюдения законов по борьбе с отмыванием денег или других целей,
                сообщенных вам.
            </p>
            <p>
                7.6. &nbsp; &nbsp;Субаккаунты. По нашему усмотрению вы можете создать
                Субаккаунт DuneEX и получить к нему доступ. На каждое физическое лицо,
                связанное с Субаккаунтом DuneEX, распространяются требования по
                верификации личности, изложенные в данном п. ‎7. Один Субаккаунт DuneEX
                может быть связан только с одним физическим лицом или корпорацией.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>8. &nbsp; &nbsp;Запросы информации</p>
            <p>
                <br />
            </p>
            <p>
                8.1. &nbsp; &nbsp;Когда мы можем запросить информацию. Мы можем потребовать
                от вас информацию в любое время в целях соблюдения любого Применимого
                законодательства или требований верификации личности, или в связи с
                выявлением отмывания денег, финансирования терроризма, мошенничества или
                любого другого финансового преступления, или по любой другой уважительной
                причине. Вы соглашаетесь предоставить нам любую запрашиваемую нами
                информацию и разрешить нам хранить записи об этой информации в течение всего
                срока существования вашего Аккаунта DuneEX и до тех пор, пока это
                необходимо для выполнения поставленных целей, или в течение другого срока,
                предусмотренного Применимым законодательством.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                8.2. &nbsp; &nbsp;Что происходит, когда вы предоставляете информацию. Ваш
                доступ к Аккаунту DuneEX и лимиты Транзакций, которые применяются к
                использованию вами Услуг DuneEX, могут быть изменены в результате
                получения информации, собираемой о вас на постоянной основе. Если есть
                обоснованные подозрения, что какая-либо предоставленная вами информация
                неверная, неправдивая, устаревшая или неполная, мы можем направить вам
                уведомление с просьбой внести исправления, удалить соответствующую
                информацию или совершить иные действия, которые мы считаем необходимыми для
                получения от вас достоверной и точной информации.
            </p>
            <p>
                <br />
            </p>
            <p>
                8.3. &nbsp; &nbsp;Если вы не предоставите запрашиваемую информацию. Вы
                обязаны предоставить нам любую информацию по нашему запросу. Если вы
                откажетесь предоставить запрашиваемую информацию или иным образом не
                выполните требования своевременно, мы оставляем за собой право немедленно
                приостановить или прекратить ваш доступ к Аккаунту DuneEX или ко всем или
                части Услуг DuneEX без уведомления.
            </p>
            <p>
                <br />
            </p>
            <p>9. &nbsp; &nbsp;Доступ к вашему Аккаунту DuneEX</p>
            <p>
                <br />
            </p>
            <p>
                9.1. &nbsp; &nbsp;Доступ. Для доступа к вашему Аккаунту DuneEX вы или, в
                соответствующих случаях, ваши Разрешенные пользователи должны иметь
                необходимое оборудование (например, компьютер или смартфон) и доступ к
                интернету. Доступ к вашему Аккаунту DuneEX можно получить непосредственно
                на Платформе или с помощью другого средства доступа (включая API), которое
                мы можем предоставить. Вы имеете право войти и использовать ваш Аккаунт
                DuneEX только с помощью Идентификаторов доступа, которые мы предоставляем
                вам или вашим Разрешенным пользователям для этих целей. Для обеспечения
                безопасности вашего Аккаунта DuneEX мы можем потребовать подключить
                многофакторную аутентификацию.
            </p>
            <p>
                <br />
            </p>
            <p>
                На использование Платформы и других средств доступа могут распространяться
                дополнительные условия, о которых мы вам сообщим.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                9.2. &nbsp; &nbsp;Ограничение доступа третьих лиц. Вы должны гарантировать,
                чтобы любой(-ые) Аккаунт(ы) DuneEX, зарегистрированный(-ые) на ваше имя,
                будет(-ут) использоваться только вами или, в случае Корпоративных аккаунтов
                DuneEX, вашими Разрешенными пользователями, если иное не предусмотрено
                настоящими Условиями.
            </p>
            <p>
                <br />
            </p>
            <p>10. &nbsp; &nbsp;Информация об Аккаунте и записи о Транзакциях</p>
            <p>
                <br />
            </p>
            <p>
                10.1. &nbsp; &nbsp;Ваша История аккаунта. Вы сможете получить доступ к вашей
                Истории аккаунта на Платформе. Вы должны внимательно изучить вашу Историю
                аккаунта и сообщить нам, если увидите какие-либо записи или Транзакции,
                которые вы не помните или считаете неправильными, в течение четырнадцати
                (14) календарных дней с даты предоставления или обеспечения вам доступа к
                вашей Истории аккаунта.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                10.2. &nbsp; &nbsp;Ошибки. Мы можем исправить любую ошибку в вашей Истории
                аккаунта в любое время и оставляем за собой право аннулировать или отменить
                любую Транзакцию, включающую или вытекающую из Явной ошибки, или изменить
                детали такой Транзакции, чтобы отразить то, что мы разумно считаем
                правильными или справедливыми деталями такой транзакции без Явной ошибки.
                Без ущерба для общего характера других положений настоящих Условий и при
                отсутствии мошеннических или недобросовестных действий, DuneEX ни при
                каких обстоятельствах не несет ответственности перед вами за любые убытки
                или требования любого характера, которые вы можете понести или с которыми
                можете столкнуться в связи с любой Явной ошибкой. Они могут иметь любую
                причину, быть прямыми или косвенными, специальными или последовательными,
                включая, помимо прочего, потерю прибыли или потерю возможностей, даже если
                DuneEX была предупреждена о возможности возникновения таких убытков или
                их можно было разумно предвидеть.
            </p>
            <p>
                <br />
            </p>
            <p>
                10.3. &nbsp; &nbsp;Передача информации. В соответствии с Применимым
                законодательством мы можем быть обязаны передавать информацию о вашем
                Аккаунте DuneEX и Истории аккаунта третьим лицам. Вы подтверждаете и
                принимаете, что мы имеем право раскрывать такую информацию.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>ИСПОЛЬЗОВАНИЕ ВАШЕГО АККАУНТА</p>
            <p>
                <br />
            </p>
            <p>11. &nbsp; &nbsp;Инструкции</p>
            <p>
                <br />
            </p>
            <p>
                11.1. &nbsp; &nbsp;Отправка Указаний. Вы должны обеспечить полноту и
                точность любого отправляемого Указания. Мы не обязаны проверять точность,
                подлинность или действительность любого Указания и не будем отслеживать или
                отклонять Указания на основании того, что они являются или кажутся
                дубликатами. Однако если у нас есть сомнения в точности, подлинности или
                действительности Указания, мы можем отказать в исполнении или отложить
                исполнение любого Указания, или запросить дополнительную информацию в
                отношении Указания.
            </p>
            <p>
                <br />
            </p>
            <p>
                Указания являются безотзывными, поэтому после отправки Указания вы или ваши
                Разрешенные пользователи не имеют права отменить или отозвать его без нашего
                письменного согласия. Ваше Указание не считается полученным нами до тех пор,
                пока оно не будет получено нашим сервером. Наша запись всех Указаний будет
                окончательной и наложит на вас обязательства для всех целей.
            </p>
            <p>
                <br />
            </p>
            <p>
                11.2. &nbsp; &nbsp;Действия по вашим Указаниям. Отправляя Указание, вы или
                ваши Разрешенные пользователи уполномочиваете нас инициировать Транзакцию в
                вашем Аккаунте DuneEX. Таким образом, мы уполномочены зачислить или
                списать (или предоставить информацию о расчетах третьим лицам для зачисления
                или списания третьими лицами) ваши Цифровые активы на или из вашего Аккаунта
                DuneEX в соответствии с вашим Указанием. Если в вашем Аккаунте DuneEX
                недостаточно Цифровых активов или Фиатной валюты для осуществления
                Транзакции (т. е. меньше суммы, необходимой для расчета по Транзакции и
                оплаты всех комиссий, связанных с Транзакцией), мы имеем право отказать в
                осуществлении Транзакции. Вы несете ответственность за хранение достаточного
                количества Цифровых активов или Фиатной валюты в Аккаунте DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                11.3. &nbsp; &nbsp;Защита Указаний. Вы осознаете, что Указания и информация,
                передаваемые на Платформе или по электронной почте, обычно передаются через
                интернет и могут быть направлены через публичные, транснациональные сети,
                которые не имеют специальной защиты. Мы не можем гарантировать, что
                передаваемые Указания и информация будут полностью защищены от
                несанкционированного доступа, и вы принимаете связанные с этим риски.
            </p>
            <p>
                <br />
            </p>
            <p>
                11.4. &nbsp; &nbsp;Вывод средств. В соответствии с настоящими Условиями и
                любыми применимыми Условиями продукта, а также при условии, что на Аккаунте
                DuneEX имеется достаточный баланс и соответствующие Цифровые активы не
                удерживаются на вашем Аккаунте DuneEX в связи с использованием какой-либо
                услуги DuneEX, вы можете дать инструкции DuneEX по переводу Цифровых
                активов на адрес внешнего кошелька, отправив запрос на вывод средств на
                Платформе. После получения запроса на вывод средств DuneEX: вычтет
                остаток средств с вашего Аккаунта DuneEX; и инициирует ончейн-перевод на
                указанный вами внешний кошелек. DuneEX может не обработать запрос на
                вывод средств, если, по нашему обоснованному мнению, Применимое
                законодательство препятствует выполнению соответствующего вывода средств.
                DuneEX также может приостановить вывод средств в то время, которое сочтет
                необходимым для устранения любых инцидентов на Платформе. Как только такие
                инциденты будут устранены, DuneEX возобновит вывод средств.
            </p>
            <p>
                <br />
            </p>
            <p>12. &nbsp; &nbsp;Транзакции</p>
            <p>
                <br />
            </p>
            <p>
                12.1. &nbsp; &nbsp;Заключение Транзакций. Вы можете заключать Транзакции
                напрямую с нами или напрямую с другими пользователями, при нашем содействии
                или без него.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Мы не заявляем и не гарантируем, что любая Транзакция будет завершена
                успешно или в течение определенного периода времени.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                12.2. &nbsp; &nbsp;Несанкционированные Транзакции. Вы несете ответственность
                за контроль и использование вашего Аккаунта DuneEX. Таким образом, мы
                будем считать, что вы или Разрешенный пользователь санкционировали любое
                Указание, отправленное с вашего Аккаунта DuneEX, если нас не уведомят об
                обратном. Вам необходимо отслеживать свою Историю аккаунта, чтобы как можно
                скорее выявлять и сообщать нам о любых несанкционированных или
                подозрительных действиях в вашем Аккаунте DuneEX. Мы не несем
                ответственности за любые претензии или убытки, возникшие в результате
                Транзакции, совершенной в результате несанкционированного Указания, если вы
                не уведомили нас в соответствии с данным пунктом.
            </p>
            <p>
                <br />
            </p>
            <p>
                12.3. &nbsp; &nbsp;Хранение информации о Транзакциях. Для обеспечения
                соответствия мировым отраслевым стандартам хранения данных вы соглашаетесь
                разрешить нам (но не требовать от нас) хранить запись всей информации о
                Транзакциях в течение всего срока действия вашего Аккаунта DuneEX, пока
                это необходимо для выполнения их предполагаемых целей, или в течение другого
                периода, предусмотренного Применимым законодательством.
            </p>
            <p>13. &nbsp; &nbsp;Материальные интересы и конфликты</p>
            <p>
                <br />
            </p>
            <p>
                13.1. &nbsp; &nbsp;Группа DuneEX. Вы понимаете, что DuneEX является
                членом группы компаний, которая занимается деятельностью, связанной с
                Цифровыми активами.
            </p>
            <p>
                <br />
            </p>
            <p>
                13.2. &nbsp; &nbsp;Характер наших обязанностей. Вы понимаете и принимаете,
                что ни ваши отношения с нами, ни любая Услуга DuneEX, предоставляемая
                нами вам, ни любая другая причина не приведут к возникновению каких-либо
                обязанностей с нашей стороны или со стороны любого Партнера DuneEX, будь
                то юридические, основанные на справедливости или фидуциарные обязанности,
                кроме тех, которые прямо указаны в настоящих условиях. В частности, мы и
                Партнеры DuneEX можем периодически выступать более чем в одном качестве,
                и в этих качествах мы можем получать комиссии или сборы от более чем одного
                пользователя (включая вас). Вы принимаете, что мы можем выступать в таких
                качествах и предоставлять любые другие Услуги DuneEX или вести любые дела
                с вами, любым Партнером DuneEX или любым другим пользователем.
            </p>
            <p>
                <br />
            </p>
            <p>
                13.3. &nbsp; &nbsp;Материальные интересы. Вы понимаете и принимаете, что ни
                мы, ни Партнеры DuneEX не обязаны: (1) учитывать какую-либо известную нам
                или Партнеру DuneEX информацию, которая представляет собой материальный
                интерес; (2) раскрывать вам такую информацию; или (3) использовать такую
                информацию в ваших интересах. Вы также признаете, что в ходе предоставления
                вам Услуг DuneEX мы можем периодически получать общую информацию о рынке,
                которую мы можем использовать в ходе нашей обычной деятельности.
            </p>
            <p>
                <br />
            </p>
            <p>
                13.4. &nbsp; &nbsp;Конфликты интересов. Мы создали и поддерживаем
                эффективные организационные и административные механизмы с целью принятия
                всех необходимых мер по выявлению и урегулированию конфликтов интересов
                между нами, нашими пользователями и соответствующими третьими лицами, чтобы
                предотвратить возникновение конфликтов интересов, негативно влияющих на
                интересы наших пользователей. В тех случаях, когда таких организационных и
                административных мер недостаточно для предотвращения рисков нанесения ущерба
                вашим интересам, мы проинформируем вас о характере и/или источниках
                соответствующих конфликтов интересов и мерах, принятых для снижения этих
                рисков, чтобы вы могли принять обоснованное решение о том, продолжать ли вам
                совершать сделки с нами. Мы оставляем за собой право в любое время
                отказаться действовать в ваших интересах, если мы не можем урегулировать
                конфликт интересов каким-либо другим способом.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>14. &nbsp; &nbsp;Лимиты Транзакций&nbsp;</p>
            <p>
                <br />
            </p>
            <p>
                14.1. &nbsp; &nbsp;Ваши лимиты Транзакции. На ваш Аккаунт DuneEX может
                быть наложен лимит на:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;количество или объем Транзакций, которые вы можете совершать
                в связи с вашим Аккаунтом DuneEX; и/или
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;количество или стоимость Фиатной валюты или Цифровых
                активов, которые вы можете перевести на свой Аккаунт DuneEX или из
                него,&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                в каждом случае за определенный период (например, за день). Все применимые
                лимиты отображаются в вашем Аккаунте DuneEX.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                14.2. &nbsp; &nbsp;Изменения в лимитах Транзакций. Мы оставляем за собой
                право изменить любой лимит Транзакций, применяемый к вашему Аккаунту DuneEX , в любое время по своему абсолютному усмотрению. Вы также можете
                запросить изменение ваших лимитов. Любое изменение будет произведено по
                нашему абсолютному усмотрению и будет зависеть от любых дополнительных
                условий, которые мы сочтем необходимыми.
            </p>
            <p>
                <br />
            </p>
            <p>15. &nbsp; &nbsp;Поддерживаемые цифровые активы</p>
            <p>
                <br />
            </p>
            <p>
                15.1. &nbsp; &nbsp;Поддерживаемые цифровые активы. Услуги DuneEX доступны
                только в связи с Поддерживаемыми цифровыми активами, которые могут
                периодически меняться. Список Поддерживаемых цифровых активов публикуется на
                нашем Сайте. Мы можем удалить или приостановить действие одного или
                нескольких Цифровых активов из списка Поддерживаемых цифровых активов. Мы
                предпримем разумные коммерческие усилия, чтобы уведомить вас об этом
                заранее. После удаления у вас больше не будет доступа к таким Цифровым
                активам в рамках Услуг DuneEX, и вы сможете только вывести Цифровые
                активы со своего Аккаунта DuneEX. Если Цифровые активы, которые больше не
                являются Поддерживаемыми цифровыми активами, остаются на вашем Аккаунте
                DuneEX после указанного срока, о котором мы вам сообщили, DuneEX может
                по своему разумному усмотрению конвертировать такие Цифровые активы в другой
                тип Цифровых активов — стейблкоины. DuneEX заблаговременно отправит
                уведомление о такой конвертации, и вы сможете вывести Цифровые активы в
                течение разумного срока, указанного DuneEX, до их конвертации в
                стейблкоины.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Мы не несем никакой ответственности в связи с любыми попытками использовать
                ваш Аккаунт DuneEX для неподдерживаемых Цифровых активов, а также в связи
                с конвертацией в другой тип цифровых активов, как описано в настоящем
                пункте.
            </p>
            <p>
                <br />
            </p>
            <p>
                15.2. &nbsp; &nbsp;Форки. Мы можем временно приостановить любые Услуги
                DuneEX в отношении конкретного Цифрового актива, пока мы принимаем
                решение о поддержке Форка. Мы не обязаны поддерживать Форк цифрового актива,
                который вы храните в Аккаунте DuneEX, независимо от того, станет ли
                Прошедший форк цифровой актив Доминирующим цифровым активом или нет. Если мы
                решим поддержать Форк цифрового актива, мы сделаем публичное заявление на
                Сайте или другими способами, которые сочтем необходимыми.
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы принимаете, что мы не контролируем и не имеем возможности влиять на
                создание или внедрение Форка. Мы не можем предоставить никаких гарантий
                относительно безопасности, функциональности или предложения любого Цифрового
                актива, включая как новый Доминирующий цифровой актив, так и другие Цифровые
                активы, связанные с соответствующим Форком. В некоторых случаях вы не
                сможете торговать на Платформе Прошедшими форк цифровыми активами и можете
                потерять любую стоимость соответствующих Цифровых активов.
            </p>
            <p>
                <br />
            </p>
            <p>
                15.3. &nbsp; &nbsp;Обеспеченные цифровые активы. Мы можем периодически
                поддерживать Цифровые активы, которые предположительно обеспечены или иным
                образом привязаны по стоимости к другому активу, включая Цифровые активы,
                Фиатную валюту или товары, такие как серебро или золото («Обеспеченные
                цифровые активы»). Вы соглашаетесь, что прочитали, поняли и приняли все
                условия и риски, связанные с каждым конкретным Обеспеченным цифровым
                активом, до заключения любой Транзакции, связанной с таким Обеспеченным
                цифровым активом. Мы не обязаны приобретать, повторно приобретать,
                осуществлять или содействовать погашению ваших Обеспеченных цифровых
                активов. Мы оставляем за собой право изменять, приостанавливать или
                прекращать любую услугу в отношении любого Обеспеченного цифрового актива.
                Мы не делаем никаких заявлений относительно того, сохранит ли какой-либо
                конкретный Обеспеченный цифровой актив свою стоимость по отношению к
                какому-либо активу, а также относительно объема или качества резервов или
                залога, имеющихся у каждого эмитента или третьей стороны в отношении любого
                Обеспеченного цифрового актива.
            </p>
            <p>
                <br />
            </p>
            <p>16. &nbsp; &nbsp;Безопасность аккаунта</p>
            <p>
                <br />
            </p>
            <p>
                16.1. &nbsp; &nbsp;Ваша ответственность. Вы несете ответственность за
                принятие соответствующих мер по защите вашего оборудования и данных от
                вирусов и вредоносных программ, а также любых неприемлемых материалов. За
                исключением случаев, предусмотренных Применимым законодательством, вы несете
                ответственность за резервное копирование и поддержание дубликатов любой
                информации, которую вы храните или передаете через Услуги DuneEX. Мы не
                несем ответственности за любые претензии или убытки, возникшие в результате
                несоблюдения вами данного пункта.
            </p>
            <p>
                <br />
            </p>
            <p>
                16.2. &nbsp; &nbsp;Меры безопасности. В любое время вы и любые Разрешенные
                пользователи должны обеспечивать надлежащую безопасность и контроль всех
                ваших Идентификаторов доступа. Вы несете ответственность за принятие
                необходимых мер безопасности (или обеспечение принятия таких мер вашими
                Разрешенными пользователями) для защиты вашего Аккаунта DuneEX и
                обеспечения безопасности вашего Идентификатора доступа, в том числе
                следующими способами:
            </p>
            <p>
                <br />
            </p>
            <p>a. &nbsp; &nbsp;строго соблюдайте все наши механизмы и процедуры;</p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;создайте надежный пароль и обеспечивайте безопасность и
                контроль ваших Идентификаторов доступа;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;предоставляйте нам актуальную Электронную почту и номер
                телефона для получения любых уведомлений или предупреждений, которые мы
                можем вам отправлять;
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;никогда не разрешайте удаленный доступ или совместное
                использование вашего компьютера и/или экрана компьютера с кем-либо другим,
                когда выполнен вход в ваш Аккаунт DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                e. &nbsp; &nbsp;помните, что мы ни при каких обстоятельствах не попросим вас
                поделиться своими паролями или кодами двухфакторной аутентификации; и
            </p>
            <p>
                <br />
            </p>
            <p>
                f. &nbsp; &nbsp;выходите из Сайтов или Платформы в конце каждого посещения.
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы должны защищать Электронную почту и Идентификаторы доступа от любых атак
                и несанкционированного доступа. Вы должны немедленно уведомить нас, если вам
                стало известно или у вас есть основания подозревать, что ваша Электронная
                почта или Электронная почта Разрешенного пользователя была взломана, или
                если вашу Электронную почту или Электронную почту Разрешенного пользователя
                использовали несанкционированно.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                16.3. &nbsp; &nbsp;Отслеживание Истории аккаунта. Вам необходимо отслеживать
                свою Историю аккаунта, чтобы как можно скорее выявлять и сообщать нам о
                любых несанкционированных или подозрительных действиях в вашем Аккаунте
                DuneEX. Вы принимаете, что любое Нарушение безопасности может привести к
                несанкционированному доступу третьих лиц к вашему Аккаунту DuneEX и
                потере или краже любых Цифровых активов и/или средств, хранящихся в вашем
                Аккаунте DuneEX и любых связанных с ним аккаунтах, включая банковские
                счета и кредитные карты.
            </p>
            <p>
                <br />
            </p>
            <p>
                16.4. &nbsp; &nbsp;Если вы подозреваете Нарушение безопасности. Если вы
                подозреваете Нарушение безопасности, вы должны убедиться, что:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;мы немедленно уведомлены в соответствии со способами связи,
                указанными в п. ‎3.1, и продолжаем получать точную и актуальную информацию в
                течение всего периода Нарушения безопасности;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;ваш Аккаунт DuneEX немедленно заблокирован с помощью
                функции отключения аккаунта на Платформе или любым другим способом, который
                мы периодически предписываем; и
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;вы предпринимаете любые другие шаги, которые мы можем
                разумно потребовать для уменьшения, контроля или уведомления о любом
                Нарушении безопасности.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Мы оставляем за собой право запрашивать, а вы соглашаетесь предоставлять всю
                информацию и документы, которые мы считаем уместными или необходимыми в
                связи с фактическим или предполагаемым Нарушением безопасности, и можем
                предоставлять такую информацию любой третьей стороне, которую мы считаем
                необходимой для разрешения любого Нарушения безопасности.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>ДРУГАЯ ВАЖНАЯ ИНФОРМАЦИЯ</p>
            <p>
                <br />
            </p>
            <p>17. &nbsp; &nbsp;Конфиденциальность</p>
            <p>
                <br />
            </p>
            <p>
                17.1. &nbsp; &nbsp;Уведомление о конфиденциальности. Сбор и использование
                нами персональных данных в связи с настоящими Условиями, Услугами DuneEX,
                Платформой и любым Сайтом осуществляются в соответствии с нашим Уведомлением
                о конфиденциальности (с периодическими обновлениями). Вы принимаете, что мы
                можем обрабатывать ваши персональные данные, которые вы предоставили нам или
                мы собрали у вас в связи с настоящими Условиями и в соответствии с
                Уведомлением о конфиденциальности. Ваши персональные данные будут
                обрабатываться в соответствии с Уведомлением о конфиденциальности, которое
                является частью настоящих Условий.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>Вы заявляете и гарантируете, что:&nbsp;</p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                a. вы подтверждаете, что прочитали и поняли наше Уведомление о
                конфиденциальности.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. наш бизнес регулярно меняется, и наше Уведомление о конфиденциальности
                также будет меняться. Поэтому если мы периодически будем предоставлять вам
                заменяющую версию Уведомления о конфиденциальности, вы должны
                незамедлительно ознакомиться с Уведомлением о конфиденциальности.
                &nbsp;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                18. &nbsp; &nbsp;Внесение изменений в настоящие Условия и другие положения
            </p>
            <p>
                <br />
            </p>
            <p>
                18.1. &nbsp; &nbsp;Как и когда мы можем вносить изменения. Мы можем вносить
                изменения в настоящие Условия и любые упоминаемые положения и условия
                (включая любые Условия продукта) в любое время, и ваше принятие настоящих
                Условий означает ваше прямое согласие. Мы сообщим вам о любых изменениях по
                электронной почте или через наш Сайт.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                О большинстве изменений мы сообщим заранее до вступления изменений в силу.
                Однако иногда нам может потребоваться внести изменения без предварительного
                уведомления. Это может произойти в следующих случаях:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;мы вносим изменения в результате законодательных и/или
                нормативных изменений;
            </p>
            <p>
                <br />
            </p>
            <p>b. &nbsp; &nbsp;вносимые изменения отвечают вашим интересам;</p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;изменения направлены на то, чтобы сделать настоящие Условия
                более понятными для вас; и/или
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;существует любая другая уважительная причина, в рамках
                которой у нас нет времени на уведомление.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Если мы не можем уведомить вас заранее, мы сообщим вам об изменениях как
                можно скорее после их внесения.
            </p>
            <p>
                <br />
            </p>
            <p>
                18.2. &nbsp; &nbsp;Когда изменения вступают в силу. За исключением случаев,
                когда изменения вступают в силу немедленно, обновленные Условия вступают в
                силу после того, как мы отправили вам уведомление. Если вы не желаете
                принимать изменения, вы вправе закрыть ваш Аккаунт DuneEX в соответствии
                с п. ‎19.1 настоящих Условий. Если мы не получим от вас ответа, то будем
                считать, что вы приняли изменения, и ваш дальнейший доступ или использование
                Услуг DuneEX будет считаться принятием обновленных Условий.
            </p>
            <p>
                <br />
            </p>
            <p>19. &nbsp; &nbsp;Закрытие Аккаунта DuneEX&nbsp;</p>
            <p>
                <br />
            </p>
            <p>
                19.1. &nbsp; &nbsp;Ваше право закрыть свой Аккаунт DuneEX. Вы можете
                закрыть свой Аккаунт DuneEX в любое время, следуя процедуре прекращения
                работы аккаунта, которую мы периодически предписываем. С вас не будет
                взиматься плата за закрытие вашего Аккаунта DuneEX, однако вы должны
                будете выплатить все непогашенные суммы, причитающиеся нам. Вы
                уполномочиваете нас отменить или приостановить любые незавершенные
                транзакции на момент закрытия, а также вычесть любые непогашенные суммы,
                которые вы должны нам, из вашего Аккаунта DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                В некоторых случаях вы не сможете закрыть свой Аккаунт DuneEX, в том
                числе если:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;вы пытаетесь уклониться от расследования соответствующих
                органов;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;у вас есть незавершенная Транзакция или открытая Претензия;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;в вашем Аккаунте DuneEX есть какие-либо непогашенные
                суммы, причитающиеся нам; или
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;на ваш Аккаунт DuneEX накладывается заморозка, удержание,
                ограничение или резервирование.
            </p>
            <p>
                <br />
            </p>
            <p>
                19.2. &nbsp; &nbsp;Что происходит, когда ваш Аккаунт DuneEX закрывается.
                Если ваш аккаунт DuneEX закрывается, вы должны будете вывести все
                Цифровые активы, находящиеся на вашем Аккаунте DuneEX. Если вы не
                выведете свои цифровые активы или не будете входить в аккаунт DuneEX в
                течение 90 дней, мы направим вам уведомление о нашем намерении рассматривать
                ваш аккаунт как неактивный.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                19.3. &nbsp; &nbsp;Что происходит, когда ваш аккаунт становится неактивным.
                Если вы не ответите на уведомление в п. 19.2 в течение 30 дней, мы можем:
            </p>
            <p>
                <br />
            </p>
            <p>a. &nbsp; &nbsp;пометить ваш Аккаунт DuneEX как неактивный;</p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;конвертировать Цифровые активы в другой тип Цифровых
                активов. При этом мы не несем ответственности за упущенную прибыль,
                налоговые обязательства или любые другие убытки, ущерб или расходы,
                понесенные вами в результате такой конвертации;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;перевести такой неактивный аккаунт и/или любые Цифровые
                активы, находящиеся на этом аккаунте, третьей стороне (включая, помимо
                прочего, другого Партнера DuneEX, любого стороннего кастодиала или
                изолированный кошелек), если &nbsp;мы сочтем это разумно необходимым. Если
                это произойдет, вы имеете право получить свои цифровые активы при условии
                выполнения разумных требований по верификации (наших или соответствующей
                третьей стороны) &nbsp;и любых других применимых условий и положений;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;скорректировать ваш неактивный аккаунт таким образом, чтобы
                вы получили договорное требование на количество и тип Цифровых активов,
                которые находились на вашем Аккаунте DuneEX до его перевода в неактивный
                статус;
            </p>
            <p>
                <br />
            </p>
            <p>
                e. &nbsp; &nbsp;взимать комиссию за неактивный аккаунт, которая может
                покрывать расходы на поддержание Цифровых активов &nbsp;любым Партнером
                DuneEX или любой третьей стороной, при этом такая комиссия должна
                ежемесячно взиматься непосредственно с неактивного аккаунта; и
            </p>
            <p>
                <br />
            </p>
            <p>f. &nbsp; &nbsp;закрыть неактивный аккаунт в любое время.</p>
            <p>
                <br />
            </p>
            <p>
                После закрытия неактивного аккаунта такой аккаунт нельзя восстановить. Если
                вы хотите продолжать пользоваться Услугами DuneEX, вам необходимо
                зарегистрировать новый Аккаунт DuneEX в соответствии с настоящими
                условиями. Вы принимаете, что, в соответствии с применимыми Условиями
                продукта, мы не обязаны выплачивать вознаграждение, поощрение или проценты,
                которые мы могли бы согласиться выплатить в противном случае, на ваш
                неактивный аккаунт в отношении зачисленных на него Цифровых активов.
            </p>
            <p>
                <br />
            </p>
            <p>20. &nbsp; &nbsp;Прекращение, приостановление, удержание и ограничения</p>
            <p>
                <br />
            </p>
            <p>
                20.1. &nbsp; &nbsp;Наше право. Мы можем в любое время изменять или
                прекращать предоставлять, временно или постоянно, любую часть или функцию
                Услуг DuneEX. В частности, мы можем: (1) отказать в завершении или
                заблокировать, отменить или, если это разрешено Применимым
                законодательством, вернуть любую Транзакцию, которую вы запустили; (2)
                прекратить, приостановить или ограничить ваш доступ к любой или всем Услугам
                DuneEX; (3) прекратить, приостановить, закрыть, удержать или ограничить
                ваш доступ к любым или всем вашим Аккаунтам DuneEX; (4) отказать в
                передаче информации или Указаний третьим лицам (включая, но не
                ограничиваясь, операторов кошельков третьих лиц); и /или (5) предпринять
                любые действия, которые мы считаем необходимыми, в каждом случае с
                немедленным вступлением в силу и по любой причине, включая, помимо прочего,
                следующие случаи:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;вы не имеете или более не имеете права пользоваться одной
                или несколькими Услугами DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>b. &nbsp; &nbsp;мы разумно подозреваем, что:</p>
            <p>
                <br />
            </p>
            <p>
                I. &nbsp; &nbsp;в ваш Аккаунт DuneEX вошли не вы или ваш Аккаунт DuneEX  был или будет использован для каких-либо незаконных, мошеннических или
                несанкционированных целей;
            </p>
            <p>
                <br />
            </p>
            <p>
                II. &nbsp; &nbsp;в ваш Корпоративный аккаунт DuneEX вошел не Разрешенный
                пользователь или ваш Корпоративный аккаунт DuneEX был или будет
                использован для каких-либо незаконных, мошеннических или несанкционированных
                целей;
            </p>
            <p>
                <br />
            </p>
            <p>
                III. &nbsp; &nbsp;более одного физического лица имеют доступ к одному
                Аккаунту DuneEX и/или совершают операции в одном Аккаунте DuneEX или
                Аккаунт DuneEX был или будет использован для каких-либо незаконных,
                мошеннических или несанкционированных целей;
            </p>
            <p>
                <br />
            </p>
            <p>
                IV. &nbsp; &nbsp;вы предоставили неверную, неправдивую, устаревшую или
                неполную информацию;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;у нас есть разумные опасения в отношении вашей
                кредитоспособности или финансового положения, включая:
            </p>
            <p>
                <br />
            </p>
            <p>
                I. &nbsp; &nbsp;если вы являетесь физическим лицом и становитесь банкротом,
                страдаете психическим расстройством, инициируете процедуру банкротства, или
                против вас возбуждено дело о банкротстве;
            </p>
            <p>
                <br />
            </p>
            <p>
                II. &nbsp; &nbsp;если вы действуете от имени партнерства и любой из
                партнеров умирает, становится банкротом, страдает психическим расстройством
                или инициирует процедуру банкротства, или против любого из партнеров
                возбуждено дело о банкротстве, или начат процесс роспуска и/или изменения
                партнеров или устава партнерства;
            </p>
            <p>
                <br />
            </p>
            <p>
                III. &nbsp; &nbsp;если вы действуете от имени корпорации и корпорация не в
                состоянии оплатить свои долги в установленные сроки, или корпорация
                участвует в процессе неплатежеспособности, судебного управления, внешнего
                управления, административного управления или любой подобной или аналогичной
                процедуры;
            </p>
            <p>
                <br />
            </p>
            <p>
                IV. &nbsp; &nbsp;вы созываете собрание своих кредиторов или предлагаете или
                заключаете какой-либо компромисс или соглашение с кредиторами или какое-либо
                поручение в пользу своих кредиторов;
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;ожидается предоставление информации и документов в
                соответствии с п. ‎8;
            </p>
            <p>
                <br />
            </p>
            <p>
                e. &nbsp; &nbsp;ожидается прохождение расширенной &nbsp;проверки в
                соответствии с п. ‎7.4;
            </p>
            <p>
                <br />
            </p>
            <p>
                f. &nbsp; &nbsp;мы разумно считаем, что этого от нас требует Применимое
                законодательство или любой суд или орган, которому мы подчиняемся в любой
                юрисдикции;
            </p>
            <p>
                <br />
            </p>
            <p>g. &nbsp; &nbsp;мы выявили или подозреваем, что:</p>
            <p>
                <br />
            </p>
            <p>
                I. &nbsp; &nbsp;вы нарушили настоящие Условия или любые Условия продукта;
            </p>
            <p>
                <br />
            </p>
            <p>
                II. &nbsp; &nbsp;вы нарушили какие-либо явные или подразумеваемые гарантии,
                содержащиеся в настоящих Условиях, или какие-либо сделанные вами заявления;
            </p>
            <p>
                <br />
            </p>
            <p>
                III. &nbsp; &nbsp;какая-либо Транзакция является несанкционированной,
                ошибочной, мошеннической или незаконной, или мы выявили или подозреваем, что
                ваш Аккаунт DuneEX или Услуги DuneEX используются мошенническим,
                несанкционированным или незаконным образом;
            </p>
            <p>
                <br />
            </p>
            <p>
                IV. &nbsp; &nbsp;произошло отмывание денег, финансирование терроризма,
                мошенничество или любое другое преступление в связи с вашим Аккаунтом DuneEX  или использованием вами Услуг DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                h. &nbsp; &nbsp;использование вашего Аккаунта DuneEX является предметом
                какого-либо предстоящего, текущего или потенциального судебного
                разбирательства, расследования или судебного, правительственного или
                нормативного разбирательства и/или мы подозреваем повышенный риск
                несоблюдения правовых или нормативных требований, связанных с действиями в
                вашем Аккаунте DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                i. &nbsp; &nbsp;вы должны DuneEX непогашенные суммы, будь то в связи с
                возвратом платежа или на любом другом основании;
            </p>
            <p>
                <br />
            </p>
            <p>
                j. &nbsp; &nbsp;любое сообщение электронной почты, направленное на вашу
                Электронную почту, возвращается как недоставленное;
            </p>
            <p>
                <br />
            </p>
            <p>k. &nbsp; &nbsp;возникла проблема с верификацией вашей личности;</p>
            <p>
                <br />
            </p>
            <p>
                l. &nbsp; &nbsp;вы предприняли какие-либо действия, которые могут обойти наш
                контроль, например открытие нескольких Аккаунтов DuneEX без нашего
                письменного согласия или злоупотребление акциями, которые мы можем
                периодически предлагать; или
            </p>
            <p>
                <br />
            </p>
            <p>
                m. &nbsp; &nbsp;существует любая другая уважительная причина, в рамках
                которой нам необходимо это сделать.
            </p>
            <p>
                <br />
            </p>
            <p>
                Мы предпримем разумные шаги, чтобы отправить вам соответствующее
                уведомление. Однако могут быть случаи, когда мы обязаны не делать этого в
                соответствии с Применимым законодательством.
            </p>
            <p>
                <br />
            </p>
            <p>20.2. &nbsp; &nbsp;Ваше принятие. Вы подтверждаете и принимаете, что:</p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;в п. ‎20.1 перечислен неполный перечень случаев, когда мы
                можем предпринять действия по прекращению, приостановке, закрытию или
                ограничению вашего доступа к Аккаунту DuneEX и/или Услугам DuneEX; и
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;наше решение предпринять определенные действия, включая,
                помимо прочего, прекращение, приостановку или ограничение вашего доступа к
                вашему Аккаунту DuneEX или Услугам DuneEX, может быть основано на
                конфиденциальных критериях, которые важны для наших протоколов управления
                рисками и безопасности. Вы соглашаетесь с тем, что мы не обязаны раскрывать
                вам подробности наших процедур управления рисками и мерами по безопасности.
            </p>
            <p>
                <br />
            </p>
            <p>
                20.3. &nbsp; &nbsp;Что происходит, когда мы используем наше право. Когда мы
                прекращаем, приостанавливаем, удерживаем или ограничиваем ваш доступ к одной
                или нескольким Услугам DuneEX:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;если у вас есть открытые Указания, сделки, позиции или
                Транзакции, они могут быть закрыты вами или нами в зависимости от
                обстоятельств прекращения, приостановки, удержания, ограничения доступа или
                других действий, которые мы предпринимаем;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;любой возврат платежа, возникший в результате использования
                вашего Аккаунта DuneEX или Услуг DuneEX, может привести к немедленному
                приостановлению и/или ограничению вашего Аккаунта DuneEX и Услуг DuneEX ;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;для восстановления приостановленного и/или ограниченного
                Аккаунта DuneEX или Услуг DuneEX от вас может потребоваться возместить
                нам полную стоимость возврата платежа, включая любые суммы, которые нам
                причитаются, в том числе любые применимые Комиссии; и
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;вы несете ответственность за любые зачисленные суммы в
                случае возврата платежа, и вы уполномочиваете нас и предоставляете нам право
                вычитать расходы и комиссии непосредственно из любых активов в вашем
                Аккаунте DuneEX без уведомления.
            </p>
            <p>
                <br />
            </p>
            <p>
                20.4. &nbsp; &nbsp;Незаконное владение. Если мы проинформированы и разумно
                полагаем, что какие-либо Цифровые активы или Фиатные валюты, хранящиеся в
                вашем Аккаунте DuneEX, украдены или иным образом не находятся в вашем
                законном владении (по ошибке или иным образом), мы можем, но не обязаны,
                удержать соответствующие средства и ваш Аккаунт DuneEX. Если мы
                удерживаем некоторые или все Цифровые активы или Фиатные валюты, хранящиеся
                в вашем Аккаунте DuneEX, или &nbsp;весь ваш Аккаунт DuneEX, мы можем
                продолжать удерживать до тех пор, пока приемлемые для нас доказательства не
                подтвердят, что вы имеете право на владение Цифровыми активами и/или
                Фиатными валютами, хранящимися в вашем Аккаунте DuneEX. Мы не будем
                принимать участие в Спорах или в разрешении Споров, связанных с любыми
                Цифровыми активами и/или Фиатными валютами, хранящимися в вашем Аккаунте
                DuneEX.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                20.5. &nbsp; &nbsp;Доступ к Услугам DuneEX в других юрисдикциях. Жители
                некоторых стран могут иметь доступ только к некоторым, но не ко всем Услугам
                DuneEX. Мы можем периодически изменять доступные вам Услуги DuneEX.
                &nbsp;Услуги DuneEX могут быть недоступны, а ваш доступ к Услугам DuneEX  может быть заблокирован. Вы принимаете, что это может повлиять на вашу
                способность торговать на Платформе и/или отслеживать любые существующие
                ордера или открытые позиции или иным образом использовать Услуги DuneEX.
                Запрещается пытаться каким-либо образом обойти любое такое ограничение, в
                том числе путем использования любой виртуальной частной сети для изменения
                вашего адреса интернет-протокола.
            </p>
            <p>
                <br />
            </p>
            <p>ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</p>
            <p>
                <br />
            </p>
            <p>21. &nbsp; &nbsp;Предшествующая ИС</p>
            <p>
                <br />
            </p>
            <p>ИС DuneEX принадлежит DuneEX.&nbsp;</p>
            <p>
                <br />
            </p>
            <p>22. &nbsp; &nbsp;Лицензия ИС DuneEX</p>
            <p>
                <br />
            </p>
            <p>
                Мы предоставляем вам неисключительную лицензию на срок действия настоящих
                Условий или до приостановления или прекращения вашего доступа к Услугам
                DuneEX, в зависимости от того, что произойдет раньше, на использование ИС
                DuneEX, за исключением Товарных знаков, исключительно в тех случаях,
                когда это необходимо для получения вами Услуг DuneEX для некоммерческого
                личного или внутреннего делового использования в соответствии с настоящими
                Условиями.
            </p>
            <p>
                <br />
            </p>
            <p>23. &nbsp; &nbsp;Лицензия ИС пользователя</p>
            <p>
                <br />
            </p>
            <p>
                23.1. &nbsp; &nbsp; Ваше предоставление лицензии. Вы предоставляете нам
                бессрочную, безотзывную, безвозмездную, всемирную и неисключительную
                лицензию на использование ИС пользователя в той мере, в какой она:&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;является частью или необходима для использования любой
                Созданной ИС;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;периодически необходима для того, чтобы мы могли
                предоставлять вам Услуги DuneEX.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                23.2. &nbsp; &nbsp; Наше право на сублицензию. Лицензия, предоставляемая вам
                в соответствии с настоящим пунктом, ‎включает наше право на сублицензию
                третьим лицам в объеме, необходимом для того, чтобы мы и Партнеры DuneEX
                могли предоставлять вам Услуги DuneEX или любую их часть.
            </p>
            <p>
                <br />
            </p>
            <p>24. &nbsp; &nbsp;Созданная ИС</p>
            <p>
                <br />
            </p>
            <p>
                24.1 &nbsp; &nbsp;Созданная ИС. Созданная ИС автоматически принадлежит нам с
                даты ее создания.
            </p>
            <p>
                <br />
            </p>
            <p>
                24.2 &nbsp; &nbsp;Передача. Настоящим вы передаете нам (и соглашаетесь
                обеспечить передачу любыми агентами, представителями или подрядчиками), с
                полной гарантией собственности, право собственности на все настоящие и
                будущие права и интересы в отношении Созданной ИС.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Если вас об этом попросят, вы должны (и соглашаетесь обеспечить
                соответствующее выполнение любыми агентами, представителями или
                подрядчиками), безвозмездно для нас, подписать и/или оформить все документы
                и совершить действия, которые мы можем потребовать для оформления передачи в
                соответствии с настоящим пунктом.
            </p>
            <p>
                <br />
            </p>
            <p>25. &nbsp; &nbsp;Общие вопросы</p>
            <p>
                <br />
            </p>
            <p>
                25.1 &nbsp; &nbsp;Мы не несем ответственности. Вы подтверждаете и
                принимаете, что: (1) мы не несем ответственности за любые Пользовательские
                материалы (предоставленные вами или третьими лицами), которые могут быть
                доступны на Платформе или Сайтах; и (2) использование любых таких
                Пользовательских материалов осуществляется на ваш риск, и мы не
                предоставляем никаких гарантий в отношении них.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                25.2 &nbsp; &nbsp;Наши права. Мы имеем право по нашему абсолютному
                усмотрению удалять, изменять или отклонять любой контент, который вы
                отправляете, размещаете или демонстрируете на Платформе или Сайтах (включая
                любой Пользовательский материал) по любой причине. Мы оставляем за собой
                право предпринять любые подходящие действия по нашему усмотрению, включая
                письменное предупреждение, удаление любого Пользовательского материала,
                взыскание с вас убытков или другой денежной компенсации, приостановление или
                прекращение действия вашего Аккаунта DuneEX (если таковой имеется),
                приостановление вашего доступа к Платформе и/или Сайтам. Мы также имеем
                право ограничить или запретить вам использование любых Услуг DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                25.3 &nbsp; &nbsp;Запись. Вы подтверждаете, что мы можем записывать любые
                сообщения, электронные, телефонные, по видеозвонкам или иные, которые
                происходят между вами и нами в связи с настоящими Условиями, и что любые
                записи, которые мы сохраняем, будут являться доказательствами общения между
                вами и нами. Вы подтверждаете, что телефонные разговоры и видеозвонки могут
                быть записаны, чтобы мы могли отвечать на запросы, обеспечивать соблюдение
                применимого законодательства, улучшать наши услуги и предоставлять поддержку
                клиентам.
            </p>
            <p>
                <br />
            </p>
            <p>ВАШИ ОБЯЗАТЕЛЬСТВА И ОТВЕТСТВЕННОСТЬ</p>
            <p>
                <br />
            </p>
            <p>26. &nbsp; &nbsp;ЗАПРЕЩЕННОЕ ИСПОЛЬЗОВАНИЕ</p>
            <p>
                <br />
            </p>
            <p>
                Создавая Аккаунт DuneEX, вы подтверждаете, без ущерба для любых других
                ограничений или лимитов, изложенных в настоящих условиях, что вы и любой
                Разрешенный пользователь не будете:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;нарушать настоящие Условия или любое соглашение, заключенное
                в соответствии или в связи с настоящими Условиями, включая, помимо прочего,
                любые Условия продукта;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;использовать Услуги DuneEX таким образом, который
                нарушает общественные интересы, общественную мораль или законные интересы
                других лиц, включая любые действия, которые могут затронуть, испортить,
                негативно повлиять на Услуги DuneEX или помешать другим пользователям
                использовать Услуги DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;использовать услуги DuneEX для перепродажи и в
                коммерческих целях, включая транзакции от имени третьих лиц и организаций,
                если это не разрешено нами в письменном виде;
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;использовать Услуги DuneEX для действий, которые, по
                мнению DuneEX, направлены на контроль или искусственное влияние на цену
                любого Цифрового актива (манипулирование рынком, включая, помимо прочего,
                схемы пампа и дампа, вош-трейдинг, совершение сделок с собой, фронтраннинг,
                намеренные манипуляции котировками, спуфинг и лееринг), независимо от того,
                запрещено ли это Применимым законодательством;
            </p>
            <p>
                <br />
            </p>
            <p>
                e. &nbsp; &nbsp;совершать мошеннические действия или вызывать у нас
                подозрения, что вы или любой Разрешенный пользователь совершаете
                мошеннические действия и/или Транзакции;
            </p>
            <p>
                <br />
            </p>
            <p>
                f. &nbsp; &nbsp;использовать Услуги DuneEX для проведения лотерей;
                аукционов с комиссиями; прогнозов на спорт и составления коэффициентов; лиг
                фэнтези-спорта с денежными призами; онлайн-игр; конкурсов; розыгрышей; или
                азартных игр;
            </p>
            <p>
                <br />
            </p>
            <p>
                g. &nbsp; &nbsp;(1) получать или пытаться получить средства как от нас, так
                и от другого пользователя за одну и ту же Транзакцию в ходе Претензии; (2)
                вести ваш бизнес или использовать Услуги DuneEX таким образом, который
                приводит или может привести к жалобам, спорам, претензиям, отменам,
                возвратам платежа, комиссиям, штрафам, пеням или другим обязательствам перед
                нами, другими пользователями, третьими лицами или вами; и (3) допускать,
                чтобы ваш Аккаунт DuneEX имел отрицательную стоимость или количество
                Цифровых активов;
            </p>
            <p>
                <br />
            </p>
            <p>
                h. &nbsp; &nbsp;предоставлять ложную, неточную или вводящую в заблуждение
                информацию в связи с использованием вами Услуг DuneEX, в общении с нами
                или иным образом в связи с настоящими Условиями;
            </p>
            <p>
                <br />
            </p>
            <p>
                i. &nbsp; &nbsp;(1) использовать любые глубокие ссылки, поисковые роботы,
                боты, пауки или другие автоматические устройства, программы, скрипты,
                алгоритмы или методы, или любые аналогичные или эквивалентные ручные
                процессы для доступа, получения, копирования или отслеживания любой части
                Платформы, или копировать или обходить навигационную структуру или
                представление Услуг DuneEX любым способом, чтобы получить или попытаться
                получить любые материалы, документы или информацию любым способом, не
                предоставленным намеренно через Услуги DuneEX; (2) пытаться получить
                доступ к любой части или функции Платформы без авторизации, подключиться к
                Услугам DuneEX, любому из наших серверов или любым другим системам или
                сетям любых Услуг DuneEX, предоставляемых через Платформу, путем взлома,
                подбора паролей или любых других незаконных или запрещенных способов; (3)
                исследовать, сканировать или проверять уязвимости Услуг DuneEX или любой
                сети, подключенной к Платформе, или нарушать любые меры безопасности или
                аутентификации Услуг DuneEX или любой сети, подключенной к Услугам DuneEX ; (4) осуществлять обратный поиск, отслеживать или пытаться отслеживать
                любую информацию любых других пользователей или посетителей Услуг DuneEX;
                (5) предпринимать любые действия, которые создают необоснованную или
                непропорционально большую нагрузку на инфраструктуру систем или сетей Услуг
                DuneEX или DuneEX или инфраструктуру любых систем или сетей,
                подключенных к Услугам DuneEX; (6) использовать любые устройства,
                программное обеспечение или обычные программы для вмешательства в нормальную
                работу Услуг DuneEX, любые транзакции в Услугах DuneEX или
                использование Услуг DuneEX любым другим лицом; или (7) подделывать
                заголовки, выдавать себя за другое лицо или иным образом манипулировать
                идентификацией, чтобы скрыть вашу личность или происхождение любых сообщений
                или передач, которые вы отправляете нам;
            </p>
            <p>
                <br />
            </p>
            <p>
                j. &nbsp; &nbsp;изменять или адаптировать всю или некоторую часть Платформы
                или объединять или включать Платформу в другую программу или приложение;
            </p>
            <p>
                <br />
            </p>
            <p>
                k. &nbsp; &nbsp;разбирать, декомпилировать, проводить обратный инжиниринг
                или иным образом пытаться извлечь исходный код, объектный код в основе
                концепций, идей и алгоритмов Платформы или любых ее компонентов;
            </p>
            <p>
                <br />
            </p>
            <p>
                l. &nbsp; &nbsp;изменять, воспроизводить, дублировать, копировать,
                скачивать, хранить, передавать, распространять, пересылать, разбирать,
                транслировать, публиковать, удалять или изменять любой знак или заявление об
                авторском праве, лицензировать, сублицензировать, продавать, повторять,
                разрабатывать, сдавать в аренду, сдавать в лизинг, создавать собственную
                торговую марку, создавать обеспечение в отношении такой ИС DuneEX или
                любой части интеллектуальной собственности, создавать производные работы или
                иным образом использовать любую часть ИС DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                m. &nbsp; &nbsp;способствовать распространению любых вирусов, троянских
                программ, червей или других компьютерных программ, которые могут повредить,
                скрытно перехватить, присвоить или пагубно повлиять на любую систему, данные
                или информацию в связи с Услугами DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                n. &nbsp; &nbsp;(1) использовать анонимный прокси; (2) использовать любую
                временную, одноразовую, самоуничтожающуюся или аналогичную электронную почту
                при создании Аккаунта DuneEX и/или использовании Услуг DuneEX; (3)
                использовать любое устройство, программное обеспечение или процедуру для
                обхода наших заголовков исключения роботов, или вмешиваться или пытаться
                вмешиваться в работу наших Сайтов или Услуг DuneEX; и (4) предпринимать
                любые действия, которые могут привести к потере нами каких-либо услуг от
                наших интернет-провайдеров или других поставщиков;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                o. &nbsp; &nbsp;создавать или предположительно создавать какое-либо
                обеспечение в отношении вашей Фиатной валюты или Цифровых активов,
                хранящихся в любом из ваших Аккаунтов DuneEX, без нашего предварительного
                письменного согласия;
            </p>
            <p>
                <br />
            </p>
            <p>
                p. &nbsp; &nbsp;нарушать или пытаться нарушить (1) любое Применимое
                законодательство; или (2) принадлежащие нам или любой третьей стороне
                авторские права, патенты, торговые марки, коммерческие тайны или другие
                права интеллектуальной собственности, или права на публичность или
                конфиденциальность; и/или
            </p>
            <p>
                <br />
            </p>
            <p>
                q. &nbsp; &nbsp;получать доступ, использовать или пытаться получить доступ
                или использовать Услуги DuneEX прямо или косвенно (1) в юрисдикциях,
                которые, по мнению DuneEX, представляют высокий риск, включая, помимо
                прочего, Кубу, Иран, Северную Корею, Сирию, или (2) лицами, которые, по
                мнению DuneEX, представляют высокий риск, включая, помимо прочего,
                физических или юридических лиц с ограниченным доступом или стороны в любом
                списке Соединенных Штатов Америки, Великобритании, Европейского союза или
                Организации Объединенных Наций, включая санкционные списки Управления по
                контролю за иностранными активами США и список запрещенных лиц и организаций
                Министерства торговли США.
            </p>
            <p>
                <br />
            </p>
            <p>27. &nbsp; &nbsp;Заявления и гарантии</p>
            <p>
                <br />
            </p>
            <p>Настоящим вы заявляете и гарантируете нам в любое время следующее:</p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;все документы и информация, которые вы предоставляете нам,
                являются правдивыми, точными, полными и актуальными во всех отношениях, и мы
                можем полагаться на них при определении того, имеете ли вы право доступа к
                Платформе или пользования Услугами DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;все решения, принятые в связи с настоящими Условиями, были
                приняты исключительно по вашему усмотрению и после вашей независимой оценки
                ваших финансовых ресурсов, способности и готовности принимать
                соответствующие риски, финансовых целей;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;у вас есть все полномочия, права и возможности для (1)
                доступа и использования Платформы и/или Услуг DuneEX; и (2) заключения,
                поставки и выполнения ваших обязательств по настоящим Условиям и любому
                соглашению, заключенному в соответствии или в связи с настоящими Условиями,
                включая, помимо прочего, любые Условия продукта;
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;если вы являетесь корпорацией, партнером в партнерстве или
                доверенным лицом траста:
            </p>
            <p>
                <br />
            </p>
            <p>
                I. &nbsp; &nbsp;корпорация, партнерство или траст действовали и будут
                действовать в соответствии с Применимым законодательством, а также любыми
                договорами о партнерстве или трасте (или другими подобными документами);
            </p>
            <p>
                <br />
            </p>
            <p>
                II. &nbsp; &nbsp;вы немедленно сообщите нам о любых изменениях, связанных с
                отставкой, смещением, назначением или смертью любого из директоров,
                партнеров, доверенных лиц, учредителей, конечных бенефициарных владельцев
                или любого лица, уполномоченного управлять вашим Аккаунтом DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                III. &nbsp; &nbsp;вы немедленно сообщите нам, если корпорация, партнерство
                или траст будут распущены на добровольной или принудительной основе;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                e. &nbsp; &nbsp;все согласия, разрешения, авторизации, одобрения и договоры
                третьих лиц и все авторизации, одобрения, разрешения, согласия, регистрации,
                декларации, подачи документов в любой Регулирующий орган, правительственный
                департамент, комиссию, агентство или другую организацию, имеющую юрисдикцию
                над вами, которые необходимо или желательно получить для того, чтобы (1)
                получить доступ и использовать Платформу и/или Услуги DuneEX; и (2)
                заключать, поставлять и выполнять Транзакции, предусмотренные настоящими
                Условиями и любым соглашением, заключенным в соответствии или в связи с
                настоящими Условиями, были безоговорочно получены в письменной форме,
                предоставлены нам в письменной форме и не были отозваны или изменены;
            </p>
            <p>
                <br />
            </p>
            <p>
                f. &nbsp; &nbsp;настоящие Условия и любое соглашение, заключенное в
                соответствии или в связи с настоящими Условиями, представляют собой
                действительные обязательства с юридической силой, подлежащие принудительному
                исполнению в отношении вас согласно их соответствующим условиям;
            </p>
            <p>
                <br />
            </p>
            <p>g. &nbsp; &nbsp;вы не являетесь Лицом с ограниченным доступом;</p>
            <p>
                <br />
            </p>
            <p>
                h. &nbsp; &nbsp;если вы являетесь юридическим лицом, вы должным образом
                зарегистрированы, должным образом организованы, законно существуете в
                соответствии с законодательством вашей юрисдикции и обладаете всеми
                полномочиями для ведения вашего бизнеса. Если вы являетесь физическим лицом,
                вы не моложе 18 лет;
            </p>
            <p>
                <br />
            </p>
            <p>
                i. &nbsp; &nbsp;ваш доступ и использование Платформы и/или Услуг DuneEX,
                ваше исполнение и поставка, а также выполнение ваших обязательств по
                настоящим Условиям и любому соглашению, заключенному в соответствии или в
                связи с настоящими Условиями:
            </p>
            <p>
                <br />
            </p>
            <p>
                I. &nbsp; &nbsp;если вы являетесь юридическим лицом, партнером в партнерстве
                или доверенным лицом траста, не приведет к нарушению или не будет
                противоречить любому положению вашей конституции, устава, соглашения о
                партнерстве, трастового договора или эквивалентных учредительных документов;
            </p>
            <p>
                <br />
            </p>
            <p>
                II. &nbsp; &nbsp;не приведет к нарушению или невыполнению обязательств по
                любому инструменту, соглашению, документу или положению, стороной которого
                вы являетесь или действие которого распространяется на вас или любую вашу
                собственность;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                III. &nbsp; &nbsp;не приведет к нарушению вами, нами или любой третьей
                стороной любого Применимого законодательства, постановления или решения
                любого суда, любого арбитражного решения или решения любого
                правительственного или Регулирующего органа в любой юрисдикции.
            </p>
            <p>
                <br />
            </p>
            <p>28. &nbsp; &nbsp;Отказ от ответственности за технологии</p>
            <p>
                <br />
            </p>
            <p>
                28.1. &nbsp; &nbsp;Никаких заверений или гарантий. Услуги DuneEX и любая
                информация на Сайтах и Платформе, включая Услуги чата, предоставляются по
                принципу «как есть» и «как доступно» без каких-либо заверений или гарантий,
                явных или подразумеваемых, в максимальной степени, разрешенной Применимым
                законодательством. В частности, мы отказываемся от любых подразумеваемых
                гарантий права собственности, соответствия ожиданиям покупателя, пригодности
                для конкретной цели и/или отсутствия нарушений прав. Мы не делаем никаких
                заявлений и не даем никаких гарантий, что доступ к Сайтам, Платформе, любому
                из ваших Аккаунтов DuneEX, Услугам DuneEX или любым содержащимся в них
                материалам будет постоянным, непрерывным, своевременным или безошибочным.
                Это может привести к невозможности торговать на Платформе в течение
                определенного периода времени, а также к задержкам.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                28.2. &nbsp; &nbsp;Приостановка доступа. Мы можем периодически
                приостанавливать доступ к вашему Аккаунту DuneEX и/или Услугам DuneEX
                как для планового, так и для аварийного обслуживания. Мы приложим разумные
                усилия для обеспечения своевременной обработки Транзакций на Платформе,
                однако мы не делаем никаких заявлений и не даем гарантий в отношении времени
                обработки, поскольку оно зависит от многих факторов, находящихся вне нашего
                контроля.
            </p>
            <p>
                <br />
            </p>
            <p>
                28.3. &nbsp; &nbsp;Контент. Хотя мы прилагаем разумные усилия для обновления
                информации на Сайтах и Платформе, мы не делаем никаких заявлений, гарантий
                или заверений, явных или подразумеваемых, что контент Сайтов и Платформы,
                включая информацию об Услугах DuneEX, является точным, полным или
                актуальным.
            </p>
            <p>
                <br />
            </p>
            <p>
                28.4. &nbsp; &nbsp;Сайты третьих лиц. Мы можем предоставить ссылки на сайты
                третьих лиц (включая, помимо прочего, контент, материалы и/или информацию на
                сайтах третьих лиц) для удобства, но мы их не контролируем. Вы подтверждаете
                и принимаете, что мы не несем ответственности за любой аспект контента,
                материалов, информации или услуг, содержащихся на любых сайтах третьих лиц,
                доступных или упоминаемых на Платформе или Сайтах.
            </p>
            <p>
                <br />
            </p>
            <p>
                28.5. &nbsp; &nbsp;Доступ к сети и совместимость. Вы несете ответственность
                за получение доступа к сети передачи данных, необходимого для использования
                Услуг DuneEX. Вы несете ответственность за приобретение и обновление
                совместимого оборудования или устройств, необходимых для доступа и
                использования Услуг DuneEX и Сайтов, а также любых обновлений к ним.
                DuneEX не гарантирует, что Услуги DuneEX или любая их часть будут
                функционировать на каком-либо конкретном оборудовании или устройствах.
                Услуги DuneEX могут быть подвержены сбоям и задержкам, связанным с
                использованием интернета и электронных средств связи.
            </p>
            <p>
                <br />
            </p>
            <p>29. &nbsp; &nbsp;Возмещение убытков</p>
            <p>
                <br />
            </p>
            <p>
                29.1. &nbsp; &nbsp;Претензии третьих лиц. Вы должны возмещать убытки и
                ограждать нас от любых претензий, исков, действий, требований, споров,
                обвинений или расследований любой третьей стороны, государственного органа
                или отраслевого органа, а также от всех претензий, обязательств, ущерба
                (фактического и последующего), убытков (включая любые прямые, косвенные или
                последующие убытки, упущенную прибыль, потерю репутации), затрат и расходов,
                включая, помимо прочего, все проценты, штрафы, судебные и другие разумные
                гонорары адвокатов, другие профессиональные затраты и расходы («Убытки»),
                возникающие в результате или в связи с:&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;вашим доступом или использованием вашего Аккаунта DuneEX
                и/или Услуг DuneEX;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;нарушением или предполагаемым нарушением вами настоящих
                Условий или совершением вами действий, противоречащих любому другому пункту
                или подпункту настоящих Условий, включая любые Условия продукта и любые
                другие упоминаемые условия и положения;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;совершением вами действий, противоречащих Применимому
                законодательству;
            </p>
            <p>
                <br />
            </p>
            <p>d. &nbsp; &nbsp;нарушением вами прав любой третьей стороны.&nbsp;</p>
            <p>
                <br />
            </p>
            <p>
                29.2. &nbsp; &nbsp;Контроль. Мы оставляем за собой право контролировать
                защиту в любой претензии третьей стороны, в рамках которой вы должны
                возместить убытки, и в случае нашего контроля вы должны сотрудничать в
                полном объеме, определяемом нами при отстаивании любой доступной защиты. Вы
                не будете урегулировать любые претензии или Убытки без нашего
                предварительного письменного согласия.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                29.3. &nbsp; &nbsp;Освобождение. Настоящим вы соглашаетесь освободить нас от
                всех претензий и требований (и отказываетесь от любых прав, которые вы
                можете иметь против нас в отношении любых возможных Убытков), возникающих
                прямо или косвенно в результате или в связи с любым вашим спором с любым
                другим пользователем или другой третьей стороной в связи с Услугами DuneEX  (включая любые Транзакции с Цифровыми активами) или предметом настоящих
                Условий.
            </p>
            <p>
                <br />
            </p>
            <p>30. &nbsp; &nbsp;Ответственность</p>
            <p>
                <br />
            </p>
            <p>
                30.1 &nbsp; &nbsp;Наша ответственность. Наша ответственность и
                ответственность Партнеров DuneEX перед вами или любыми третьими лицами
                при любых обстоятельствах ограничивается фактической суммой ущерба или
                убытков, которые вызваны прямо и разумно предсказуемы в результате нарушения
                нами настоящих условий, и ни в коем случае не должна превышать сумму
                комиссий, уплаченных вами DuneEX за 12 месяцев до события, которое
                привело к возникновению Убытка. Такая сумма должна быть выплачена нами вам в
                качестве полного и окончательного расчета и полностью удовлетворит нашу
                ответственность и ответственность любого Партнера DuneEX за все убытки и
                претензии, связанные с соответствующим нарушением, независимо от причин их
                возникновения. Вы подтверждаете и принимаете, что ни DuneEX, ни Партнеры
                DuneEX не знают о каких-либо особых обстоятельствах, относящихся к вам,
                что возмещение убытков является достаточным средством правовой защиты и что
                вы не имеете права на какие-либо другие претензии или средства правовой
                защиты по закону или по праву справедливости, включая, помимо прочего, любые
                вещные иски, судебные постановления и/или конкретное исполнение.
            </p>
            <p>
                <br />
            </p>
            <p>
                30.2 &nbsp; &nbsp;Ограничения ответственности. Вне зависимости от любых
                других пунктов настоящих Условий, мы или любой Партнер DuneEX ни при
                каких обстоятельствах не несем ответственности перед вами или любым другим
                физическим или юридическим лицом за:
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;любые прямые или косвенные убытки (включая упущенную
                прибыль, потерю бизнеса или возможностей), ущерб или расходы, возникающие в
                результате или в связи с настоящими Условиями, включая, помимо прочего:
            </p>
            <p>
                <br />
            </p>
            <p>
                I. &nbsp; &nbsp;работу протоколов в основе любого Цифрового актива, их
                функции, безопасность и доступность;
            </p>
            <p>
                <br />
            </p>
            <p>
                II. &nbsp; &nbsp;сохраняют ли Обеспеченные цифровые активы свою стоимость по
                отношению к какому-либо активу, или имеет ли эмитент Обеспеченного цифрового
                актива достаточные резервы в отношении любого Обеспеченного цифрового
                актива;
            </p>
            <p>
                <br />
            </p>
            <p>
                III. &nbsp; &nbsp;любое действие или бездействие в соответствии с настоящими
                Условиями;
            </p>
            <p>
                <br />
            </p>
            <p>
                IV. &nbsp; &nbsp;любые неточности, дефекты или упущения в данных о цене
                Цифровых активов, любые ошибки или задержки в передаче таких данных, а также
                перерывы в передаче любых таких данных;
            </p>
            <p>
                <br />
            </p>
            <p>
                V. &nbsp; &nbsp;регулярное или внеплановое обслуживание, которое мы
                проводим, включая любые изменения и перерывы в предоставлении услуг,
                вызванные таким обслуживанием;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                VI. &nbsp; &nbsp;кражу устройства, с помощью которого осуществляется доступ
                и использование Услуг DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                VII. &nbsp; &nbsp;действия, бездействие или нарушение настоящих Условий
                другими пользователями, а также любой ущерб, причиненный действиями любых
                других третьих лиц;
            </p>
            <p>
                <br />
            </p>
            <p>
                VIII. &nbsp; &nbsp;(1) любой ущерб или перерывы в работе, вызванные любыми
                компьютерными вирусами, шпионскими программами или другими вредоносными
                программами, которые могут повлиять на ваш компьютер или другое
                оборудование, или любым фишингом, спуфингом или другими атаками; (2) выход
                из строя, повреждение или уничтожение вашего оборудования, повреждение или
                потерю любых записей или данных на вашем оборудовании по любой причине; или
                (3) использование вами интернета для подключения к Услугам DuneEX или
                любые технические проблемы, системные сбои, неисправности, отказ линий
                связи, высокую нагрузку или спрос на интернет-трафик, сопутствующие
                проблемы, нарушения безопасности или любые подобные технические проблемы или
                дефекты;
            </p>
            <p>
                <br />
            </p>
            <p>
                IX. &nbsp; &nbsp;наше решение отклонить вашу заявку на создание
                Аккаунта(-ов) DuneEX в соответствии с п. ‎7.1;
            </p>
            <p>
                <br />
            </p>
            <p>
                X. &nbsp; &nbsp;любое прекращение, приостановление, удержание или
                ограничение доступа к любому Аккаунту DuneEX или Услугам DuneEX,
                включая вашу неспособность выводить Цифровые активы, отправлять Указания или
                заключать Транзакции в период любого приостановления, удержания или
                ограничения в соответствии с настоящими Условиями или любыми Условиями
                продукта;
            </p>
            <p>
                <br />
            </p>
            <p>
                XI. &nbsp; &nbsp;любые лимиты Транзакций, применяемые к вашему Аккаунту
                DuneEX в соответствии с п. ‎14.1;
            </p>
            <p>
                <br />
            </p>
            <p>
                XII. &nbsp; &nbsp;любое наше решение поддерживать или не поддерживать
                Цифровые активы в соответствии с п. ‎15.1;
            </p>
            <p>
                <br />
            </p>
            <p>
                XIII. &nbsp; &nbsp;нашу невозможность связаться с вами, используя
                предоставленную вами контактную информацию в соответствии с п. ‎3.2;
            </p>
            <p>
                <br />
            </p>
            <p>
                XIV. &nbsp; &nbsp;закрытие нами неактивного аккаунта в соответствии с п.
                ‎19.3;
            </p>
            <p>
                <br />
            </p>
            <p>
                XV. &nbsp; &nbsp;неисполнение Транзакции или несоответствие времени,
                необходимого для завершения любой Транзакции, в соответствии с п. ‎12.1;
            </p>
            <p>
                <br />
            </p>
            <p>
                XVI. &nbsp; &nbsp;деятельность по маркет-мейкингу в соответствии с п. ‎13.2;
            </p>
            <p>
                <br />
            </p>
            <p>
                XVII. &nbsp; &nbsp;наше доверие к любому Указанию, отправленному с вашего
                Аккаунта DuneEX или Электронной почты;
            </p>
            <p>
                <br />
            </p>
            <p>
                XVIII. &nbsp; &nbsp;наш отказ или задержку при выполнении любого Указания в
                соответствии с п. ‎11.1;
            </p>
            <p>
                <br />
            </p>
            <p>
                XIX. &nbsp; &nbsp;любое нарушение защиты вашей Электронной почты или
                Нарушение безопасности;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                XX. &nbsp; &nbsp;убытки, причиненные вам в результате мошенничества или афер
                третьих лиц, в которых DuneEX участвует только как получатель вашей
                Фиатной валюты или Цифровых активов, и/или осуществляет конвертацию Фиатной
                валюты в Цифровые активы, и/или осуществляет перевод Цифровых активов с
                нашей платформы по вашему запросу;
            </p>
            <p>
                <br />
            </p>
            <p>
                XXI. &nbsp; &nbsp;любые убытки, возникающие в результате или в связи с
                новыми предложениями Цифровых активов, первичными предложениями монет (ICO)
                или решением о добавлении или недобавлении Цифровых активов на Платформу;
            </p>
            <p>
                <br />
            </p>
            <p>
                XXII. &nbsp; &nbsp;правильность, качество, точность, безопасность, полноту,
                надежность, эффективность, своевременность, цену или постоянную доступность
                Услуг DuneEX или задержки или упущения в Услугах DuneEX, или
                неспособность любой службы соединения или связи обеспечить или поддерживать
                ваш доступ к Услугам DuneEX, или любой перерыв или нарушение вашего
                доступа, или любые ошибочные сообщения между нами, независимо от причины;
                и&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                XXIII. &nbsp; &nbsp;любые Транзакции, Указания или операции, осуществленные
                вами или предположительно осуществленные вами через вашу Электронную почту
                или Аккаунт DuneEX;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;любую упущенную прибыль, потерю бизнеса, ожидаемых
                сбережений или возможностей, или любые специальные, штрафные, отягчающие,
                случайные, косвенные или последующие убытки или ущерб, возникающие в
                результате или в связи с нашими Сайтами, Платформой, вашим Аккаунтом DuneEX , Услугами DuneEX, настоящими Условиями, Условиями продукта,
                Уведомлением о конфиденциальности и/или любым соглашением, заключенным в
                результате или в связи с настоящими Условиями или иным образом; и/или
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;любые убытки, являющиеся частью Претензии, которая не была
                начата путем официального судебного разбирательства В ТЕЧЕНИЕ ОДНОГО
                КАЛЕНДАРНОГО ГОДА после события, которое привело к возникновению Претензии.
                ВЫ ПОДТВЕРЖДАЕТЕ И ПРИНИМАЕТЕ, ЧТО ДАННЫЙ ПУНКТ ИЗМЕНЯЕТ ЛЮБОЙ СРОК ИСКОВОЙ
                ДАВНОСТИ, ПРИ ДРУГИХ ОБСТОЯТЕЛЬСТВАХ ПРИМЕНИМЫЙ ПО ЗАКОНОДАТЕЛЬСТВУ, И ЧТО
                ЕСЛИ ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО ЗАПРЕЩАЕТ ЭТО, ТО ДАННЫЙ ПУНКТ БУДЕТ
                РАССМАТРИВАТЬСЯ КАК СРОК ИСКОВОЙ ДАВНОСТИ МИНИМАЛЬНО ДОПУСТИМОЙ
                ПРОДОЛЖИТЕЛЬНОСТИ. БЕЗ УЩЕРБА ДЛЯ ОБЩЕГО ХАРАКТЕРА ВЫШЕСКАЗАННОГО, ОБРАЩАЕМ
                ВНИМАНИЕ НА П. 32, СОГЛАСНО КОТОРОМУ УРЕГУЛИРОВАНИЕ ПРЕТЕНЗИЙ ВЫПОЛНЯЕТСЯ
                ИСКЛЮЧИТЕЛЬНО ПУТЕМ ОБЯЗАТЕЛЬНОГО АРБИТРАЖА.
            </p>
            <p>
                <br />
            </p>
            <p>
                30.3 &nbsp; &nbsp;Повреждение или перерывы. Мы не несем ответственности за
                любой ущерб или перерывы в работе, вызванные любыми компьютерными вирусами,
                шпионскими программами, поддельными антивирусами, троянскими программами,
                червями или другими вредоносными программами, которые могут повлиять на ваш
                компьютер или другое оборудование, или любым фишингом, спуфингом или другими
                атаками. Мы рекомендуем регулярно использовать надежное и легкодоступное
                программное обеспечение для выявления и защиты от вирусов. Учитывайте, что
                службы SMS и электронной почты подвержены спуфингу и фишингу, и будьте
                осторожны при получении сообщений, якобы исходящих от нас. Вы и, в
                соответствующих случаях, ваши Разрешенные пользователи несете
                ответственность за все учетные данные для входа в систему, включая имена
                пользователей и пароли, и должны всегда сохранять их в безопасности.
            </p>
            <p>
                <br />
            </p>
            <p>РАЗРЕШЕНИЕ СПОРОВ: СУДЫ, АРБИТРАЖ, ОТКАЗ ОТ КОЛЛЕКТИВНЫХ ИСКОВ</p>
            <p>
                <br />
            </p>
            <p>
                Во избежание неверного толкования: ничто в данном разделе не лишает вас
                какого-либо обязательного юридического права, которое принадлежит вам в
                соответствии с Применимым законодательством.
            </p>
            <p>
                <br />
            </p>
            <p>31. &nbsp; &nbsp;Уведомление о претензии и Период разрешения спора</p>
            <p>
                <br />
            </p>
            <p>
                31.1. &nbsp; &nbsp;Если у вас возникли какие-либо проблемы с Услугами,
                пожалуйста, сначала свяжитесь с DuneEX. DuneEX хочет решить ваши
                проблемы без официального судебного разбирательства, если это возможно. Если
                вы не будете удовлетворены решением ваших проблем при первом обращении, вам
                будет присвоен номер билета. При создании номера билета начинается процедура
                внутреннего разрешения спора. DuneEX попытается разрешить спор за счет
                внутренних процедур как можно скорее. Стороны соглашаются добросовестно
                вести переговоры для разрешения спора (эти переговоры должны оставаться
                конфиденциальными и подчиняться применимым правилам, защищающим переговоры
                по урегулированию от использования в качестве доказательства в любом
                судебном процессе).
            </p>
            <p>
                <br />
            </p>
            <p>
                31.2. &nbsp; &nbsp;Если спор не может быть разрешен удовлетворительно и вы
                хотите выдвинуть Претензию против DuneEX, то вы соглашаетесь изложить
                основания такой Претензии в письменном «Уведомлении о претензии» в качестве
                предварительного уведомления DuneEX. Уведомление о претензии должно (1)
                описывать характер и основание претензии или спора, (2) содержать конкретное
                средство судебной защиты, (3) номер изначального билета и (4) электронную
                почту вашего кастодиального аккаунта. Уведомление о претензии следует
                отправить на по следующему адресу:{" "}
                <a data-fr-linked="true" href="https://dune-ex.net/contacts">
                    https://dune-ex.net/contacts
                </a>
                . После того как вы направите Уведомление о претензии в DuneEX, спор,
                указанный в Уведомлении о претензии, может быть передан либо DuneEX, либо
                вами в арбитраж в соответствии с приведенным ниже пунктом (Соглашение об
                арбитраже).&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                31.3. &nbsp; &nbsp;Во избежание недопониманий передача спора в DuneEX для
                разрешения путем внутренних процедур и отправка Уведомления о претензии в
                DuneEX являются необходимыми условиями для начала арбитражного
                разбирательства (или любого другого судебного разбирательства).
            </p>
            <p>
                <br />
            </p>
            <p>
                31.4. &nbsp; &nbsp;Во время арбитража запрещается раскрывать арбитру сумму
                любого предложения об урегулировании, сделанного вами или DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>32. &nbsp; &nbsp;Соглашение об арбитраже</p>
            <p>
                <br />
            </p>
            <p>
                32.1. &nbsp; &nbsp;За исключением случаев, когда Применимое законодательство
                требует или предоставляет вам иной выбор, вы и DuneEX, учитывая
                предыдущий пункт выше (Уведомление о претензии и Период разрешения спора),
                соглашаетесь с тем, что любая претензия должна быть рассмотрена в рамках
                обязательного, окончательного и индивидуального (не коллективного)
                арбитражного процесса под управлением Гонконгского международного
                арбитражного центра (HKIAC) и в соответствии с действующими правилами HKIAC,
                которые считаются включенными в настоящий пункт путем добавления ссылки.
            </p>
            <p>
                <br />
            </p>
            <p>
                32.2. &nbsp; &nbsp;Место проведения арбитражного судебного процесса —
                Гонконг.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                32.3. &nbsp; &nbsp;Судейская коллегия состоит из одного (1) третейского
                судьи, назначаемого в соответствии с действующим Регламентом HKIAC.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>32.4. &nbsp; &nbsp;Языком арбитражного суда является английский.</p>
            <p>
                <br />
            </p>
            <p>
                32.5. &nbsp; &nbsp;Вы и DuneEX также соглашаетесь с тем, что третейский
                судья будет обладать исключительными полномочиями выносить решения по
                вопросам собственной юрисдикции, включая, помимо прочего, любые возражения в
                отношении существования, объема или действительности Соглашения об
                арбитраже, а также возможности разрешения какой-либо Претензии в арбитражном
                суде.
            </p>
            <p>
                <br />
            </p>
            <p>
                32.6. &nbsp; &nbsp;Положения об арбитраже, изложенные в настоящем пункте,
                сохраняют силу после прекращения действия настоящих Условий.
            </p>
            <p>
                <br />
            </p>
            <p>
                32.7. &nbsp; &nbsp;Срок исковой давности. Любой арбитраж против DuneEX
                должен быть начат путем подачи и вручения Уведомления об арбитраже в
                соответствии с Регламентом HKIAC в течение одного (1) года после даты, когда
                пользователь, предъявляющий Претензию, впервые узнал или разумно должен был
                узнать о предполагаемом действии, бездействии или невыполнении обязательств,
                дающим основание для Претензии («Срок исковой давности»). Во избежание
                недопониманий Срок исковой давности включает Период разрешения спора,
                указанный в п. 31.1. Пользователь не имеет права на какое-либо средство
                правовой защиты или средство судебной защиты на основании любой Претензии
                пользователя, если Уведомление об арбитраже в отношении этой Претензии не
                было подано и вручено DuneEX в течение Срока исковой давности. Если Срок
                исковой давности противоречит применимому законодательству, пользователь
                обязан предъявить любую Претензию к DuneEX в течение кратчайшего периода
                времени, разрешенного применимым законодательством. Уведомление об арбитраже
                может быть направлено DuneEX в соответствии с Применимым
                законодательством и правилами вручения.
            </p>
            <p>
                <br />
            </p>
            <p>
                32.8. &nbsp; &nbsp;Уведомление: если DuneEX начнет арбитражное
                разбирательство против вас, DuneEX отправит вам уведомление на указанную
                вами электронную почту или почтовый адрес. Вы подтверждаете, что любое
                уведомление, отправленное на эту электронную почту или почтовый адрес, будет
                считаться действительным для всех целей, включая, помимо прочего,
                определение соответствия услуги. Вы обязаны убедиться в том, что электронная
                почта и/или почтовый адрес, предоставленные DuneEX, являются актуальными
                и точными.
            </p>
            <p>
                <br />
            </p>
            <p>33. &nbsp; &nbsp;Конфиденциальность</p>
            <p>
                <br />
            </p>
            <p>
                33.1. &nbsp; &nbsp;Стороны согласны с тем, что арбитраж должен быть
                конфиденциальным. Существование арбитража, существование или содержание
                Претензии, все документы и информация, которые стороны предоставили или
                которыми обменялись в связи с арбитражем, а также любые заявления,
                постановления или решения, принятые в арбитраже, должны оставаться
                конфиденциальными, и ни одна из сторон не должна раскрывать что-либо из
                вышеизложенного какой-либо третьей стороне за исключением арбитражного
                трибунала, HKIAC, сторон, их адвокатов, экспертов, свидетелей, бухгалтеров и
                аудиторов, страховщиков и перестраховщиков, а также любых других лиц,
                необходимых для проведения арбитража. Вне зависимости от вышесказанного,
                сторона может раскрыть такую конфиденциальную информацию:
            </p>
            <p>
                <br />
            </p>
            <p>a. &nbsp; &nbsp;если получено письменное согласие другой стороны;</p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;в той мере, в какой это требуется применимым
                законодательством или постановлениями любого регулирующего или надзорного
                органа компетентной юрисдикции, которая распространяется или может
                распространяться на сторону, или в соответствии с любым постановлением суда
                или другого компетентного органа или трибунала компетентной юрисдикции;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;в связи с началом, ведением или защитой стороной любого
                добросовестного судебного разбирательства по исполнению или оспариванию
                любого решения, вынесенного в арбитраже; и
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;в той мере, в какой соответствующая конфиденциальная
                информация является общественным достоянием и не нарушает настоящее
                соглашение.
            </p>
            <p>
                <br />
            </p>
            <p>
                Условие конфиденциальности, изложенное в настоящем пункте, сохраняет силу
                после прекращения действия настоящих Условий и завершения или
                приостановления любого арбитража, возбужденного в соответствии с настоящими
                Условиями.
            </p>
            <p>
                <br />
            </p>
            <p>34. &nbsp; &nbsp;Регулирующее законодательство</p>
            <p>
                <br />
            </p>
            <p>
                За исключением случаев, когда Применимое законодательство требует или
                предоставляет вам иной выбор, настоящие Условия (включая настоящее
                соглашение о арбитраже) регулируются и толкуются в соответствии с
                законодательством Гонконга.
            </p>
            <p>
                <br />
            </p>
            <p>35. &nbsp; &nbsp;Отказ от коллективных исков</p>
            <p>
                <br />
            </p>
            <p>
                Вы и DuneEX соглашаетесь, что любые должны быть предъявлены DuneEX в
                арбитражном суде только на индивидуальной основе, а не в качестве истца или
                участника в предполагаемом коллективном или представительском иске. Вы также
                соглашаетесь отказаться от любого права на предъявление, рассмотрение или
                арбитраж таких Претензий в качестве группового, коллективного,
                представительского или частного иска, насколько это допустимо применимым
                законодательством. Объединение или консолидация отдельных арбитражей в один
                арбитраж не допускается без согласия DuneEX.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>ОБЩИЕ ПОЛОЖЕНИЯ&nbsp;</p>
            <p>
                <br />
            </p>
            <p>36. &nbsp; &nbsp;Общие положения</p>
            <p>
                <br />
            </p>
            <p>
                36.1. &nbsp; &nbsp;Применимое законодательство. Вы и любой Разрешенный
                пользователь должны соблюдать Применимое законодательство, лицензионные
                требования и права третьих лиц (включая законы о конфиденциальности данных и
                законы о борьбе с отмыванием денег и финансированием терроризма) при
                использовании вами Услуг DuneEX, вашего Аккаунта DuneEX и Платформы.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.2. &nbsp; &nbsp;Уведомления. Мы можем отправлять уведомления на вашу
                Электронную почту. Вы несете ответственность за указание актуальной и верной
                Электронной почты. Мы можем отправлять уведомления на вашу Электронную
                почту, в случае чего уведомления будут считаться полученными, даже если
                получено уведомление об ошибке доставки.
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы можете отправлять нам уведомления только в соответствии с нашими
                указаниями, которые могут периодически меняться.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Любые уведомления, согласия или другие сообщения, предоставляемые в
                соответствии с настоящими Условиями, должны быть отправлены в письменной
                форме на английском языке и подписаны или иным образом уполномочены
                стороной, предоставляющей их.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.3. &nbsp; &nbsp;Анонсы. Все официальные анонсы, новости, промоакции,
                конкурсы и аирдропы будут размещаться на Сайте. Эти анонсы важны и могут
                содержать информацию о факторах, которые могут влиять на стоимость или
                безопасность ваших Цифровых активов. Вы несете ответственность за
                отслеживание Сайта, чтение и рассмотрение этих анонсов.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.4. &nbsp; &nbsp;Полное соглашение. Настоящие Условия вместе с любыми
                применимыми Условиями продукта составляют полное соглашение между вами и
                нами в отношении Услуг DuneEX. Каждая сторона признает, что она не
                полагалась на содержание и не имеет никаких прав или средств правовой защиты
                в отношении любого заявления, утверждения, заверения или гарантии (сделанной
                по небрежности или невиновно), кроме тех, которые прямо изложены в Условиях
                или Условиях продукта.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.5. &nbsp; &nbsp;Передача. Вы не можете передавать или переносить
                какие-либо ваши права или обязательства по Условиям без нашего
                предварительного письменного согласия. Однако мы можем передать или
                перенести любые наши права или обязательства по Условиям в любое время
                любому другому лицу, включая, помимо прочего, в связи с любым слиянием,
                приобретением или другой корпоративной реорганизацией с участием DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.6. &nbsp; &nbsp;Недействительность. Если в любое время какой-либо пункт
                или подпункт Условий является или становится незаконным, недействительным
                или не имеющим юридической силы в какой-либо степени, это никоим образом не
                повлияет на законность, действительность или юридическую силу остальных
                пунктов или подпунктов.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.7. &nbsp; &nbsp;Записи. Вы соглашаетесь с тем, что мы можем записывать
                любые разговоры с вами по телефону, электронной почте и в чате, а также
                любые другие формы связи, включая коммуникации для отправки Указаний или
                осуществления Транзакций, между вами и нами, и что эти записи могут быть
                использованы в качестве доказательств в ходе любых разбирательств, связанных
                с любым соглашением с вами. Эти записи будут являться нашей исключительной
                собственностью.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.8. &nbsp; &nbsp;Язык. Настоящие Условия могут быть переведены на язык,
                отличный от английского. Любой такой перевод предоставляется исключительно
                для вашего удобства. В случае расхождений или неопределенности текст на
                английском языке будет иметь преимущественную силу.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.9. &nbsp; &nbsp;Права третьих лиц. За исключением случаев, касающихся
                Партнеров DuneEX, ничто выраженное или упомянутое в настоящих Условиях не
                должно быть истолковано как предоставление любому лицу, кроме сторон
                настоящих Условий, какого-либо юридического или основанного на
                справедливости права, средства правовой защиты или претензии в соответствии
                или в связи с настоящими Условиями или любым пунктом или подпунктом
                настоящих Условий. Настоящие Условия и все их пункты и подпункты
                предназначены только для единственной и исключительной выгоды сторон
                настоящих Условий и их преемников и разрешенных цессионариев.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.10. &nbsp; &nbsp;Сохранение силы. Все пункты и подпункты настоящих
                Условий, которые по своей природе распространяются на период после истечения
                срока или прекращения действия настоящих Условий, продолжают быть
                обязательными и действовать после истечения срока или прекращения действия
                настоящих Условий.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.11. &nbsp; &nbsp;Отношения сторон. DuneEX не является вашим агентом
                при выполнении настоящих Условий. Настоящие Условия не должны толковаться
                как факты или свидетельства ассоциации, совместного предприятия, партнерства
                или франшизы между сторонами.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.12. &nbsp; &nbsp;Цифровые активы. Мы ведем в вашем Аккаунте DuneEX
                запись количества и типа любых цифровых активов, которые хранятся в вашем
                Аккаунте. DuneEX не является доверенным лицом и не несет никаких
                обязанностей доверенного лица в отношении любых Цифровых активов, хранящихся
                в вашем Аккаунте. Когда вы отправляете DuneEX Указание перевести Цифровые
                активы или иным образом поступить с ними, DuneEX не будет использовать
                какие-либо конкретные Цифровые активы, как бы они ни были идентифицированы,
                для выполнения ваших Указаний. В соответствии с настоящими Условиями DuneEX  будет использовать Цифровые активы в том же количестве и того же типа,
                которые были зачислены в ваш Аккаунт DuneEX при выполнении ваших
                Указаний.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.13. &nbsp; &nbsp;Форс-мажор. Мы не несем ответственности за любую
                задержку или невыполнение требований настоящих Условий в той мере, в какой
                задержка или невыполнение вызваны форс-мажорными обстоятельствами.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.14. &nbsp; &nbsp;Сохранение прав. Никакая задержка или бездействие с
                нашей стороны в осуществлении любого права или применении любого средства
                правовой защиты в соответствии с настоящими Условиями не может расцениваться
                как отказ от осуществления такого права или применения такого средства
                правовой защиты либо любых других прав или средств правовой защиты в будущем
                в соответствии с Условиями. Права и средства правовой защиты,
                предусмотренные Условиями, являются совокупными и не исключают любые права
                или средства правовой защиты, предусмотренные Применимым законодательством.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.15. &nbsp; &nbsp;Зачет. В дополнение к любым юридическим или иным
                средствам правовой защиты, доступным в соответствии с Условиями или по
                закону, мы можем зачесть любые суммы, которые вы должны нам в соответствии с
                Условиями или иным образом. Вы должны выплачивать все суммы, которые вы
                должны нам, без зачетов, встречных претензий, вычетов или удержаний любого
                рода, кроме случаев, предусмотренных применимым законодательством.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.16. &nbsp; &nbsp;Конфиденциальность третьих лиц. Если вы получаете
                информацию о другом пользователе через Платформу или в результате
                использования Услуг DuneEX, вы должны сохранять конфиденциальность
                информации и использовать ее только в связи с Услугами DuneEX и всегда в
                соответствии с Применимым законодательством. Вы не должны раскрывать или
                распространять любую информацию о пользователе третьим лицам или
                использовать ее каким-либо образом, за исключением случаев, когда это
                разумно необходимо для совершения Транзакции.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.17. &nbsp; &nbsp;Публикация нарушений. Если вы нарушили настоящие
                Условия, DuneEX может опубликовать или иным образом предоставить своим
                пользователям сведения о нарушении, включая любую информацию, которую вы
                предоставили DuneEX. DuneEX может это сделать только при необходимости
                для защиты других пользователей, и если это разрешено Применимым
                законодательством.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.18. &nbsp; &nbsp;Смерть владельца Аккаунта DuneEX. В случае вашей
                смерти или недееспособности администратор(ы) вашего наследства или
                оставшийся(-иеся) в живых наследник(и) должен (должны) уведомить нас в
                письменном виде как можно скорее. Если у нас есть основания полагать, что вы
                умерли, мы можем приостановить действие вашего Аккаунта DuneEX. Ваш
                Аккаунт DuneEX будет приостановлен до тех пор, пока не будут выполнены
                следующие условия:&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;администратор вашего наследства или уполномоченный
                бенефициар либо (1) создаст Аккаунт DuneEX в соответствии с настоящими
                Условиями; либо (2) предоставит указания по банковскому переводу; и
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;предоставит достаточные юридические документы,
                подтверждающие, что он имеет право на получение активов в вашем Аккаунте
                DuneEX; или
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;вы предоставите удовлетворительное доказательство того, что
                вы не умерли.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Бенефициары, получающие банковский перевод, получат ликвидационную стоимость
                активов в Аккаунте DuneEX за вычетом любых комиссий и расходов, связанных
                с переводом. Наша способность предоставить вашему(-им) представителю(-ям)
                активы в вашем Аккаунте DuneEX зависит от ограничений, налагаемых
                Применимым законодательством и настоящими Условиями. Мы не обязуемся
                соблюдать какие-либо конкретные сроки перевода активов, хранящихся в вашем
                Аккаунте DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                36.19. &nbsp; &nbsp;Налог. Вы несете ответственность за определение того,
                какие налоги, если таковые имеются, применяются к платежам, которые вы
                совершаете или получаете, а также за сбор, декларацию и перечисление
                правильного налога в соответствующий налоговый орган. Вы соглашаетесь, что
                мы не несем ответственности за определение того, применяются ли какие-либо
                налоги к вашему использованию Услуг DuneEX, или за сбор, декларацию или
                перечисление любых налогов, возникающих в результате любой Транзакции или
                использования Услуг DuneEX.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Вы признаете, что мы можем предоставлять определенные отчеты налоговым
                органам в отношении Транзакций, совершенных на Платформе, и что мы можем по
                нашему усмотрению или в соответствии с Применимым законодательством
                предоставлять вам дополнительную документацию или записи, необходимые вам
                для расчета любых налоговых обязательств. Мы также можем по своему
                усмотрению удерживать и вычитать у источника любые налоги, причитающиеся в
                соответствии с Применимым законодательством.
            </p>
            <p>
                <br />
            </p>
            <p>37. &nbsp; &nbsp;Определения и интерпретация</p>
            <p>
                <br />
            </p>
            <p>&nbsp; &nbsp; В настоящих Условиях:</p>
            <p>
                <br />
            </p>
            <p>
                a. &nbsp; &nbsp;заголовки и нумерация пунктов приведены исключительно для
                удобства и не влияют на значение, приоритет или толкование любого пункта или
                подпункта настоящих Условий;
            </p>
            <p>
                <br />
            </p>
            <p>
                b. &nbsp; &nbsp;слова «включает» или «включая» означают «включает без
                ограничений» и «включая без ограничений» соответственно;
            </p>
            <p>
                <br />
            </p>
            <p>
                c. &nbsp; &nbsp;любое обязательство совершить или не совершать какое-либо
                действие подразумевает обязательство не допускать совершения или не
                совершения этого действия или вещи в отношении себя или других лиц;
            </p>
            <p>
                <br />
            </p>
            <p>
                d. &nbsp; &nbsp;слова, обозначающие единственное число, включают
                множественное число и наоборот, а слова, обозначающие пол, включают любой
                пол;&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                e. &nbsp; &nbsp;в любой упоминаемый документ периодически вносятся поправки,
                изменения или дополнения, за исключением случаев, нарушающих настоящие
                Условия или условия такого документа;
            </p>
            <p>
                <br />
            </p>
            <p>
                f. &nbsp; &nbsp;в случае расхождений между настоящими Условиями (включая
                любые документы, упомянутые в настоящих Условиях) такие положения и
                документы имеют преимущественную силу в следующем порядке:
            </p>
            <p>
                <br />
            </p>
            <p>I. &nbsp; &nbsp;Условия продукта;</p>
            <p>
                <br />
            </p>
            <p>II. &nbsp; &nbsp;Уведомление о конфиденциальности;</p>
            <p>
                <br />
            </p>
            <p>III. &nbsp; &nbsp;настоящие Условия.</p>
            <p>
                <br />
            </p>
            <p>
                g. &nbsp; &nbsp;за исключением случаев, когда контекст требует иного,
                следующие термины имеют следующие значения:
            </p>
            <p>
                <br />
            </p>
            <p>
                Идентификаторы доступа означает данные вашего Аккаунта DuneEX, имя
                пользователя, пароли, персональные идентификационные номера, ключи API,
                секретные ключи API или любые другие коды или формы аутентификации, которые
                вы используете для доступа к вашему Аккаунту DuneEX или Услугам DuneEX
                или для отправки Указаний.
            </p>
            <p>
                <br />
            </p>
            <p>
                История аккаунта означает письменные записи (включая электронные записи) о
                ваших Транзакциях и вашем Аккаунте DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>API означает программный интерфейс приложения.</p>
            <p>
                <br />
            </p>
            <p>
                Применимое законодательство означает все соответствующие или применимые
                статуты, законы (включая нормы общего права), принципы справедливости,
                правила, положения, нормативные принципы и требования, уведомления, приказы,
                предписания, судебные запреты, судебные решения, подзаконные правовые акты,
                постановления, директивы, официальные объявления, циркуляры, обязательные
                кодексы поведения, руководства, практические руководства и толкования (будь
                то от правительственного органа, регулирующего или иного органа или
                саморегулируемой организации, членом которой является DuneEX), которые
                применимы к предоставлению, получению или использованию Услуг DuneEX или
                любых других продуктов или результатов, предоставляемых, используемых или
                получаемых в связи с Услугами DuneEX, настоящими Условиями или любыми
                Условиями продукта.
            </p>
            <p>
                <br />
            </p>
            <p>Обеспеченные цифровые активы имеет значение, указанное в п. ‎15.3.</p>
            <p>
                <br />
            </p>
            <p>
                DuneEX, мы, наш или нам означает Nest Services Limited (компания,
                зарегистрированная в соответствии с законодательством Республики Сейшельские
                Острова с регистрационным номером 238045 и юридическим адресом: House of
                Francis, Room 303, Ile Du Port, Mahe, Seychelles).
            </p>
            <p>
                <br />
            </p>
            <p>
                Аккаунт DuneEX означает любые аккаунты (включая Корпоративные аккаунты
                DuneEX) или субаккаунты (включая любой Субаккаунт DuneEX), которые
                DuneEX открыла для вас с целью учета использования вами Услуг DuneEX .&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Партнеры DuneEX означает в отношении Nest Services Limited: любое другое
                лицо, которое прямо или косвенно Контролирует, Контролируется или находится
                под общим Контролем вместе с Nest Services Limited.
            </p>
            <p>
                <br />
            </p>
            <p>
                API DuneEX означает API, предоставляемый вам Партнером DuneEX в
                качестве услуги, или приложения третьих лиц с таким API.
            </p>
            <p>
                <br />
            </p>
            <p>
                ИС DuneEX означает Созданную ИС и все другие Права интеллектуальной
                собственности, принадлежащие нам или лицензированные нами на сублицензионной
                основе на дату Условий, а также любые другие Права интеллектуальной
                собственности, принадлежащие нам, или приобретенные нами, или
                лицензированные нами на сублицензионной основе после даты настоящих Условий,
                предоставляемые нами вам в ходе предоставления вам Услуг DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>
                Услуги DuneEX означает услуги, предлагаемые нами вам через Платформу.
            </p>
            <p>
                <br />
            </p>
            <p>
                Субаккаунт DuneEX означает субаккаунт, созданный в рамках основного
                Аккаунта DuneEX.
            </p>
            <p>
                <br />
            </p>
            <p>Услуга чата имеет значение, указанное в п. ‎5.</p>
            <p>
                <br />
            </p>
            <p>
                Претензия означает любые споры, требования, различия или разногласия между
                вами и DuneEX (и/или любыми Партнерами DuneEX), возникающие из, в
                связи с или каким-либо образом относящиеся к:
            </p>
            <p>
                <br />
            </p>
            <p>
            (a) &nbsp; &nbsp;настоящим Условиям или каким-либо Условиям продукта,
            включая существование, действительность, предмет, толкование, исполнение,
            нарушение, обсуждение, прекращение, возможность принудительного исполнения
            или последствия недействительности таких условий;
        </p>
    <p>
        <br />
    </p>
    <p>
        (b) &nbsp; &nbsp;вашим отношениям с DuneEX (и/или Партнерами DuneEX) в
        качестве пользователя (независимо от того, основаны ли они на договоре,
        правонарушении, законе, мошенничестве, введении в заблуждение или любых
        других юридических нормах, и независимо от того, возникли ли претензии в
        период или после прекращения действия настоящих Условий); или
    </p>
    <p>
        <br />
    </p>
    <p>
        (c) &nbsp; &nbsp;любым внедоговорным обязательствам, возникающим из или
        относящимся к настоящим Условия, любым Условиям продуктов или вашим
        отношеним с DuneEX (и/или любыми Партнерами DuneEX).
    </p>
    <p>
        <br />
    </p>
    <p>
        Контроль означает полномочия лица обеспечивать ведение дел другого лица в
        соответствии с желаниями первого лица, будь то посредством:
    </p>
    <p>
        <br />
    </p>
    <p>
        (a) &nbsp; &nbsp;в случае компании, являясь бенефициарным владельцем более
        пятидесяти процентов (50%) выпущенного акционерного капитала или прав голоса
        в этой компании, или имея право назначать и смещать большинство директоров
        или иным образом контролировать голоса на заседаниях совета директоров этой
        компании в силу любых полномочий, предоставленных организационными
        документами, соглашением акционеров, большинством голосов совета директоров
        или любым другим документом, регулирующим деятельность этой компании, или
        любым другим способом; или
    </p>
    <p>
        <br />
    </p>
    <p>
        (b) &nbsp; &nbsp;в случае партнерства, являясь бенефициарным владельцем
        более пятидесяти процентов (50%) капитала этого партнерства, или имея право
        контролировать состав или голоса большинства участников руководства этого
        партнерства в силу любых полномочий, предоставленных соглашением о
        партнерстве или любым другим документом, регулирующим дела этого
        партнерства, или любым другим способом.
    </p>
    <p>
        <br />
    </p>
    <p>
        Корпоративный аккаунт DuneEX означает Аккаунт DuneEX, который
        предоставляет корпорации, юридическому лицу или иной организации доступ к
        Услугам DuneEX.
    </p>
    <p>
        <br />
    </p>
    <p>
        Созданная ИС означает любые права интеллектуальной собственности, созданные
        вами в соответствии с настоящими Условиями, включая Пользовательские
        материалы, но исключая любую другую ИС пользователя.
    </p>
    <p>
        <br />
    </p>
    <p>
        Цифровые активы означает цифровое представление стоимости или прав, которые
        могут передаваться и храниться в электронном виде с использованием
        технологии распределенного реестра или схожей технологии, включая, помимо
        прочего, криптовалюты, стейблкоины, невзаимозаменяемые токены и
        токенизированные деривативы любых других цифровых активов.
    </p>
    <p>
        <br />
    </p>
    <p>
        Доминирующий цифровой актив означает Прошедший форк цифровой актив, который
        доминирует над одной или несколькими другими версиями Цифрового актива,
        связанного с соответствующим Форком, что определяется нами по нашему
        усмотрению.
    </p>
    <p>
        <br />
    </p>
    <p>
        Электронная почта означает электронную(-ые) почту(-ы), связанную(-ые) с
        вашим(и) Аккаунтом(-ами) DuneEX, как периодически согласовывается с
        DuneEX, в соответствии с любыми процессами, определенными DuneEX при
        использовании Платформы.
    </p>
    <p>
        <br />
    </p>
    <p>
        Фиатная валюта означает любую выпущенную правительством или центральным
        банком национальную или наднациональную валюту или другое денежное
        обязательство, выраженное в такой валюте и не являющееся Цифровым активом.
    </p>
    <p>
        <br />
    </p>
    <p>Форс-мажорные обстоятельства означает:&nbsp;</p>
    <p>
        <br />
    </p>
    <p>
        a. &nbsp; &nbsp;любой пожар, забастовку, бунт, гражданские беспорядки,
        террористический акт, войну или промышленный конфликт;
    </p>
    <p>
        <br />
    </p>
    <p>
        b. &nbsp; &nbsp;любые стихийные бедствия, такие как наводнения, торнадо,
        землетрясения и ураганы;
    </p>
    <p>
        <br />
    </p>
    <p>
        c. &nbsp; &nbsp;любую эпидемию, пандемию или чрезвычайную ситуацию в области
        общественного здравоохранения, имеющую национальное или международное
        значение;
    </p>
    <p>
        <br />
    </p>
    <p>
        d. &nbsp; &nbsp;любой акт или постановление правительства, наднационального
        органа или учреждения, который, по нашему мнению, препятствует
        предоставлению нами Услуг DuneEX на Платформе;
    </p>
    <p>
        <br />
    </p>
    <p>e. &nbsp; &nbsp;приостановление или закрытие любого Партнера DuneEX;</p>
    <p>
        <br />
    </p>
    <p>f. &nbsp; &nbsp;национализация любого Партнера DuneEX;</p>
    <p>
        <br />
    </p>
    <p>
        g. &nbsp; &nbsp;введение правительством ограничений или необычных условий
        для любых Цифровых активов, торгуемых на нашей Платформе;
    </p>
    <p>
        <br />
    </p>
    <p>
        h. &nbsp; &nbsp;чрезмерные изменения цены, предложения или спроса на любой
        Цифровой актив;
    </p>
    <p>
        <br />
    </p>
    <p>
        i. &nbsp; &nbsp;технические сбои в средствах передачи информации, средствах
        связи или компьютерном оборудовании, включая сбои в подаче электроэнергии и
        сбои в работе электроники или оборудования;
    </p>
    <p>
        <br />
    </p>
    <p>
        j. &nbsp; &nbsp;неспособность любого поставщика, брокера-посредника, агента,
        основного кастодиала, вспомогательного кастодиала, дилера, биржи, платформы
        стейкинга, пула ликвидности, провайдера моста, эмитента Обеспеченного
        цифрового актива, маркет-мейкера, клиринговой палаты или регулирующей
        организации выполнить свои обязательства перед нами;&nbsp;
    </p>
    <p>
        <br />
    </p>
    <p>
        k. &nbsp; &nbsp;поставщики ликвидности не предоставляют или не могут
        предоставить нам ликвидность;&nbsp;
    </p>
    <p>
        <br />
    </p>
    <p>
        l. &nbsp; &nbsp;любые трудовые или торговые споры, забастовки, промышленные
        конфликты или локауты (кроме случаев, когда это инициирует DuneEX или
        Партнеры DuneEX); и/или
    </p>
    <p>
        <br />
    </p>
    <p>
        m. &nbsp; &nbsp;событие, которое существенно влияет на рынок Цифровых
        активов, включая чрезмерное изменение цены, предложения или спроса на
        Цифровой актив, как регулируемое, так и нерегулируемое.
    </p>
    <p>
        <br />
    </p>
    <p>
        Форк означает любые запланированные, незапланированные, внезапные,
        намеченные, ожидаемые, неожиданные, обнародованные, малоизвестные,
        согласованные и/или спорные изменения основных правил эксплуатации
        определенных Цифровых активов, которые могут периодически происходить таким
        образом, чтобы привести к созданию одной или нескольких связанных версий
        существующего Цифрового актива.
    </p>
    <p>
        <br />
    </p>
    <p>
        Прошедший форк цифровой актив означает Цифровой актив, возникший в
        результате Форка.
    </p>
    <p>
        <br />
    </p>
    <p>
        Указание означает любое указание, запрос или приказ, переданный вами или
        Разрешенным пользователем компании DuneEX в связи с работой вашего
        Аккаунта DuneEX или для исполнения любой Транзакции, через такую среду, в
        такой форме и таким способом, которые могут потребоваться компании DuneEX .
    </p>
    <p>
        <br />
    </p>
    <p>
        Права интеллектуальной собственности означает: (a) авторские права, патенты,
        права на базы данных и права на торговые марки, дизайн, ноу-хау и
        конфиденциальную информацию (как зарегистрированные, так и
        незарегистрированные); (b) заявки на регистрацию и права на подачу заявки на
        регистрацию любого из вышеперечисленных прав; и (c) все другие права
        интеллектуальной собственности и эквивалентные или аналогичные формы защиты,
        существующие в любой точке мира.
    </p>
    <p>
        <br />
    </p>
    <p>
        Местные условия означает условия, регулирующие использование вами Услуг
        DuneEX, предоставляемых в любой конкретной юрисдикции.
    </p>
    <p>
        <br />
    </p>
    <p>Убытки имеет значение, указанное в п. ‎29.1.</p>
    <p>
        <br />
    </p>
    <p>
        Явная ошибка означает любую ошибку, упущение или неправильное цитирование
        (будь то ошибка DuneEX или третьей стороны), которое является явным или
        ощутимым, включая неправильное цитирование любым представителем DuneEX с
        учетом текущих рыночных условий, или любую ошибку любой информации,
        источника, официального лица, официального результата или произношения.
    </p>
    <p>
        <br />
    </p>
    <p>
        Разрешенный пользователь означает любое лицо, указанное вами и сообщенное
        нам в соответствии с настоящими Условиями, которое уполномочено действовать
        от имени пользователя в отношении любого Корпоративного аккаунта DuneEX.
    </p>
    <p>
        <br />
    </p>
    <p>
        Платформа означает цифровую платформу, которую мы или любой из Партнеров
        DuneEX можем сделать доступной для вас через Сайты, Мобильное приложение,
        API DuneEX или другими способами, которые Партнеры DuneEX могут
        периодически предписывать для использования Услуг DuneEX.
    </p>
    <p>
        <br />
    </p>
    <p>
        Уведомление о конфиденциальности означает уведомление о конфиденциальности
        по адресу{" "}
        <a data-fr-linked="true" href="https://www.dune-ex.net">
            https://www.dune-ex.net
        </a>{" "}
        FX.com/en/privacy.&nbsp;
    </p>
    <p>
        <br />
    </p>
    <p>
        Условия продукта означает условия и положения, относящиеся к конкретному
        продукту, которые применяются к использованию Услуги DuneEX в дополнение
        к настоящим Условиям.
    </p>
    <p>
        <br />
    </p>
    <p>
        Регулирующий орган означает любой иностранный, национальный,
        государственный, федеральный, кантональный, муниципальный или местный
        правительственный, исполнительный, законодательный, судебный,
        административный, надзорный или регулирующий орган, агентство,
        квазиправительственный орган, суд, комиссию, правительственную организацию,
        саморегулируемую организацию с регулирующими полномочиями, трибунал,
        арбитражный трибунал, арбитражную комиссию или наднациональную организацию
        или любое их подразделение или орган, включая любой налоговый орган.
    </p>
    <p>
        <br />
    </p>
    <p>
        Лицо с ограниченным доступом означает физическое или юридическое лицо,
        которое (a) включено в любое торговое эмбарго или экономические санкции,
        список террористов или коррумпированных иностранных должностных лиц
        (например, Санкционный перечень Совета Безопасности ООН, изданный
        правительственным агентством, включая Список лиц особых категорий и
        запрещенных лиц Управления по контролю за иностранными активами (OFAC)
        Министерства финансов США или список запрещенных лиц и организаций
        Министерства торговли США, а также списки Великобритании, Европейского союза
        и Канады)
    </p>
    <p>
        Предупреждение о рисках означает общее предупреждение о рисках,
        опубликованное на Сайте.
    </p>
    <p>
        <br />
    </p>
    <p>Нарушение безопасности означает&nbsp;</p>
    <p>
        <br />
    </p>
    <p>
        a. &nbsp; &nbsp;взлом вашего(-их) Аккаунта(-ов) DuneEX или любого из
        ваших Идентификаторов доступа;
    </p>
    <p>
        <br />
    </p>
    <p>
        b. &nbsp; &nbsp;потерю, кражу, несанкционированное использование любого из
        ваших Идентификаторов доступа или любой несанкционированный доступ и
        использование вашего Аккаунта DuneEX или Услуг DuneEX от вашего имени;
        или
    </p>
    <p>
        <br />
    </p>
    <p>
        c. &nbsp; &nbsp;любой другой инцидент в системе безопасности (включая
        кибератаку), затрагивающий вас и/или DuneEX.
    </p>
    <p>
        <br />
    </p>
    <p>
        Сайты означает наш Сайт и любые другие сайты, страницы, функции или контент,
        которыми мы владеем или управляем.
    </p>
    <p>
        <br />
    </p>
    <p>
        Поддерживаемые цифровые активы означает те Цифровые активы, которые доступны
        в связи с использованием вами Услуг DuneEX.
    </p>
    <p>
        <br />
    </p>
    <p>
        Условия означает (при каждом упоминании, а также периодических изменениях
        или дополнениях) настоящие условия использования, а также любые другие явным
        образом упоминаемые документы, включая Условия продукта.
    </p>
    <p>
        <br />
    </p>
    <p>
        Торговые марки означает Права интеллектуальной собственности на торговые
        марки, знаки обслуживания и логотипы, используемые и отображаемые на
        Платформе, Сайтах и/или Услугах DuneEX или через них.
    </p>
    <p>
        <br />
    </p>
    <p>
        Транзакция означает продажу, покупку или заключение любого другого типа
        транзакций либо согласие на продажу, покупку или заключение любого другого
        типа транзакций с Цифровым(и) активом(-ами), их деривативами, другим(и)
        активом(-ами) или продуктом(-ами), которые DuneEX может периодически
        разрешать осуществлять на Платформе, а также ввод или вывод Цифровых активов
        на ваш Аккаунт DuneEX или с него.
    </p>
    <p>
        <br />
    </p>
    <p>
        ИС пользователя означает Права интеллектуальной собственности, принадлежащие
        вам или лицензированные вами на дату настоящих Условий, и любые другие Права
        интеллектуальной собственности, принадлежащие вам или приобретенные вами или
        лицензированные вами после даты настоящих Условий, за исключением ИС DuneEX .&nbsp;
    </p>
    <p>
        <br />
    </p>
    <p>
        Пользовательские материалы означает права Интеллектуальной собственности на
        любые обзоры, публикации, информацию, данные и комментарии, которые вы или
        другие пользователи предоставляете нам на Сайтах (через наши страницы
        «Свяжитесь с нами» или иным образом), Платформе, посредством использования
        Услуг DuneEX или иным образом.
    </p>
    <p>
        <br />
    </p>
    <p>Сайт означает сайт по адресу www.dune-ex.net</p>
    <p>
        <br />
    </p>
    <p>
        <br />
    </p>
</div> : ""}

            {currentLanguage === "en" ? <div className="comps__content">

                <p className="footer-title-comp" style={{color: "#FFFFFF"}}>TERMS OF USE</p>

                <p>&nbsp;</p>

                <p>Last updated: February 11, 2024</p>

                <p>&nbsp;</p>

                <p>These Terms constitute a legally binding agreement between you
                    (&ldquo;you,&rdquo; &ldquo;your&rdquo;) and DuneEX
                    (&ldquo;DuneEX,&rdquo; &ldquo;we,&rdquo; &ldquo;our,&rdquo; &ldquo;us&rdquo;). These Terms govern
                    your use of the DuneEX Services, which are made available to you on the Platform or otherwise. The
                    DuneEX Services may be provided by DuneEX or, where specified in these Terms, in any Product Terms
                    or any supplementary terms, by any DuneEX Partner.</p>

                <p>&nbsp;</p>

                <p>By registering for a DuneEX Account, accessing the Platform, and/or using the DuneEX Services, you
                    acknowledge that you have read, understood, and accepted these Terms, as well as any additional
                    documents or terms referred to in these Terms. You acknowledge and agree that you are bound to
                    comply with these Terms, which will be updated and modified periodically.</p>

                <p>&nbsp;</p>

                <p>If you do not understand and accept these Terms in full, you should not register for a DuneEX
                    Account, access the Platform, or use any DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>Risk Warning</p>

                <p>The value of Digital Assets can fluctuate significantly, resulting in the risk of economic losses if
                    you buy, sell, hold, or invest in Digital Assets. Therefore, you need to assess whether trading or
                    holding Digital Assets is suitable for you, considering your financial circumstances.</p>

                <p>&nbsp;</p>

                <p>You must fully understand all risks before deciding to use DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>We are not your broker, intermediary, agent, or advisor, and we do not have any fiduciary
                    relationship or obligation to you in connection with any Transactions or other actions you take when
                    using DuneEX Services. We do not provide investment or advisory advice of any kind, and any
                    information or communication that we provide to you does not constitute and should not be considered
                    as advice of any kind.</p>

                <p>&nbsp;</p>

                <p>You alone determine whether any investments, investment strategies, or related transactions are
                    suitable for your personal investment goals, financial situation, and permissible risk, and you are
                    responsible for any related losses or liabilities. We do not recommend that you buy, earn, sell, or
                    hold any Digital Asset. Before deciding to buy, sell, or hold any Digital Asset, you should conduct
                    your own research and consult with a financial professional. We are not responsible for your
                    decisions to buy, earn, sell, or hold Digital Assets based on information we provide, including any
                    losses you may incur as a result of such decisions.</p>

                <p><br/>
                    <strong>INFORMATION ABOUT OUR AGREEMENT WITH CLIENTS</strong></p>

                <p>&nbsp;</p>

                <p>1. Introduction</p>

                <p>&nbsp;</p>

                <p>1.1. About Us. The DuneEX Group is an ecosystem centered around an online exchange for trading
                    Digital Assets. The DuneEX Group provides users with a trading platform for buying and selling
                    Digital Assets, an integrated custodial solution that allows users to store their Digital Assets,
                    and other services related to Digital Assets.</p>

                <p>&nbsp;</p>

                <p>1.2. These Terms. By registering to create a DuneEX Account, you enter into a legally binding
                    agreement with us. These Terms will govern your use of DuneEX Services and inform you about who we
                    are, how we will provide DuneEX Services to you, how these Terms may be amended or terminated, what
                    to do in case of problems, and other important information.</p>

                <p>&nbsp;</p>

                <p>You must read these Terms and the documents referenced in the Terms carefully and notify us if you do
                    not understand anything.</p>

                <p>&nbsp;</p>

                <p>If any Local Terms apply to your use of DuneEX Services, such Local Terms will take precedence over
                    these Terms.</p>

                <p>&nbsp;</p>

                <p>1.3. Additional Documents. These Terms refer to several additional documents that also apply to your
                    use of DuneEX Services. These include:</p>

                <p>&nbsp;</p>

                <p>a. Our Privacy Notice, which explains how we process your personal data and ensures that the data you
                    provide is accurate and up to date.</p>

                <p>&nbsp;</p>

                <p>b. Our Risk Warning, which outlines important information about the risks that may arise when buying,
                    selling, holding, or investing in Digital Assets.</p>

                <p>&nbsp;</p>

                <p>d. Product Terms, which outline additional terms and conditions that will apply to your use of
                    specific DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>You acknowledge and agree to comply with any applicable additional terms and conditions that apply to
                    your use of DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>2. Eligibility</p>

                <p>&nbsp;</p>

                <p>2.1. Eligibility Criteria. To be eligible to register a DuneEX Account and use DuneEX Services, you
                    must meet the following criteria:</p>

                <p>&nbsp;</p>

                <p>a. Be an individual, corporation, legal entity, company, or other organization that has all necessary
                    powers, rights, and capacities to (1) access and use DuneEX Services; and (2) enter into and perform
                    your obligations under these Terms;</p>

                <p>&nbsp;</p>

                <p>b. If you are an individual, be at least 18 years old;</p>

                <p>&nbsp;</p>

                <p>c. If you act as an employee or agent of a legal entity and enter into these Terms on its behalf, you
                    must be duly authorized to act on behalf of such legal entity and bind it to the obligations under
                    these Terms;</p>

                <p>&nbsp;</p>

                <p>d. You have not previously been suspended or had DuneEX Services terminated;</p>

                <p>&nbsp;</p>

                <p>e. You are not a Restricted Person;</p>

                <p>&nbsp;</p>

                <p>f. You do not have an existing DuneEX Account;</p>

                <p>&nbsp;</p>

                <p>g. You are not located, registered, or otherwise established in a country, are not a citizen or
                    resident of a country, and do not conduct business in a country that is:</p>

                <p>&nbsp;</p>

                <p>i. A jurisdiction where, under Applicable Law, your access or use of DuneEX Services is illegal or
                    would cause us or any third party to violate any Applicable Law.</p>

                <p>&nbsp;</p>

                <p>2.2. Changing Our Eligibility Criteria. We may change our eligibility criteria at any time at our
                    discretion. We will notify you of any changes in advance whenever possible. However, sometimes
                    changes may need to be made without prior notice, such as:</p>

                <p>&nbsp;</p>

                <p>a. Changes are made due to legislative and/or regulatory changes;</p>

                <p>&nbsp;</p>

                <p>b. Changes are made in your interest; and/or</p>

                <p>&nbsp;</p>

                <p>c. There is any other good reason that prevents us from giving prior notice.</p>

                <p>&nbsp;</p>

                <p>If we cannot notify you in advance, we will inform you of the changes as soon as possible after they
                    have been made.</p>

                <p>&nbsp;</p>

                <p>3. Means of Communication</p>

                <p>&nbsp;</p>

                <p>3.1. How You Can Contact Us. For more information about DuneEX, you can refer to the information on
                    our Website. If you have any questions, feedback, or complaints, you can contact us through our
                    Support Service at https://dune-ex.net/contacts. These Terms may provide contact details for
                    specific types of messages. We do not accept such messages at the address provided above.</p>

                <p>&nbsp;</p>

                <p>3.2. How We Will Contact You. We will contact you using the information you provide. Means of
                    communication may include email, SMS, or phone. Ensure that you provide accurate and up-to-date
                    contact details. If your contact details change, you must notify us immediately. If you do not do
                    so, we will not be responsible if you do not receive any information, notifications, or other
                    important information from us.</p>

                <p>&nbsp;</p>

                <p>4. DuneEX Services</p>

                <p>&nbsp;</p>

                <p>4.1. Specific Product Terms. Once you create a DuneEX Account, you will be able to use DuneEX
                    Services in accordance with these Terms and the Product Terms that govern your use of each specific
                    DuneEX Service.</p>

                <p>&nbsp;</p>

                <p>4.2. Intra-Group Services. You acknowledge and agree that some DuneEX Services may be provided by
                    DuneEX Partners.</p>

                <p>&nbsp;</p>

                <p>5. Chat Service</p>

                <p>&nbsp;</p>

                <p>5.1. Availability of the Chat Service. We may make our interactive online chat service (&quot;Chat
                    Service&quot;) available to you in connection with your use of any DuneEX Services at any time. By
                    using the Chat Service, you may interact with a bot, chatbot, or other non-human feature. We will
                    disclose information about the use of a chatbot or other non-human feature to the extent required
                    under Applicable Law. By interacting with us through the Chat Service, you authorize us to monitor
                    and store your chats.</p>

                <p>&nbsp;</p>

                <p>5.2. Important Information. The Chat Service is provided for convenience, often to help you
                    understand DuneEX Services more easily. Our Chat Service will make reasonable efforts to provide you
                    with accurate and up-to-date information according to your question or need. Nothing we communicate
                    through the Chat Service can be considered a legal agreement, representation, or warranty regarding
                    DuneEX Services, processes, decisions, or response times. Any personal data provided to us when
                    using the Chat Service is subject to applicable policies and privacy notices described in our
                    Privacy Notice.</p>

                <p>&nbsp;</p>

                <p>5.3. User Chats. Additionally, we may make chats available to you that allow you to interact directly
                    with other users of the Platform (&quot;User Chats&quot;).</p>

                <p>&nbsp;</p>

                <p>5.4. Prohibited Actions. You are prohibited from using the Chat Service or any User Chat to send
                    offensive, defamatory, malicious, or obscene messages; messages intended to manipulate the market or
                    spread false or misleading information; messages that otherwise violate Applicable Law. Such actions
                    may result in the termination of the Chat Service session and the restriction of your access to
                    DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>6. Fees</p>

                <p>&nbsp;</p>

                <p>6.1. Payment of Fees. You agree to pay all applicable fees in connection with your use of DuneEX
                    Services.</p>

                <p>&nbsp;</p>

                <p>You authorize us to deduct all applicable fees, charges, interest, payments, and other amounts you
                    owe from your DuneEX Account in accordance with these Terms or any Product Terms using the
                    calculation method. If you owe us an amount in one Digital Asset but do not have enough funds in
                    that Digital Asset, we may deduct the amount owed in another Digital Asset to make the payment (in
                    which case we will convert the Digital Asset you have into the Digital Asset you owe us (or the
                    equivalent in Fiat currency) at the current rate on the Platform or at another commercially
                    reasonable rate at our discretion). If your DuneEX Account does not have enough Digital Assets, you
                    agree that any amount you owe under this clause is a debt, and you must immediately pay it to us in
                    a commercially reasonable manner in the amount and form (in the form of Digital Assets or other
                    assets) determined at our discretion.</p>

                <p>&nbsp;</p>

                <p>6.2. Changing Our Fees. We may periodically adjust our fees in accordance with clause 18.3 of these
                    Terms.</p>

                <p>&nbsp;</p>

                <p><strong>INFORMATION ABOUT YOUR DuneEX ACCOUNT</strong></p>

                <p>7. Creation of a DuneEX Account</p>

                <p>&nbsp;</p>

                <p>7.1. Account Creation. To access the DuneEX Services and Platform, you must create and maintain a
                    DuneEX Account. This may be a DuneEX Account for an individual or a Corporate DuneEX Account for a
                    corporation, legal entity, or other organization.</p>

                <p>&nbsp;</p>

                <p>All DuneEX Accounts are provided at our sole discretion. We reserve the right to reject any
                    application to create a DuneEX Account or limit the number of DuneEX Accounts you can have without
                    any reason.</p>

                <p>&nbsp;</p>

                <p>7.2. Single Beneficiary. By creating a DuneEX Account, you acknowledge that:</p>

                <p>&nbsp;</p>

                <p>a. If you are an individual user, you will use your DuneEX Account solely for yourself and not on
                    behalf of any third parties unless you have received our prior written consent;</p>

                <p>&nbsp;</p>

                <p>b. If you are a corporate user, your Authorized Users will use the Corporate DuneEX Account solely
                    for your benefit and not on behalf of any third parties unless you have received our prior written
                    consent.</p>

                <p>&nbsp;</p>

                <p>You are fully responsible for all activities that occur in your DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>7.3. Identity Verification. Before you are permitted to create a DuneEX Account, access the DuneEX
                    Services, and use them, you will be required to undergo an identity verification procedure by
                    providing us with certain information about yourself and, where applicable, about your Authorized
                    Users. All information you provide must be complete, accurate, and truthful. If this information
                    changes, you must promptly update it.</p>

                <p>&nbsp;</p>

                <p>You authorize us to make inquiries, directly or through third parties, that we consider necessary to
                    verify your identity and the identities of any Authorized Users or to protect you and/or us from
                    fraud, money laundering, terrorist financing, or other financial crimes, and to take any action we
                    deem necessary based on the results of such inquiries.</p>

                <p>&nbsp;</p>

                <p>When we make inquiries, you acknowledge and accept that your personal data and the personal data of
                    any Authorized Users may be disclosed to identity verification agencies, compliance data
                    registration, credit history, fraud prevention, or financial crime prevention agencies, and that
                    these agencies may respond to our inquiries in full.</p>

                <p>&nbsp;</p>

                <p>7.4. Enhanced Due Diligence. We may also require you to undergo enhanced due diligence by providing
                    additional information about yourself, your business, or your Authorized Users, providing additional
                    records or documents, or conducting in-person meetings with DuneEX representatives.</p>

                <p>&nbsp;</p>

                <p>7.5. Records. We store your personal data to allow you to continue using the DuneEX Services. Data is
                    stored for as long as necessary to fulfill the respective purposes described in this Privacy Notice
                    and in accordance with legal requirements, such as for tax and accounting purposes, anti-money
                    laundering compliance, or other purposes communicated to you.</p>

                <p>&nbsp;</p>

                <p>7.6. Subaccounts. At our discretion, you may create and access a DuneEX Subaccount. Each individual
                    associated with a DuneEX Subaccount is subject to the identity verification requirements outlined in
                    this section 7. One DuneEX Subaccount may be associated with only one individual or corporation.</p>

                <p>&nbsp;</p>

                <p>8. Information Requests</p>

                <p>&nbsp;</p>

                <p>8.1. When We May Request Information. We may request information from you at any time for compliance
                    with any Applicable Law or identity verification requirements, or in connection with detecting money
                    laundering, terrorist financing, fraud, or any other financial crime, or for any other legitimate
                    reason. You agree to provide us with any information we request and allow us to retain records of
                    this information for the duration of your DuneEX Account and as long as necessary to fulfill the
                    purposes or for another period required by Applicable Law.</p>

                <p>&nbsp;</p>

                <p>8.2. What Happens When You Provide Information. Your access to your DuneEX Account and the
                    Transaction limits that apply to your use of the DuneEX Services may change as a result of the
                    information collected about you on an ongoing basis. If there are reasonable grounds to suspect that
                    any information you have provided is incorrect, untruthful, outdated, or incomplete, we may send you
                    a notice requesting corrections, deleting the relevant information, or taking other actions we deem
                    necessary to obtain accurate and truthful information from you.</p>

                <p>&nbsp;</p>

                <p>8.3. If You Do Not Provide Requested Information. You are required to provide us with any information
                    we request. If you refuse to provide the requested information or otherwise fail to comply promptly,
                    we reserve the right to immediately suspend or terminate your access to your DuneEX Account or all
                    or part of the DuneEX Services without notice.</p>

                <p>&nbsp;</p>

                <p>9. Access to Your DuneEX Account</p>

                <p>&nbsp;</p>

                <p>9.1. Access. To access your DuneEX Account, you or, where applicable, your Authorized Users must have
                    the necessary equipment (e.g., a computer or smartphone) and access to the internet. Access to your
                    DuneEX Account can be obtained directly on the Platform or through another access means (including
                    API) that we may provide. You are entitled to log in and use your DuneEX Account only using the
                    Access Identifiers that we provide to you or your Authorized Users for these purposes. To ensure the
                    security of your DuneEX Account, we may require enabling multi-factor authentication.</p>

                <p>&nbsp;</p>

                <p>The use of the Platform and other access means may be subject to additional terms, which we will
                    notify you of.</p>

                <p>&nbsp;</p>

                <p>9.2. Restriction on Third-Party Access. You must ensure that any DuneEX Account registered in your
                    name will be used only by you or, in the case of Corporate DuneEX Accounts, by your Authorized
                    Users, unless otherwise provided in these Terms.</p>

                <p>&nbsp;</p>

                <p>10. Account Information and Transaction Records</p>

                <p>&nbsp;</p>

                <p>10.1. Your Account History. You will be able to access your Account History on the Platform. You must
                    carefully review your Account History and notify us if you see any records or Transactions you do
                    not remember or believe to be incorrect within fourteen (14) calendar days from the date of
                    providing or making accessible your Account History.</p>

                <p>&nbsp;</p>

                <p>10.2. Errors. We may correct any error in your Account History at any time and reserve the right to
                    annul or cancel any Transaction involving or resulting from an Obvious Error or amend the details of
                    such Transaction to reflect what we reasonably believe are the correct or fair details of such
                    Transaction without the Obvious Error. Without prejudice to the general nature of other provisions
                    of these Terms and in the absence of fraudulent or bad faith actions, DuneEX shall not be liable to
                    you under any circumstances for any losses or claims of any nature that you may incur or face in
                    connection with any Obvious Error. They may have any cause, be direct or indirect, special or
                    consequential, including but not limited to loss of profit or loss of opportunities, even if DuneEX
                    has been warned of the possibility of such losses or they could reasonably have been foreseen.</p>

                <p>&nbsp;</p>

                <p>10.3. Information Transfer. Under Applicable Law, we may be required to transfer information about
                    your DuneEX Account and Account History to third parties. You acknowledge and accept that we have
                    the right to disclose such information.</p>

                <p><br/>
                    USE OF YOUR ACCOUNT</p>

                <p>&nbsp;</p>

                <p>11. Instructions</p>

                <p>&nbsp;</p>

                <p>11.1. Sending Instructions. You must ensure that any Instruction you send is complete and accurate.
                    We are not obligated to verify the accuracy, authenticity, or validity of any Instruction and will
                    not monitor or reject Instructions on the grounds that they are or appear to be duplicates. However,
                    if we have doubts about the accuracy, authenticity, or validity of an Instruction, we may refuse to
                    execute or delay the execution of any Instruction or request additional information regarding the
                    Instruction.</p>

                <p>&nbsp;</p>

                <p>Instructions are irrevocable, so once an Instruction is sent, neither you nor your Authorized Users
                    have the right to cancel or revoke it without our written consent. Your Instruction is not
                    considered received by us until it has been received by our server. Our record of all Instructions
                    will be final and binding on you for all purposes.</p>

                <p>&nbsp;</p>

                <p>11.2. Actions on your Instructions. By sending an Instruction, you or your Authorized Users authorize
                    us to initiate a Transaction in your DuneEX Account. Accordingly, we are authorized to credit or
                    debit (or provide settlement information to third parties for crediting or debiting by third
                    parties) your Digital Assets to or from your DuneEX Account in accordance with your Instruction. If
                    your DuneEX Account does not have sufficient Digital Assets or Fiat Currency to complete the
                    Transaction (i.e., less than the amount required for the settlement of the Transaction and the
                    payment of all fees related to the Transaction), we have the right to refuse to execute the
                    Transaction. You are responsible for maintaining a sufficient balance of Digital Assets or Fiat
                    Currency in your DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>11.3. Security of Instructions. You acknowledge that Instructions and information transmitted on the
                    Platform or via email are typically transmitted over the internet and may be routed through public,
                    transnational networks that do not have special security protections. We cannot guarantee that
                    transmitted Instructions and information will be completely protected from unauthorized access, and
                    you accept the associated risks.</p>

                <p>&nbsp;</p>

                <p>11.4. Withdrawals. Subject to these Terms and any applicable Product Terms, and provided that there
                    is a sufficient balance in the DuneEX Account and the relevant Digital Assets are not held in your
                    DuneEX Account in connection with the use of any DuneEX Service, you may instruct DuneEX to transfer
                    Digital Assets to an external wallet address by submitting a withdrawal request on the Platform.
                    Upon receiving a withdrawal request, DuneEX will: deduct the balance from your DuneEX Account; and
                    initiate an on-chain transfer to the external wallet specified by you. DuneEX may not process a
                    withdrawal request if, in our reasonable opinion, Applicable Law prevents the relevant withdrawal.
                    DuneEX may also suspend withdrawals for as long as deemed necessary to address any incidents on the
                    Platform. Once such incidents are addressed, DuneEX will resume withdrawals.</p>

                <p>&nbsp;</p>

                <p>12. Transactions</p>

                <p>&nbsp;</p>

                <p>12.1. Conclusion of Transactions. You may enter into Transactions directly with us or directly with
                    other users, with or without our facilitation.</p>

                <p>&nbsp;</p>

                <p>We make no representations or warranties that any Transaction will be completed successfully or
                    within a certain period of time.</p>

                <p>&nbsp;</p>

                <p>12.2. Unauthorized Transactions. You are responsible for the control and use of your DuneEX Account.
                    Therefore, we will assume that you or an Authorized User authorized any Instruction sent from your
                    DuneEX Account unless we are notified otherwise. You need to monitor your Account History to
                    promptly identify and report any unauthorized or suspicious activity in your DuneEX Account. We are
                    not liable for any claims or losses resulting from a Transaction made as a result of an unauthorized
                    Instruction if you have not notified us in accordance with this clause.</p>

                <p>&nbsp;</p>

                <p>12.3. Storage of Transaction Information. To comply with global industry standards for data storage,
                    you agree to allow us (but not require us) to store a record of all Transaction information for the
                    duration of your DuneEX Account as long as necessary to fulfill their intended purposes, or for any
                    other period required by Applicable Law.</p>

                <p>&nbsp;</p>

                <p>13. Material Interests and Conflicts</p>

                <p>&nbsp;</p>

                <p>13.1. DuneEX Group. You understand that DuneEX is a member of a group of companies engaged in
                    activities related to Digital Assets.</p>

                <p>&nbsp;</p>

                <p>13.2. Nature of our Duties. You understand and accept that neither your relationship with us, nor any
                    DuneEX Service provided by us to you, nor any other reason will create any duties on our part or on
                    the part of any DuneEX Partner, whether legal, equitable, or fiduciary duties, other than those
                    expressly stated in these Terms. In particular, we and DuneEX Partners may act in more than one
                    capacity from time to time, and in those capacities, we may receive commissions or fees from more
                    than one user (including you). You accept that we may act in such capacities and provide any other
                    DuneEX Services or conduct any business with you, any DuneEX Partner, or any other user.</p>

                <p>&nbsp;</p>

                <p>13.3. Material Interests. You understand and accept that neither we nor DuneEX Partners are obligated
                    to: (1) take into account any information known to us or a DuneEX Partner that constitutes a
                    material interest; (2) disclose such information to you; or (3) use such information in your
                    interest. You also acknowledge that in the course of providing you with DuneEX Services, we may
                    receive general market information from time to time, which we may use in the course of our ordinary
                    business.</p>

                <p>&nbsp;</p>

                <p>13.4. Conflicts of Interest. We have established and maintain effective organizational and
                    administrative mechanisms to take all necessary steps to identify and manage conflicts of interest
                    between us, our users, and relevant third parties to prevent conflicts of interest from adversely
                    affecting the interests of our users. Where such organizational and administrative measures are
                    insufficient to prevent risks to your interests, we will inform you of the nature and/or sources of
                    the relevant conflicts of interest and the measures taken to mitigate these risks so that you can
                    make an informed decision on whether to continue transacting with us. We reserve the right at any
                    time to decline to act in your interest if we cannot manage a conflict of interest in any other
                    way.</p>

                <p>&nbsp;</p>

                <p>14. Transaction Limits</p>

                <p>&nbsp;</p>

                <p>14.1. Your Transaction Limits. Your DuneEX Account may be subject to limits on:</p>

                <p>&nbsp;</p>

                <p>a. the number or volume of Transactions you may undertake in connection with your DuneEX Account;
                    and/or</p>

                <p>&nbsp;</p>

                <p>b. the amount or value of Fiat Currency or Digital Assets you may transfer to or from your DuneEX
                    Account,</p>

                <p>&nbsp;</p>

                <p>in each case for a given period (e.g., per day). All applicable limits are displayed in your DuneEX
                    Account.</p>

                <p>&nbsp;</p>

                <p>14.2. Changes to Transaction Limits. We reserve the right to change any Transaction limit applicable
                    to your DuneEX Account at any time at our absolute discretion. You may also request a change to your
                    limits. Any change will be made at our absolute discretion and will be subject to any additional
                    conditions we deem necessary.</p>

                <p>&nbsp;</p>

                <p>15. Supported Digital Assets</p>

                <p>&nbsp;</p>

                <p>15.1. Supported Digital Assets. DuneEX Services are available only in connection with Supported
                    Digital Assets, which may change from time to time. The list of Supported Digital Assets is
                    published on our Site. We may remove or suspend one or more Digital Assets from the list of
                    Supported Digital Assets. We will use reasonable commercial efforts to notify you in advance. Upon
                    removal, you will no longer have access to such Digital Assets under the DuneEX Services, and you
                    will only be able to withdraw Digital Assets from your DuneEX Account. If Digital Assets that are no
                    longer Supported Digital Assets remain in your DuneEX Account after the specified period we have
                    notified you about, DuneEX may at its sole discretion convert such Digital Assets into another type
                    of Digital Assets&mdash;stablecoins. DuneEX will send advance notice of such conversion, and you
                    will be able to withdraw Digital Assets within a reasonable period specified by DuneEX before their
                    conversion into stablecoins.</p>

                <p>&nbsp;</p>

                <p>We accept no liability in connection with any attempts to use your DuneEX Account for unsupported
                    Digital Assets, as well as in connection with the conversion into another type of digital assets as
                    described in this clause.</p>

                <p>&nbsp;</p>

                <p>15.2. Forks. We may temporarily suspend any DuneEX Services in relation to a particular Digital Asset
                    while we decide on the support of a Fork. We are not obligated to support a Fork of a digital asset
                    that you hold in your DuneEX Account, regardless of whether the Forked digital asset becomes a
                    Dominant digital asset or not. If we decide to support a Fork of a digital asset, we will make a
                    public statement on the Site or other means as we deem necessary.</p>

                <p>&nbsp;</p>

                <p>You accept that we do not control and cannot influence the creation or implementation of a Fork. We
                    make no guarantees regarding the security, functionality, or offering of any Digital Asset,
                    including both the new Dominant digital asset and other Digital Assets associated with the relevant
                    Fork. In some cases, you may not be able to trade Forked digital assets on the Platform and may lose
                    any value associated with the relevant Digital Assets.</p>

                <p>&nbsp;</p>

                <p>15.3. Collateralized Digital Assets. We may from time to time support Digital Assets that are
                    purportedly collateralized or otherwise pegged in value to another asset, including Digital Assets,
                    Fiat Currency, or commodities such as silver or gold (&quot;Collateralized Digital Assets&quot;).
                    You agree that you have read, understood, and accepted all terms and risks associated with each
                    specific Collateralized Digital Asset before entering into any Transaction related to such
                    Collateralized Digital Asset. We are not obligated to purchase, repurchase, redeem, or facilitate
                    the redemption of your Collateralized Digital Assets. We reserve the right to modify, suspend, or
                    terminate any service concerning any Collateralized Digital Asset. We make no representations
                    regarding whether any particular Collateralized Digital Asset will retain its value relative to any
                    asset, as well as regarding the volume or quality of reserves or collateral held by each issuer or
                    third party in relation to any Collateralized Digital Asset.</p>

                <p><br/>
                    16. Account Security</p>

                <p>&nbsp;</p>

                <p>16.1. Your Responsibility. You are responsible for taking appropriate measures to protect your
                    equipment and data from viruses, malware, and any unacceptable materials. Except as required by
                    Applicable Law, you are responsible for backing up and maintaining duplicates of any information you
                    store or transmit through DuneEX Services. We are not responsible for any claims or losses resulting
                    from your failure to comply with this clause.</p>

                <p>&nbsp;</p>

                <p>16.2. Security Measures. At all times, you and any Authorized Users must ensure the proper security
                    and control of all your Access Identifiers. You are responsible for taking necessary security
                    measures (or ensuring that your Authorized Users take such measures) to protect your DuneEX Account
                    and secure your Access Identifier, including the following ways:</p>

                <p>&nbsp;</p>

                <p>a. strictly adhere to all our mechanisms and procedures;</p>

                <p>&nbsp;</p>

                <p>b. create a strong password and ensure the security and control of your Access Identifiers;</p>

                <p>&nbsp;</p>

                <p>c. provide us with up-to-date Email and phone numbers to receive any notifications or alerts that we
                    may send you;</p>

                <p>&nbsp;</p>

                <p>d. never allow remote access or share your computer and/or computer screen with anyone else when
                    logged into your DuneEX Account;</p>

                <p>&nbsp;</p>

                <p>e. remember that under no circumstances will we ask you to share your passwords or two-factor
                    authentication codes; and</p>

                <p>&nbsp;</p>

                <p>f. log out of the Sites or Platform at the end of each visit.</p>

                <p>&nbsp;</p>

                <p>You must protect your Email and Access Identifiers from any attacks and unauthorized access. You must
                    immediately notify us if you become aware of or have reason to suspect that your Email or the Email
                    of an Authorized User has been hacked, or if your Email or the Email of an Authorized User has been
                    used without authorization.</p>

                <p>&nbsp;</p>

                <p>16.3. Monitoring Account History. You need to monitor your Account History to promptly identify and
                    report any unauthorized or suspicious activity in your DuneEX Account. You acknowledge that any
                    Security Breach may result in unauthorized third-party access to your DuneEX Account and the loss or
                    theft of any Digital Assets and/or funds stored in your DuneEX Account and any related accounts,
                    including bank accounts and credit cards.</p>

                <p>&nbsp;</p>

                <p>16.4. If You Suspect a Security Breach. If you suspect a Security Breach, you must ensure that:</p>

                <p>&nbsp;</p>

                <p>a. we are immediately notified in accordance with the communication methods specified in
                    clause &lrm;3.1 and continue to receive accurate and up-to-date information throughout the Security
                    Breach period;</p>

                <p>&nbsp;</p>

                <p>b. your DuneEX Account is immediately locked using the account disablement function on the Platform
                    or any other method we may periodically prescribe; and</p>

                <p>&nbsp;</p>

                <p>c. you take any other steps we may reasonably require to mitigate, control, or notify about any
                    Security Breach.</p>

                <p>&nbsp;</p>

                <p>We reserve the right to request, and you agree to provide, all information and documents we deem
                    relevant or necessary in connection with any actual or suspected Security Breach, and we may provide
                    such information to any third party we deem necessary to resolve any Security Breach.</p>

                <p>&nbsp;</p>

                <p>OTHER IMPORTANT INFORMATION</p>

                <p>&nbsp;</p>

                <p>17. Privacy</p>

                <p>&nbsp;</p>

                <p>17.1. Privacy Notice. The collection and use of personal data by us in connection with these Terms,
                    the DuneEX Services, the Platform, and any Site are conducted in accordance with our Privacy Notice
                    (as periodically updated). You acknowledge that we may process your personal data that you have
                    provided to us or that we have collected from you in connection with these Terms and in accordance
                    with the Privacy Notice. Your personal data will be processed in accordance with the Privacy Notice,
                    which is part of these Terms.</p>

                <p>&nbsp;</p>

                <p>You represent and warrant that:</p>

                <p>&nbsp;</p>

                <p>a. you confirm that you have read and understood our Privacy Notice.</p>

                <p>&nbsp;</p>

                <p>b. our business regularly changes, and our Privacy Notice will also change. Therefore, if we
                    periodically provide you with a replacement version of the Privacy Notice, you must promptly review
                    the Privacy Notice.</p>

                <p>&nbsp;</p>

                <p>18. Amendments to these Terms and Other Provisions</p>

                <p>&nbsp;</p>

                <p>18.1. How and when we can make changes. We may make changes to these Terms and any referenced terms
                    and conditions (including any Product Terms) at any time, and your acceptance of these Terms
                    signifies your express agreement. We will notify you of any changes via email or through our
                    Site.</p>

                <p>&nbsp;</p>

                <p>We will notify you of most changes in advance before they take effect. However, sometimes we may need
                    to make changes without prior notice. This may occur in the following cases:</p>

                <p>&nbsp;</p>

                <p>a. we are making changes as a result of legislative and/or regulatory changes;</p>

                <p>&nbsp;</p>

                <p>b. the changes are in your interest;</p>

                <p>&nbsp;</p>

                <p>c. the changes are intended to make these Terms clearer to you; and/or</p>

                <p>&nbsp;</p>

                <p>d. there is any other valid reason for which we do not have time for notification.</p>

                <p>&nbsp;</p>

                <p>If we cannot notify you in advance, we will inform you of the changes as soon as possible after they
                    are made.</p>

                <p>&nbsp;</p>

                <p>18.2. When changes take effect. Unless changes take effect immediately, the updated Terms will take
                    effect after we have sent you notification. If you do not wish to accept the changes, you have the
                    right to close your DuneEX Account in accordance with Clause &lrm;19.1 of these Terms. If we do not
                    receive a response from you, we will assume that you have accepted the changes, and your continued
                    access or use of the DuneEX Services will be deemed acceptance of the updated Terms.<br/>
                    <br/>
                    19. Closing Your DuneEX Account</p>

                <p>&nbsp;</p>

                <p>19.1. Your Right to Close Your DuneEX Account. You may close your DuneEX Account at any time by
                    following the account termination procedure that we periodically prescribe. There will be no charge
                    for closing your DuneEX Account; however, you must settle all outstanding amounts owed to us. You
                    authorize us to cancel or suspend any pending transactions at the time of closure and to deduct any
                    outstanding amounts you owe us from your DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>In certain cases, you will not be able to close your DuneEX Account, including if:</p>

                <p>&nbsp;</p>

                <p>a. you are attempting to evade an investigation by relevant authorities;</p>

                <p>&nbsp;</p>

                <p>b. you have an open transaction or an unresolved claim;</p>

                <p>&nbsp;</p>

                <p>c. there are any outstanding amounts owed to us in your DuneEX Account; or</p>

                <p>&nbsp;</p>

                <p>d. your DuneEX Account is subject to a freeze, hold, restriction, or reservation.</p>

                <p>&nbsp;</p>

                <p>19.2. What Happens When Your DuneEX Account Is Closed. If your DuneEX Account is closed, you will
                    need to withdraw all Digital Assets held in your DuneEX Account. If you do not withdraw your Digital
                    Assets or do not log into your DuneEX Account within 90 days, we will send you a notice of our
                    intention to consider your account inactive.</p>

                <p>&nbsp;</p>

                <p>19.3. What Happens When Your Account Becomes Inactive. If you do not respond to the notice under
                    clause 19.2 within 30 days, we may:</p>

                <p>&nbsp;</p>

                <p>a. mark your DuneEX Account as inactive;</p>

                <p>&nbsp;</p>

                <p>b. convert Digital Assets into another type of Digital Assets. We will not be liable for any missed
                    profits, tax liabilities, or any other losses, damages, or expenses incurred by you as a result of
                    such conversion;</p>

                <p>&nbsp;</p>

                <p>c. transfer such inactive account and/or any Digital Assets held in such account to a third party
                    (including, but not limited to, another DuneEX Partner, any third-party custodian, or a segregated
                    wallet), if we deem it reasonably necessary. If this occurs, you will have the right to claim your
                    Digital Assets, subject to fulfilling reasonable verification requirements (ours or those of the
                    relevant third party) and any other applicable terms and conditions;</p>

                <p>&nbsp;</p>

                <p>d. adjust your inactive account so that you receive a contractual claim for the amount and type of
                    Digital Assets that were held in your DuneEX Account before it became inactive;</p>

                <p>&nbsp;</p>

                <p>e. charge a fee for an inactive account, which may cover the costs of maintaining the Digital Assets
                    by any DuneEX Partner or any third party, with such fee being charged monthly directly to the
                    inactive account; and</p>

                <p>&nbsp;</p>

                <p>f. close the inactive account at any time.</p>

                <p>&nbsp;</p>

                <p>After closing the inactive account, it cannot be reinstated. If you wish to continue using DuneEX
                    Services, you will need to register a new DuneEX Account in accordance with these terms. You agree
                    that, subject to applicable Product Terms, we are not obliged to pay any rewards, incentives, or
                    interest that we might otherwise agree to pay on your inactive account in respect of any Digital
                    Assets credited to it.</p>

                <p>&nbsp;</p>

                <p>20. Termination, Suspension, Hold, and Restrictions</p>

                <p>&nbsp;</p>

                <p>20.1. Our Right. We may at any time modify or discontinue, temporarily or permanently, any part or
                    feature of the DuneEX Services. In particular, we may: (1) refuse to complete or block, cancel, or,
                    if permitted by Applicable Law, reverse any Transaction you have initiated; (2) terminate, suspend,
                    or restrict your access to any or all of the DuneEX Services; (3) terminate, suspend, close, hold,
                    or restrict your access to any or all of your DuneEX Accounts; (4) refuse to transmit information or
                    Instructions to third parties (including, but not limited to, third-party wallet operators); and/or
                    (5) take any action we deem necessary, in each case with immediate effect and for any reason,
                    including, but not limited to, the following cases:</p>

                <p>&nbsp;</p>

                <p>a. you do not have or no longer have the right to use one or more of the DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>b. we reasonably suspect that:</p>

                <p>&nbsp;</p>

                <p>I. your DuneEX Account has been accessed by someone other than you or that your DuneEX Account has
                    been or will be used for any unlawful, fraudulent, or unauthorized purposes;</p>

                <p>&nbsp;</p>

                <p>II. your Corporate DuneEX Account has been accessed by someone other than an Authorized User or that
                    your Corporate DuneEX Account has been or will be used for any unlawful, fraudulent, or unauthorized
                    purposes;</p>

                <p>&nbsp;</p>

                <p>III. more than one natural person has access to a single DuneEX Account and/or performs transactions
                    in a single DuneEX Account, or the DuneEX Account has been or will be used for any unlawful,
                    fraudulent, or unauthorized purposes;</p>

                <p>&nbsp;</p>

                <p>IV. you have provided incorrect, false, outdated, or incomplete information;</p>

                <p>&nbsp;</p>

                <p>c. we have reasonable concerns about your creditworthiness or financial position, including:</p>

                <p>&nbsp;</p>

                <p>I. if you are an individual and become bankrupt, suffer from a mental disorder, initiate bankruptcy
                    proceedings, or bankruptcy proceedings are initiated against you;</p>

                <p>&nbsp;</p>

                <p>II. if you are acting on behalf of a partnership and any of the partners dies, becomes bankrupt,
                    suffers from a mental disorder, initiates bankruptcy proceedings, or bankruptcy proceedings are
                    initiated against any of the partners, or the process of dissolution and/or change of partners or
                    the partnership charter is initiated;</p>

                <p>&nbsp;</p>

                <p>III. if you are acting on behalf of a corporation and the corporation is unable to pay its debts when
                    due, or the corporation is involved in insolvency proceedings, judicial administration, external
                    administration, administrative administration, or any similar or analogous procedure;</p>

                <p>&nbsp;</p>

                <p>IV. you convene a meeting of your creditors or propose or enter into any compromise or arrangement
                    with creditors or any assignment in favor of your creditors;</p>

                <p>&nbsp;</p>

                <p>d. information and documents are expected to be provided in accordance with clause &lrm;8;</p>

                <p>&nbsp;</p>

                <p>e. extended verification is expected to be completed in accordance with clause &lrm;7.4;</p>

                <p>&nbsp;</p>

                <p>f. we reasonably believe that Applicable Law or any court or authority to which we are subject in any
                    jurisdiction requires us to do so;</p>

                <p>&nbsp;</p>

                <p>g. we have identified or suspect that:</p>

                <p>&nbsp;</p>

                <p>I. you have violated these Terms or any Product Terms;</p>

                <p>&nbsp;</p>

                <p>II. you have violated any express or implied warranties contained in these Terms or any statements
                    you have made;</p>

                <p>&nbsp;</p>

                <p>III. any Transaction is unauthorized, erroneous, fraudulent, or illegal, or we have identified or
                    suspect that your DuneEX Account or the DuneEX Services are being used in a fraudulent,
                    unauthorized, or illegal manner;</p>

                <p>&nbsp;</p>

                <p>IV. money laundering, terrorist financing, fraud, or any other crime has occurred in connection with
                    your DuneEX Account or your use of the DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>h. the use of your DuneEX Account is subject to any pending, current, or potential litigation,
                    investigation, or judicial, governmental, or regulatory proceedings, and/or we suspect an increased
                    risk of non-compliance with legal or regulatory requirements associated with activities in your
                    DuneEX Account;</p>

                <p>&nbsp;</p>

                <p>i. you owe DuneEX any outstanding amounts, whether in connection with a chargeback or on any other
                    basis;</p>

                <p>&nbsp;</p>

                <p>j. any email sent to your Email Address is returned as undeliverable;</p>

                <p>&nbsp;</p>

                <p>k. there is an issue with verifying your identity;</p>

                <p>&nbsp;</p>

                <p>l. you have taken any action that may circumvent our controls, such as opening multiple DuneEX
                    Accounts without our written consent or abusing promotions that we may periodically offer; or</p>

                <p>&nbsp;</p>

                <p>m. there is any other reasonable cause that necessitates this action.</p>

                <p>&nbsp;</p>

                <p>We will take reasonable steps to send you appropriate notice. However, there may be cases where we
                    are required not to do so in accordance with Applicable Law.</p>

                <p>&nbsp;</p>

                <p>20.2. Your Acceptance. You acknowledge and accept that:</p>

                <p>&nbsp;</p>

                <p>a. clause &lrm;20.1 provides a non-exhaustive list of cases where we may take action to terminate,
                    suspend, close, or restrict your access to the DuneEX Account and/or the DuneEX Services; and</p>

                <p>&nbsp;</p>

                <p>b. our decision to take certain actions, including, but not limited to, terminating, suspending, or
                    restricting your access to your DuneEX Account or the DuneEX Services, may be based on confidential
                    criteria that are important for our risk management and security protocols. You agree that we are
                    not obliged to disclose to you the details of our risk management procedures and security
                    measures.</p>

                <p>&nbsp;</p>

                <p>20.3. What Happens When We Exercise Our Right. When we terminate, suspend, hold, or restrict your
                    access to one or more of the DuneEX Services:</p>

                <p>&nbsp;</p>

                <p>a. if you have open Instructions, trades, positions, or Transactions, they may be closed by you or by
                    us depending on the circumstances of termination, suspension, hold, restriction of access, or other
                    actions we take;</p>

                <p>&nbsp;</p>

                <p>b. any chargeback resulting from the use of your DuneEX Account or the DuneEX Services may result in
                    immediate suspension and/or restriction of your DuneEX Account and the DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>c. to reinstate a suspended and/or restricted DuneEX Account or the DuneEX Services, you may be
                    required to reimburse us for the full cost of the chargeback, including any amounts owed to us,
                    including any applicable Fees; and</p>

                <p>&nbsp;</p>

                <p>d. you are responsible for any amounts credited in case of a chargeback, and you authorize us and
                    grant us the right to deduct costs and fees directly from any assets in your DuneEX Account without
                    notice.</p>

                <p>&nbsp;</p>

                <p>20.4. Unlawful Possession. If we are informed and reasonably believe that any Digital Assets or Fiat
                    currencies stored in your DuneEX Account are stolen or otherwise not lawfully owned by you (by
                    mistake or otherwise), we may, but are not obligated to, withhold the corresponding funds and your
                    DuneEX Account. If we withhold any or all Digital Assets or Fiat currencies stored in your DuneEX
                    Account, or your entire DuneEX Account, we may continue to withhold them until acceptable evidence
                    confirms that you have the right to ownership of the Digital Assets and/or Fiat currencies stored in
                    your DuneEX Account. We will not engage in Disputes or resolution of Disputes related to any Digital
                    Assets and/or Fiat currencies stored in your DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>20.5. Access to DuneEX Services in Other Jurisdictions. Residents of certain countries may have
                    access only to certain, but not all, DuneEX Services. We may periodically change the DuneEX Services
                    available to you. DuneEX Services may be unavailable, and your access to DuneEX Services may be
                    blocked. You acknowledge that this may affect your ability to trade on the Platform and/or monitor
                    any existing orders or open positions or otherwise use DuneEX Services. Attempting to circumvent any
                    such restriction, including by using any virtual private network to change your internet protocol
                    address, is prohibited.</p>

                <p><br/>
                    21. Intellectual Property</p>

                <p>&nbsp;</p>

                <p>Prior IP</p>

                <p>&nbsp;</p>

                <p>DuneEX&#39;s IP belongs to DuneEX.</p>

                <p>&nbsp;</p>

                <p>22. DuneEX IP License</p>

                <p>&nbsp;</p>

                <p>We grant you a non-exclusive license for the duration of these Terms or until the suspension or
                    termination of your access to DuneEX Services, whichever occurs earlier, to use DuneEX IP, excluding
                    Trademarks, solely to the extent necessary for you to receive DuneEX Services for non-commercial
                    personal or internal business use in accordance with these Terms.</p>

                <p>&nbsp;</p>

                <p>23. User IP License</p>

                <p>&nbsp;</p>

                <p>23.1. Granting Your License</p>

                <p>You grant us a perpetual, irrevocable, royalty-free, worldwide, and non-exclusive license to use User
                    IP to the extent that it:</p>

                <p>a. is part of or necessary for the use of any Created IP;</p>

                <p>b. is periodically required for us to provide you with DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>23.2. Our Right to Sub-license</p>

                <p>The license granted to you under this section includes our right to sublicense to third parties to
                    the extent necessary for us and DuneEX Partners to provide you with DuneEX Services or any part
                    thereof.</p>

                <p>&nbsp;</p>

                <p>24. Created IP</p>

                <p>&nbsp;</p>

                <p>24.1. Created IP</p>

                <p>Created IP automatically belongs to us from the date of its creation.</p>

                <p>&nbsp;</p>

                <p>24.2. Transfer</p>

                <p>You hereby transfer to us (and agree to ensure transfer by any agents, representatives, or
                    contractors) all present and future ownership rights and interests in Created IP with full warranty
                    of ownership. If requested by us, you must (and agree to ensure compliance by any agents,
                    representatives, or contractors) sign and/or execute all documents and take actions that we may
                    require to effectuate the transfer under this section.</p>

                <p>&nbsp;</p>

                <p>25. General Matters</p>

                <p>&nbsp;</p>

                <p>25.1. Our Non-Liability</p>

                <p>You acknowledge and accept that:</p>

                <p>1. we are not liable for any User Materials (provided by you or third parties) that may be available
                    on the Platform or Sites; and</p>

                <p>2. your use of any such User Materials is at your own risk, and we provide no warranties regarding
                    them.</p>

                <p>&nbsp;</p>

                <p>25.2. Our Rights</p>

                <p>We reserve the right, at our absolute discretion, to delete, modify, or reject any content that you
                    submit, post, or display on the Platform or Sites (including any User Material) for any reason. We
                    reserve the right to take any appropriate actions at our discretion, including issuing written
                    warnings, deleting any User Materials, recovering damages or other monetary compensation from you,
                    suspending or terminating your DuneEX Account (if applicable), suspending your access to the
                    Platform and/or Sites. We also reserve the right to restrict or prohibit your use of any DuneEX
                    Services.</p>

                <p>&nbsp;</p>

                <p>25.3. Record</p>

                <p>You acknowledge that we may record any messages, electronic, telephone, video call, or otherwise,
                    that occur between you and us in connection with these Terms, and that any recordings we maintain
                    will constitute evidence of communications between you and us. You acknowledge that telephone calls
                    and video calls may be recorded so that we can respond to inquiries, ensure compliance with
                    applicable laws, improve our services, and provide customer support.</p>

                <p><br/>
                    <strong>YOUR OBLIGATIONS AND RESPONSIBILITIES</strong></p>

                <p>&nbsp;</p>

                <p>26. PROHIBITED USE</p>

                <p>&nbsp;</p>

                <p>By creating a DuneEX Account, you confirm, without prejudice to any other restrictions or limits set
                    forth herein, that you and any Authorized User will not:</p>

                <p>&nbsp;</p>

                <p>a. violate these Terms or any agreement entered into in accordance or connection with these Terms,
                    including, among others, any Product Terms;</p>

                <p>&nbsp;</p>

                <p>b. use DuneEX Services in a manner that violates public interests, public morality, or lawful
                    interests of other persons, including any actions that may affect, disrupt, negatively affect DuneEX
                    Services or interfere with other users&#39; use of DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>c. use DuneEX Services for resale and commercial purposes, including transactions on behalf of third
                    parties and organizations, unless permitted by us in writing;</p>

                <p>&nbsp;</p>

                <p>d. use DuneEX Services for actions that, in the opinion of DuneEX, are aimed at controlling or
                    artificially influencing the price of any Digital Asset (market manipulation, including, among
                    others, pump and dump schemes, wash trading, self-trading, front-running, intentional quote
                    manipulations, spoofing, and layering), whether prohibited by Applicable Law or not;</p>

                <p>&nbsp;</p>

                <p>e. engage in fraudulent activities or raise suspicions that you or any Authorized User are engaging
                    in fraudulent activities and/or Transactions;</p>

                <p>&nbsp;</p>

                <p>f. use DuneEX Services to conduct lotteries; auctions with commissions; sports betting and odds
                    setting; fantasy sports leagues with cash prizes; online gaming; contests; raffles; or gambling;</p>

                <p>&nbsp;</p>

                <p>g. (1) receive or attempt to receive funds from both us and another user for the same Transaction in
                    the course of a Claim; (2) conduct your business or use DuneEX Services in a manner that leads or
                    may lead to complaints, disputes, claims, cancellations, payment refunds, fees, penalties, fines, or
                    other obligations to us, other users, third parties, or yourself; and (3) allow your DuneEX Account
                    to have a negative value or amount of Digital Assets;</p>

                <p>&nbsp;</p>

                <p>h. provide false, inaccurate, or misleading information in connection with your use of DuneEX
                    Services, in communication with us, or otherwise in connection with these Terms;</p>

                <p>&nbsp;</p>

                <p>i. (1) use any deep links, search robots, bots, spiders, or other automatic devices, programs,
                    scripts, algorithms, or methods, or any similar or equivalent manual processes to access, obtain,
                    copy, or track any part of the Platform, or to copy or circumvent the navigational structure or
                    presentation of DuneEX Services in any way to obtain or attempt to obtain any materials, documents,
                    or information not intentionally provided through DuneEX Services; (2) attempt to gain access to any
                    part or feature of the Platform without authorization, connect to DuneEX Services, any of our
                    servers, or any other systems or networks of any DuneEX Services, provided through the Platform, by
                    hacking, password mining, or any other illegal or prohibited methods; (3) scan, scan, or test
                    vulnerabilities of DuneEX Services or any network connected to the Platform, or violate any security
                    or authentication measures of DuneEX Services or any network connected to DuneEX Services; (4)
                    reverse search, track, or attempt to track any information of any other users or visitors of DuneEX
                    Services; (5) take any actions that create an unreasonable or disproportionately large load on the
                    infrastructure of systems or networks of DuneEX Services or DuneEX or the infrastructure of any
                    systems or networks connected to DuneEX Services; (6) use any devices, software, or regular programs
                    to interfere with the normal operation of DuneEX Services, any transactions in DuneEX Services, or
                    the use of DuneEX Services by any other person; or (7) forge headers, impersonate any person, or
                    otherwise manipulate the identification to hide your identity or origin of any messages or
                    transmissions you send to us;</p>

                <p>&nbsp;</p>

                <p>j. modify or adapt all or part of the Platform or integrate or include the Platform into another
                    program or application;</p>

                <p>&nbsp;</p>

                <p>k. disassemble, decompile, reverse engineer, or otherwise attempt to extract the source code, object
                    code based on concepts, ideas, and algorithms of the Platform or any of its components;</p>

                <p>&nbsp;</p>

                <p>l. modify, reproduce, duplicate, copy, download, store, transmit, distribute, transmit, dissect,
                    broadcast, publish, delete or change any copyright notice or statement, license, sublicense, sell,
                    reproduce, develop, rent, lease, create your own trademark, create software for such DuneEX IP or
                    any part of intellectual property, create derivative works or otherwise use any part of DuneEX
                    IP;</p>

                <p>&nbsp;</p>

                <p>m. promote the spread of any viruses, Trojan horses, worms, or other computer programs that may
                    damage, covertly intercept, assign, or adversely affect any system, data, or information related to
                    DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>n. (1) use anonymous proxy; (2) use any temporary, disposable, self-destructing, or similar email
                    when creating a DuneEX Account and/or using DuneEX Services; (3) use any device, software, or
                    procedure to bypass our robot exclusion headers, or intervene or attempt to interfere with the
                    operation of our Sites or DuneEX Services; and (4) take any action that may result in us losing any
                    services from our internet providers or other suppliers;</p>

                <p>&nbsp;</p>

                <p>o. create or purportedly create any software in relation to your Fiat currency or Digital Assets
                    stored in any of your DuneEX Accounts without our prior written consent;</p>

                <p>&nbsp;</p>

                <p>p. violate or attempt to violate (1) any Applicable Law; or (2) our or any third party&#39;s
                    copyrights, patents, trademarks, trade secrets, or other intellectual property rights, or rights of
                    publicity or privacy; and/or</p>

                <p>&nbsp;</p>

                <p>q. access, use, or attempt to access or use DuneEX Services directly or indirectly (1) in
                    jurisdictions which, in the opinion of DuneEX, represent high risk, including, among others, Cuba,
                    Iran, North Korea, Syria, or (2) by persons who, in the opinion of DuneEX, represent high risk,
                    including, among others, individuals or legal entities with restricted access or parties on any list
                    of the United States of America, United Kingdom, European Union, or United Nations, including the US
                    Office of Foreign Assets Control sanctions list and the US Department of Commerce list of prohibited
                    persons and organizations.</p>

                <p>&nbsp;</p>

                <p>27. REPRESENTATIONS AND WARRANTIES</p>

                <p>&nbsp;</p>

                <p>Hereby, you represent and warrant to us at any time the following:</p>

                <p>&nbsp;</p>

                <p>a. all documents and information provided by you to us are true, accurate, complete, and current in
                    all respects, and we may rely on them in determining whether you have the right to access the
                    Platform or use DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>b. all decisions made in connection with these Terms were made solely at your discretion and after
                    your independent assessment of your financial resources, ability, and readiness to assume relevant
                    risks, financial goals;</p>

                <p>&nbsp;</p>

                <p>c. you have all the authority, rights, and opportunities for (1) accessing and using the Platform
                    and/or DuneEX Services; and (2) concluding, delivering, and performing your obligations under these
                    Terms and any agreement entered into in accordance or in connection with these Terms, including,
                    among others, any Product Terms;</p>

                <p>&nbsp;</p>

                <p>d. if you are a corporation, partner in a partnership, or trustee:</p>

                <p>&nbsp;</p>

                <p>I. the corporation, partnership, or trust have acted and will act in accordance with Applicable Law,
                    as well as any partnership or trust agreements (or similar documents);</p>

                <p>&nbsp;</p>

                <p>II. you will promptly notify us of any changes related to the resignation, displacement, appointment,
                    or death of any directors, partners, trustees, founders, ultimate beneficial owners, or any person
                    authorized to manage your DuneEX Account;</p>

                <p>&nbsp;</p>

                <p>III. you will promptly notify us if the corporation, partnership, or trust will be dissolved on a
                    voluntary or compulsory basis;</p>

                <p>&nbsp;</p>

                <p>e. all consents, permissions, authorizations, approvals, and third-party agreements and all
                    authorizations, approvals, permissions, consents, registrations, statements, document submissions to
                    any Regulatory authority, government department, commission, agency, or other organization having
                    jurisdiction over you, which are necessary or desirable for (1) gaining access and using the
                    Platform and/or DuneEX Services; and (2) concluding, delivering, and performing Transactions
                    provided by these Terms and any agreement entered into in accordance or in connection with these
                    Terms, have been unconditionally obtained in writing, provided to us in writing, and have not been
                    revoked or amended;</p>

                <p>&nbsp;</p>

                <p>f. these Terms and any agreement entered into in accordance or in connection with these Terms
                    represent valid obligations with legal force enforceable against you according to their respective
                    terms;</p>

                <p>&nbsp;</p>

                <p>g. you are not a Person with restricted access;</p>

                <p>&nbsp;</p>

                <p>h. if you are a legal entity, you are properly registered, properly organized, lawfully exist under
                    the laws of your jurisdiction, and have all the powers to conduct your business. If you are a
                    natural person, you are not under the age of 18;</p>

                <p>&nbsp;</p>

                <p>i. your access and use of the Platform and/or DuneEX Services, your performance and delivery, as well
                    as the performance of your obligations under these Terms and any agreement entered into in
                    accordance or in connection with these Terms:</p>

                <p>&nbsp;</p>

                <p>I. if you are a legal entity, partner in a partnership, or trustee, will not result in a breach of or
                    be contrary to any provision of your constitution, articles of incorporation, partnership agreement,
                    trust agreement, or equivalent founding documents;</p>

                <p>&nbsp;</p>

                <p>II. will not result in a breach or non-performance of obligations under any instrument, agreement,
                    document, or provision, the party of which you are or whose action applies to you or any of your
                    property;</p>

                <p>&nbsp;</p>

                <p>III. will not result in a violation by you, us, or any third party of any Applicable Law, decree, or
                    decision of any court, any arbitration award, or decision of any governmental or Regulatory
                    authority in any jurisdiction.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>28. DISCLAIMER OF TECHNOLOGIES</p>

                <p>&nbsp;</p>

                <p>28.1. No Representations or Warranties. DuneEX Services and any information on the Websites and
                    Platform, including Chat Services, are provided on an &quot;as is&quot; and &quot;as
                    available&quot; basis without any representations or warranties, express or implied, to the fullest
                    extent permitted by Applicable Law. In particular, we disclaim any implied warranties of title,
                    merchantability, fitness for a particular purpose, and non-infringement. We make no representations
                    and provide no warranties that access to the Websites, Platform, any of your DuneEX Accounts, DuneEX
                    Services, or any content therein will be continuous, uninterrupted, timely, or error-free. This may
                    result in inability to trade on the Platform for certain periods and delays.</p>

                <p>&nbsp;</p>

                <p>28.2. Suspension of Access. We may periodically suspend access to your DuneEX Account and/or DuneEX
                    Services for scheduled or emergency maintenance. We will use reasonable efforts to process
                    Transactions on the Platform in a timely manner; however, we make no representations or warranties
                    regarding processing times as they depend on factors beyond our control.</p>

                <p>&nbsp;</p>

                <p>28.3. Content. While we make reasonable efforts to update information on the Websites and Platform,
                    we make no representations, warranties, or assurances, express or implied, that the content of the
                    Websites and Platform, including information about DuneEX Services, is accurate, complete, or
                    current.</p>

                <p>&nbsp;</p>

                <p>28.4. Third-Party Websites. We may provide links to third-party websites (including, among others,
                    content, materials, and/or information on third-party websites) for convenience, but we do not
                    control them. You acknowledge and agree that we are not responsible for any aspect of the content,
                    materials, information, or services contained on any third-party websites accessible or mentioned on
                    the Platform or Websites.</p>

                <p>&nbsp;</p>

                <p>28.5. Network Access and Compatibility. You are responsible for obtaining access to the data
                    transmission network necessary for using DuneEX Services. You are responsible for acquiring and
                    updating compatible equipment or devices necessary for access and use of DuneEX Services and
                    Websites, including any updates. DuneEX does not guarantee that DuneEX Services or any part thereof
                    will function on any specific equipment or devices. DuneEX Services may be subject to failures and
                    delays related to internet use and electronic communications.</p>

                <p>&nbsp;</p>

                <p>29. INDEMNIFICATION</p>

                <p>&nbsp;</p>

                <p>29.1. Claims by Third Parties. You must indemnify and hold us harmless from any claims, suits,
                    actions, demands, disputes, allegations, or investigations by any third party, governmental body, or
                    industry body, as well as from all claims, liabilities, damages (actual and consequential), losses
                    (including any direct, indirect, or consequential losses), costs, and expenses, including, among
                    others, all interest, penalties, legal and other reasonable attorney fees, other professional
                    expenses and costs (&quot;Losses&quot;), arising out of or in connection with:</p>

                <p>&nbsp;</p>

                <p>a. Your access to or use of your DuneEX Account and/or DuneEX Services;</p>

                <p>&nbsp;</p>

                <p>b. Your breach or alleged breach of these Terms or any action by you contrary to any other provision
                    or subparagraph of these Terms, including any Product Terms and any other referenced terms and
                    conditions;</p>

                <p>&nbsp;</p>

                <p>c. Your actions contrary to Applicable Law;</p>

                <p>&nbsp;</p>

                <p>d. Your infringement of any third party rights.</p>

                <p>&nbsp;</p>

                <p>29.2. Control. We reserve the right to control the defense of any third-party claim for which you are
                    required to indemnify Losses, and in such case, you must cooperate fully as determined by us in
                    defending any available defenses. You will not settle any claims or Losses without our prior written
                    consent.</p>

                <p>&nbsp;</p>

                <p>29.3. Release. You hereby agree to release us from all claims and demands (and waive any rights that
                    you may have against us in respect of any possible Losses), directly or indirectly arising out of or
                    in connection with any dispute between you and any other user or other third party in connection
                    with DuneEX Services (including any Transactions involving Digital Assets) or the subject matter of
                    these Terms.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>30. LIABILITY</p>

                <p>&nbsp;</p>

                <p>30.1 Our Liability. Our liability and the liability of DuneEX Partners to you or any third parties
                    under any circumstances is limited to the actual amount of damages or losses directly and reasonably
                    foreseeable as a result of our breach of these terms, and in no event shall exceed the amount of
                    fees paid by you to DuneEX in the 12 months preceding the event giving rise to the loss. Such amount
                    shall be paid by us to you as a full and final settlement and shall fully satisfy our liability and
                    the liability of any DuneEX Partner for all losses and claims arising out of the respective breach,
                    regardless of the reasons for their occurrence. You acknowledge and accept that neither DuneEX nor
                    DuneEX Partners are aware of any special circumstances relating to you, that compensation for losses
                    is an adequate remedy at law, and that you have no right to any other claims or remedies in equity,
                    including, without limitation, any specific performance, under these terms or otherwise.</p>

                <p>&nbsp;</p>

                <p>30.2 Limitation of Liability. Notwithstanding any other provisions of these Terms, we or any DuneEX
                    Partner shall in no event be liable to you or any other natural or legal person for:</p>

                <p>&nbsp;</p>

                <p>a. Any direct or indirect losses (including lost profits, business or opportunities), damages or
                    expenses arising out of or in connection with these Terms, including, but not limited to:</p>

                <p>&nbsp;</p>

                <p>&nbsp;&nbsp; I. The operation of protocols underlying any Digital Asset, their functionality,
                    security, and availability;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; II. Whether Secured Digital Assets maintain their value relative to any asset or whether
                    the issuer of Secured Digital Assets has adequate reserves in relation to any Secured Digital
                    Asset;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; III. Any action or inaction under these Terms;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; IV. Any inaccuracies, defects, or omissions in Digital Asset price data, any errors or
                    delays in transmitting such data, or interruptions in transmitting such data;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; V. Regular or unplanned maintenance that we conduct, including any changes and
                    interruptions in service caused by such maintenance;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; VI. Theft of the device through which access and use of DuneEX Services are carried
                    out;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; VII. Actions, inactions, or breaches of these Terms by other users, as well as any
                    damage caused by actions of any other third parties;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; VIII. (1) Any damage or disruption caused by any computer viruses, spyware, or other
                    malicious software that may affect your computer or other equipment, or any phishing, spoofing, or
                    other attacks; (2) failure, damage, or destruction of your equipment, damage or loss of any records
                    or data on your equipment for any reason; or (3) your use of the internet to connect to DuneEX
                    Services or any technical issues, system failures, malfunctions, line failures, high traffic or
                    demand for internet traffic, associated problems, security breaches, or any similar technical issues
                    or defects;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; IX. Our decision to reject your application to create DuneEX Account(s) under
                    section &lrm;7.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; X. Any termination, suspension, withholding, or limitation of access to any DuneEX
                    Account or DuneEX Services, including your inability to withdraw Digital Assets, issue Instructions,
                    or execute Transactions during any suspension, withholding, or limitation under these Terms or any
                    product terms;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XI. Any Transaction limits applied to your DuneEX Account under section &lrm;14.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XII. Our decision to support or not support Digital Assets under section &lrm;15.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XIII. Our inability to contact you using the contact information you provided under
                    section &lrm;3.2;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XIV. Closure of inactive accounts by us under section &lrm;19.3;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XV. Failure to execute a Transaction or failure to meet the timing required to complete
                    any Transaction under section &lrm;12.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XVI. Market-making activities under section &lrm;13.2;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XVII. Our reliance on any Instructions sent from your DuneEX Account or email;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XVIII. Our refusal or delay in executing any Instruction under section &lrm;11.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XIX. Any breach of your email security or Security Breach;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XX. Losses incurred by you due to fraud or scams by third parties in which DuneEX
                    participates only as the recipient of your Fiat Currency or Digital Assets, and/or performs
                    conversion of Fiat Currency into Digital Assets, and/or transfers Digital Assets from our platform
                    upon your request;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XXI. Any losses arising out of or in connection with new Digital Asset offerings,
                    initial coin offerings (ICOs), or decisions to add or not add Digital Assets to the Platform;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XXII. The accuracy, quality, completeness, reliability, efficiency, timeliness, price,
                    or continuous availability of DuneEX Services or delays or failures in DuneEX Services, or the
                    inability of any connection or communication service to provide or maintain your access to DuneEX
                    Services, or any interruption or disruption of your access, or any erroneous messages between us,
                    regardless of the cause; and</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XXIII. Any Transactions, Instructions, or operations conducted by you or purportedly
                    conducted by you through your email or DuneEX Account;</p>

                <p>&nbsp; &nbsp;</p>

                <p>b. Any lost profits, loss of business, anticipated savings or opportunities, or any special,
                    punitive, aggravated, incidental, indirect, or consequential losses or damages arising out of or in
                    connection with our Sites, Platform, your DuneEX Account, DuneEX Services, these Terms, product
                    terms, Privacy Notice, and/or any agreement entered into as a result of or in connection with these
                    Terms or otherwise; and/or</p>

                <p>&nbsp;</p>

                <p>c. Any losses that are part of a Claim that has not been initiated through formal litigation WITHIN
                    ONE CALENDAR YEAR after the event giving rise to the Claim. YOU ACKNOWLEDGE AND AGREE THAT THIS
                    PROVISION ALTERS THE STATUTE OF LIMITATIONS APPLICABLE UNDER LAW IN OTHER CIRCUMSTANCES, AND THAT IF
                    APPLICABLE LAW PROHIBITS THIS, THIS PROVISION SHALL BE CONSTRUED AS A LIMITATION OF LIABILITY OF
                    MINIMAL DURATION PERMISSIBLE. WITHOUT LIMITING THE GENERAL NATURE OF THE FOREGOING, PLEASE NOTE
                    SECTION 32, WHICH STATES THAT CLAIMS RESOLUTION IS EXCLUSIVELY BY MANDATORY ARBITRATION.</p>

                <p>&nbsp;</p>

                <p>30.3 Damage or Interruptions. We are not liable for any damage or interruptions caused by any
                    computer viruses, spyware, counterfeit antivirus, trojans, worms, or other malicious software that
                    may affect your computer or other equipment, or any phishing, spoofing, or other attacks. We
                    recommend using reliable and readily available software to detect and protect against viruses
                    regularly. Note that SMS and email services are susceptible to spoofing and phishing, and exercise
                    caution when receiving messages purportedly from us. You and, where applicable, your Authorized
                    Users are responsible for all login credentials, including usernames and passwords, and must always
                    keep them secure.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p><strong>DISPUTE RESOLUTION: COURTS, ARBITRATION, WAIVER OF CLASS ACTIONS</strong></p>

                <p>&nbsp;</p>

                <p>To avoid misinterpretation: nothing in this section deprives you of any mandatory legal rights you
                    have under Applicable Law.</p>

                <p>&nbsp;</p>

                <p>31. Notice of Claim and Dispute Resolution Period</p>

                <p>&nbsp;</p>

                <p>31.1. If you encounter any issues with the Services, please first contact DuneEX. DuneEX aims to
                    resolve your issues without formal litigation if possible. If you are not satisfied with the
                    resolution of your issues on initial contact, a ticket number will be assigned to you. The issuance
                    of a ticket initiates the internal dispute resolution process. DuneEX will attempt to resolve the
                    dispute through internal procedures as promptly as possible. The parties agree to negotiate in good
                    faith to resolve the dispute (these negotiations must remain confidential and comply with applicable
                    rules protecting settlement negotiations from being used as evidence in any judicial
                    proceeding).</p>

                <p>&nbsp;</p>

                <p>31.2. If the dispute cannot be satisfactorily resolved and you wish to file a Claim against DuneEX,
                    you agree to set forth the grounds for such Claim in a written &quot;Notice of Claim&quot; as a
                    preliminary notice to DuneEX. The Notice of Claim must (1) describe the nature and basis of the
                    Claim or dispute, (2) specify a specific remedy sought, (3) include the original ticket number, and
                    (4) provide the email address of your custodial account. The Notice of Claim should be sent to the
                    following address: https://dune-ex.net/contacts. Once you send the Notice of Claim to DuneEX, the
                    dispute specified in the Notice of Claim may be referred to either DuneEX or to arbitration as set
                    forth below (Arbitration Agreement).</p>

                <p>&nbsp;</p>

                <p>31.3. To avoid misunderstandings, the submission of a dispute to DuneEX for resolution through
                    internal procedures and the sending of a Notice of Claim to DuneEX are prerequisites for commencing
                    arbitration proceedings (or any other judicial proceedings).</p>

                <p>&nbsp;</p>

                <p>31.4. During arbitration, disclosing to the arbitrator the amount of any settlement offer made by you
                    or DuneEX is prohibited.</p>

                <p>&nbsp;</p>

                <p>32. Arbitration Agreement</p>

                <p>&nbsp;</p>

                <p>32.1. Except where Applicable Law requires or provides you with another choice, you and DuneEX,
                    taking into account the preceding section above (Notice of Claim and Dispute Resolution Period),
                    agree that any Claim shall be resolved through mandatory, final, and individual (non-class)
                    arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) and in accordance
                    with the current HKIAC rules, which are deemed incorporated into this section by reference.</p>

                <p>&nbsp;</p>

                <p>32.2. The venue of the arbitration shall be Hong Kong.</p>

                <p>&nbsp;</p>

                <p>32.3. The panel shall consist of one (1) arbitrator appointed in accordance with the current HKIAC
                    Rules.</p>

                <p>&nbsp;</p>

                <p>32.4. The language of the arbitration shall be English.</p>

                <p>&nbsp;</p>

                <p>32.5. You and DuneEX also agree that the arbitrator shall have exclusive authority to resolve issues
                    regarding its own jurisdiction, including, among other things, any objections to the existence,
                    scope, or validity of the Arbitration Agreement, and the ability to resolve any Claim in the
                    arbitration.</p>

                <p>&nbsp;</p>

                <p>32.6. The arbitration provisions set forth herein shall survive the termination of these Terms.</p>

                <p>&nbsp;</p>

                <p>32.7. Statute of Limitations. Any arbitration against DuneEX must be initiated by filing and serving
                    a Notice of Arbitration in accordance with the HKIAC Rules within one (1) year from the date when
                    the user asserting the Claim first knew or reasonably should have known of the alleged act,
                    omission, or failure to perform that forms the basis of the Claim (&quot;Statute of
                    Limitations&quot;). To avoid misunderstandings, the Statute of Limitations includes the Dispute
                    Resolution Period specified in section 31.1. A user is not entitled to any legal or equitable remedy
                    based on any Claim unless a Notice of Arbitration regarding that Claim has been filed and served on
                    DuneEX within the Statute of Limitations. If the Statute of Limitations contradicts Applicable Law,
                    the user must assert any Claim against DuneEX within the shortest period of time allowed by
                    Applicable Law. Notice of Arbitration may be served on DuneEX in accordance with Applicable Law and
                    rules of service.</p>

                <p>&nbsp;</p>

                <p>32.8. Notice: if DuneEX initiates arbitration against you, DuneEX will send notice to the email
                    address or postal address you provided. You acknowledge that any notice sent to this email address
                    or postal address shall be deemed valid for all purposes, including, among other things, service
                    determination. You are responsible for ensuring that the email and/or postal address provided to
                    DuneEX are current and accurate.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>33. Confidentiality</p>

                <p>&nbsp;</p>

                <p>33.1. The parties agree that arbitration shall be confidential. The existence of the arbitration, the
                    existence or content of any claim, all documents and information exchanged or provided by the
                    parties in connection with the arbitration, as well as any statements, rulings, or awards made in
                    the arbitration, shall remain confidential, and neither party shall disclose any of the foregoing to
                    any third party except to the arbitration tribunal, HKIAC, the parties, their attorneys, experts,
                    witnesses, accountants, auditors, insurers, and reinsurers, as well as any other persons necessary
                    for the conduct of the arbitration. Notwithstanding the above, a party may disclose such
                    confidential information:</p>

                <p>&nbsp;</p>

                <p>a. if obtained written consent from the other party;</p>

                <p>&nbsp;</p>

                <p>b. to the extent required by applicable law or regulations of any regulatory or supervisory authority
                    of competent jurisdiction applicable to the party, or pursuant to any court order or other competent
                    authority or tribunal of competent jurisdiction;</p>

                <p>&nbsp;</p>

                <p>c. in connection with the commencement, conduct, or defense by the party of any bona fide judicial
                    proceeding to enforce or challenge any award rendered in the arbitration; and</p>

                <p>&nbsp;</p>

                <p>d. to the extent such confidential information is in the public domain and does not violate this
                    Agreement.</p>

                <p>&nbsp;</p>

                <p>The confidentiality provision set forth in this section shall remain in effect after the termination
                    of these Terms and the conclusion or suspension of any arbitration initiated under these Terms.</p>

                <p>&nbsp;</p>

                <p>34. Governing Law</p>

                <p>&nbsp;</p>

                <p>Except where applicable law requires or provides otherwise, these Terms (including this arbitration
                    agreement) shall be governed by and construed in accordance with the laws of Hong Kong.</p>

                <p>&nbsp;</p>

                <p>35. Waiver of Class Actions</p>

                <p>&nbsp;</p>

                <p>You and DuneEX agree that any claims against DuneEX must be brought in an individual capacity in
                    arbitration and not as a plaintiff or class member in any purported class or representative action.
                    You also agree to waive any right to bring, litigate, or arbitrate such claims as a class,
                    collective, representative, or private attorney general action to the extent permissible under
                    applicable law. Joinder or consolidation of individual arbitrations into one arbitration is not
                    permitted without the consent of DuneEX.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p><strong>GENERAL PROVISIONS</strong></p>

                <p>&nbsp;</p>

                <p>36. General Provisions</p>

                <p>&nbsp;</p>

                <p>36.1. Applicable Law. You and any Authorized User must comply with Applicable Law, licensing
                    requirements, and third-party rights (including data privacy laws and anti-money laundering and
                    counter-terrorism financing laws) when using DuneEX Services, your DuneEX Account, and the
                    Platform.</p>

                <p>&nbsp;</p>

                <p>36.2. Notices. We may send notifications to your Email. You are responsible for providing accurate
                    and current Email information. Notifications sent to your Email will be considered received even if
                    a delivery error notification is received.</p>

                <p>&nbsp;</p>

                <p>You may send us notifications only in accordance with our instructions, which may change from time to
                    time.</p>

                <p>&nbsp;</p>

                <p>Any notifications, consents, or other communications provided under these Terms must be in writing in
                    English and signed or otherwise authorized by the party providing them.</p>

                <p>&nbsp;</p>

                <p>36.3. Announcements. All official announcements, news, promotions, contests, and airdrops will be
                    posted on the Website. These announcements are important and may contain information affecting the
                    value or security of your Digital Assets. You are responsible for monitoring the Website, reading,
                    and considering these announcements.</p>

                <p>&nbsp;</p>

                <p>36.4. Entire Agreement. These Terms together with any applicable Product Terms constitute the entire
                    agreement between you and us regarding DuneEX Services. Each party acknowledges that it has not
                    relied on any statement, representation, warranty (whether made negligently or innocently), except
                    those expressly set out in these Terms or Product Terms.</p>

                <p>&nbsp;</p>

                <p>36.5. Assignment. You may not assign or transfer any of your rights or obligations under the Terms
                    without our prior written consent. However, we may assign or transfer any of our rights or
                    obligations under the Terms at any time to any other person, including, without limitation, in
                    connection with any merger, acquisition, or other corporate reorganization involving DuneEX.</p>

                <p>&nbsp;</p>

                <p>36.6. Severability. If at any time any provision or sub-provision of the Terms is or becomes illegal,
                    invalid, or unenforceable to any extent, this shall not affect the legality, validity, or
                    enforceability of the remaining provisions or sub-provisions in any way.</p>

                <p>&nbsp;</p>

                <p>36.7. Records. You agree that we may record any conversations with you by phone, email, chat, or any
                    other form of communication, including communications for issuing Instructions or conducting
                    Transactions, between you and us, and that these recordings may be used as evidence in any
                    proceedings related to any agreement with you. These recordings will be our exclusive property.</p>

                <p>&nbsp;</p>

                <p>36.8. Language. These Terms may be translated into languages other than English. Any such translation
                    is provided for your convenience only. In the event of discrepancies or ambiguities, the English
                    language text shall prevail.</p>

                <p>&nbsp;</p>

                <p>36.9. Third Party Rights. Except as it relates to DuneEX Partners, nothing expressed or referred to
                    in these Terms shall be construed as conferring any legal or equitable right, remedy, or claim upon
                    any person other than the parties to these Terms. These Terms and all their provisions are intended
                    solely for the benefit of the parties to these Terms and their successors and permitted assigns.</p>

                <p>&nbsp;</p>

                <p>36.10. Survival. All provisions and sub-provisions of these Terms that by their nature extend beyond
                    the expiration or termination of these Terms shall remain in effect and binding upon expiration or
                    termination of these Terms.</p>

                <p>&nbsp;</p>

                <p>36.11. Relationship of Parties. DuneEX is not your agent under these Terms. These Terms shall not be
                    construed as evidence or a fact of association, joint venture, partnership, or franchise between the
                    parties.</p>

                <p>&nbsp;</p>

                <p>36.12. Digital Assets. We maintain a record in your DuneEX Account of the amount and type of any
                    digital assets held in your Account. DuneEX is not a trustee and has no fiduciary obligations with
                    respect to any digital assets held in your Account. When you submit Instructions to DuneEX to
                    transfer Digital Assets or otherwise deal with them, DuneEX will not use any specific Digital Assets
                    as identified to fulfill your Instructions. Pursuant to these Terms, DuneEX will use Digital Assets
                    of the same amount and type as credited to your DuneEX Account when executing your Instructions.</p>

                <p>&nbsp;</p>

                <p>36.13. Force Majeure. We shall not be liable for any delay or failure to perform under these Terms to
                    the extent that such delay or failure is caused by force majeure events.</p>

                <p>&nbsp;</p>

                <p>36.14. Reservation of Rights. No delay or failure by us in exercising any right or remedy under these
                    Terms shall be construed as a waiver of such right or remedy or any other rights or remedies in the
                    future under these Terms. The rights and remedies provided in these Terms are cumulative and do not
                    exclude any rights or remedies provided by Applicable Law.</p>

                <p>&nbsp;</p>

                <p>36.15. Set-Off. In addition to any legal or other rights and remedies available under these Terms or
                    by law, we may set off any amounts owed by you under these Terms or otherwise. You must pay all
                    amounts owed to us without any set-offs, counterclaims, deductions, or withholdings of any kind
                    except as required by Applicable Law.</p>

                <p>&nbsp;</p>

                <p>36.16. Third Party Confidentiality. If you obtain information about another user through the Platform
                    or as a result of using DuneEX Services, you must maintain the confidentiality of the information
                    and use it only in connection with DuneEX Services and always in accordance with Applicable Law. You
                    must not disclose or disseminate any information about third-party users or use it in any manner
                    except where reasonably necessary to complete a Transaction.</p>

                <p>&nbsp;</p>

                <p>36.17. Publication of Violations. If you breach these Terms, DuneEX may publish or otherwise disclose
                    information about the breach, including any information you provided to DuneEX. DuneEX may do so
                    only as necessary to protect other users and as permitted by Applicable Law.</p>

                <p>&nbsp;</p>

                <p>36.18. Death of DuneEX Account Owner. In the event of your death or incapacity, the administrator(s)
                    of your estate or surviving heir(s) must notify us in writing as soon as reasonably possible. If we
                    have reason to believe that you have died, we may suspend your DuneEX Account. Your DuneEX Account
                    will be suspended until the following conditions are met:</p>

                <p>&nbsp;</p>

                <p>a. the administrator of your estate or authorized beneficiary either (1) creates a DuneEX Account in
                    accordance with these Terms; or (2) provides wire transfer instructions; and</p>

                <p>&nbsp;</p>

                <p>b. provides sufficient legal documentation confirming their right to receive assets in your DuneEX
                    Account; or</p>

                <p>&nbsp;</p>

                <p>c. you provide satisfactory proof that you have not died.</p>

                <p>&nbsp;</p>

                <p>Beneficiaries receiving a wire transfer will receive the liquidation value of assets in the DuneEX
                    Account less any fees and expenses associated with the transfer. Our ability to provide assets in
                    your DuneEX Account to your representative(s) depends on limitations imposed by Applicable Law and
                    these Terms. We are not obligated to meet any specific deadlines for transferring assets held in
                    your DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>36.19. Tax. You are responsible for determining which, if any, taxes apply to payments you make or
                    receive, and for collecting, reporting, and remitting the correct tax to the appropriate tax
                    authority. You agree that we are not responsible for determining whether any taxes apply to your use
                    of DuneEX Services, or for collecting, reporting, or remitting any taxes arising from any
                    Transaction or your use of DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>You acknowledge that we may provide certain tax reports to tax authorities regarding Transactions
                    conducted on the Platform, and that we may, at our discretion or as required by Applicable Law,
                    provide you with additional documentation or records necessary for calculating any tax obligations.
                    We may also, at our discretion, withhold and deduct any taxes due in accordance with Applicable
                    Law.</p>

                <p>&nbsp;</p>

                <p>37. Definitions and Interpretation</p>

                <p>&nbsp;</p>

                <p>In these Terms:</p>

                <p>&nbsp;</p>

                <p>a. Headings and numbering are for convenience only and do not affect the meaning, priority, or
                    interpretation of any provision or subparagraph of these Terms;</p>

                <p>&nbsp;</p>

                <p>b. The words &quot;includes&quot; or &quot;including&quot; mean &quot;includes without
                    limitation&quot; and &quot;including without limitation,&quot; respectively;</p>

                <p>&nbsp;</p>

                <p>c. Any obligation to perform or refrain from any action implies an obligation not to permit the
                    performance or non-performance of such action or thing concerning yourself or others;</p>

                <p>&nbsp;</p>

                <p>d. Words denoting singular number include the plural and vice versa, and words denoting gender
                    include all genders;</p>

                <p>&nbsp;</p>

                <p>e. Any referenced document is periodically amended, modified, or supplemented, except where such
                    amendments would violate these Terms or the terms of such document;</p>

                <p>&nbsp;</p>

                <p>f. In case of discrepancies between these Terms (including any documents mentioned herein), the
                    provisions and documents shall prevail in the following order:</p>

                <p>&nbsp;</p>

                <p>I. Product Terms;</p>

                <p>&nbsp;</p>

                <p>II. Privacy Notice;</p>

                <p>&nbsp;</p>

                <p>III. these Terms.</p>

                <p>&nbsp;</p>

                <p>g. Unless the context requires otherwise, the following terms have the following meanings:</p>

                <p>&nbsp;</p>

                <p>Access Credentials means your DuneEX Account data, usernames, passwords, personal identification
                    numbers, API keys, API secret keys, or any other codes or forms of authentication you use to access
                    your DuneEX Account or DuneEX Services or to issue Instructions.</p>

                <p>&nbsp;</p>

                <p>Account History means written records (including electronic records) of your Transactions and your
                    DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>API means application programming interface.</p>

                <p>&nbsp;</p>

                <p>Applicable Law means all relevant or applicable statutes, laws (including common law principles),
                    principles of equity, rules, regulations, regulatory principles and requirements, notices, orders,
                    directives, court orders, judicial decisions, subordinate legal acts, resolutions, directives,
                    official announcements, circulars, mandatory codes of conduct, guidelines, practice guides, and
                    interpretations (whether from a governmental, regulatory, or other body or self-regulatory
                    organization of which DuneEX is a member) applicable to the provision, receipt, or use of DuneEX
                    Services or any other products or outcomes provided, used, or obtained in connection with DuneEX
                    Services, these Terms, or any Product Terms.</p>

                <p>&nbsp;</p>

                <p>Secured Digital Assets has the meaning set forth in Section 15.3.</p>

                <p>&nbsp;</p>

                <p>DuneEX, we, our, or us means Nest Services Limited (a company incorporated under the laws of the
                    Republic of Seychelles with registration number 238045 and registered address at House of Francis,
                    Room 303, Ile Du Port, Mahe, Seychelles).</p>

                <p>&nbsp;</p>

                <p>DuneEX Account means any accounts (including Corporate DuneEX Accounts) or sub-accounts (including
                    any DuneEX Sub-Accounts) opened by DuneEX for you for the purpose of accounting for your use of
                    DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>DuneEX Partners means with respect to Nest Services Limited, any other person who directly or
                    indirectly Controls, is Controlled by, or is under common Control with Nest Services Limited.</p>

                <p>&nbsp;</p>

                <p>DuneEX API means an API provided to you by a DuneEX Partner as a service or third-party applications
                    with such API.</p>

                <p>&nbsp;</p>

                <p>DuneEX IP means Intellectual Property Rights owned by us or licensed by us on a sublicensable basis
                    as of the date of these Terms, and any other Intellectual Property Rights owned by us, acquired by
                    us, or licensed by us on a sublicensable basis after the date of these Terms, provided to you in
                    connection with the provision of DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>DuneEX Services means the services provided by us to you through the Platform.</p>

                <p>&nbsp;</p>

                <p>DuneEX Sub-Account means a sub-account created within the main DuneEX Account.</p>

                <p>&nbsp;</p>

                <p>Service chat means the value specified in clause 5.</p>

                <p>&nbsp;</p>

                <p>Claim means any disputes, claims, differences, or disagreements between you and DuneEX (and/or any
                    DuneEX Partners), arising from, related to, or in any way connected to:</p>

                <p>&nbsp;</p>

                <p>(a) these Terms or any Product Terms, including the existence, validity, subject matter,
                    interpretation, performance, breach, termination, enforceability, or consequences of the invalidity
                    of such terms;</p>

                <p>&nbsp;</p>

                <p>(b) your relationship with DuneEX (and/or DuneEX Partners) as a user (whether based on contract,
                    tort, statute, fraud, misrepresentation, or any other legal norm, and regardless of whether claims
                    arise during or after the termination of these Terms); or</p>

                <p>&nbsp;</p>

                <p>(c) any non-contractual obligations arising from or relating to these Terms, any Product Terms, or
                    your relationship with DuneEX (and/or any DuneEX Partners).</p>

                <p>&nbsp;</p>

                <p>Control means the authority of a person to manage the affairs of another person in accordance with
                    the wishes of the first person, whether by:</p>

                <p>&nbsp;</p>

                <p>(a) in the case of a company, being the beneficial owner of more than fifty percent (50%) of the
                    issued share capital or voting rights in that company, or having the right to appoint and remove a
                    majority of the directors or otherwise control the votes at meetings of the board of directors of
                    that company by virtue of any powers granted by the organizational documents, shareholder agreement,
                    majority of votes of the board of directors, or any other document governing the operations of that
                    company, or by any other means; or</p>

                <p>&nbsp;</p>

                <p>(b) in the case of a partnership, being the beneficial owner of more than fifty percent (50%) of the
                    capital of that partnership, or having the right to control the composition or votes of a majority
                    of the management of that partnership by virtue of any powers granted by the partnership agreement
                    or any other document governing the affairs of that partnership, or by any other means.</p>

                <p>&nbsp;</p>

                <p>Corporate DuneEX Account means a DuneEX Account that provides a corporation, legal entity, or other
                    organization with access to DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>Created IP means any intellectual property rights created by you in accordance with these Terms,
                    including User Materials, but excluding any other User IP.</p>

                <p>&nbsp;</p>

                <p>Digital Assets mean a digital representation of value or rights that can be transferred and stored
                    electronically using distributed ledger technology or similar technology, including, among others,
                    cryptocurrencies, stablecoins, non-fungible tokens, and tokenized derivatives of any other digital
                    assets.</p>

                <p>&nbsp;</p>

                <p>Dominant Digital Asset means a Forked Digital Asset that dominates over one or more other versions of
                    the Digital Asset associated with the respective Fork, as determined by us at our discretion.</p>

                <p>&nbsp;</p>

                <p>Email means email(s) associated with your DuneEX Account(s), as periodically agreed with DuneEX, in
                    accordance with any processes defined by DuneEX when using the Platform.</p>

                <p>&nbsp;</p>

                <p>Fiat Currency means any government-issued or central bank-issued national or supranational currency
                    or other monetary obligation expressed in such currency and not a Digital Asset.</p>

                <p>&nbsp;</p>

                <p>Force Majeure Events means:</p>

                <p>&nbsp;</p>

                <p>(a) any fire, strike, riot, civil unrest, terrorist act, war, or industrial conflict;</p>

                <p>&nbsp;</p>

                <p>(b) any natural disasters such as floods, tornadoes, earthquakes, and hurricanes;</p>

                <p>&nbsp;</p>

                <p>(c) any epidemic, pandemic, or public health emergency of national or international significance;</p>

                <p>&nbsp;</p>

                <p>(d) any act or regulation of government, supranational body, or institution which, in our opinion,
                    prevents us from providing DuneEX Services on the Platform;</p>

                <p>&nbsp;</p>

                <p>(e) suspension or closure of any DuneEX Partner;</p>

                <p>&nbsp;</p>

                <p>(f) nationalization of any DuneEX Partner;</p>

                <p>&nbsp;</p>

                <p>(g) imposition by government of restrictions or unusual conditions on any Digital Assets traded on
                    our Platform;</p>

                <p>&nbsp;</p>

                <p>(h) excessive changes in the price, supply, or demand of any Digital Asset;</p>

                <p>&nbsp;</p>

                <p>(i) technical failures in information transmission means, communication means, or computer equipment,
                    including power supply failures and failures in the operation of electronics or equipment;</p>

                <p>&nbsp;</p>

                <p>(j) inability of any liquidity provider, broker-dealer, agent, primary custodian, sub-custodian,
                    dealer, exchange, staking platform, liquidity pool, bridge provider, secured digital asset issuer,
                    market maker, clearing house, or regulatory organization to fulfill its obligations to us;</p>

                <p>&nbsp;</p>

                <p>(k) liquidity providers not providing or unable to provide liquidity to us;</p>

                <p>&nbsp;</p>

                <p>(l) any labor or trade disputes, strikes, industrial conflicts, or lockouts (except when initiated by
                    DuneEX or DuneEX Partners); and/or</p>

                <p>&nbsp;</p>

                <p>(m) an event that significantly affects the market of Digital Assets, including excessive changes in
                    the price, supply, or demand of a Digital Asset, both regulated and unregulated.</p>

                <p>&nbsp;</p>

                <p>Fork means any planned, unplanned, sudden, scheduled, expected, unexpected, disclosed, little-known,
                    agreed and/or disputed changes to the fundamental operating rules of certain Digital Assets, which
                    may periodically occur in such a way as to lead to the creation of one or more related versions of
                    the existing Digital Asset.</p>

                <p>&nbsp;</p>

                <p>Past fork of a digital asset means a Digital Asset that arose as a result of a Fork.</p>

                <p>&nbsp;</p>

                <p>Instruction means any instruction, request, or order transmitted by you or an Authorized User of
                    DuneEX in connection with the operation of your DuneEX Account or to execute any Transaction,
                    through such medium, in such form, and by such means as may be required by DuneEX.</p>

                <p>&nbsp;</p>

                <p>Intellectual Property Rights mean: (a) copyright, patents, database rights, and rights to trademarks,
                    designs, know-how, and confidential information (whether registered or unregistered); (b)
                    applications for registration and rights to apply for registration of any of the aforementioned
                    rights; and (c) all other intellectual property rights and equivalent or similar forms of protection
                    existing in any part of the world.</p>

                <p>&nbsp;</p>

                <p>Local Terms means the terms governing your use of DuneEX Services provided in any specific
                    jurisdiction.</p>

                <p>&nbsp;</p>

                <p>Losses means as defined in section 29.1.</p>

                <p>&nbsp;</p>

                <p>Manifest error means any error, omission or misquotation (whether by DuneEX or any third party) which
                    is manifest or palpable, including misquotation by any representative of DuneEX in light of current
                    market conditions, or any error in any information, source, official, official result or
                    pronunciation.</p>

                <p>&nbsp;</p>

                <p>Authorized User means any person identified by you and notified to us in accordance with these Terms
                    who is authorized to act on behalf of the user in relation to any DuneEX Corporate Account.</p>

                <p>&nbsp;</p>

                <p>Platform means the digital platform that we or any DuneEX Partner may make available to you through
                    Websites, Mobile App, DuneEX API, or other means that DuneEX Partners may periodically prescribe for
                    the use of DuneEX Services.</p>

                <p>&nbsp;</p>

                <p>Privacy Notice means the privacy notice located at https://www.dune-ex.net/en/privacy.</p>

                <p>&nbsp;</p>

                <p>Product Terms means terms and conditions relating to a specific product that apply to the use of
                    DuneEX Services in addition to these Terms.</p>

                <p>&nbsp;</p>

                <p>Regulatory Authority means any foreign, national, state, federal, cantonal, municipal or local
                    government, executive, legislative, judicial, administrative, supervisory or regulatory authority,
                    agency, quasi-governmental body, court, commission, governmental organization, self-regulatory
                    organization with regulatory powers, tribunal, arbitration panel, arbitration commission or
                    supranational organization or any subdivision or organ thereof, including any tax authority.</p>

                <p>&nbsp;</p>

                <p>Restricted Person means any natural or legal person who (a) is included in any trade embargo or
                    economic sanctions, terrorist list or corrupt foreign official list (e.g., United Nations Security
                    Council Sanctions List issued by a government agency, including the List of specially designated
                    nationals and blocked persons of the Office of Foreign Assets Control (OFAC) of the U.S. Department
                    of the Treasury or the list of prohibited persons and organizations of the U.S. Department of
                    Commerce, as well as the lists of the United Kingdom, European Union and Canada)</p>

                <p>&nbsp;</p>

                <p>Risk Warning means a general risk warning published on the Site.</p>

                <p>&nbsp;</p>

                <p>Security Breach means</p>

                <p>&nbsp;</p>

                <p>a. hacking your DuneEX Account(s) or any of your Access Credentials;</p>

                <p>&nbsp;</p>

                <p>b. loss, theft, unauthorized use of any of your Access Credentials or any unauthorized access and use
                    of your DuneEX Account or DuneEX Services on your behalf; or</p>

                <p>&nbsp;</p>

                <p>c. any other security incident (including cyber attack) affecting you and/or DuneEX.</p>

                <p>&nbsp;</p>

                <p>Websites mean our Website and any other websites, pages, features or content that we own or
                    operate.</p>

                <p>&nbsp;</p>

                <p>Supported Digital Assets mean those Digital Assets available in connection with your use of DuneEX
                    Services.</p>

                <p>&nbsp;</p>

                <p>Terms mean (at each mention, as well as periodic changes or additions) these terms of use, as well as
                    any other documents explicitly mentioned, including Product Terms.</p>

                <p>&nbsp;</p>

                <p>Trademarks mean Intellectual Property Rights in trademarks, service marks, and logos used and
                    displayed on the Platform, Websites, and/or DuneEX Services or through them.</p>

                <p>&nbsp;</p>

                <p>Transaction means sale, purchase or conclusion of any other type of transactions or consent to sale,
                    purchase or conclusion of any other type of transactions with Digital Asset(s), their derivatives,
                    other asset(s) or product(s) which DuneEX may periodically allow to be conducted on the Platform, as
                    well as deposit or withdrawal of Digital Assets to your DuneEX Account or from it.</p>

                <p>&nbsp;</p>

                <p>User IP means Intellectual Property Rights owned by you or licensed to you as of the date of these
                    Terms, and any other Intellectual Property Rights owned by you or acquired or licensed by you after
                    the date of these Terms, excluding DuneEX IP.</p>

                <p>&nbsp;</p>

                <p>User Materials mean Intellectual Property Rights in any reviews, publications, information, data, and
                    comments that you or other users provide to us on the Websites (through our &quot;Contact
                    Us&quot; pages or otherwise), Platform, through the use of DuneEX Services or otherwise.</p>

                <p><br/>
                    Website means the website located at<a href="http://www.dune-ex.net"> www.dune-ex.net</a></p>

            </div> : "" }
            {currentLanguage === "de" ? <div className="comps__content">
                    <p className="footer-title-comp" style={{color: "#FFFFFF"}}>NUTZUNGSBEDINGUNGEN</p>

                <p>&nbsp;</p>

                <p>Zuletzt aktualisiert: 11. Februar 2024</p>

                <p>&nbsp;</p>

                <p>Diese Bedingungen stellen eine rechtsverbindliche Vereinbarung zwischen Ihnen
                    (&bdquo;Sie&ldquo;, &bdquo;Ihr&ldquo;) und DuneEX
                    (&bdquo;DuneEX&ldquo;, &bdquo;wir&ldquo;, &bdquo;unser&ldquo;, &bdquo;uns&ldquo;) dar. Diese
                    Bedingungen regeln Ihre Nutzung der DuneEX-Dienste, die Ihnen auf der Plattform oder anderweitig zur
                    Verf&uuml;gung gestellt werden. Die DuneEX-Dienste k&ouml;nnen von DuneEX oder, wo in diesen
                    Bedingungen, in den Produktbedingungen oder in zus&auml;tzlichen Bedingungen angegeben, von einem
                    DuneEX-Partner bereitgestellt werden.</p>

                <p>&nbsp;</p>

                <p>Durch die Registrierung eines DuneEX-Kontos, den Zugriff auf die Plattform und/oder die Nutzung der
                    DuneEX-Dienste best&auml;tigen Sie, dass Sie diese Bedingungen sowie alle zus&auml;tzlichen
                    Dokumente oder Bedingungen, die in diesen Bedingungen erw&auml;hnt werden, gelesen, verstanden und
                    akzeptiert haben. Sie erkennen an und stimmen zu, dass Sie verpflichtet sind, diese Bedingungen
                    einzuhalten, die regelm&auml;&szlig;ig aktualisiert und ge&auml;ndert werden.</p>

                <p>&nbsp;</p>

                <p>Wenn Sie diese Bedingungen nicht vollst&auml;ndig verstehen und akzeptieren, sollten Sie kein
                    DuneEX-Konto registrieren, nicht auf die Plattform zugreifen oder irgendwelche DuneEX-Dienste
                    nutzen.</p>

                <p>&nbsp;</p>

                <p><strong>Risikowarnung</strong></p>

                <p>&nbsp;</p>

                <p>Der Wert von digitalen Verm&ouml;genswerten kann erheblich schwanken, was zu wirtschaftlichen
                    Verlusten f&uuml;hren kann, wenn Sie digitale Verm&ouml;genswerte kaufen, verkaufen, halten oder in
                    sie investieren. Daher m&uuml;ssen Sie beurteilen, ob der Handel mit oder das Halten von digitalen
                    Verm&ouml;genswerten f&uuml;r Sie geeignet ist, unter Ber&uuml;cksichtigung Ihrer finanziellen
                    Verh&auml;ltnisse.</p>

                <p>&nbsp;</p>

                <p>Sie m&uuml;ssen alle Risiken vollst&auml;ndig verstehen, bevor Sie sich entscheiden, die
                    DuneEX-Dienste zu nutzen.</p>

                <p>&nbsp;</p>

                <p>Wir sind nicht Ihr Broker, Vermittler, Agent oder Berater und haben keine treuh&auml;nderische
                    Beziehung oder Verpflichtung Ihnen gegen&uuml;ber in Bezug auf Transaktionen oder andere Handlungen,
                    die Sie bei der Nutzung der DuneEX-Dienste vornehmen. Wir geben keine Anlage- oder
                    Beratungsleistungen jeglicher Art und keine Informationen oder Mitteilungen, die wir Ihnen zur
                    Verf&uuml;gung stellen, stellen Beratung jeglicher Art dar oder sollten als solche betrachtet
                    werden.</p>

                <p>&nbsp;</p>

                <p>Sie allein bestimmen, ob irgendwelche Investitionen, Anlagestrategien oder damit verbundene
                    Transaktionen Ihren pers&ouml;nlichen Anlagezielen, Ihrer finanziellen Situation und dem
                    zul&auml;ssigen Risiko entsprechen, und Sie sind f&uuml;r alle damit verbundenen Verluste oder
                    Verbindlichkeiten verantwortlich. Wir empfehlen Ihnen nicht, irgendwelche digitalen
                    Verm&ouml;genswerte zu kaufen, zu verdienen, zu verkaufen oder zu halten. Bevor Sie sich
                    entscheiden, irgendwelche digitalen Verm&ouml;genswerte zu kaufen, zu verkaufen oder zu halten,
                    sollten Sie Ihre eigenen Nachforschungen anstellen und einen Finanzexperten konsultieren. Wir sind
                    nicht verantwortlich f&uuml;r Ihre Entscheidungen, digitale Verm&ouml;genswerte zu kaufen, zu
                    verdienen, zu verkaufen oder zu halten, basierend auf den von uns bereitgestellten Informationen,
                    einschlie&szlig;lich aller Verluste, die Sie aufgrund solcher Entscheidungen erleiden.</p>

                <p><br/>
                    <strong>INFORMATIONEN &Uuml;BER UNSERE VEREINBARUNG MIT KLIENTEN</strong></p>

                <p>&nbsp;</p>

                <p>1. Einf&uuml;hrung</p>

                <p>&nbsp;</p>

                <p>1.1. &Uuml;ber uns. Die DuneEX-Gruppe ist ein &Ouml;kosystem, das sich um eine Online-B&ouml;rse
                    f&uuml;r den Handel mit digitalen Verm&ouml;genswerten dreht. Die DuneEX-Gruppe bietet den Nutzern
                    eine Handelsplattform zum Kauf und Verkauf digitaler Verm&ouml;genswerte, eine integrierte
                    Verwahrungsl&ouml;sung, die es den Nutzern erm&ouml;glicht, ihre digitalen Verm&ouml;genswerte zu
                    speichern, und andere Dienstleistungen im Zusammenhang mit digitalen Verm&ouml;genswerten.</p>

                <p>&nbsp;</p>

                <p>1.2. Diese Bedingungen. Durch die Registrierung eines DuneEX-Kontos schlie&szlig;en Sie mit uns eine
                    rechtlich verbindliche Vereinbarung ab. Diese Bedingungen regeln Ihre Nutzung der DuneEX-Dienste und
                    informieren Sie dar&uuml;ber, wer wir sind, wie wir Ihnen die DuneEX-Dienste bereitstellen, wie
                    diese Bedingungen ge&auml;ndert oder gek&uuml;ndigt werden k&ouml;nnen, was bei Problemen zu tun ist
                    und andere wichtige Informationen.</p>

                <p>&nbsp;</p>

                <p>Sie m&uuml;ssen diese Bedingungen und die in den Bedingungen genannten Dokumente sorgf&auml;ltig
                    lesen und uns mitteilen, wenn Sie etwas nicht verstehen.</p>

                <p>&nbsp;</p>

                <p>Wenn f&uuml;r Ihre Nutzung der DuneEX-Dienste lokale Bedingungen gelten, haben diese lokalen
                    Bedingungen Vorrang vor diesen Bedingungen.</p>

                <p>&nbsp;</p>

                <p>1.3. Zus&auml;tzliche Dokumente. Diese Bedingungen verweisen auf mehrere zus&auml;tzliche Dokumente,
                    die ebenfalls f&uuml;r Ihre Nutzung der DuneEX-Dienste gelten. Diese beinhalten:</p>

                <p>&nbsp;</p>

                <p>a. Unsere Datenschutzerkl&auml;rung, die erkl&auml;rt, wie wir Ihre pers&ouml;nlichen Daten
                    verarbeiten und sicherstellt, dass die von Ihnen bereitgestellten Daten genau und aktuell sind.</p>

                <p>&nbsp;</p>

                <p>b. Unsere Risikowarnung, die wichtige Informationen &uuml;ber die Risiken enth&auml;lt, die beim
                    Kauf, Verkauf, Halten oder Investieren in digitale Verm&ouml;genswerte auftreten k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>d. Produktbedingungen, die zus&auml;tzliche Bestimmungen und Bedingungen enthalten, die f&uuml;r Ihre
                    Nutzung spezifischer DuneEX-Dienste gelten.</p>

                <p>&nbsp;</p>

                <p>Sie erkennen an und stimmen zu, alle entsprechenden zus&auml;tzlichen Bedingungen und Bestimmungen
                    einzuhalten, die f&uuml;r Ihre Nutzung der DuneEX-Dienste gelten.</p>

                <p>&nbsp;</p>

                <p>2. Zug&auml;nglichkeit</p>

                <p>&nbsp;</p>

                <p>2.1. Zulassungskriterien. Um sich f&uuml;r ein DuneEX-Konto zu registrieren und die DuneEX-Dienste zu
                    nutzen, m&uuml;ssen Sie die folgenden Kriterien erf&uuml;llen:</p>

                <p>&nbsp;</p>

                <p>a. Eine nat&uuml;rliche Person, eine K&ouml;rperschaft, eine juristische Person, ein Unternehmen oder
                    eine andere Organisation sein, die alle erforderlichen Befugnisse, Rechte und Kapazit&auml;ten
                    besitzt, um (1) auf die DuneEX-Dienste zuzugreifen und diese zu nutzen; und (2) die Verpflichtungen
                    aus diesen Bedingungen einzugehen und zu erf&uuml;llen;</p>

                <p>&nbsp;</p>

                <p>b. Wenn Sie eine nat&uuml;rliche Person sind, mindestens 18 Jahre alt sein;</p>

                <p>&nbsp;</p>

                <p>c. Wenn Sie als Mitarbeiter oder Vertreter einer juristischen Person handeln und diese Bedingungen in
                    deren Namen abschlie&szlig;en, m&uuml;ssen Sie ordnungsgem&auml;&szlig; befugt sein, im Namen dieser
                    juristischen Person zu handeln und diese an die Verpflichtungen aus diesen Bedingungen zu
                    binden;</p>

                <p>&nbsp;</p>

                <p>d. Ihnen wurden die DuneEX-Dienste nicht zuvor ausgesetzt oder gek&uuml;ndigt;</p>

                <p>&nbsp;</p>

                <p>e. Sie sind keine eingeschr&auml;nkte Person;</p>

                <p>&nbsp;</p>

                <p>f. Sie haben kein bestehendes DuneEX-Konto;</p>

                <p>&nbsp;</p>

                <p>g. Sie sind nicht in einem Land ans&auml;ssig, registriert oder anderweitig gegr&uuml;ndet, sind kein
                    B&uuml;rger oder Einwohner eines Landes und betreiben keine Gesch&auml;ftst&auml;tigkeit in einem
                    Land, das ist:</p>

                <p>&nbsp;</p>

                <p>i. Eine Gerichtsbarkeit, in der es nach geltendem Recht illegal ist oder zu einem Versto&szlig; gegen
                    geltendes Recht durch uns oder Dritte f&uuml;hrt, wenn Sie auf die DuneEX-Dienste zugreifen oder
                    diese nutzen.</p>

                <p>&nbsp;</p>

                <p>2.2. &Auml;nderung unserer Zulassungskriterien. Wir k&ouml;nnen unsere Zulassungskriterien jederzeit
                    nach eigenem Ermessen &auml;ndern. Wir werden Sie nach M&ouml;glichkeit im
                    Voraus &uuml;ber &Auml;nderungen informieren. Manchmal m&uuml;ssen &Auml;nderungen jedoch ohne
                    vorherige Ank&uuml;ndigung vorgenommen werden, beispielsweise:</p>

                <p>&nbsp;</p>

                <p>a. &Auml;nderungen aufgrund von Gesetzes- und/oder Regulierungsvorschriften;</p>

                <p>&nbsp;</p>

                <p>b. &Auml;nderungen zu Ihrem Vorteil; und/oder</p>

                <p>&nbsp;</p>

                <p>c. Es gibt einen anderen wichtigen Grund, der eine vorherige Benachrichtigung verhindert.</p>

                <p>&nbsp;</p>

                <p>Wenn wir Sie nicht im Voraus benachrichtigen k&ouml;nnen, werden wir Sie so schnell wie m&ouml;glich
                    nach der &Auml;nderung informieren.</p>

                <p>&nbsp;</p>

                <p>3. Kommunikationsmittel</p>

                <p>&nbsp;</p>

                <p>3.1. Wie Sie uns kontaktieren k&ouml;nnen. Weitere Informationen zu DuneEX finden Sie auf unserer
                    Website. Wenn Sie Fragen, Feedback oder Beschwerden haben, k&ouml;nnen Sie uns &uuml;ber unseren
                    Support-Service unter https://dune-ex.net/contacts kontaktieren. Diese Bedingungen k&ouml;nnen
                    Kontaktinformationen f&uuml;r bestimmte Arten von Nachrichten bereitstellen. Wir akzeptieren solche
                    Nachrichten nicht an der oben angegebenen Adresse.</p>

                <p>&nbsp;</p>

                <p>3.2. Wie wir Sie kontaktieren werden. Wir werden Sie &uuml;ber die von Ihnen bereitgestellten
                    Informationen kontaktieren. Kommunikationsmittel k&ouml;nnen E-Mail, SMS oder Telefon umfassen.
                    Stellen Sie sicher, dass Sie genaue und aktuelle Kontaktdaten angeben. Wenn sich Ihre
                    Kontaktdaten &auml;ndern, m&uuml;ssen Sie uns dies sofort mitteilen. Wenn Sie dies nicht tun, sind
                    wir nicht verantwortlich, wenn Sie keine Informationen, Benachrichtigungen oder andere wichtige
                    Informationen von uns erhalten.</p>

                <p>&nbsp;</p>

                <p>4. DuneEX-Dienste</p>

                <p>&nbsp;</p>

                <p>4.1. Spezifische Produktbedingungen. Nach der Erstellung eines DuneEX-Kontos k&ouml;nnen Sie die
                    DuneEX-Dienste gem&auml;&szlig; diesen Bedingungen und den Produktbedingungen nutzen, die Ihre
                    Nutzung der einzelnen DuneEX-Dienste regeln.</p>

                <p>&nbsp;</p>

                <p>4.2. Innerbetriebliche Dienstleistungen. Sie erkennen an und stimmen zu, dass einige DuneEX-Dienste
                    von DuneEX-Partnern bereitgestellt werden k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>5. Chat-Service</p>

                <p>&nbsp;</p>

                <p>5.1. Verf&uuml;gbarkeit des Chat-Services. Wir k&ouml;nnen Ihnen jederzeit unseren interaktiven
                    Online-Chat-Service (&quot;Chat-Service&quot;) im Zusammenhang mit Ihrer Nutzung eines der
                    DuneEX-Dienste zur Verf&uuml;gung stellen. Durch die Nutzung des Chat-Services k&ouml;nnen Sie mit
                    einem Bot, Chatbot oder einer anderen nicht-menschlichen Funktion interagieren. Wir werden
                    Informationen &uuml;ber die Nutzung eines Chatbots oder einer anderen nicht-menschlichen Funktion in
                    dem Ma&szlig;e offenlegen, wie es nach geltendem Recht erforderlich ist. Durch die Interaktion mit
                    uns &uuml;ber den Chat-Service autorisieren Sie uns, Ihre Chats zu &uuml;berwachen und zu
                    speichern.</p>

                <p>&nbsp;</p>

                <p>5.2. Wichtige Informationen. Der Chat-Service wird zur Vereinfachung bereitgestellt, oft um Ihnen das
                    Verst&auml;ndnis der DuneEX-Dienste zu erleichtern. Unser Chat-Service wird angemessene
                    Anstrengungen unternehmen, um Ihnen genaue und aktuelle Informationen gem&auml;&szlig; Ihrer Frage
                    oder Ihrem Bedarf bereitzustellen. Nichts, was wir Ihnen &uuml;ber den Chat-Service mitteilen, kann
                    als rechtliche Vereinbarung, Zusicherung oder Garantie in Bezug auf die DuneEX-Dienste, Prozesse,
                    Entscheidungen oder Antwortzeiten angesehen werden. Pers&ouml;nliche Daten, die uns bei der Nutzung
                    des Chat-Services bereitgestellt werden, unterliegen den geltenden Datenschutzrichtlinien und
                    Hinweisen, die in unserer Datenschutzerkl&auml;rung beschrieben sind.</p>

                <p>&nbsp;</p>

                <p>5.3. Benutzer-Chats. Zus&auml;tzlich k&ouml;nnen wir Ihnen Chats zur Verf&uuml;gung stellen, die es
                    Ihnen erm&ouml;glichen, direkt mit anderen Nutzern der Plattform zu interagieren
                    (&quot;Benutzer-Chats&quot;).</p>

                <p>&nbsp;</p>

                <p>5.4. Verbotene Handlungen. Es ist verboten, den Chat-Service oder einen Benutzer-Chat zu nutzen, um
                    beleidigende, verleumderische, b&ouml;swillige oder obsz&ouml;ne Nachrichten zu senden; Nachrichten,
                    die darauf abzielen, den Markt zu manipulieren oder falsche oder irref&uuml;hrende Informationen zu
                    verbreiten; Nachrichten, die anderweitig gegen geltendes Recht versto&szlig;en. Solche Handlungen
                    k&ouml;nnen zur Beendigung der Chat-Service-Sitzung und zur Einschr&auml;nkung Ihres Zugriffs auf
                    die DuneEX-Dienste f&uuml;hren.</p>

                <p>&nbsp;</p>

                <p>6. Geb&uuml;hren</p>

                <p>&nbsp;</p>

                <p>6.1. Zahlung von Geb&uuml;hren. Sie stimmen zu, alle anfallenden Geb&uuml;hren im Zusammenhang mit
                    Ihrer Nutzung der DuneEX-Dienste zu bezahlen.</p>

                <p>&nbsp;</p>

                <p>Sie erm&auml;chtigen uns, alle anfallenden Geb&uuml;hren, Abgaben, Zinsen, Zahlungen und anderen
                    Betr&auml;ge, die Sie schulden, von Ihrem DuneEX-Konto gem&auml;&szlig; diesen Bedingungen oder den
                    Produktbedingungen unter Verwendung der Berechnungsmethode abzuziehen. Wenn Sie uns einen Betrag in
                    einem digitalen Verm&ouml;genswert schulden, aber nicht &uuml;ber ausreichende Mittel in diesem
                    digitalen Verm&ouml;genswert verf&uuml;gen, k&ouml;nnen wir den geschuldeten Betrag in einem anderen
                    digitalen Verm&ouml;genswert abziehen, um die Zahlung zu leisten (in diesem Fall konvertieren wir
                    den digitalen Verm&ouml;genswert, den Sie haben, in den digitalen Verm&ouml;genswert, den Sie uns
                    schulden (oder das &Auml;quivalent in Fiat-W&auml;hrung) zum aktuellen Kurs auf der Plattform oder
                    zu einem anderen kommerziell angemessenen Kurs nach unserem Ermessen). Wenn Ihr DuneEX-Konto
                    nicht &uuml;ber ausreichende digitale Verm&ouml;genswerte verf&uuml;gt, stimmen Sie zu, dass jeder
                    Betrag, den Sie gem&auml;&szlig; diesem Absatz schulden, eine Schuld ist, und Sie verpflichten sich,
                    diesen Betrag sofort auf kommerziell angemessene Weise in der H&ouml;he und Form (in Form von
                    digitalen Verm&ouml;genswerten oder anderen Verm&ouml;genswerten), die nach unserem Ermessen
                    festgelegt werden, zu zahlen.</p>

                <p>&nbsp;</p>

                <p>6.2. &Auml;nderung unserer Geb&uuml;hren. Wir k&ouml;nnen unsere Geb&uuml;hren regelm&auml;&szlig;ig
                    gem&auml;&szlig; Abschnitt 18.3 dieser Bedingungen anpassen.</p>

                <p><br/>
                    <strong>INFORMATIONEN &Uuml;BER IHR DuneEX-KONTO</strong></p>

                <p>&nbsp;</p>

                <p>7. Erstellung eines DuneEX-Kontos</p>

                <p>&nbsp;</p>

                <p>7.1. Kontoerstellung. Um Zugriff auf die DuneEX-Dienste und die Plattform zu erhalten, m&uuml;ssen
                    Sie ein DuneEX-Konto erstellen und pflegen. Dies kann ein DuneEX-Konto f&uuml;r eine Einzelperson
                    oder ein Unternehmens-DuneEX-Konto f&uuml;r eine Firma, eine juristische Person oder eine andere
                    Organisation sein.</p>

                <p>&nbsp;</p>

                <p>Alle DuneEX-Konten werden nach unserem alleinigen Ermessen bereitgestellt. Wir behalten uns das Recht
                    vor, ohne Angabe von Gr&uuml;nden jede Antragstellung auf ein DuneEX-Konto abzulehnen oder die
                    Anzahl der DuneEX-Konten zu begrenzen, die Sie besitzen k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>7.2. Einzelner Beg&uuml;nstigter. Durch die Erstellung eines DuneEX-Kontos erkennen Sie an, dass:</p>

                <p>&nbsp;</p>

                <p>a. wenn Sie ein einzelner Nutzer sind, Sie Ihr DuneEX-Konto nur f&uuml;r sich selbst und nicht im
                    Namen Dritter nutzen werden, es sei denn, Sie haben unsere vorherige schriftliche Zustimmung
                    erhalten;</p>

                <p>&nbsp;</p>

                <p>b. wenn Sie ein Unternehmensnutzer sind, Ihre autorisierten Benutzer das Unternehmens-DuneEX-Konto
                    nur zu Ihren Gunsten und nicht im Namen Dritter nutzen werden, es sei denn, Sie haben unsere
                    vorherige schriftliche Zustimmung erhalten.</p>

                <p>&nbsp;</p>

                <p>Sie sind voll verantwortlich f&uuml;r alle Aktivit&auml;ten, die in Ihrem DuneEX-Konto
                    stattfinden.</p>

                <p>&nbsp;</p>

                <p>7.3. Identit&auml;tspr&uuml;fung. Bevor Sie die Erlaubnis erhalten, ein DuneEX-Konto zu erstellen,
                    Zugriff auf die DuneEX-Dienste zu erhalten und diese zu nutzen, m&uuml;ssen Sie ein
                    Identit&auml;tspr&uuml;fungsverfahren durchlaufen, indem Sie uns bestimmte Informationen &uuml;ber
                    sich selbst und, falls zutreffend, &uuml;ber Ihre autorisierten Benutzer bereitstellen. Alle von
                    Ihnen bereitgestellten Informationen m&uuml;ssen vollst&auml;ndig, genau und
                    wahrheitsgem&auml;&szlig; sein. Wenn sich diese Informationen &auml;ndern, m&uuml;ssen Sie sie
                    unverz&uuml;glich aktualisieren.</p>

                <p>&nbsp;</p>

                <p>Sie erm&auml;chtigen uns, Anfragen zu stellen, direkt oder &uuml;ber Dritte, die wir f&uuml;r
                    notwendig halten, um Ihre Identit&auml;t und die Identit&auml;ten aller autorisierten Benutzer
                    zu &uuml;berpr&uuml;fen oder um Sie und/oder uns vor Betrug, Geldw&auml;sche,
                    Terrorismusfinanzierung oder anderen Finanzverbrechen zu sch&uuml;tzen und Ma&szlig;nahmen zu
                    ergreifen, die wir aufgrund der Ergebnisse solcher Anfragen f&uuml;r notwendig erachten.</p>

                <p>&nbsp;</p>

                <p>Wenn wir Anfragen stellen, erkennen Sie an und akzeptieren, dass Ihre pers&ouml;nlichen Daten und die
                    pers&ouml;nlichen Daten aller autorisierten Benutzer an Identit&auml;tspr&uuml;fungsagenturen,
                    Compliance-Datenregistrierungsstellen, Kreditauskunftsstellen, Betrugspr&auml;ventions- oder
                    Finanzkriminalit&auml;tspr&auml;ventionsagenturen weitergegeben werden k&ouml;nnen und dass diese
                    Agenturen in vollem Umfang auf unsere Anfragen antworten k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>7.4. Erweiterte Pr&uuml;fung. Wir k&ouml;nnen auch von Ihnen verlangen, dass Sie eine erweiterte
                    Pr&uuml;fung durchlaufen, indem Sie zus&auml;tzliche Informationen &uuml;ber sich selbst, Ihr
                    Gesch&auml;ft oder Ihre autorisierten Benutzer angeben, zus&auml;tzliche Aufzeichnungen oder
                    Dokumente bereitstellen oder pers&ouml;nliche Treffen mit Vertretern von DuneEX
                    durchf&uuml;hren.</p>

                <p>&nbsp;</p>

                <p>7.5. Aufzeichnungen. Wir speichern Ihre pers&ouml;nlichen Daten, damit Sie die DuneEX-Dienste
                    weiterhin nutzen k&ouml;nnen. Daten werden so lange gespeichert, wie sie zur Erf&uuml;llung der
                    entsprechenden Zwecke, die in dieser Datenschutzerkl&auml;rung beschrieben sind, erforderlich sind
                    und in &Uuml;bereinstimmung mit den gesetzlichen Anforderungen, wie z. B. f&uuml;r Steuer- und
                    Buchhaltungszwecke, zur Einhaltung von Geldw&auml;schegesetzen oder f&uuml;r andere Ihnen
                    mitgeteilte Zwecke.</p>

                <p>&nbsp;</p>

                <p>7.6. Unterkonten. Nach unserem Ermessen k&ouml;nnen Sie ein DuneEX-Unterkonto erstellen und darauf
                    zugreifen. Jede Einzelperson, die mit einem DuneEX-Unterkonto verbunden ist, unterliegt den in
                    diesem Abschnitt 7 festgelegten Identit&auml;tspr&uuml;fungsanforderungen. Ein DuneEX-Unterkonto
                    kann nur mit einer Einzelperson oder einer Firma verbunden sein.</p>

                <p>&nbsp;</p>

                <p>8. Informationsanfragen</p>

                <p>&nbsp;</p>

                <p>8.1. Wann wir Informationen anfordern k&ouml;nnen. Wir k&ouml;nnen jederzeit Informationen von Ihnen
                    anfordern, um geltendes Recht oder Identit&auml;tspr&uuml;fungsanforderungen zu erf&uuml;llen oder
                    im Zusammenhang mit der Erkennung von Geldw&auml;sche, Terrorismusfinanzierung, Betrug oder anderen
                    Finanzverbrechen oder aus einem anderen legitimen Grund. Sie stimmen zu, uns alle angeforderten
                    Informationen zur Verf&uuml;gung zu stellen und uns zu gestatten, Aufzeichnungen &uuml;ber diese
                    Informationen f&uuml;r die Dauer Ihres DuneEX-Kontos und so lange wie n&ouml;tig zur Erf&uuml;llung
                    der Zwecke oder f&uuml;r einen anderen Zeitraum, der durch geltendes Recht vorgesehen ist,
                    aufzubewahren.</p>

                <p>&nbsp;</p>

                <p>8.2. Was passiert, wenn Sie Informationen bereitstellen. Ihr Zugriff auf Ihr DuneEX-Konto und die
                    Transaktionslimits, die f&uuml;r Ihre Nutzung der DuneEX-Dienste gelten, k&ouml;nnen sich aufgrund
                    der &uuml;ber Sie kontinuierlich gesammelten Informationen &auml;ndern. Wenn es begr&uuml;ndete
                    Verdachtsmomente gibt, dass eine von Ihnen bereitgestellte Information falsch, unwahr, veraltet oder
                    unvollst&auml;ndig ist, k&ouml;nnen wir Ihnen eine Benachrichtigung senden, in der wir Sie bitten,
                    Korrekturen vorzunehmen, die entsprechenden Informationen zu l&ouml;schen oder andere
                    Ma&szlig;nahmen zu ergreifen, die wir f&uuml;r notwendig erachten, um genaue und
                    wahrheitsgem&auml;&szlig;e Informationen von Ihnen zu erhalten.</p>

                <p>&nbsp;</p>

                <p>8.3. Wenn Sie die angeforderten Informationen nicht bereitstellen. Sie sind verpflichtet, uns alle
                    von uns angeforderten Informationen zur Verf&uuml;gung zu stellen. Wenn Sie sich weigern, die
                    angeforderten Informationen bereitzustellen oder anderweitig nicht rechtzeitig zu erf&uuml;llen,
                    behalten wir uns das Recht vor, Ihren Zugriff auf Ihr DuneEX-Konto oder auf alle oder einen Teil der
                    DuneEX-Dienste sofort auszusetzen oder zu beenden, ohne Benachrichtigung.</p>

                <p>&nbsp;</p>

                <p>9. Zugriff auf Ihr DuneEX-Konto</p>

                <p>&nbsp;</p>

                <p>9.1. Zugriff. Um auf Ihr DuneEX-Konto zuzugreifen, m&uuml;ssen Sie oder, falls zutreffend, Ihre
                    autorisierten Benutzer die notwendige Ausr&uuml;stung (z. B. Computer oder Smartphone) und
                    Internetzugang haben. Der Zugriff auf Ihr DuneEX-Konto kann direkt auf der Plattform oder &uuml;ber
                    ein anderes Zugangsmittel (einschlie&szlig;lich API), das wir m&ouml;glicherweise bereitstellen,
                    erfolgen. Sie sind berechtigt, sich in Ihr DuneEX-Konto einzuloggen und es nur mit den Zugangsdaten
                    zu nutzen, die wir Ihnen oder Ihren autorisierten Benutzern zu diesem Zweck zur Verf&uuml;gung
                    stellen. Zur Sicherheit Ihres DuneEX-Kontos k&ouml;nnen wir die Aktivierung einer
                    Mehrfaktorauthentifizierung verlangen.</p>

                <p>&nbsp;</p>

                <p>Die Nutzung der Plattform und anderer Zugangsmittel kann zus&auml;tzlichen Bedingungen
                    unterliegen, &uuml;ber die wir Sie informieren werden.</p>

                <p>&nbsp;</p>

                <p>9.2. Einschr&auml;nkung des Zugriffs durch Dritte. Sie m&uuml;ssen sicherstellen, dass jedes
                    DuneEX-Konto, das auf Ihren Namen registriert ist, nur von Ihnen oder, im Falle von
                    Unternehmens-DuneEX-Konten, von Ihren autorisierten Benutzern genutzt wird, es sei denn, in diesen
                    Bedingungen ist etwas anderes vorgesehen.</p>

                <p>&nbsp;</p>

                <p>10. Kontoinformationen und Transaktionsaufzeichnungen</p>

                <p>&nbsp;</p>

                <p>10.1. Ihr Kontoverlauf. Sie k&ouml;nnen auf Ihren Kontoverlauf auf der Plattform zugreifen. Sie
                    m&uuml;ssen Ihren Kontoverlauf sorgf&auml;ltig &uuml;berpr&uuml;fen und uns benachrichtigen, wenn
                    Sie irgendwelche Aufzeichnungen oder Transaktionen sehen, an die Sie sich nicht erinnern oder die
                    Sie f&uuml;r falsch halten, innerhalb von vierzehn (14) Kalendertagen ab dem Datum, an dem Ihnen Ihr
                    Kontoverlauf zur Verf&uuml;gung gestellt oder zug&auml;nglich gemacht wird.</p>

                <p>&nbsp;</p>

                <p>10.2. Fehler. Wir k&ouml;nnen jederzeit einen Fehler in Ihrem Kontoverlauf korrigieren und behalten
                    uns das Recht vor, jede Transaktion, die eine Offensichtliche Fehler beinhaltet oder daraus
                    resultiert, zu annullieren oder zu stornieren oder die Details einer solchen Transaktion
                    zu &auml;ndern, um das, was wir vern&uuml;nftigerweise f&uuml;r die korrekten oder fairen Details
                    dieser Transaktion ohne den Offensichtlichen Fehler halten, widerzuspiegeln. Unbeschadet der
                    allgemeinen Natur anderer Bestimmungen dieser Bedingungen und in Abwesenheit von betr&uuml;gerischen
                    oder b&ouml;swilligen Handlungen haftet DuneEX unter keinen Umst&auml;nden Ihnen gegen&uuml;ber
                    f&uuml;r Verluste oder Anspr&uuml;che jeglicher Art, die Sie m&ouml;glicherweise erleiden oder denen
                    Sie sich m&ouml;glicherweise ausgesetzt sehen im Zusammenhang mit einem Offensichtlichen Fehler. Sie
                    k&ouml;nnen jede Ursache haben, direkt oder indirekt, speziell oder nachfolgend sein,
                    einschlie&szlig;lich, aber nicht beschr&auml;nkt auf, Gewinnverlust oder Verlust von
                    M&ouml;glichkeiten, selbst wenn DuneEX vor der M&ouml;glichkeit solcher Verluste gewarnt wurde oder
                    sie vern&uuml;nftigerweise vorhersehbar waren.</p>

                <p>&nbsp;</p>

                <p>10.3. Informations&uuml;bertragung. Nach geltendem Recht k&ouml;nnen wir verpflichtet sein,
                    Informationen &uuml;ber Ihr DuneEX-Konto und Ihren Kontoverlauf an Dritte weiterzugeben. Sie
                    erkennen an und akzeptieren, dass wir das Recht haben, solche Informationen offenzulegen.</p>

                <p><br/>
                    VERWENDUNG IHRES KONTOS</p>

                <p>&nbsp;</p>

                <p>11. Anweisungen</p>

                <p>&nbsp;</p>

                <p>11.1. Senden von Anweisungen. Sie m&uuml;ssen sicherstellen, dass jede von Ihnen gesendete Anweisung
                    vollst&auml;ndig und genau ist. Wir sind nicht verpflichtet, die Genauigkeit, Authentizit&auml;t
                    oder G&uuml;ltigkeit einer Anweisung zu &uuml;berpr&uuml;fen und werden Anweisungen
                    nicht &uuml;berwachen oder ablehnen, weil sie Duplikate sind oder so erscheinen. Wenn wir jedoch
                    Zweifel an der Genauigkeit, Authentizit&auml;t oder G&uuml;ltigkeit einer Anweisung haben,
                    k&ouml;nnen wir die Ausf&uuml;hrung einer Anweisung verweigern oder verz&ouml;gern oder
                    zus&auml;tzliche Informationen zur Anweisung anfordern.</p>

                <p>&nbsp;</p>

                <p>Anweisungen sind unwiderruflich, sodass Sie oder Ihre autorisierten Benutzer nicht das Recht haben,
                    eine Anweisung ohne unsere schriftliche Zustimmung zu stornieren oder zu widerrufen. Ihre Anweisung
                    gilt erst dann als von uns empfangen, wenn sie von unserem Server empfangen wurde. Unser Protokoll
                    aller Anweisungen ist f&uuml;r alle Zwecke endg&uuml;ltig und verbindlich f&uuml;r Sie.</p>

                <p>&nbsp;</p>

                <p>11.2. Ma&szlig;nahmen zu Ihren Anweisungen. Durch das Senden einer Anweisung autorisieren Sie oder
                    Ihre autorisierten Benutzer uns, eine Transaktion in Ihrem DuneEX-Konto zu initiieren.
                    Dementsprechend sind wir berechtigt, Ihre digitalen Verm&ouml;genswerte gem&auml;&szlig; Ihrer
                    Anweisung Ihrem DuneEX-Konto gutzuschreiben oder abzubuchen (oder Abwicklungsinformationen an Dritte
                    zu &uuml;bermitteln, damit diese die Gutschrift oder Abbuchung vornehmen). Wenn Ihr DuneEX-Konto
                    nicht &uuml;ber ausreichende digitale Verm&ouml;genswerte oder Fiat-W&auml;hrung verf&uuml;gt, um
                    die Transaktion abzuschlie&szlig;en (d. h. weniger als den f&uuml;r die Abwicklung der Transaktion
                    und die Zahlung aller mit der Transaktion verbundenen Geb&uuml;hren erforderlichen Betrag), haben
                    wir das Recht, die Ausf&uuml;hrung der Transaktion zu verweigern. Sie sind daf&uuml;r
                    verantwortlich, ein ausreichendes Guthaben an digitalen Verm&ouml;genswerten oder Fiat-W&auml;hrung
                    in Ihrem DuneEX-Konto zu halten.</p>

                <p>&nbsp;</p>

                <p>11.3. Sicherheit von Anweisungen. Sie erkennen an, dass Anweisungen und Informationen, die auf der
                    Plattform oder per E-Mail &uuml;bertragen werden, typischerweise &uuml;ber das
                    Internet &uuml;bertragen werden und &uuml;ber &ouml;ffentliche, transnationale Netzwerke geleitet
                    werden k&ouml;nnen, die keine besonderen Sicherheitsvorkehrungen bieten. Wir k&ouml;nnen nicht
                    garantieren, dass &uuml;bermittelte Anweisungen und Informationen vollst&auml;ndig vor unbefugtem
                    Zugriff gesch&uuml;tzt sind, und Sie akzeptieren die damit verbundenen Risiken.</p>

                <p>&nbsp;</p>

                <p>11.4. Abhebungen. Vorbehaltlich dieser Bedingungen und etwaiger geltender Produktbedingungen sowie
                    eines ausreichenden Guthabens in Ihrem DuneEX-Konto und sofern die betreffenden digitalen
                    Verm&ouml;genswerte nicht in Verbindung mit der Nutzung eines DuneEX-Dienstes in Ihrem DuneEX-Konto
                    gehalten werden, k&ouml;nnen Sie DuneEX anweisen, digitale Verm&ouml;genswerte durch Einreichung
                    einer Abhebungsanforderung auf der Plattform auf eine externe Wallet-Adresse zu &uuml;bertragen.
                    Nach Erhalt einer Abhebungsanforderung wird DuneEX: das Guthaben von Ihrem DuneEX-Konto abbuchen;
                    und eine On-Chain-&Uuml;bertragung an die von Ihnen angegebene externe Wallet-Adresse initiieren.
                    DuneEX kann eine Abhebungsanforderung nicht bearbeiten, wenn nach unserer vern&uuml;nftigen Meinung
                    geltendes Recht die betreffende Abhebung verhindert. DuneEX kann Abhebungen auch so lange aussetzen,
                    wie es erforderlich ist, um Vorf&auml;lle auf der Plattform zu beheben. Sobald diese Vorf&auml;lle
                    behoben sind, wird DuneEX die Abhebungen wieder aufnehmen.</p>

                <p>&nbsp;</p>

                <p>12. Transaktionen</p>

                <p>&nbsp;</p>

                <p>12.1. Abschluss von Transaktionen. Sie k&ouml;nnen Transaktionen direkt mit uns oder direkt mit
                    anderen Benutzern, mit oder ohne unsere Vermittlung, abschlie&szlig;en.</p>

                <p>&nbsp;</p>

                <p>Wir geben keine Zusicherungen oder Gew&auml;hrleistungen ab, dass eine Transaktion erfolgreich
                    abgeschlossen oder innerhalb eines bestimmten Zeitraums abgeschlossen wird.</p>

                <p>&nbsp;</p>

                <p>12.2. Unbefugte Transaktionen. Sie sind f&uuml;r die Kontrolle und Nutzung Ihres DuneEX-Kontos
                    verantwortlich. Daher gehen wir davon aus, dass jede von Ihrem DuneEX-Konto gesendete Anweisung von
                    Ihnen oder einem autorisierten Benutzer autorisiert wurde, es sei denn, wir werden anderweitig
                    benachrichtigt. Sie m&uuml;ssen Ihre Kontohistorie &uuml;berwachen, um unbefugte oder
                    verd&auml;chtige Aktivit&auml;ten in Ihrem DuneEX-Konto unverz&uuml;glich zu erkennen und zu melden.
                    Wir haften nicht f&uuml;r Anspr&uuml;che oder Verluste, die sich aus einer Transaktion aufgrund
                    einer unbefugten Anweisung ergeben, wenn Sie uns nicht gem&auml;&szlig; dieser Klausel
                    benachrichtigt haben.</p>

                <p>&nbsp;</p>

                <p>12.3. Speicherung von Transaktionsinformationen. Zur Einhaltung globaler Industriestandards f&uuml;r
                    die Datenspeicherung stimmen Sie zu, dass wir (aber nicht verpflichtet sind), alle
                    Transaktionsinformationen f&uuml;r die Dauer Ihres DuneEX-Kontos so lange zu speichern, wie dies zur
                    Erf&uuml;llung ihrer beabsichtigten Zwecke oder f&uuml;r einen anderen Zeitraum, der nach geltendem
                    Recht erforderlich ist, notwendig ist.</p>

                <p>&nbsp;</p>

                <p>13. Wesentliche Interessen und Konflikte</p>

                <p>&nbsp;</p>

                <p>13.1. DuneEX-Gruppe. Sie verstehen, dass DuneEX Mitglied einer Gruppe von Unternehmen ist, die sich
                    mit Aktivit&auml;ten im Zusammenhang mit digitalen Verm&ouml;genswerten besch&auml;ftigen.</p>

                <p>&nbsp;</p>

                <p>13.2. Art unserer Pflichten. Sie verstehen und akzeptieren, dass weder Ihre Beziehung zu uns, noch
                    ein von uns an Sie erbrachter DuneEX-Dienst, noch ein anderer Grund irgendwelche Pflichten
                    unsererseits oder seitens eines DuneEX-Partners schafft, sei es rechtliche, angemessene oder
                    treuh&auml;nderische Pflichten, au&szlig;er den ausdr&uuml;cklich in diesen Bedingungen genannten.
                    Insbesondere k&ouml;nnen wir und DuneEX-Partner von Zeit zu Zeit in mehr als einer Eigenschaft
                    handeln und in diesen Eigenschaften Provisionen oder Geb&uuml;hren von mehr als einem Benutzer
                    (einschlie&szlig;lich Ihnen) erhalten. Sie akzeptieren, dass wir in solchen Funktionen t&auml;tig
                    werden und andere DuneEX-Dienste oder Gesch&auml;fte mit Ihnen, einem DuneEX-Partner oder einem
                    anderen Benutzer durchf&uuml;hren k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>13.3. Wesentliche Interessen. Sie verstehen und akzeptieren, dass weder wir noch DuneEX-Partner
                    verpflichtet sind: (1) Informationen zu ber&uuml;cksichtigen, die uns oder einem DuneEX-Partner
                    bekannt sind und ein wesentliches Interesse darstellen; (2) solche Informationen Ihnen offenzulegen;
                    oder (3) solche Informationen in Ihrem Interesse zu nutzen. Sie erkennen auch an, dass wir im Laufe
                    der Erbringung von DuneEX-Diensten an Sie von Zeit zu Zeit allgemeine Marktinformationen erhalten
                    k&ouml;nnen, die wir im Rahmen unseres gew&ouml;hnlichen Gesch&auml;ftsbetriebs nutzen
                    k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>13.4. Interessenkonflikte. Wir haben wirksame organisatorische und administrative Mechanismen
                    eingerichtet und unterhalten diese, um alle notwendigen Schritte zu unternehmen, um
                    Interessenkonflikte zwischen uns, unseren Benutzern und relevanten Dritten zu identifizieren und zu
                    verwalten, um Interessenkonflikte zu verhindern, die die Interessen unserer Benutzer
                    beeintr&auml;chtigen k&ouml;nnten. Wenn solche organisatorischen und administrativen Ma&szlig;nahmen
                    nicht ausreichen, um Risiken f&uuml;r Ihre Interessen zu verhindern, werden wir Sie &uuml;ber die
                    Art und/oder Quellen der relevanten Interessenkonflikte und die ergriffenen Ma&szlig;nahmen zur
                    Minderung dieser Risiken informieren, damit Sie eine informierte Entscheidung dar&uuml;ber treffen
                    k&ouml;nnen, ob Sie weiterhin mit uns Transaktionen durchf&uuml;hren m&ouml;chten. Wir behalten uns
                    das Recht vor, jederzeit in Ihrem Interesse zu handeln, wenn wir einen Interessenkonflikt auf keine
                    andere Weise verwalten k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>14. Transaktionslimits</p>

                <p>&nbsp;</p>

                <p>14.1. Ihre Transaktionslimits. Ihr DuneEX-Konto kann Limits unterliegen f&uuml;r:</p>

                <p>&nbsp;</p>

                <p>a. die Anzahl oder das Volumen der Transaktionen, die Sie im Zusammenhang mit Ihrem DuneEX-Konto
                    durchf&uuml;hren k&ouml;nnen; und/oder</p>

                <p>&nbsp;</p>

                <p>b. den Betrag oder Wert von Fiat-W&auml;hrung oder digitalen Verm&ouml;genswerten, die Sie auf Ihr
                    DuneEX-Konto &uuml;berweisen k&ouml;nnen,</p>

                <p>&nbsp;</p>

                <p>jeweils f&uuml;r einen bestimmten Zeitraum (z. B. pro Tag). Alle anwendbaren Limits werden in Ihrem
                    DuneEX-Konto angezeigt.</p>

                <p>&nbsp;</p>

                <p>14.2. &Auml;nderungen der Transaktionslimits. Wir behalten uns das Recht vor, jederzeit nach unserem
                    absoluten Ermessen jedes Transaktionslimit, das f&uuml;r Ihr DuneEX-Konto gilt, zu &auml;ndern. Sie
                    k&ouml;nnen auch eine &Auml;nderung Ihrer Limits beantragen. Jede &Auml;nderung erfolgt nach unserem
                    absoluten Ermessen und unterliegt allen zus&auml;tzlichen Bedingungen, die wir f&uuml;r notwendig
                    erachten.</p>

                <p>&nbsp;</p>

                <p>15. Unterst&uuml;tzte digitale Verm&ouml;genswerte</p>

                <p>&nbsp;</p>

                <p>15.1. Unterst&uuml;tzte digitale Verm&ouml;genswerte. DuneEX-Dienste sind nur im Zusammenhang mit
                    unterst&uuml;tzten digitalen Verm&ouml;genswerten verf&uuml;gbar, die sich von Zeit zu
                    Zeit &auml;ndern k&ouml;nnen. Die Liste der unterst&uuml;tzten digitalen Verm&ouml;genswerte ist auf
                    unserer Website ver&ouml;ffentlicht. Wir k&ouml;nnen ein oder mehrere digitale Verm&ouml;genswerte
                    aus der Liste der unterst&uuml;tzten digitalen Verm&ouml;genswerte entfernen oder aussetzen. Wir
                    werden angemessene kommerzielle Anstrengungen unternehmen, um Sie im Voraus zu benachrichtigen. Nach
                    der Entfernung haben Sie keinen Zugriff mehr auf solche digitalen Verm&ouml;genswerte unter den
                    DuneEX-Diensten, und Sie k&ouml;nnen digitale Verm&ouml;genswerte nur noch von Ihrem DuneEX-Konto
                    abheben. Wenn digitale Verm&ouml;genswerte, die keine unterst&uuml;tzten digitalen
                    Verm&ouml;genswerte mehr sind, nach dem angegebenen Zeitraum, &uuml;ber den wir Sie benachrichtigt
                    haben, weiterhin in Ihrem DuneEX-Konto verbleiben, kann DuneEX nach eigenem Ermessen solche
                    digitalen Verm&ouml;genswerte in eine andere Art von digitalen Verm&ouml;genswerten
                    umwandeln&mdash;Stablecoins. DuneEX wird eine Vorank&uuml;ndigung einer solchen Umwandlung senden,
                    und Sie k&ouml;nnen digitale Verm&ouml;genswerte innerhalb eines von DuneEX festgelegten
                    angemessenen Zeitraums vor ihrer Umwandlung in Stablecoins abheben.</p>

                <p>&nbsp;</p>

                <p>Wir &uuml;bernehmen keine Haftung im Zusammenhang mit Versuchen, Ihr DuneEX-Konto f&uuml;r nicht
                    unterst&uuml;tzte digitale Verm&ouml;genswerte zu verwenden, sowie im Zusammenhang mit der
                    Umwandlung in eine andere Art von digitalen Verm&ouml;genswerten, wie in dieser Klausel
                    beschrieben.</p>

                <p>&nbsp;</p>

                <p>15.2. Forks. Wir k&ouml;nnen DuneEX-Dienste in Bezug auf einen bestimmten digitalen
                    Verm&ouml;genswert vor&uuml;bergehend aussetzen, w&auml;hrend wir &uuml;ber die Unterst&uuml;tzung
                    eines Forks entscheiden. Wir sind nicht verpflichtet, einen Fork eines digitalen Verm&ouml;genswerts
                    zu unterst&uuml;tzen, den Sie in Ihrem DuneEX-Konto halten, unabh&auml;ngig davon, ob der geforkte
                    digitale Verm&ouml;genswert zu einem dominanten digitalen Verm&ouml;genswert wird oder nicht. Wenn
                    wir uns entscheiden, einen Fork eines digitalen Verm&ouml;genswerts zu unterst&uuml;tzen, werden wir
                    eine &ouml;ffentliche Erkl&auml;rung auf der Website oder auf andere Weise abgeben, die wir f&uuml;r
                    notwendig halten.</p>

                <p>&nbsp;</p>

                <p>Sie akzeptieren, dass wir die Erstellung oder Implementierung eines Forks nicht kontrollieren und
                    nicht beeinflussen k&ouml;nnen. Wir geben keine Garantien hinsichtlich der Sicherheit,
                    Funktionalit&auml;t oder des Angebots eines digitalen Verm&ouml;genswerts, einschlie&szlig;lich
                    sowohl des neuen dominanten digitalen Verm&ouml;genswerts als auch anderer digitaler
                    Verm&ouml;genswerte, die mit dem entsprechenden Fork verbunden sind. In einigen F&auml;llen
                    k&ouml;nnen Sie geforkte digitale Verm&ouml;genswerte m&ouml;glicherweise nicht auf der Plattform
                    handeln und k&ouml;nnen jeglichen Wert verlieren, der mit den entsprechenden digitalen
                    Verm&ouml;genswerten verbunden ist.</p>

                <p>&nbsp;</p>

                <p>15.3. Besicherte digitale Verm&ouml;genswerte. Wir k&ouml;nnen von Zeit zu Zeit digitale
                    Verm&ouml;genswerte unterst&uuml;tzen, die angeblich besichert oder anderweitig an den Wert eines
                    anderen Verm&ouml;genswerts gekoppelt sind, einschlie&szlig;lich digitaler Verm&ouml;genswerte,
                    Fiat-W&auml;hrungen oder Rohstoffen wie Silber oder Gold (&quot;besicherte digitale
                    Verm&ouml;genswerte&quot;). Sie stimmen zu, dass Sie alle Bedingungen und Risiken, die mit jedem
                    spezifischen besicherten digitalen Verm&ouml;genswert verbunden sind, gelesen, verstanden und
                    akzeptiert haben, bevor Sie eine Transaktion in Bezug auf einen solchen besicherten digitalen
                    Verm&ouml;genswert eingehen. Wir sind nicht verpflichtet, Ihre besicherten digitalen
                    Verm&ouml;genswerte zu kaufen, zur&uuml;ckzukaufen, einzul&ouml;sen oder die Einl&ouml;sung zu
                    erleichtern. Wir behalten uns das Recht vor, jede Dienstleistung in Bezug auf einen besicherten
                    digitalen Verm&ouml;genswert zu &auml;ndern, auszusetzen oder zu beenden. Wir machen keine
                    Zusicherungen dar&uuml;ber, ob ein bestimmter besicherter digitaler Verm&ouml;genswert seinen Wert
                    relativ zu einem Verm&ouml;genswert beibehalten wird, sowie &uuml;ber das Volumen oder die
                    Qualit&auml;t der Reserven oder Sicherheiten, die von jedem Emittenten oder Dritten in Bezug auf
                    einen besicherten digitalen Verm&ouml;genswert gehalten werden.</p>

                <p><br/>
                    16. Kontosicherheit</p>

                <p>&nbsp;</p>

                <p>16.1. Ihre Verantwortung. Sie sind verantwortlich f&uuml;r die Ergreifung geeigneter Ma&szlig;nahmen
                    zum Schutz Ihrer Ausr&uuml;stung und Daten vor Viren, Malware und anderen unzul&auml;ssigen
                    Materialien. Mit Ausnahme der F&auml;lle, die durch das Anwendbare Recht vorgeschrieben sind, sind
                    Sie f&uuml;r das Backup und die Pflege von Duplikaten jeglicher Informationen verantwortlich, die
                    Sie &uuml;ber die DuneEX-Dienste speichern oder &uuml;bertragen. Wir sind nicht verantwortlich
                    f&uuml;r Anspr&uuml;che oder Verluste, die aus der Nichtbeachtung dieser Klausel resultieren.</p>

                <p>&nbsp;</p>

                <p>16.2. Sicherheitsma&szlig;nahmen. Sie und alle autorisierten Benutzer m&uuml;ssen jederzeit die
                    ordnungsgem&auml;&szlig;e Sicherheit und Kontrolle aller Ihrer Zugangskennungen gew&auml;hrleisten.
                    Sie sind daf&uuml;r verantwortlich, die notwendigen Sicherheitsma&szlig;nahmen zu ergreifen (oder
                    sicherzustellen, dass Ihre autorisierten Benutzer solche Ma&szlig;nahmen ergreifen), um Ihr
                    DuneEX-Konto zu sch&uuml;tzen und Ihre Zugangskennung zu sichern, einschlie&szlig;lich der folgenden
                    Ma&szlig;nahmen:</p>

                <p>&nbsp;</p>

                <p>a. Strikte Einhaltung aller unserer Mechanismen und Verfahren;</p>

                <p>&nbsp;</p>

                <p>b. Erstellung eines starken Passworts und Gew&auml;hrleistung der Sicherheit und Kontrolle Ihrer
                    Zugangskennungen;</p>

                <p>&nbsp;</p>

                <p>c. Bereitstellung einer aktuellen E-Mail-Adresse und Telefonnummer f&uuml;r den Erhalt von
                    Benachrichtigungen oder Warnungen, die wir Ihnen senden k&ouml;nnen;</p>

                <p>&nbsp;</p>

                <p>d. Niemals Remote-Zugriff zulassen oder Ihren Computer und/oder Bildschirm mit jemand anderem teilen,
                    wenn Sie in Ihr DuneEX-Konto eingeloggt sind;</p>

                <p>&nbsp;</p>

                <p>e. Denken Sie daran, dass wir unter keinen Umst&auml;nden von Ihnen verlangen werden, Ihre
                    Passw&ouml;rter oder Zwei-Faktor-Authentifizierungscodes weiterzugeben; und</p>

                <p>&nbsp;</p>

                <p>f. Abmeldung von den Seiten oder der Plattform am Ende jedes Besuchs.</p>

                <p>&nbsp;</p>

                <p>Sie m&uuml;ssen Ihre E-Mail-Adresse und Zugangskennungen vor Angriffen und unbefugtem Zugriff
                    sch&uuml;tzen. Sie m&uuml;ssen uns unverz&uuml;glich benachrichtigen, wenn Sie Kenntnis davon haben
                    oder den Verdacht haben, dass Ihre E-Mail-Adresse oder die E-Mail-Adresse eines autorisierten
                    Benutzers gehackt wurde, oder wenn Ihre E-Mail-Adresse oder die E-Mail-Adresse eines autorisierten
                    Benutzers unbefugt verwendet wurde.</p>

                <p>&nbsp;</p>

                <p>16.3. &Uuml;berwachung der Kontohistorie. Sie m&uuml;ssen Ihre Kontohistorie &uuml;berwachen, um
                    unbefugte oder verd&auml;chtige Aktivit&auml;ten in Ihrem DuneEX-Konto rechtzeitig zu identifizieren
                    und uns dar&uuml;ber zu informieren. Sie erkennen an, dass jede Sicherheitsverletzung zu unbefugtem
                    Zugriff Dritter auf Ihr DuneEX-Konto und dem Verlust oder Diebstahl von digitalen
                    Verm&ouml;genswerten und/oder Geldern, die in Ihrem DuneEX-Konto und allen damit verbundenen Konten,
                    einschlie&szlig;lich Bankkonten und Kreditkarten, gespeichert sind, f&uuml;hren kann.</p>

                <p>&nbsp;</p>

                <p>16.4. Wenn Sie eine Sicherheitsverletzung vermuten. Wenn Sie eine Sicherheitsverletzung vermuten,
                    m&uuml;ssen Sie sicherstellen, dass:</p>

                <p>&nbsp;</p>

                <p>a. wir unverz&uuml;glich gem&auml;&szlig; den in Ziffer &lrm;3.1 angegebenen Kommunikationswegen
                    benachrichtigt werden und w&auml;hrend des gesamten Zeitraums der Sicherheitsverletzung genaue und
                    aktuelle Informationen erhalten;</p>

                <p>&nbsp;</p>

                <p>b. Ihr DuneEX-Konto unverz&uuml;glich mit der Deaktivierungsfunktion auf der Plattform oder auf jede
                    andere Weise, die wir von Zeit zu Zeit vorschreiben, gesperrt wird; und</p>

                <p>&nbsp;</p>

                <p>c. Sie alle anderen Schritte unternehmen, die wir vern&uuml;nftigerweise zur Minderung, Kontrolle
                    oder Benachrichtigung &uuml;ber eine Sicherheitsverletzung verlangen.</p>

                <p>&nbsp;</p>

                <p>Wir behalten uns das Recht vor, alle Informationen und Dokumente anzufordern, die wir im Zusammenhang
                    mit einer tats&auml;chlichen oder vermuteten Sicherheitsverletzung f&uuml;r relevant oder notwendig
                    erachten, und Sie stimmen zu, diese bereitzustellen. Wir k&ouml;nnen solche Informationen jeder
                    dritten Partei zur Verf&uuml;gung stellen, die wir f&uuml;r notwendig halten, um eine
                    Sicherheitsverletzung zu beheben.<br/>
                    <br/>
                    <strong>ANDERE WICHTIGE INFORMATIONEN</strong></p>

                <p>&nbsp;</p>

                <p>17. Datenschutz</p>

                <p>&nbsp;</p>

                <p>17.1. Datenschutzhinweis. Die Erhebung und Nutzung personenbezogener Daten durch uns im Zusammenhang
                    mit diesen Bedingungen, den DuneEX-Diensten, der Plattform und jeder Website erfolgt
                    gem&auml;&szlig; unserem Datenschutzhinweis (mit regelm&auml;&szlig;igen Aktualisierungen). Sie
                    best&auml;tigen, dass wir Ihre personenbezogenen Daten, die Sie uns zur Verf&uuml;gung gestellt
                    haben oder die wir von Ihnen im Zusammenhang mit diesen Bedingungen erhoben haben,
                    gem&auml;&szlig; dem Datenschutzhinweis verarbeiten d&uuml;rfen. Ihre personenbezogenen Daten werden
                    gem&auml;&szlig; dem Datenschutzhinweis verarbeitet, der Teil dieser Bedingungen ist.</p>

                <p>&nbsp;</p>

                <p>Sie versichern und garantieren, dass:</p>

                <p>&nbsp;</p>

                <p>a. Sie best&auml;tigen, dass Sie unseren Datenschutzhinweis gelesen und verstanden haben.</p>

                <p>&nbsp;</p>

                <p>b. unser Gesch&auml;ft sich regelm&auml;&szlig;ig &auml;ndert und unser Datenschutzhinweis sich
                    ebenfalls &auml;ndern wird. Daher m&uuml;ssen Sie, wenn wir Ihnen regelm&auml;&szlig;ig eine
                    Ersatzversion des Datenschutzhinweises zur Verf&uuml;gung stellen, den Datenschutzhinweis
                    umgehend &uuml;berpr&uuml;fen.</p>

                <p>&nbsp;</p>

                <p>18. &Auml;nderungen dieser Bedingungen und anderer Bestimmungen</p>

                <p>&nbsp;</p>

                <p>18.1. Wie und wann wir &Auml;nderungen vornehmen k&ouml;nnen. Wir k&ouml;nnen &Auml;nderungen an
                    diesen Bedingungen und allen darin genannten Bestimmungen (einschlie&szlig;lich etwaiger
                    Produktbedingungen) jederzeit vornehmen, und Ihre Annahme dieser Bedingungen bedeutet Ihre
                    ausdr&uuml;ckliche Zustimmung. Wir werden Sie &uuml;ber alle &Auml;nderungen per E-Mail
                    oder &uuml;ber unsere Website informieren.</p>

                <p>&nbsp;</p>

                <p>&Uuml;ber die meisten &Auml;nderungen werden wir Sie im Voraus informieren, bevor sie in Kraft
                    treten. Manchmal kann es jedoch erforderlich sein, &Auml;nderungen ohne vorherige Ank&uuml;ndigung
                    vorzunehmen. Dies kann in folgenden F&auml;llen geschehen:</p>

                <p>&nbsp;</p>

                <p>a. Wir nehmen &Auml;nderungen infolge gesetzlicher und/oder regulatorischer &Auml;nderungen vor;</p>

                <p>&nbsp;</p>

                <p>b. die vorgenommenen &Auml;nderungen liegen in Ihrem Interesse;</p>

                <p>&nbsp;</p>

                <p>c. die &Auml;nderungen sollen diese Bedingungen f&uuml;r Sie verst&auml;ndlicher machen; und/oder</p>

                <p>&nbsp;</p>

                <p>d. es gibt einen anderen triftigen Grund, bei dem wir keine Zeit zur Benachrichtigung haben.</p>

                <p>&nbsp;</p>

                <p>Wenn wir Sie nicht im Voraus benachrichtigen k&ouml;nnen, werden wir Sie so bald wie m&ouml;glich
                    nach den &Auml;nderungen informieren.</p>

                <p>&nbsp;</p>

                <p>18.2. Wann &Auml;nderungen in Kraft treten. Sofern die &Auml;nderungen nicht sofort in Kraft treten,
                    werden die aktualisierten Bedingungen wirksam, nachdem wir Ihnen die Benachrichtigung gesendet
                    haben. Wenn Sie die &Auml;nderungen nicht akzeptieren m&ouml;chten, haben Sie das Recht, Ihr
                    DuneEX-Konto gem&auml;&szlig; Ziffer &lrm;19.1 dieser Bedingungen zu schlie&szlig;en. Wenn wir keine
                    Antwort von Ihnen erhalten, gehen wir davon aus, dass Sie die &Auml;nderungen akzeptiert haben, und
                    Ihr fortgesetzter Zugriff oder die Nutzung der DuneEX-Dienste wird als Annahme der aktualisierten
                    Bedingungen betrachtet.<br/>
                    <br/>
                    19. Schlie&szlig;en Ihres DuneEX-Kontos</p>

                <p>&nbsp;</p>

                <p>19.1. Ihr Recht, Ihr DuneEX-Konto zu schlie&szlig;en. Sie k&ouml;nnen Ihr DuneEX-Konto jederzeit
                    schlie&szlig;en, indem Sie das von uns regelm&auml;&szlig;ig vorgeschriebene Verfahren zur
                    Kontoschlie&szlig;ung befolgen. Es fallen keine Geb&uuml;hren f&uuml;r die Schlie&szlig;ung Ihres
                    DuneEX-Kontos an; Sie m&uuml;ssen jedoch alle ausstehenden Betr&auml;ge, die Sie uns schulden,
                    begleichen. Sie erm&auml;chtigen uns, alle ausstehenden Transaktionen zum Zeitpunkt der
                    Schlie&szlig;ung zu stornieren oder auszusetzen und alle ausstehenden Betr&auml;ge, die Sie uns
                    schulden, von Ihrem DuneEX-Konto abzuziehen.</p>

                <p>&nbsp;</p>

                <p>In bestimmten F&auml;llen k&ouml;nnen Sie Ihr DuneEX-Konto nicht schlie&szlig;en,
                    einschlie&szlig;lich wenn:</p>

                <p>&nbsp;</p>

                <p>a. Sie versuchen, einer Untersuchung durch zust&auml;ndige Beh&ouml;rden zu entgehen;</p>

                <p>&nbsp;</p>

                <p>b. Sie eine offene Transaktion oder eine ungel&ouml;ste Forderung haben;</p>

                <p>&nbsp;</p>

                <p>c. sich noch ausstehende Betr&auml;ge in Ihrem DuneEX-Konto befinden; oder</p>

                <p>&nbsp;</p>

                <p>d. Ihr DuneEX-Konto eingefroren, gesperrt, eingeschr&auml;nkt oder reserviert ist.</p>

                <p>&nbsp;</p>

                <p>19.2. Was passiert, wenn Ihr DuneEX-Konto geschlossen wird. Wenn Ihr DuneEX-Konto geschlossen wird,
                    m&uuml;ssen Sie alle in Ihrem DuneEX-Konto gehaltenen Digitalen Assets abheben. Wenn Sie Ihre
                    Digitalen Assets nicht abheben oder sich nicht innerhalb von 90 Tagen in Ihr DuneEX-Konto einloggen,
                    senden wir Ihnen eine Benachrichtigung &uuml;ber unsere Absicht, Ihr Konto als inaktiv zu
                    betrachten.</p>

                <p>&nbsp;</p>

                <p>19.3. Was passiert, wenn Ihr Konto inaktiv wird. Wenn Sie nicht innerhalb von 30 Tagen auf die
                    Benachrichtigung gem&auml;&szlig; Klausel 19.2 reagieren, k&ouml;nnen wir:</p>

                <p>&nbsp;</p>

                <p>a. Ihr DuneEX-Konto als inaktiv markieren;</p>

                <p>&nbsp;</p>

                <p>b. Digitale Assets in eine andere Art von Digitalen Assets umwandeln. Wir haften nicht f&uuml;r
                    entgangene Gewinne, Steuerschulden oder andere Verluste, Sch&auml;den oder Ausgaben, die Ihnen durch
                    eine solche Umwandlung entstehen;</p>

                <p>&nbsp;</p>

                <p>c. ein solches inaktives Konto und/oder alle darin gehaltenen Digitalen Assets an eine dritte
                    Partei &uuml;bertragen (einschlie&szlig;lich, jedoch nicht beschr&auml;nkt auf einen anderen
                    DuneEX-Partner, einen Dritt-Custodian oder eine segregierte Wallet), wenn wir dies f&uuml;r
                    angemessen halten. In diesem Fall haben Sie das Recht, Ihre Digitalen Assets zur&uuml;ckzufordern,
                    unter der Voraussetzung, dass Sie die angemessenen Verifizierungsanforderungen (unsere oder die der
                    relevanten Drittpartei) und andere anwendbare Bedingungen erf&uuml;llen;</p>

                <p>&nbsp;</p>

                <p>d. Ihr inaktives Konto so anpassen, dass Sie einen vertraglichen Anspruch auf die Menge und Art der
                    Digitalen Assets erhalten, die in Ihrem DuneEX-Konto gehalten wurden, bevor es inaktiv wurde;</p>

                <p>&nbsp;</p>

                <p>e. eine Geb&uuml;hr f&uuml;r ein inaktives Konto erheben, die die Kosten f&uuml;r die
                    Aufrechterhaltung der Digitalen Assets durch jeden DuneEX-Partner oder jede Drittpartei abdecken
                    kann, wobei diese Geb&uuml;hr monatlich direkt vom inaktiven Konto abgebucht wird; und</p>

                <p>&nbsp;</p>

                <p>f. das inaktive Konto jederzeit schlie&szlig;en.</p>

                <p>&nbsp;</p>

                <p>Nach Schlie&szlig;ung des inaktiven Kontos kann es nicht wieder aktiviert werden. Wenn Sie die
                    DuneEX-Services weiterhin nutzen m&ouml;chten, m&uuml;ssen Sie gem&auml;&szlig; diesen Bedingungen
                    ein neues DuneEX-Konto registrieren. Sie erkl&auml;ren sich damit einverstanden, dass wir
                    vorbehaltlich der geltenden Produktbedingungen nicht verpflichtet sind, irgendwelche Belohnungen,
                    Anreize oder Zinsen zu zahlen, die wir m&ouml;glicherweise auf Ihrem inaktiven Konto f&uuml;r
                    jegliche Digitalen Assets gutschreiben w&uuml;rden.</p>

                <p><br/>
                    20. Beendigung, Aussetzung, Zur&uuml;ckhaltung und Einschr&auml;nkungen</p>

                <p>&nbsp;</p>

                <p>20.1. Unser Recht. Wir k&ouml;nnen jederzeit jede Teil oder Funktion der DuneEX-Dienste
                    vor&uuml;bergehend oder dauerhaft &auml;ndern oder einstellen. Insbesondere k&ouml;nnen wir: (1) die
                    Ausf&uuml;hrung ablehnen oder jede von Ihnen initiierte Transaktion blockieren, stornieren oder,
                    sofern nach geltendem Recht zul&auml;ssig, r&uuml;ckg&auml;ngig machen; (2) Ihren Zugang zu einem
                    oder allen DuneEX-Diensten beenden, aussetzen oder einschr&auml;nken; (3) Ihr DuneEX-Konto beenden,
                    aussetzen, schlie&szlig;en, halten oder einschr&auml;nken; (4) die &Uuml;bermittlung von
                    Informationen oder Anweisungen an Dritte (einschlie&szlig;lich, aber nicht beschr&auml;nkt auf
                    Betreiber von Dritt-Wallets) verweigern; und/oder (5) jede Ma&szlig;nahme ergreifen, die wir
                    f&uuml;r erforderlich halten, jeweils sofort und aus beliebigem Grund, einschlie&szlig;lich, aber
                    nicht beschr&auml;nkt auf die folgenden F&auml;lle:</p>

                <p>&nbsp;</p>

                <p>a. Sie haben nicht das Recht oder nicht mehr das Recht, einen oder mehrere der DuneEX-Dienste zu
                    nutzen;</p>

                <p>&nbsp;</p>

                <p>b. wir haben begr&uuml;ndeten Verdacht, dass:</p>

                <p>&nbsp;</p>

                <p>I. Ihr DuneEX-Konto von jemand anderem als Ihnen selbst zugegriffen wurde oder dass Ihr DuneEX-Konto
                    oder in Zukunft f&uuml;r rechtswidrige, betr&uuml;gerische oder unbefugte Zwecke genutzt wird;</p>

                <p>&nbsp;</p>

                <p>II. Ihr Firmen-DuneEX-Konto von jemand anderem als einem autorisierten Benutzer zugegriffen wurde
                    oder dass Ihr Firmen-DuneEX-Konto oder in Zukunft f&uuml;r rechtswidrige, betr&uuml;gerische oder
                    unbefugte Zwecke genutzt wird;</p>

                <p>&nbsp;</p>

                <p>III. mehr als eine nat&uuml;rliche Person Zugang zu einem einzelnen DuneEX-Konto hat und/oder
                    Transaktionen in einem einzelnen DuneEX-Konto durchf&uuml;hrt oder das DuneEX-Konto f&uuml;r
                    rechtswidrige, betr&uuml;gerische oder unbefugte Zwecke genutzt wurde oder in Zukunft genutzt werden
                    wird;</p>

                <p>&nbsp;</p>

                <p>IV. Sie falsche, falsche, veraltete oder unvollst&auml;ndige Informationen bereitgestellt haben;</p>

                <p>&nbsp;</p>

                <p>c. wir begr&uuml;ndete Bedenken hinsichtlich Ihrer Kreditw&uuml;rdigkeit oder finanziellen Lage
                    haben, einschlie&szlig;lich:</p>

                <p>&nbsp;</p>

                <p>I. wenn Sie eine nat&uuml;rliche Person sind und zahlungsunf&auml;hig werden, an einer
                    Geisteskrankheit leiden, ein Insolvenzverfahren einleiten oder ein Insolvenzverfahren gegen Sie
                    eingeleitet wird;</p>

                <p>&nbsp;</p>

                <p>II. wenn Sie im Namen einer Partnerschaft handeln und einer der Partner stirbt, zahlungsunf&auml;hig
                    wird, an einer Geisteskrankheit leidet, ein Insolvenzverfahren einleitet oder ein Insolvenzverfahren
                    gegen einen der Partner eingeleitet wird, oder der Prozess der Aufl&ouml;sung und/oder &Auml;nderung
                    der Partner oder der Partnerschaftssatzung eingeleitet wird;</p>

                <p>&nbsp;</p>

                <p>III. wenn Sie im Namen eines Unternehmens handeln und das Unternehmen seine Schulden nicht begleichen
                    kann, oder das Unternehmen an Insolvenzverfahren, gerichtliche Verwaltung, externe Verwaltung,
                    Verwaltung oder ein &auml;hnliches oder analoges Verfahren beteiligt ist;</p>

                <p>&nbsp;</p>

                <p>IV. Sie ein Treffen Ihrer Gl&auml;ubiger einberufen oder einen Kompromiss oder eine Vereinbarung mit
                    Gl&auml;ubigern vorschlagen oder eingehen oder eine Abtretung zugunsten Ihrer Gl&auml;ubiger
                    vornehmen;</p>

                <p>&nbsp;</p>

                <p>d. Informationen und Dokumente gem&auml;&szlig; Klausel &lrm;8 erwartet werden;</p>

                <p>&nbsp;</p>

                <p>e. eine erweiterte Verifizierung gem&auml;&szlig; Klausel &lrm;7.4 abgeschlossen werden soll;</p>

                <p>&nbsp;</p>

                <p>f. wir begr&uuml;ndet glauben, dass geltendes Recht oder eine Gerichtsbarkeit oder Beh&ouml;rde, der
                    wir in irgendeiner Gerichtsbarkeit unterliegen, dies erfordert;</p>

                <p>&nbsp;</p>

                <p>g. wir festgestellt haben oder vermuten, dass:</p>

                <p>&nbsp;</p>

                <p>I. Sie gegen diese Bedingungen oder irgendwelche Produktbedingungen versto&szlig;en haben;</p>

                <p>&nbsp;</p>

                <p>II. Sie irgendwelche ausdr&uuml;cklichen oder stillschweigenden Garantien, die in diesen Bedingungen
                    enthalten sind, verletzt haben oder irgendwelche Aussagen gemacht haben;</p>

                <p>&nbsp;</p>

                <p>III. eine Transaktion unbefugt, fehlerhaft, betr&uuml;gerisch oder illegal ist, oder wir festgestellt
                    haben oder vermuten, dass Ihr DuneEX-Konto oder die DuneEX-Dienste auf betr&uuml;gerische, unbefugte
                    oder illegale Weise genutzt werden;</p>

                <p>&nbsp;</p>

                <p>IV. Geldw&auml;sche, Finanzierung des Terrorismus, Betrug oder ein anderes Verbrechen im Zusammenhang
                    mit Ihrem DuneEX-Konto oder Ihrer Nutzung der DuneEX-Dienste stattgefunden hat;</p>

                <p>&nbsp;</p>

                <p>h. die Nutzung Ihres DuneEX-Kontos einer anh&auml;ngigen, aktuellen oder potenziellen gerichtlichen,
                    staatlichen oder regulatorischen Untersuchung, Klage oder Verfahren unterliegt, und/oder wir ein
                    erh&ouml;htes Risiko der Nichterf&uuml;llung von rechtlichen oder regulatorischen Anforderungen im
                    Zusammenhang mit Aktivit&auml;ten in Ihrem DuneEX-Konto vermuten;</p>

                <p>&nbsp;</p>

                <p>i. Sie DuneEX noch ausstehende Betr&auml;ge schulden, sei es im Zusammenhang mit einer
                    R&uuml;cklastschrift oder aus einem anderen Grund;</p>

                <p>&nbsp;</p>

                <p>j. jede E-Mail an Ihre E-Mail-Adresse als unzustellbar zur&uuml;ckgesendet wird;</p>

                <p>&nbsp;</p>

                <p>k. ein Problem mit der &Uuml;berpr&uuml;fung Ihrer Identit&auml;t besteht;</p>

                <p>&nbsp;</p>

                <p>l. Sie Ma&szlig;nahmen ergriffen haben, die unsere Kontrollen umgehen k&ouml;nnten, wie z.B.
                    das &Ouml;ffnen mehrerer DuneEX-Konten ohne unsere schriftliche Zustimmung oder den Missbrauch von
                    Promotionen, die wir gelegentlich anbieten k&ouml;nnten; oder</p>

                <p>&nbsp;</p>

                <p>m. es gibt einen anderen vern&uuml;nftigen Grund, der diese Ma&szlig;nahme erforderlich macht.</p>

                <p>&nbsp;</p>

                <p>Wir werden angemessene Schritte unternehmen, um Ihnen eine angemessene Benachrichtigung zukommen zu
                    lassen. Es kann jedoch F&auml;lle geben, in denen wir gesetzlich verpflichtet sind, dies nicht zu
                    tun.</p>

                <p>&nbsp;</p>

                <p>20.2. Ihre Akzeptanz. Sie erkennen an und akzeptieren, dass:</p>

                <p>&nbsp;</p>

                <p>a. Klausel &lrm;20.1 eine nicht ersch&ouml;pfende Liste von F&auml;llen bereitstellt, in denen wir
                    Ma&szlig;nahmen ergreifen k&ouml;nnen, um Ihr DuneEX-Konto und/oder den Zugang zu den
                    DuneEX-Diensten zu beenden, auszusetzen, zu schlie&szlig;en oder einzuschr&auml;nken; und</p>

                <p>&nbsp;</p>

                <p>b. unsere Entscheidung, bestimmte Ma&szlig;nahmen zu ergreifen, einschlie&szlig;lich, aber nicht
                    beschr&auml;nkt auf die Beendigung, Aussetzung oder Einschr&auml;nkung Ihres Zugangs zu Ihrem
                    DuneEX-Konto oder den DuneEX-Diensten, kann auf vertraulichen Kriterien beruhen, die f&uuml;r unsere
                    Risikomanagement- und Sicherheitsprotokolle wichtig sind. Sie stimmen zu, dass wir nicht
                    verpflichtet sind, Ihnen die Details unserer Risikomanagementverfahren und
                    Sicherheitsma&szlig;nahmen offenzulegen.</p>

                <p>&nbsp;</p>

                <p>20.3. Was passiert, wenn wir unser Recht aus&uuml;ben. Wenn wir Ihren Zugang zu einem oder mehreren
                    der DuneEX-Dienste beenden, aussetzen, halten oder einschr&auml;nken:</p>

                <p>&nbsp;</p>

                <p>a. Wenn Sie offene Anweisungen, Trades, Positionen oder Transaktionen haben, k&ouml;nnen diese je
                    nach Umst&auml;nden der Beendigung, Aussetzung, Halten, Einschr&auml;nkung des Zugangs oder anderer
                    von uns getroffener Ma&szlig;nahmen von Ihnen oder von uns geschlossen werden;</p>

                <p>&nbsp;</p>

                <p>b. Jede R&uuml;cklastschrift aufgrund der Nutzung Ihres DuneEX-Kontos oder der DuneEX-Dienste kann zu
                    einer sofortigen Aussetzung und/oder Einschr&auml;nkung Ihres DuneEX-Kontos und der DuneEX-Dienste
                    f&uuml;hren;</p>

                <p>&nbsp;</p>

                <p>c. Um ein ausgesetztes und/oder eingeschr&auml;nktes DuneEX-Konto oder die DuneEX-Dienste
                    wiederherzustellen, m&uuml;ssen Sie uns m&ouml;glicherweise die vollst&auml;ndigen Kosten der
                    R&uuml;cklastschrift erstatten, einschlie&szlig;lich aller Betr&auml;ge, die uns geschuldet werden,
                    einschlie&szlig;lich etwaiger anfallender Geb&uuml;hren; und</p>

                <p>&nbsp;</p>

                <p>d. Sie sind f&uuml;r alle Gutschriften verantwortlich, die im Falle einer R&uuml;cklastschrift
                    erfolgen, und Sie erm&auml;chtigen uns und gew&auml;hren uns das Recht, Kosten und Geb&uuml;hren
                    direkt von Verm&ouml;genswerten in Ihrem DuneEX-Konto ohne Benachrichtigung abzuziehen.</p>

                <p>&nbsp;</p>

                <p>20.4. Unrechtm&auml;&szlig;iger Besitz. Falls wir informiert werden und vern&uuml;nftigerweise
                    glauben, dass irgendwelche Digitalen Assets oder Fiat-W&auml;hrungen, die in Ihrem DuneEX-Konto
                    gespeichert sind, gestohlen oder anderweitig nicht rechtm&auml;&szlig;ig im Besitz von Ihnen sind
                    (versehentlich oder anderweitig), k&ouml;nnen wir, sind jedoch nicht verpflichtet, die
                    entsprechenden Mittel und Ihr DuneEX-Konto zur&uuml;ckhalten. Wenn wir irgendwelche oder alle
                    Digitalen Assets oder Fiat-W&auml;hrungen, die in Ihrem DuneEX-Konto gespeichert sind, oder Ihr
                    gesamtes DuneEX-Konto zur&uuml;ckhalten, k&ouml;nnen wir dies weiterhin tun, bis akzeptable Beweise
                    best&auml;tigen, dass Sie das Recht auf Eigentum an den Digitalen Assets und/oder
                    Fiat-W&auml;hrungen, die in Ihrem DuneEX-Konto gespeichert sind, haben. Wir werden uns nicht an
                    Streitigkeiten oder deren L&ouml;sung beteiligen, die sich auf irgendwelche Digitalen Assets
                    und/oder Fiat-W&auml;hrungen beziehen, die in Ihrem DuneEX-Konto gespeichert sind.</p>

                <p>&nbsp;</p>

                <p>20.5. Zugang zu DuneEX-Diensten in anderen Gerichtsbarkeiten. Bewohner bestimmter L&auml;nder haben
                    m&ouml;glicherweise nur Zugang zu bestimmten, aber nicht allen DuneEX-Diensten. Wir k&ouml;nnen die
                    verf&uuml;gbaren DuneEX-Dienste f&uuml;r Sie periodisch &auml;ndern. DuneEX-Dienste k&ouml;nnen
                    nicht verf&uuml;gbar sein, und Ihr Zugang zu DuneEX-Diensten kann blockiert werden. Sie erkennen an,
                    dass dies Ihre F&auml;higkeit beeintr&auml;chtigen kann, auf der Plattform zu handeln und/oder
                    bestehende Auftr&auml;ge oder offene Positionen zu &uuml;berwachen oder anderweitig DuneEX-Dienste
                    zu nutzen. Es ist untersagt, eine solche Einschr&auml;nkung zu umgehen, einschlie&szlig;lich der
                    Nutzung eines virtuellen privaten Netzwerks zur &Auml;nderung Ihrer Internetprotokolladresse.</p>

                <p>&nbsp;</p>

                <p>21. Geistiges Eigentum (Intellectual Property - IP)</p>

                <p>&nbsp;</p>

                <p>Vorheriges geistiges Eigentum</p>

                <p>&nbsp;</p>

                <p>Das geistige Eigentum von DuneEX geh&ouml;rt DuneEX.</p>

                <p>&nbsp;</p>

                <p>22. DuneEX IP-Lizenz</p>

                <p>&nbsp;</p>

                <p>Wir gew&auml;hren Ihnen eine nicht-exklusive Lizenz f&uuml;r die Dauer dieser Bedingungen oder bis
                    zur Aussetzung oder Beendigung Ihres Zugangs zu den DuneEX-Diensten, je nachdem, was fr&uuml;her
                    eintritt, zur Nutzung der DuneEX IP, mit Ausnahme der Marken, ausschlie&szlig;lich soweit
                    erforderlich, um die DuneEX-Dienste f&uuml;r nicht-kommerzielle pers&ouml;nliche oder interne
                    gesch&auml;ftliche Zwecke gem&auml;&szlig; diesen Bedingungen zu erhalten.</p>

                <p>&nbsp;</p>

                <p>23. Benutzer-IP-Lizenz</p>

                <p>&nbsp;</p>

                <p>23.1. Gew&auml;hrung Ihrer Lizenz</p>

                <p>Sie gew&auml;hren uns eine zeitlich unbegrenzte, unwiderrufliche, geb&uuml;hrenfreie, weltweite und
                    nicht-exklusive Lizenz zur Nutzung der Benutzer-IP in dem Umfang, in dem sie:</p>

                <p>a. Teil von oder f&uuml;r die Nutzung einer erstellten IP erforderlich ist;</p>

                <p>b. periodisch ben&ouml;tigt wird, damit wir Ihnen DuneEX-Dienste bereitstellen k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>23.2. Unser Recht zur Unterlizenzierung</p>

                <p>Die Ihnen gem&auml;&szlig; diesem Abschnitt gew&auml;hrte Lizenz umfasst unser Recht zur
                    Unterlizenzierung an Dritte, soweit dies f&uuml;r uns und DuneEX-Partner erforderlich ist, um Ihnen
                    DuneEX-Dienste oder Teile davon bereitzustellen.</p>

                <p>&nbsp;</p>

                <p>24. Erstellte IP</p>

                <p>&nbsp;</p>

                <p>24.1. Erstellte IP</p>

                <p>Erstellte IP geh&ouml;rt automatisch ab dem Zeitpunkt ihrer Erstellung uns.</p>

                <p>&nbsp;</p>

                <p>24.2. &Uuml;bertragung</p>

                <p>Hiermit &uuml;bertragen Sie uns (und sichern die &Uuml;bertragung durch etwaige Agenten, Vertreter
                    oder Auftragnehmer) alle gegenw&auml;rtigen und zuk&uuml;nftigen Eigentumsrechte und -interessen an
                    der erstellten IP mit voller Gew&auml;hrleistung des Eigentums. Falls von uns gefordert, m&uuml;ssen
                    Sie (und sichern die entsprechende Erf&uuml;llung durch etwaige Agenten, Vertreter oder
                    Auftragnehmer) alle Dokumente unterzeichnen und/oder ausf&uuml;hren und Ma&szlig;nahmen ergreifen,
                    die wir m&ouml;glicherweise ben&ouml;tigen, um die &Uuml;bertragung gem&auml;&szlig; diesem
                    Abschnitt durchzuf&uuml;hren.</p>

                <p>&nbsp;</p>

                <p>25. Allgemeine Angelegenheiten</p>

                <p>&nbsp;</p>

                <p>25.1. Unsere Nicht-Haftung</p>

                <p>Sie best&auml;tigen und akzeptieren, dass:</p>

                <p>1. wir nicht f&uuml;r irgendwelche Benutzermaterialien haften (die von Ihnen oder Dritten
                    bereitgestellt werden), die m&ouml;glicherweise auf der Plattform oder den Websites verf&uuml;gbar
                    sind; und</p>

                <p>2. Ihre Nutzung solcher Benutzermaterialien erfolgt auf Ihr eigenes Risiko, und wir geben keine
                    Garantien daf&uuml;r ab.</p>

                <p>&nbsp;</p>

                <p>25.2. Unsere Rechte</p>

                <p>Wir behalten uns das Recht vor, nach unserem absoluten Ermessen jeglichen von Ihnen eingereichten,
                    ver&ouml;ffentlichten oder angezeigten Inhalt auf der Plattform oder den Websites
                    (einschlie&szlig;lich jeglicher Benutzermaterialien) aus beliebigen Gr&uuml;nden zu l&ouml;schen,
                    zu &auml;ndern oder abzulehnen. Wir behalten uns das Recht vor, bei unserem Ermessen geeignete
                    Ma&szlig;nahmen zu ergreifen, einschlie&szlig;lich der Ausstellung schriftlicher Warnungen,
                    L&ouml;schung jeglicher Benutzermaterialien, Eintreibung von Sch&auml;den oder anderer
                    monet&auml;rer Entsch&auml;digung von Ihnen, Aussetzung oder Beendigung Ihres DuneEX-Kontos (sofern
                    vorhanden), Aussetzung Ihres Zugangs zur Plattform und/oder zu den Websites. Wir behalten uns auch
                    das Recht vor, Ihre Nutzung jeglicher DuneEX-Dienste einzuschr&auml;nken oder zu verbieten.</p>

                <p>&nbsp;</p>

                <p>25.3. Aufzeichnung</p>

                <p>Sie erkennen an, dass wir m&ouml;glicherweise s&auml;mtliche Nachrichten, elektronische,
                    telefonische, Videogespr&auml;che oder sonstige Kommunikationen zwischen Ihnen und uns im
                    Zusammenhang mit diesen Bedingungen aufzeichnen, und dass s&auml;mtliche von uns gef&uuml;hrten
                    Aufzeichnungen Beweise f&uuml;r die Kommunikation zwischen Ihnen und uns darstellen. Sie
                    best&auml;tigen, dass Telefonate und Videogespr&auml;che aufgezeichnet werden k&ouml;nnen, damit wir
                    Anfragen beantworten, die Einhaltung geltender Gesetze gew&auml;hrleisten, unsere Dienstleistungen
                    verbessern und Kundensupport bieten k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p><strong>VERPFLICHTUNGEN UND HAFTUNG</strong></p>

                <p>&nbsp;</p>

                <p>26. VERBOTENE VERWENDUNG</p>

                <p>&nbsp;</p>

                <p>Durch die Erstellung eines DuneEX-Kontos best&auml;tigen Sie, dass Sie und jeder autorisierte Nutzer
                    ohne Beeintr&auml;chtigung anderer in diesen Bedingungen festgelegter Einschr&auml;nkungen oder
                    Grenzen:</p>

                <p>&nbsp;</p>

                <p>a. diese Bedingungen oder eine damit verbundene Vereinbarung verletzen werden, einschlie&szlig;lich,
                    aber nicht beschr&auml;nkt auf etwaige Produktbedingungen;</p>

                <p>&nbsp;</p>

                <p>b. die DuneEX-Dienste nicht in einer Weise nutzen werden, die die &ouml;ffentlichen Interessen,
                    die &ouml;ffentliche Moral oder die legitimen Interessen anderer Personen verletzt,
                    einschlie&szlig;lich Handlungen, die die DuneEX-Dienste beeintr&auml;chtigen, st&ouml;ren oder
                    anderen Nutzern die Nutzung der DuneEX-Dienste erschweren k&ouml;nnten;</p>

                <p>&nbsp;</p>

                <p>c. die DuneEX-Dienste nicht f&uuml;r den Wiederverkauf und kommerzielle Zwecke nutzen werden,
                    einschlie&szlig;lich Transaktionen im Namen Dritter und Organisationen, es sei denn, dies ist von
                    uns schriftlich genehmigt;</p>

                <p>&nbsp;</p>

                <p>d. die DuneEX-Dienste nicht f&uuml;r Handlungen nutzen werden, die nach Ansicht von DuneEX darauf
                    abzielen, den Preis eines digitalen Assets zu kontrollieren oder k&uuml;nstlich zu beeinflussen
                    (Marktmanipulation, einschlie&szlig;lich Pump-and-Dump-Schemata, Insiderhandel, Selbsttransaktionen,
                    Front-Running, absichtliches Zitat-Manipulation, Spoofing und Layering), unabh&auml;ngig davon, ob
                    dies durch geltendes Recht untersagt ist;</p>

                <p>&nbsp;</p>

                <p>e. betr&uuml;gerische Handlungen ausf&uuml;hren oder den Verdacht aufkommen lassen, dass Sie oder ein
                    autorisierter Nutzer betr&uuml;gerische Handlungen oder Transaktionen ausf&uuml;hren;</p>

                <p>&nbsp;</p>

                <p>f. die DuneEX-Dienste nicht f&uuml;r Lotterien, Auktionen mit Provisionen, Sportwetten und
                    Quotenfestsetzung, Fantasy-Sportligen mit Geldpreisen, Online-Spielen, Wettbewerben, Verlosungen
                    oder Gl&uuml;cksspielen verwenden;</p>

                <p>&nbsp;</p>

                <p>g. weder (1) Mittel von uns oder einem anderen Nutzer f&uuml;r dieselbe Transaktion im Rahmen einer
                    Beanstandung erhalten oder zu erhalten versuchen; (2) Ihr Gesch&auml;ft f&uuml;hren oder die
                    DuneEX-Dienste in einer Weise verwenden, die zu Beschwerden, Streitigkeiten, Beanstandungen,
                    Stornierungen, R&uuml;ckerstattungen, Geb&uuml;hren, Strafen, Geldstrafen oder anderen
                    Verpflichtungen gegen&uuml;ber uns, anderen Nutzern, Dritten oder Ihnen f&uuml;hren kann; (3)
                    zulassen, dass Ihr DuneEX-Konto einen negativen Wert oder negative Menge an digitalen Assets
                    aufweist;</p>

                <p>&nbsp;</p>

                <p>h. falsche, ungenaue oder irref&uuml;hrende Informationen im Zusammenhang mit der Nutzung der
                    DuneEX-Dienste, der Kommunikation mit uns oder anderweitig im Zusammenhang mit diesen Bedingungen
                    bereitzustellen;</p>

                <p>&nbsp;</p>

                <p>i. (1) Deep Links, Suchroboter, Bots, Spider oder andere automatisierte Ger&auml;te, Programme,
                    Skripte, Algorithmen oder Methoden oder &auml;hnliche oder gleichwertige manuelle Prozesse nicht
                    verwenden, um auf einen Teil der Plattform zuzugreifen, Informationen zu erhalten, zu kopieren oder
                    zu &uuml;berwachen, es sei denn, diese wurden absichtlich nicht durch die DuneEX-Dienste
                    bereitgestellt; (2) versuchen, auf einen Teil oder eine Funktion der Plattform ohne Autorisierung
                    zuzugreifen, sich mit den DuneEX-Diensten, einem unserer Server oder anderen Systemen oder
                    Netzwerken der DuneEX-Dienste zu verbinden oder sich auf andere Weise durch Hacking, Passwortknacken
                    oder andere gesetzwidrige oder verbotene Methoden Zugang zu verschaffen; (3) Schwachstellen der
                    DuneEX-Dienste oder eines mit der Plattform verbundenen Netzwerks erforschen, scannen oder testen
                    oder Sicherheits- oder Authentifizierungsma&szlig;nahmen der DuneEX-Dienste oder eines mit den
                    DuneEX-Diensten verbundenen Netzwerks verletzen; (4) R&uuml;ckw&auml;rtssuche durchf&uuml;hren,
                    Informationen &uuml;ber andere Nutzer oder Besucher der DuneEX-Dienste verfolgen oder zu verfolgen
                    versuchen; (5) Handlungen unternehmen, die eine unangemessene oder unverh&auml;ltnism&auml;&szlig;ig
                    hohe Belastung der Infrastruktur der Systeme oder Netzwerke der DuneEX-Dienste oder der
                    Infrastruktur jeglicher Systeme oder Netzwerke, die mit den DuneEX-Diensten verbunden sind,
                    darstellen; (6) Ger&auml;te, Software oder Routineprogramme verwenden, um in den normalen Betrieb
                    der DuneEX-Dienste einzugreifen, Transaktionen in den DuneEX-Diensten auszuf&uuml;hren oder die
                    DuneEX-Dienste durch eine andere Person zu nutzen; oder (7) Kopfzeilen f&auml;lschen, sich als eine
                    andere Person ausgeben oder anderweitig die Identifikation manipulieren, um Ihre Identit&auml;t oder
                    Herkunft von Nachrichten oder &Uuml;bertragungen zu verbergen, die Sie uns senden;</p>

                <p>&nbsp;</p>

                <p>j. die gesamte oder Teile der Plattform &auml;ndern oder anpassen oder die Plattform in eine andere
                    Software oder Anwendung integrieren oder einschlie&szlig;en;</p>

                <p>&nbsp;</p>

                <p>k. die Plattform zerlegen, dekompilieren, Reverse Engineering durchf&uuml;hren oder anderweitig
                    versuchen, den Quellcode, den Objektcode auf der Grundlage von Konzepten, Ideen und Algorithmen der
                    Plattform oder ihrer Komponenten zu extrahieren;</p>

                <p>&nbsp;</p>

                <p>l. irgendein Urheberrechtszeichen oder -aussage &auml;ndern, reproduzieren, duplizieren, kopieren,
                    herunterladen, speichern, &uuml;bertragen, verbreiten, weiterleiten,
                    disassemblieren, &uuml;bertragen, ver&ouml;ffentlichen, l&ouml;schen oder &auml;ndern oder
                    irgendwelche Markenrechte lizenzieren, unterlizenzieren, verkaufen, wiederholen, entwickeln,
                    vermieten, vermieten, eine eigene Marke erstellen oder Sicherheiten in Bezug auf solche IP DuneEX
                    oder einen Teil des geistigen Eigentums erstellen, abgeleitete Werke erstellen oder anderweitig
                    einen Teil des IS DuneEX verwenden;</p>

                <p>&nbsp;</p>

                <p>m. zur Verbreitung von Viren, Trojanern, W&uuml;rmern oder anderen Computerprogrammen beitragen, die
                    Systeme, Daten oder Informationen in Verbindung mit den DuneEX-Diensten sch&auml;digen, heimlich
                    abfangen, aneignen oder beeintr&auml;chtigen k&ouml;nnen;</p>

                <p>&nbsp;</p>

                <p>n. (1) anonyme Proxy-Server verwenden; (2) bei der Erstellung eines DuneEX-Kontos und/oder der
                    Nutzung der DuneEX-Dienste eine tempor&auml;re, Einweg-, selbstzerst&ouml;rende oder &auml;hnliche
                    E-Mail-Adresse verwenden; (3) Ger&auml;te, Software oder Verfahren verwenden, um unsere
                    Robot-Ausschluss-&Uuml;berschriften zu umgehen oder in den Betrieb unserer Websites oder
                    DuneEX-Dienste einzugreifen oder einzumischen; und (4) Handlungen unternehmen, die dazu f&uuml;hren
                    k&ouml;nnten, dass wir von unseren Internetdienstanbietern oder anderen Anbietern jegliche Dienste
                    verlieren;</p>

                <p>&nbsp;</p>

                <p>o. irgendeine Software in Bezug auf Ihre Fiat-W&auml;hrung oder digitalen Assets, die in einem Ihrer
                    DuneEX-Konten gespeichert sind, erstellen oder mutma&szlig;lich erstellen, ohne unsere vorherige
                    schriftliche Zustimmung;</p>

                <p>&nbsp;</p>

                <p>p. (1) geltendes Recht oder (2) unsere oder die Rechte eines Dritten an geistigem Eigentum,
                    einschlie&szlig;lich Urheberrechten, Patenten, Marken, Gesch&auml;ftsgeheimnissen oder anderen
                    Rechten an geistigem Eigentum, oder Rechte an &Ouml;ffentlichkeit oder Privatsph&auml;re, verletzen
                    oder versuchen zu verletzen; und/oder</p>

                <p>&nbsp;</p>

                <p>q. direkt oder indirekt auf die DuneEX-Dienste zugreifen oder sie nutzen (1) in Gerichtsbarkeiten,
                    die nach Ansicht von DuneEX ein hohes Risiko darstellen, einschlie&szlig;lich, aber nicht
                    beschr&auml;nkt auf Kuba, Iran, Nordkorea, Syrien; oder (2) von Personen, die nach Ansicht von
                    DuneEX ein hohes Risiko darstellen, einschlie&szlig;lich physischer oder juristischer Personen mit
                    eingeschr&auml;nktem Zugang oder Parteien auf einer Liste der Vereinigten Staaten von Amerika, des
                    Vereinigten K&ouml;nigreichs, der Europ&auml;ischen Union oder der Vereinten Nationen,
                    einschlie&szlig;lich der Sanktionslisten des US Office of Foreign Assets Control und der Liste der
                    verbotenen Personen und Organisationen des US Department of Commerce.</p>

                <p>&nbsp;</p>

                <p>27. DARSTELLUNGEN UND GEW&Auml;HRLEIPTUNGEN</p>

                <p>&nbsp;</p>

                <p>Hiermit versichern und garantieren Sie uns jederzeit Folgendes:</p>

                <p>&nbsp;</p>

                <p>a. Alle von Ihnen an uns &uuml;bermittelten Dokumente und Informationen sind in jeder Hinsicht wahr,
                    genau, vollst&auml;ndig und aktuell, und wir k&ouml;nnen uns auf sie verlassen, um festzustellen, ob
                    Sie das Recht haben, auf die Plattform zuzugreifen oder die DuneEX-Dienste zu nutzen;</p>

                <p>&nbsp;</p>

                <p>b. Alle Entscheidungen, die im Zusammenhang mit diesen Bedingungen getroffen wurden, wurden
                    ausschlie&szlig;lich nach Ihrem Ermessen und nach Ihrer unabh&auml;ngigen Bewertung Ihrer
                    finanziellen Ressourcen, Ihrer F&auml;higkeiten und Ihrer Bereitschaft getroffen, die relevanten
                    Risiken, finanziellen Ziele;</p>

                <p>&nbsp;</p>

                <p>c. Sie haben alle Befugnisse, Rechte und M&ouml;glichkeiten f&uuml;r (1) den Zugang und die Nutzung
                    der Plattform und/oder der DuneEX-Dienste; und (2) den Abschluss, die Lieferung und die
                    Erf&uuml;llung Ihrer Verpflichtungen gem&auml;&szlig; diesen Bedingungen und jeder Vereinbarung, die
                    gem&auml;&szlig; oder im Zusammenhang mit diesen Bedingungen abgeschlossen wurde,
                    einschlie&szlig;lich etwaiger Produktbedingungen;</p>

                <p>&nbsp;</p>

                <p>d. Wenn Sie eine Gesellschaft, Partner in einer Partnerschaft oder Treuh&auml;nder sind:</p>

                <p>&nbsp;</p>

                <p>I. Die Gesellschaft, Partnerschaft oder Treuhand handelt und wird gem&auml;&szlig; dem geltenden
                    Recht sowie etwaigen Partnerschafts- oder Treuhandvereinbarungen (oder &auml;hnlichen Dokumenten)
                    handeln;</p>

                <p>&nbsp;</p>

                <p>II. Sie werden uns umgehend &uuml;ber etwaige &Auml;nderungen im Zusammenhang mit dem R&uuml;cktritt,
                    der Abl&ouml;sung, der Ernennung oder dem Tod von Direktoren, Partnern, Treuh&auml;ndern,
                    Gr&uuml;ndern, letzten wirtschaftlich Berechtigten oder jeder Person, die berechtigt ist, Ihr
                    DuneEX-Konto zu verwalten, informieren;</p>

                <p>&nbsp;</p>

                <p>III. Sie werden uns umgehend benachrichtigen, wenn die Gesellschaft, Partnerschaft oder Treuhand auf
                    freiwilliger oder zwangsweiser Basis aufgel&ouml;st wird;</p>

                <p>&nbsp;</p>

                <p>e. Alle Zustimmungen, Genehmigungen, Autorisierungen, Freigaben und Drittanbietervereinbarungen sowie
                    alle Genehmigungen, Freigaben, Zustimmungen, Eintragungen, Erkl&auml;rungen, Dokumenteneinreichungen
                    bei einer Aufsichtsbeh&ouml;rde, Regierungsabteilung, Kommission, Agentur oder einer anderen
                    Organisation mit Zust&auml;ndigkeit &uuml;ber Sie, die f&uuml;r (1) den Zugang und die Nutzung der
                    Plattform und/oder der DuneEX-Dienste; und (2) den Abschluss, die Lieferung und die
                    Durchf&uuml;hrung von Transaktionen gem&auml;&szlig; diesen Bedingungen und jeder Vereinbarung, die
                    gem&auml;&szlig; oder im Zusammenhang mit diesen Bedingungen abgeschlossen wurde, erforderlich oder
                    w&uuml;nschenswert sind, wurden bedingungslos schriftlich eingeholt, uns schriftlich zur
                    Verf&uuml;gung gestellt und nicht widerrufen oder ge&auml;ndert;</p>

                <p>&nbsp;</p>

                <p>f. Diese Bedingungen und jede Vereinbarung, die gem&auml;&szlig; oder im Zusammenhang mit diesen
                    Bedingungen abgeschlossen wurde, stellen g&uuml;ltige Verpflichtungen mit rechtlicher
                    Durchsetzbarkeit gegen Sie gem&auml;&szlig; ihren jeweiligen Bedingungen dar;</p>

                <p>&nbsp;</p>

                <p>g. Sie sind keine Person mit eingeschr&auml;nktem Zugang;</p>

                <p>&nbsp;</p>

                <p>h. Wenn Sie eine juristische Person sind, sind Sie ordnungsgem&auml;&szlig; registriert,
                    ordnungsgem&auml;&szlig; organisiert, rechtm&auml;&szlig;ig nach den Gesetzen Ihrer Rechtsordnung
                    t&auml;tig und verf&uuml;gen &uuml;ber alle Befugnisse zur F&uuml;hrung Ihres Gesch&auml;fts. Wenn
                    Sie eine nat&uuml;rliche Person sind, sind Sie nicht unter 18 Jahre alt;</p>

                <p>&nbsp;</p>

                <p>i. Ihr Zugang und Ihre Nutzung der Plattform und/oder der DuneEX-Dienste, Ihre Leistung und Lieferung
                    sowie die Erf&uuml;llung Ihrer Verpflichtungen gem&auml;&szlig; diesen Bedingungen und jeder
                    Vereinbarung, die gem&auml;&szlig; oder im Zusammenhang mit diesen Bedingungen abgeschlossen
                    wurde:</p>

                <p>&nbsp;</p>

                <p>I. Wenn Sie eine juristische Person, Partner in einer Partnerschaft oder Treuh&auml;nder sind, wird
                    dies nicht zu einem Versto&szlig; gegen oder im Widerspruch zu einer Bestimmung Ihrer Satzung, Ihrer
                    Satzung, Ihrer Partnerschaftsvereinbarung, Ihrer Treuhandvereinbarung oder gleichwertiger
                    Gr&uuml;ndungsdokumente f&uuml;hren;</p>

                <p>&nbsp;</p>

                <p>II. wird nicht zu einem Versto&szlig; oder zur Nichterf&uuml;llung von Verpflichtungen unter einem
                    Instrument, einer Vereinbarung, einem Dokument oder einer Bestimmung f&uuml;hren, deren Partei Sie
                    sind oder deren Handeln sich auf Sie oder Ihr Eigentum bezieht;</p>

                <p>&nbsp;</p>

                <p>III. wird nicht zu einem Versto&szlig; gegen Sie, uns oder eine dritte Partei gegen geltendes Recht,
                    Dekret oder Entscheidung eines Gerichts, eines Schiedsspruchs oder einer Entscheidung einer
                    staatlichen oder Aufsichtsbeh&ouml;rde in irgendeiner Gerichtsbarkeit f&uuml;hren.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>28. HAFTUNGSAUSSCHLUSS F&Uuml;R TECHNOLOGIEN</p>

                <p>&nbsp;</p>

                <p>28.1. Keine Zusicherungen oder Gew&auml;hrleistungen. Die Dienstleistungen von DuneEX und alle
                    Informationen auf den Websites und der Plattform, einschlie&szlig;lich Chat-Diensten, werden nach
                    dem Prinzip &quot;wie sie sind&quot; und &quot;wie verf&uuml;gbar&quot; ohne jegliche Zusicherungen
                    oder Gew&auml;hrleistungen, ausdr&uuml;cklich oder implizit, in dem maximal zul&auml;ssigen Umfang
                    nach geltendem Recht bereitgestellt. Insbesondere lehnen wir jegliche stillschweigenden
                    Gew&auml;hrleistungen bez&uuml;glich Eigentumsrecht, Marktg&auml;ngigkeit, Eignung f&uuml;r einen
                    bestimmten Zweck und Nichtverletzung ab. Wir geben keine Zusicherungen und Gew&auml;hrleistungen ab,
                    dass der Zugang zu den Websites, der Plattform, einem Ihrer DuneEX-Konten, den
                    DuneEX-Dienstleistungen oder Inhalten darin kontinuierlich, unterbrechungsfrei, zeitgerecht oder
                    fehlerfrei sein wird. Dies kann dazu f&uuml;hren, dass es f&uuml;r bestimmte Zeitr&auml;ume nicht
                    m&ouml;glich ist, auf der Plattform zu handeln, und zu Verz&ouml;gerungen.</p>

                <p>&nbsp;</p>

                <p>28.2. Aussetzung des Zugangs. Wir k&ouml;nnen den Zugang zu Ihrem DuneEX-Konto und/oder den
                    DuneEX-Dienstleistungen von Zeit zu Zeit f&uuml;r geplante oder Notfallwartungsarbeiten aussetzen.
                    Wir werden angemessene Anstrengungen unternehmen, um Transaktionen auf der Plattform zeitgerecht zu
                    verarbeiten; jedoch &uuml;bernehmen wir keine Zusicherungen oder Gew&auml;hrleistungen
                    bez&uuml;glich der Verarbeitungszeiten, da diese von Faktoren abh&auml;ngen, die au&szlig;erhalb
                    unserer Kontrolle liegen.</p>

                <p>&nbsp;</p>

                <p>28.3. Inhalte. Obwohl wir angemessene Anstrengungen unternehmen, um Informationen auf den Websites
                    und der Plattform zu aktualisieren, geben wir keine Zusicherungen, Gew&auml;hrleistungen oder
                    Versicherungen, ausdr&uuml;cklich oder implizit, dass der Inhalt der Websites und der Plattform,
                    einschlie&szlig;lich Informationen zu DuneEX-Dienstleistungen, genau, vollst&auml;ndig oder aktuell
                    ist.</p>

                <p>&nbsp;</p>

                <p>28.4. Websites von Dritten. Wir k&ouml;nnen Links zu Websites von Dritten bereitstellen
                    (einschlie&szlig;lich unter anderem Inhalt, Materialien und/oder Informationen auf Websites von
                    Dritten) aus Gr&uuml;nden der Bequemlichkeit, jedoch kontrollieren wir diese nicht. Sie erkennen an
                    und stimmen zu, dass wir nicht f&uuml;r irgendeinen Aspekt des Inhalts, Materialien, Informationen
                    oder Dienstleistungen auf Websites von Dritten haftbar sind, die auf der Plattform oder den Websites
                    zug&auml;nglich oder erw&auml;hnt sind.</p>

                <p>&nbsp;</p>

                <p>28.5. Netzwerkzugang und Kompatibilit&auml;t. Sie sind daf&uuml;r verantwortlich, Zugang zum
                    Daten&uuml;bertragungsnetz zu erhalten, das f&uuml;r die Nutzung der DuneEX-Dienstleistungen
                    erforderlich ist. Sie sind verantwortlich f&uuml;r die Beschaffung und Aktualisierung kompatibler
                    Ger&auml;te oder Ger&auml;te, die f&uuml;r den Zugang und die Nutzung der DuneEX-Dienstleistungen
                    und Websites erforderlich sind, einschlie&szlig;lich etwaiger Updates. DuneEX garantiert nicht, dass
                    die DuneEX-Dienstleistungen oder Teile davon auf einem bestimmten Ger&auml;t oder Ger&auml;ten
                    funktionieren werden. Die DuneEX-Dienstleistungen k&ouml;nnen Ausf&auml;llen und Verz&ouml;gerungen
                    unterliegen, die mit der Nutzung des Internets und elektronischer Kommunikationsmittel verbunden
                    sind.</p>

                <p>&nbsp;</p>

                <p>29. SCHADLOSSTELLUNG</p>

                <p>&nbsp;</p>

                <p>29.1. Anspr&uuml;che Dritter. Sie m&uuml;ssen uns von Anspr&uuml;chen, Klagen, Handlungen,
                    Forderungen, Streitigkeiten, Anschuldigungen oder Untersuchungen durch Dritte, staatliche Stellen
                    oder Branchenverb&auml;nde sowie von allen Anspr&uuml;chen, Verpflichtungen, Sch&auml;den
                    (tats&auml;chlichen und Folgesch&auml;den), Verlusten (einschlie&szlig;lich direkter, indirekter
                    oder Folgeverluste), Kosten und Ausgaben, einschlie&szlig;lich unter anderem Zinsen, Strafen,
                    Rechts- und anderen angemessenen Anwaltsgeb&uuml;hren, anderen professionellen Ausgaben und Kosten
                    (&quot;Sch&auml;den&quot;), freistellen, die sich aus oder im Zusammenhang mit ergeben:</p>

                <p>&nbsp;</p>

                <p>a. Ihrem Zugang zu oder Ihrer Nutzung Ihres DuneEX-Kontos und/oder der DuneEX-Dienstleistungen;</p>

                <p>&nbsp;</p>

                <p>b. Ihrem Versto&szlig; oder vermuteten Versto&szlig; gegen diese Bedingungen oder jegliche Handlung
                    von Ihnen, die im Widerspruch zu einer anderen Bestimmung oder Unterpunkt dieser Bedingungen steht,
                    einschlie&szlig;lich etwaiger Produktbedingungen und anderer erw&auml;hnter Bedingungen und
                    Bestimmungen;</p>

                <p>&nbsp;</p>

                <p>c. Ihren Handlungen im Widerspruch zum geltenden Recht;</p>

                <p>&nbsp;</p>

                <p>d. Ihrer Verletzung von Rechten Dritter.</p>

                <p>&nbsp;</p>

                <p>29.2. Kontrolle. Wir behalten uns das Recht vor, die Verteidigung jeglicher Anspr&uuml;che Dritter zu
                    kontrollieren, f&uuml;r die Sie zur Freistellung von Sch&auml;den verpflichtet sind, und in einem
                    solchen Fall m&uuml;ssen Sie vollst&auml;ndig kooperieren, wie von uns festgelegt, um jegliche
                    verf&uuml;gbaren Verteidigungen zu verteidigen. Sie werden keine Anspr&uuml;che oder Sch&auml;den
                    ohne unsere vorherige schriftliche Zustimmung regeln.</p>

                <p>&nbsp;</p>

                <p>29.3. Freistellung. Hiermit erkl&auml;ren Sie sich damit einverstanden, uns von allen Anspr&uuml;chen
                    und Forderungen freizustellen (und auf etwaige Rechte zu verzichten, die Sie gegen uns in Bezug auf
                    m&ouml;gliche Sch&auml;den haben k&ouml;nnten), die direkt oder indirekt aus oder im Zusammenhang
                    mit einem Streit zwischen Ihnen und einem anderen Benutzer oder einer anderen dritten Partei im
                    Zusammenhang mit DuneEX-Dienstleistungen (einschlie&szlig;lich aller Transaktionen mit digitalen
                    Verm&ouml;genswerten) oder dem Gegenstand dieser Bedingungen entstehen.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>30. HAFTUNG</p>

                <p>&nbsp;</p>

                <p>30.1 Unsere Haftung. Unsere Haftung und die Haftung der DuneEX-Partner Ihnen oder Dritten unter allen
                    Umst&auml;nden ist auf den tats&auml;chlichen Betrag der Sch&auml;den oder Verluste beschr&auml;nkt,
                    die direkt und vern&uuml;nftigerweise als Folge unserer Verletzung dieser Bedingungen vorhersehbar
                    sind, und darf in keinem Fall die H&ouml;he der von Ihnen an DuneEX gezahlten Geb&uuml;hren in den
                    12 Monaten vor dem Ereignis &uuml;berschreiten, das den Verlust verursacht hat. Ein solcher Betrag
                    wird von uns an Sie als vollst&auml;ndige und endg&uuml;ltige Abfindung gezahlt und erf&uuml;llt
                    vollst&auml;ndig unsere Haftung und die Haftung eines DuneEX-Partners f&uuml;r alle Verluste und
                    Anspr&uuml;che im Zusammenhang mit der jeweiligen Verletzung, unabh&auml;ngig von den Gr&uuml;nden
                    f&uuml;r deren Entstehung. Sie erkennen an und akzeptieren, dass weder DuneEX noch DuneEX-Partner
                    von besonderen Umst&auml;nden in Bezug auf Sie wissen, dass eine Entsch&auml;digung f&uuml;r
                    Verluste ein angemessenes Rechtsmittel ist und dass Sie kein Recht auf andere Anspr&uuml;che oder
                    Rechtsbehelfe haben, einschlie&szlig;lich spezifischer Leistung, unter diesen Bedingungen oder
                    anderweitig.</p>

                <p>&nbsp;</p>

                <p>30.2 Haftungsbeschr&auml;nkung. Ungeachtet anderer Bestimmungen dieser Bedingungen haften wir oder
                    jeder DuneEX-Partner Ihnen oder einer anderen nat&uuml;rlichen oder juristischen Person in keinem
                    Fall f&uuml;r:</p>

                <p>&nbsp;</p>

                <p>a. Jegliche direkten oder indirekten Verluste (einschlie&szlig;lich entgangener Gewinne,
                    Gesch&auml;fts- oder Opportunit&auml;tsverluste), Sch&auml;den oder Ausgaben, die im Zusammenhang
                    mit diesen Bedingungen stehen, einschlie&szlig;lich, jedoch nicht beschr&auml;nkt auf:</p>

                <p>&nbsp;</p>

                <p>&nbsp;&nbsp; I. Die Funktionsweise der Protokolle, die jedem Digitalen Verm&ouml;genswert zugrunde
                    liegen, deren Funktionalit&auml;t, Sicherheit und Verf&uuml;gbarkeit;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; II. Ob Gesicherte Digitale Verm&ouml;genswerte ihren Wert im Vergleich zu einem
                    beliebigen Verm&ouml;genswert behalten oder ob der Emittent Gesicherter Digitaler
                    Verm&ouml;genswerte ausreichende Reserven in Bezug auf einen solchen Verm&ouml;genswert hat;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; III. Jede Handlung oder Unterlassung gem&auml;&szlig; diesen Bedingungen;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; IV. Jegliche Ungenauigkeiten, M&auml;ngel oder Auslassungen in den Preisdaten f&uuml;r
                    Digitale Verm&ouml;genswerte, jegliche Fehler oder Verz&ouml;gerungen bei der &Uuml;bermittlung
                    solcher Daten oder Unterbrechungen bei der &Uuml;bermittlung solcher Daten;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; V. Regelm&auml;&szlig;ige oder ungeplante Wartungsarbeiten, die wir durchf&uuml;hren,
                    einschlie&szlig;lich aller &Auml;nderungen und Unterbrechungen des Dienstes, die durch solche
                    Wartungsarbeiten verursacht werden;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; VI. Den Diebstahl des Ger&auml;ts, &uuml;ber das der Zugang und die Nutzung der
                    DuneEX-Dienste erfolgen;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; VII. Handlungen, Unterlassungen oder Verst&ouml;&szlig;e gegen diese Bedingungen durch
                    andere Benutzer sowie jeden Schaden, der durch Handlungen Dritter verursacht wird;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; VIII. (1) Jeden Schaden oder jede St&ouml;rung, die durch Computer-Viren, Spyware oder
                    andere sch&auml;dliche Software verursacht wird, die Ihren Computer oder andere Ger&auml;te
                    beeintr&auml;chtigen k&ouml;nnen, oder durch Phishing, Spoofing oder andere Angriffe; (2) Ausfall,
                    Besch&auml;digung oder Zerst&ouml;rung Ihrer Ger&auml;te, Schaden oder Verlust von Aufzeichnungen
                    oder Daten auf Ihren Ger&auml;ten aus irgendeinem Grund; oder (3) Ihre Nutzung des Internets zur
                    Verbindung mit den DuneEX-Diensten oder jegliche technischen Probleme, Systemausf&auml;lle,
                    Fehlfunktionen, Leitungsst&ouml;rungen, hohe Datenverkehr oder Nachfrage nach Internetdatenverkehr,
                    damit verbundene Probleme, Sicherheitsverletzungen oder &auml;hnliche technische Probleme oder
                    M&auml;ngel;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; IX. Unsere Entscheidung, Ihre Anwendung zur Erstellung von DuneEX-Konten
                    gem&auml;&szlig; Abschnitt &lrm;7.1 abzulehnen;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; X. Jede Beendigung, Aussetzung, Zur&uuml;ckhaltung oder Einschr&auml;nkung des Zugangs
                    zu einem DuneEX-Konto oder DuneEX-Diensten, einschlie&szlig;lich Ihrer Unf&auml;higkeit, Digitale
                    Verm&ouml;genswerte abzuheben, Anweisungen zu geben oder Transaktionen w&auml;hrend einer solchen
                    Aussetzung, Zur&uuml;ckhaltung oder Einschr&auml;nkung gem&auml;&szlig; diesen Bedingungen oder den
                    Produktbedingungen durchzuf&uuml;hren;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XI. Jegliche Transaktionsbeschr&auml;nkungen, die f&uuml;r Ihr DuneEX-Konto
                    gem&auml;&szlig; Abschnitt &lrm;14.1 gelten;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XII. Unsere Entscheidung, Digitale Verm&ouml;genswerte
                    gem&auml;&szlig; Abschnitt &lrm;15.1 zu unterst&uuml;tzen oder nicht zu unterst&uuml;tzen;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XIII. Unsere Unf&auml;higkeit, Sie unter Verwendung der von Ihnen
                    gem&auml;&szlig; Abschnitt &lrm;3.2 bereitgestellten Kontaktinformationen zu kontaktieren;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XIV. Die Schlie&szlig;ung inaktiver Konten durch uns
                    gem&auml;&szlig; Abschnitt &lrm;19.3;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XV. Das Vers&auml;umnis, eine Transaktion auszuf&uuml;hren, oder die Nichterf&uuml;llung
                    des f&uuml;r den Abschluss einer Transaktion erforderlichen Zeitrahmens
                    gem&auml;&szlig; Abschnitt &lrm;12.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XVI. Market-Making-Aktivit&auml;ten gem&auml;&szlig; Abschnitt &lrm;13.2;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XVII. Unser Vertrauen auf Anweisungen, die von Ihrem DuneEX-Konto oder Ihrer E-Mail
                    gesendet wurden;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XVIII. Unsere Ablehnung oder Verz&ouml;gerung bei der Ausf&uuml;hrung von Anweisungen
                    gem&auml;&szlig; Abschnitt &lrm;11.1;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XIX. Jeder Versto&szlig; gegen Ihre E-Mail-Sicherheit oder Sicherheitsverletzung;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XX. Verluste, die Ihnen durch Betrug oder Betr&uuml;gereien Dritter entstehen, an denen
                    DuneEX nur als Empf&auml;nger Ihrer Fiat-W&auml;hrung oder Digitalen Verm&ouml;genswerte beteiligt
                    ist, und/oder die Umwandlung von Fiat-W&auml;hrung in Digitale Verm&ouml;genswerte durchf&uuml;hrt
                    und/oder Digitale Verm&ouml;genswerte von unserer Plattform auf Ihren
                    Wunsch &uuml;bertr&auml;gt;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XXI. Jegliche Verluste im Zusammenhang mit neuen Angeboten von Digitalen
                    Verm&ouml;genswerten, Initial Coin Offerings (ICOs) oder Entscheidungen zur Aufnahme oder
                    Nichtaufnahme von Digitalen Verm&ouml;genswerten in die Plattform;</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XXII. Die Genauigkeit, Qualit&auml;t, Vollst&auml;ndigkeit, Zuverl&auml;ssigkeit,
                    Effizienz, rechtzeitige Lieferung, Preisgestaltung oder kontinuierliche Verf&uuml;gbarkeit der
                    DuneEX-Dienste oder Verz&ouml;gerungen oder Ausf&auml;lle in den DuneEX-Diensten oder die
                    Unf&auml;higkeit eines Verbindungs- oder Kommunikationsdienstes, Ihren Zugang zu den DuneEX-Diensten
                    bereitzustellen oder aufrechtzuerhalten, oder jede Unterbrechung oder St&ouml;rung Ihres Zugangs
                    oder jegliche fehlerhaften Nachrichten zwischen uns, unabh&auml;ngig von der Ursache; und</p>

                <p>&nbsp; &nbsp;</p>

                <p>&nbsp;&nbsp; XXIII. Jegliche Transaktionen, Anweisungen oder Operationen, die von Ihnen oder
                    angeblich von Ihnen &uuml;ber Ihre E-Mail oder Ihr DuneEX-Konto durchgef&uuml;hrt werden;</p>

                <p>&nbsp; &nbsp;</p>

                <p>b. Entgangene Gewinne, Gesch&auml;ftsverluste, erwartete Ersparnisse oder Gelegenheiten oder jegliche
                    speziellen, strafenden, erschwerenden, zuf&auml;lligen, indirekten oder Folgesch&auml;den oder
                    Verluste, die aus oder im Zusammenhang mit unseren Websites, der Plattform, Ihrem DuneEX-Konto, den
                    DuneEX-Diensten, diesen Bedingungen, Produktbedingungen, Datenschutzerkl&auml;rung und/oder einer
                    Vereinbarung entstehen, die als Ergebnis oder im Zusammenhang mit diesen Bedingungen oder
                    anderweitig abgeschlossen wurde; und/oder</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>c. Alle Verluste, die Teil einer Forderung sind, die nicht innerhalb eines Kalenderjahres nach dem
                    Ereignis, das zur Entstehung der Forderung gef&uuml;hrt hat, durch f&ouml;rmliche Gerichtsverfahren
                    eingeleitet wurde. SIE ERKENNEN AN UND STIMMEN ZU, DASS DIESE BESTIMMUNG DIE
                    VERJ&Auml;HRUNGSFRISTEN &Auml;NDERT, DIE UNTER ANDEREN GESETZLICHEN UMST&Auml;NDEN ANWENDBAR SIND,
                    UND DASS, WENN ANWENDBARES RECHT DIES VERBIETET, DIESE BESTIMMUNG ALS BESCHR&Auml;NKUNG DER HAFTUNG
                    VON MINIMAL ZUL&Auml;SSIGER DAUER AUSGELEGT WERDEN SOLL. OHNE DIE ALLGEMEINE NATUR DES VORANGEHENDEN
                    EINZUSCHR&Auml;NKEN, BEACHTEN SIE BITTE ABSCHNITT 32, DER FESTLEGT, DASS DIE REGELUNG VON
                    ANSPR&Uuml;CHEN AUSSCHLIESSLICH DURCH VERPFLICHTENDE SCHIEDSVERFAHREN ERFOLGT.</p>

                <p>&nbsp;</p>

                <p>30.3 Sch&auml;den oder Unterbrechungen. Wir haften nicht f&uuml;r Sch&auml;den oder Unterbrechungen,
                    die durch Computerviren, Spyware, gef&auml;lschte Antivirenprogramme, Trojaner, W&uuml;rmer oder
                    andere sch&auml;dliche Software verursacht werden k&ouml;nnen, die Ihren Computer oder andere
                    Ger&auml;te beeintr&auml;chtigen k&ouml;nnen, oder durch Phishing, Spoofing oder andere Angriffe.
                    Wir empfehlen, regelm&auml;&szlig;ig zuverl&auml;ssige und leicht verf&uuml;gbare Software zur
                    Erkennung und zum Schutz vor Viren zu verwenden. Beachten Sie, dass SMS- und E-Mail-Dienste
                    anf&auml;llig f&uuml;r Spoofing und Phishing sind, und seien Sie vorsichtig beim Empfang von
                    Nachrichten, die angeblich von uns stammen. Sie und, falls zutreffend, Ihre autorisierten Benutzer
                    sind f&uuml;r alle Anmeldedaten verantwortlich, einschlie&szlig;lich Benutzernamen und
                    Passw&ouml;rtern, und m&uuml;ssen diese stets sicher aufbewahren.</p>

                <p>&nbsp;</p>

                <p><strong>STREITBEILEGUNG: GERICHTSVERFAHREN, SCHIEDSVERFAHREN, VERZICHT AUF SAMMELKLAGE</strong></p>

                <p>&nbsp;</p>

                <p>Zur Vermeidung von Missverst&auml;ndnissen: Nichts in diesem Abschnitt entzieht Ihnen irgendwelche
                    zwingenden gesetzlichen Rechte, die Ihnen gem&auml;&szlig; geltendem Recht zustehen.</p>

                <p>&nbsp;</p>

                <p>31. Mitteilung &uuml;ber Anspr&uuml;che und Streitbeilegungsfrist</p>

                <p>&nbsp;</p>

                <p>31.1. Wenn Sie Probleme mit den Dienstleistungen haben, nehmen Sie bitte zun&auml;chst Kontakt mit
                    DuneEX auf. DuneEX beabsichtigt, Ihre Probleme ohne f&ouml;rmliche gerichtliche Auseinandersetzung
                    zu l&ouml;sen, wenn m&ouml;glich. Falls Sie mit der L&ouml;sung Ihrer Probleme beim ersten Kontakt
                    nicht zufrieden sind, wird Ihnen eine Ticketnummer zugewiesen. Die Vergabe einer Ticketnummer leitet
                    den internen Streitbeilegungsprozess ein. DuneEX wird versuchen, den Streit durch interne Verfahren
                    so schnell wie m&ouml;glich beizulegen. Die Parteien verpflichten sich, in gutem Glauben
                    Verhandlungen zur Beilegung des Streits zu f&uuml;hren (diese Verhandlungen m&uuml;ssen vertraulich
                    bleiben und den geltenden Regeln zum Schutz von Vergleichsverhandlungen vor der Verwendung als
                    Beweismittel in gerichtlichen Verfahren unterliegen).</p>

                <p>&nbsp;</p>

                <p>31.2. Wenn der Streit nicht zufriedenstellend gel&ouml;st werden kann und Sie eine Forderung gegen
                    DuneEX geltend machen m&ouml;chten, stimmen Sie zu, die Gr&uuml;nde f&uuml;r eine solche Forderung
                    in einer schriftlichen &quot;Mitteilung &uuml;ber Anspr&uuml;che&quot; als Vorabinformation an
                    DuneEX darzulegen. Die Mitteilung &uuml;ber Anspr&uuml;che muss (1) die Art und Grundlage des
                    Anspruchs oder Streits beschreiben, (2) eine spezifische Rechtsmittelangabe enthalten, (3) die
                    urspr&uuml;ngliche Ticketnummer und (4) die E-Mail-Adresse Ihres Verwahrer-Kontos enthalten. Die
                    Mitteilung &uuml;ber Anspr&uuml;che sollte an folgende Adresse gesendet werden:
                    https://dune-ex.net/contacts. Sobald Sie die Mitteilung &uuml;ber Anspr&uuml;che an DuneEX gesendet
                    haben, kann der in der Mitteilung &uuml;ber Anspr&uuml;che angegebene Streitfall entweder an DuneEX
                    oder gem&auml;&szlig; der unten aufgef&uuml;hrten Schiedsvereinbarung (Schiedsvereinbarung)
                    weitergeleitet werden.</p>

                <p>&nbsp;</p>

                <p>31.3. Um Missverst&auml;ndnisse zu vermeiden, sind die Einreichung eines Streits bei DuneEX zur
                    L&ouml;sung durch interne Verfahren und die &Uuml;bersendung einer Mitteilung &uuml;ber
                    Anspr&uuml;che an DuneEX Voraussetzungen f&uuml;r die Einleitung eines Schiedsverfahrens (oder eines
                    anderen gerichtlichen Verfahrens).</p>

                <p>&nbsp;</p>

                <p>31.4. W&auml;hrend des Schiedsverfahrens ist es untersagt, dem Schiedsrichter den Betrag eines von
                    Ihnen oder DuneEX gemachten Vergleichsangebots offenzulegen.</p>

                <p>&nbsp;</p>

                <p>32. Schiedsvereinbarung</p>

                <p>&nbsp;</p>

                <p>32.1. Sofern geltendes Recht dies nicht verlangt oder Ihnen eine andere Wahl l&auml;sst, stimmen Sie
                    und DuneEX unter Ber&uuml;cksichtigung des vorhergehenden Abschnitts (Mitteilung &uuml;ber
                    Anspr&uuml;che und Streitbeilegungsfrist) zu, dass jeder Anspruch durch ein obligatorisches,
                    abschlie&szlig;endes und individuelles (nicht kollektives) Schiedsverfahren gem&auml;&szlig; den
                    aktuellen Regeln des Hong Kong International Arbitration Centre (HKIAC) und in &Uuml;bereinstimmung
                    mit den geltenden HKIAC-Regeln, die durch Verweis in diesen Abschnitt aufgenommen werden, beigelegt
                    wird.</p>

                <p>&nbsp;</p>

                <p>32.2. Der Ort des Schiedsverfahrens ist Hongkong.</p>

                <p>&nbsp;</p>

                <p>32.3. Das Gremium besteht aus einem (1) Schiedsrichter, der gem&auml;&szlig; den aktuellen
                    HKIAC-Regeln ernannt wird.</p>

                <p>&nbsp;</p>

                <p>32.4. Die Verhandlungssprache des Schiedsverfahrens ist Englisch.</p>

                <p>&nbsp;</p>

                <p>32.5. Sie und DuneEX stimmen ebenfalls zu, dass der Schiedsrichter ausschlie&szlig;liche Befugnis
                    hat, Fragen zu seiner eigenen Zust&auml;ndigkeit zu kl&auml;ren, einschlie&szlig;lich unter anderem
                    etwaiger Einw&auml;nde gegen das Bestehen, den Umfang oder die G&uuml;ltigkeit der
                    Schiedsvereinbarung sowie der M&ouml;glichkeit, einen Anspruch im Schiedsverfahren beizulegen.</p>

                <p>&nbsp;</p>

                <p>32.6. Die in diesem Abschnitt festgelegten Schiedsbestimmungen bleiben nach Beendigung dieser
                    Bedingungen in Kraft.</p>

                <p>&nbsp;</p>

                <p>32.7. Verj&auml;hrungsfrist. Jedes Schiedsverfahren gegen DuneEX muss innerhalb eines (1) Jahres ab
                    dem Datum, an dem der Nutzer, der den Anspruch geltend macht, erstmals von der behaupteten Handlung,
                    Unterlassung oder Nichterf&uuml;llung erfahren hat oder vern&uuml;nftigerweise h&auml;tte erfahren
                    m&uuml;ssen, eingeleitet werden, was Grundlage des Anspruchs bildet
                    (&quot;Verj&auml;hrungsfrist&quot;). Um Missverst&auml;ndnisse zu vermeiden, umfasst die
                    Verj&auml;hrungsfrist die in Abschnitt 31.1 angegebene Streitbeilegungsfrist. Ein Nutzer hat kein
                    Recht auf jegliche rechtliche oder sachliche Abhilfe aufgrund eines Anspruchs, es sei denn, eine
                    Mitteilung &uuml;ber das Schiedsverfahren bez&uuml;glich dieses Anspruchs wurde innerhalb der
                    Verj&auml;hrungsfrist bei DuneEX eingereicht und zugestellt. Wenn die Verj&auml;hrungsfrist im
                    Widerspruch zu geltendem Recht steht, muss der Nutzer jeden Anspruch gegen DuneEX innerhalb der
                    k&uuml;rzesten vom geltenden Recht zugelassenen Frist geltend machen. Die Mitteilung &uuml;ber das
                    Schiedsverfahren kann gem&auml;&szlig; geltendem Recht und den Regeln zur Zustellung an DuneEX
                    gerichtet werden.</p>

                <p>&nbsp;</p>

                <p>32.8. Benachrichtigung: Wenn DuneEX ein Schiedsverfahren gegen Sie einleitet, wird DuneEX Ihnen eine
                    Benachrichtigung an die von Ihnen angegebene E-Mail-Adresse oder Postanschrift senden. Sie
                    best&auml;tigen, dass jede an diese E-Mail-Adresse oder Postanschrift gesendete Benachrichtigung
                    f&uuml;r alle Zwecke als g&uuml;ltig gilt, einschlie&szlig;lich unter anderem der Feststellung der
                    Zustellung. Sie sind daf&uuml;r verantwortlich, sicherzustellen, dass die von Ihnen an DuneEX
                    bereitgestellte E-Mail- und/oder Postanschrift aktuell und korrekt sind.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>33. Vertraulichkeit</p>

                <p>&nbsp;</p>

                <p>33.1. Die Parteien sind sich einig, dass das Schiedsverfahren vertraulich sein soll. Das Bestehen des
                    Schiedsverfahrens, das Bestehen oder der Inhalt eines Anspruchs, alle Dokumente und Informationen,
                    die im Zusammenhang mit dem Schiedsverfahren von den Parteien ausgetauscht oder bereitgestellt
                    wurden, sowie alle Erkl&auml;rungen, Entscheidungen oder Urteile, die im Schiedsverfahren getroffen
                    wurden, m&uuml;ssen vertraulich bleiben. Keine der Parteien darf eines der genannten Elemente einer
                    dritten Partei offenlegen, au&szlig;er vor dem Schiedsgericht, dem HKIAC, den Parteien, ihren
                    Anw&auml;lten, Experten, Zeugen, Buchhaltern, Wirtschaftspr&uuml;fern, Versicherern und
                    R&uuml;ckversicherern sowie allen anderen Personen, die f&uuml;r die Durchf&uuml;hrung des
                    Schiedsverfahrens erforderlich sind. Ungeachtet des Vorstehenden kann eine Partei solche
                    vertraulichen Informationen offenlegen:</p>

                <p>&nbsp;</p>

                <p>a. falls schriftliche Zustimmung der anderen Partei vorliegt;</p>

                <p>&nbsp;</p>

                <p>b. soweit dies durch anwendbares Recht oder Vorschriften einer Aufsichts- oder
                    Regulierungsbeh&ouml;rde der zust&auml;ndigen Gerichtsbarkeit erforderlich ist oder
                    gem&auml;&szlig; einer Gerichtsentscheidung oder einer anderen zust&auml;ndigen Beh&ouml;rde oder
                    eines Schiedsgerichts der zust&auml;ndigen Gerichtsbarkeit;</p>

                <p>&nbsp;</p>

                <p>c. im Zusammenhang mit der Einleitung, Durchf&uuml;hrung oder Verteidigung durch eine Partei eines
                    bona fide gerichtlichen Verfahrens zur Durchsetzung oder Anfechtung eines im Schiedsverfahren
                    ergangenen Urteils; und</p>

                <p>&nbsp;</p>

                <p>d. soweit solche vertraulichen Informationen &ouml;ffentlich zug&auml;nglich sind und nicht gegen
                    diese Vereinbarung versto&szlig;en.</p>

                <p>&nbsp;</p>

                <p>Die Vertraulichkeitsklausel gem&auml;&szlig; diesem Abschnitt bleibt nach Beendigung dieser
                    Bedingungen und dem Abschluss oder der Aussetzung eines gem&auml;&szlig; diesen Bedingungen
                    eingeleiteten Schiedsverfahrens in Kraft.</p>

                <p>&nbsp;</p>

                <p>34. Anwendbares Recht</p>

                <p>&nbsp;</p>

                <p>Sofern das anwendbare Recht nichts anderes verlangt oder vorsieht, unterliegen diese Bedingungen
                    (einschlie&szlig;lich dieser Schiedsvereinbarung) dem Recht von Hongkong.</p>

                <p>&nbsp;</p>

                <p>35. Verzicht auf Sammelklagen</p>

                <p>&nbsp;</p>

                <p>Sie und DuneEX stimmen zu, dass Anspr&uuml;che gegen DuneEX nur in individueller Eigenschaft in einem
                    Schiedsverfahren und nicht als Kl&auml;ger oder Mitglied einer vermeintlichen Sammel- oder
                    Vertreterklage geltend gemacht werden m&uuml;ssen. Sie verzichten auch darauf, das Recht geltend zu
                    machen, solche Anspr&uuml;che als Sammelklage, Gruppenklage, Vertreterklage oder private Klage
                    gem&auml;&szlig; dem anwendbaren Recht zu f&uuml;hren, soweit dies zul&auml;ssig ist. Eine
                    Zusammenf&uuml;hrung oder Konsolidierung von einzelnen Schiedsverfahren zu einem Schiedsverfahren
                    ist ohne Zustimmung von DuneEX nicht gestattet.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p><strong>ALLGEMEINE BESTIMMUNGEN</strong></p>

                <p>&nbsp;</p>

                <p>36. Allgemeine Bestimmungen</p>

                <p>&nbsp;</p>

                <p>36.1. Anwendbares Recht. Sie und jeder autorisierte Benutzer m&uuml;ssen geltendes Recht,
                    Lizenzanforderungen und Rechte Dritter (einschlie&szlig;lich Datenschutzgesetze sowie Gesetze zur
                    Bek&auml;mpfung von Geldw&auml;sche und Terrorismusfinanzierung) einhalten, wenn Sie die
                    DuneEX-Dienste, Ihr DuneEX-Konto und die Plattform nutzen.</p>

                <p>&nbsp;</p>

                <p>36.2. Benachrichtigungen. Wir k&ouml;nnen Benachrichtigungen an Ihre E-Mail senden. Sie sind
                    daf&uuml;r verantwortlich, genaue und aktuelle E-Mail-Informationen anzugeben. Benachrichtigungen,
                    die an Ihre E-Mail gesendet werden, gelten als empfangen, auch wenn eine Benachrichtigung &uuml;ber
                    einen Zustellungsfehler vorliegt.</p>

                <p>&nbsp;</p>

                <p>Sie k&ouml;nnen uns nur entsprechend unseren Anweisungen benachrichtigen, die sich von Zeit zu
                    Zeit &auml;ndern k&ouml;nnen.</p>

                <p>&nbsp;</p>

                <p>Alle unter diesen Bedingungen bereitgestellten Benachrichtigungen, Zustimmungen oder sonstigen
                    Mitteilungen m&uuml;ssen schriftlich in englischer Sprache verfasst und von der sie bereitstellenden
                    Partei unterschrieben oder anderweitig autorisiert sein.</p>

                <p>&nbsp;</p>

                <p>36.3. Ank&uuml;ndigungen. Alle offiziellen Ank&uuml;ndigungen, Nachrichten, Aktionen, Wettbewerbe und
                    Luftabw&uuml;rfe werden auf der Website ver&ouml;ffentlicht. Diese Ank&uuml;ndigungen sind wichtig
                    und k&ouml;nnen Informationen enthalten, die den Wert oder die Sicherheit Ihrer digitalen
                    Verm&ouml;genswerte beeinflussen k&ouml;nnen. Sie sind daf&uuml;r verantwortlich, die Website
                    zu &uuml;berwachen, diese Ank&uuml;ndigungen zu lesen und zu ber&uuml;cksichtigen.</p>

                <p>&nbsp;</p>

                <p>36.4. Gesamte Vereinbarung. Diese Bedingungen zusammen mit den anwendbaren Produktbedingungen stellen
                    die gesamte Vereinbarung zwischen Ihnen und uns in Bezug auf die DuneEX-Dienste dar. Jede Partei
                    erkennt an, dass sie sich nicht auf Aussagen, Darstellungen oder Garantien (ob fahrl&auml;ssig oder
                    unschuldig gemacht), au&szlig;er den ausdr&uuml;cklich in diesen Bedingungen oder Produktbedingungen
                    festgelegten, verlassen hat.</p>

                <p>&nbsp;</p>

                <p>36.5. &Uuml;bertragung. Sie d&uuml;rfen Ihre Rechte oder Pflichten aus den Bedingungen ohne unsere
                    vorherige schriftliche Zustimmung nicht abtreten oder &uuml;bertragen. Wir k&ouml;nnen jedoch
                    jederzeit unsere Rechte oder Pflichten aus den Bedingungen auf jede andere Person &uuml;bertragen,
                    einschlie&szlig;lich, ohne Einschr&auml;nkung, im Rahmen einer Fusion, &Uuml;bernahme oder anderer
                    Unternehmensumstrukturierung, an der DuneEX beteiligt ist.</p>

                <p>&nbsp;</p>

                <p>36.6. Teilbarkeit. Sollte zu irgendeinem Zeitpunkt eine Bestimmung oder Unterbestimmung dieser
                    Bedingungen in irgendeinem Umfang rechtswidrig, ung&uuml;ltig oder nicht durchsetzbar werden, so
                    ber&uuml;hrt dies in keiner Weise die Rechtm&auml;&szlig;igkeit, G&uuml;ltigkeit oder
                    Durchsetzbarkeit der &uuml;brigen Bestimmungen oder Unterbestimmungen.</p>

                <p>&nbsp;</p>

                <p>36.7. Aufzeichnungen. Sie stimmen zu, dass wir alle Gespr&auml;che mit Ihnen per Telefon, E-Mail,
                    Chat oder jeder anderen Form der Kommunikation aufzeichnen d&uuml;rfen, einschlie&szlig;lich der
                    Kommunikation zur Erteilung von Anweisungen oder Durchf&uuml;hrung von Transaktionen zwischen Ihnen
                    und uns, und dass diese Aufzeichnungen als Beweismittel in allen Verfahren im Zusammenhang mit einer
                    Vereinbarung mit Ihnen verwendet werden k&ouml;nnen. Diese Aufzeichnungen sind unser
                    ausschlie&szlig;liches Eigentum.</p>

                <p>&nbsp;</p>

                <p>36.8. Sprache. Diese Bedingungen k&ouml;nnen in andere Sprachen als Englisch &uuml;bersetzt werden.
                    Eine solche &Uuml;bersetzung dient nur zu Ihrer Bequemlichkeit. Im Falle von Diskrepanzen oder
                    Unklarheiten hat der Text in englischer Sprache Vorrang.</p>

                <p>&nbsp;</p>

                <p>36.9. Rechte Dritter. Mit Ausnahme der DuneEX-Partner darf nichts, das in diesen Bedingungen
                    ausgedr&uuml;ckt oder erw&auml;hnt wird, als Gew&auml;hrung irgendwelcher rechtlichen oder
                    gerechtlichen Rechte, Mittel oder Anspr&uuml;che an irgendeine Person au&szlig;er den Parteien
                    dieser Bedingungen ausgelegt werden. Diese Bedingungen und alle ihre Bestimmungen sind
                    ausschlie&szlig;lich zum Nutzen der Parteien dieser Bedingungen und ihrer Rechtsnachfolger und
                    zul&auml;ssigen Abtretungsempf&auml;nger bestimmt.</p>

                <p>&nbsp;</p>

                <p>36.10. Fortbestand. Alle Bestimmungen und Unterbestimmungen dieser Bedingungen, die aufgrund ihrer
                    Natur &uuml;ber das Ende oder die Beendigung dieser Bedingungen hinaus gelten, bleiben nach dem Ende
                    oder der Beendigung dieser Bedingungen in Kraft und bindend.</p>

                <p>&nbsp;</p>

                <p>36.11. Beziehung der Parteien. DuneEX ist nicht Ihr Vertreter nach diesen Bedingungen. Diese
                    Bedingungen d&uuml;rfen nicht als Hinweis oder Tatsache einer Assoziation, eines Joint Ventures,
                    einer Partnerschaft oder Franchise zwischen den Parteien ausgelegt werden.</p>

                <p>&nbsp;</p>

                <p>36.12. Digitale Verm&ouml;genswerte. Wir f&uuml;hren in Ihrem DuneEX-Konto Aufzeichnungen &uuml;ber
                    die Menge und Art der in Ihrem Konto gehaltenen digitalen Verm&ouml;genswerte. DuneEX ist kein
                    Treuh&auml;nder und hat keine treuh&auml;nderischen Verpflichtungen in Bezug auf irgendwelche
                    digitalen Verm&ouml;genswerte, die in Ihrem Konto gehalten werden. Wenn Sie DuneEX Anweisungen
                    zur &Uuml;bertragung digitaler Verm&ouml;genswerte oder anderweitigen Umgang damit geben, wird
                    DuneEX keine bestimmten digitalen Verm&ouml;genswerte verwenden, die identifiziert wurden, um Ihre
                    Anweisungen zu erf&uuml;llen. Gem&auml;&szlig; diesen Bedingungen wird DuneEX digitale
                    Verm&ouml;genswerte derselben Menge und Art verwenden, die Ihrem DuneEX-Konto gutgeschrieben wurden,
                    bei der Ausf&uuml;hrung Ihrer Anweisungen.</p>

                <p>&nbsp;</p>

                <p>36.13. H&ouml;here Gewalt. Wir haften nicht f&uuml;r Verz&ouml;gerungen oder Nichterf&uuml;llung
                    gem&auml;&szlig; diesen Bedingungen, soweit solche Verz&ouml;gerungen oder Nichterf&uuml;llungen
                    durch h&ouml;here Gewalt verursacht werden.</p>

                <p>&nbsp;</p>

                <p>36.14. Vorbehalt von Rechten. Keine Verz&ouml;gerung oder Unterlassung unsererseits bei der
                    Aus&uuml;bung eines Rechts oder Mittels gem&auml;&szlig; diesen Bedingungen darf als Verzicht auf
                    ein solches Recht oder Mittel oder auf irgendwelche anderen Rechte oder Mittel in der Zukunft
                    gem&auml;&szlig; diesen Bedingungen ausgelegt werden. Die in diesen Bedingungen vorgesehenen Rechte
                    und Mittel sind kumulativ und schlie&szlig;en keine durch geltendes Recht vorgesehenen Rechte oder
                    Mittel aus.</p>

                <p>&nbsp;</p>

                <p>36.15. Aufrechnung. Zus&auml;tzlich zu allen gem&auml;&szlig; diesen Bedingungen oder nach Gesetz
                    verf&uuml;gbaren rechtlichen oder sonstigen Rechten und Mitteln k&ouml;nnen wir alle von Ihnen
                    gem&auml;&szlig; diesen Bedingungen oder anderweitig geschuldeten Betr&auml;ge verrechnen. Sie
                    m&uuml;ssen alle Ihnen geschuldeten Betr&auml;ge ohne Abz&uuml;ge, Gegenforderungen, Einbehalte oder
                    Zur&uuml;ckbehaltungen jeglicher Art zahlen, au&szlig;er wie durch geltendes Recht vorgesehen.</p>

                <p>&nbsp;</p>

                <p>36.16. Vertraulichkeit von Dritten. Wenn Sie &uuml;ber die Plattform oder durch die Nutzung der
                    DuneEX-Dienste Informationen &uuml;ber einen anderen Benutzer erhalten, m&uuml;ssen Sie die
                    Vertraulichkeit der Informationen wahren und sie nur im Zusammenhang mit den DuneEX-Diensten und
                    immer in &Uuml;bereinstimmung mit geltendem Recht verwenden. Sie d&uuml;rfen keine
                    Informationen &uuml;ber Dritte offenlegen oder verbreiten oder sie in irgendeiner Weise verwenden,
                    au&szlig;er wenn dies vern&uuml;nftigerweise erforderlich ist, um eine Transaktion
                    abzuschlie&szlig;en.</p>

                <p>&nbsp;</p>

                <p>36.17. Ver&ouml;ffentlichung von Verst&ouml;&szlig;en. Wenn Sie diese Bedingungen verletzen, kann
                    DuneEX Informationen &uuml;ber die Verletzung ver&ouml;ffentlichen oder anderweitig offenlegen,
                    einschlie&szlig;lich der von Ihnen bereitgestellten Informationen. DuneEX darf dies nur tun, wenn es
                    zum Schutz anderer Benutzer erforderlich ist und dies durch geltendes Recht gestattet ist.</p>

                <p>&nbsp;</p>

                <p>36.18. Tod des Eigent&uuml;mers des DuneEX-Kontos. Im Falle Ihres Todes oder Ihrer
                    Gesch&auml;ftsunf&auml;higkeit m&uuml;ssen die Administratorinnen oder Administratoren Ihres
                    Nachlasses oder die &uuml;berlebenden Erben uns so bald wie vern&uuml;nftigerweise m&ouml;glich
                    schriftlich benachrichtigen. Wenn wir Grund zu der Annahme haben, dass Sie verstorben sind,
                    k&ouml;nnen wir Ihr DuneEX-Konto sperren. Ihr DuneEX-Konto wird gesperrt, bis die folgenden
                    Bedingungen erf&uuml;llt sind:</p>

                <p>&nbsp;</p>

                <p>a. Der Administrator oder die Administratorin Ihres Nachlasses oder der autorisierte Beg&uuml;nstigte
                    erstellt entweder (1) ein DuneEX-Konto gem&auml;&szlig; diesen Bedingungen oder (2)
                    stellt &Uuml;berweisungsanweisungen bereit; und</p>

                <p>&nbsp;</p>

                <p>b. stellt ausreichende rechtliche Unterlagen zur Verf&uuml;gung, die ihr Recht best&auml;tigen,
                    Verm&ouml;genswerte in Ihrem DuneEX-Konto zu empfangen; oder</p>

                <p>&nbsp;</p>

                <p>c. Sie legen zufriedenstellenden Nachweis daf&uuml;r vor, dass Sie nicht verstorben sind.</p>

                <p>&nbsp;</p>

                <p>Beg&uuml;nstigte, die eine &Uuml;berweisung erhalten, erhalten den Liquidationswert der
                    Verm&ouml;genswerte im DuneEX-Konto abz&uuml;glich etwaiger Geb&uuml;hren und Ausgaben im
                    Zusammenhang mit der &Uuml;berweisung. Unsere F&auml;higkeit, Verm&ouml;genswerte in Ihrem
                    DuneEX-Konto an Ihre Vertreter zu &uuml;bertragen, h&auml;ngt von den durch anwendbare Gesetze und
                    diese Bedingungen auferlegten Einschr&auml;nkungen ab. Wir sind nicht verpflichtet, bestimmte
                    Fristen f&uuml;r die &Uuml;bertragung von Verm&ouml;genswerten, die in Ihrem DuneEX-Konto gehalten
                    werden, einzuhalten.</p>

                <p>&nbsp;</p>

                <p>36.19. Steuer. Sie sind daf&uuml;r verantwortlich zu bestimmen, welche Steuern gegebenenfalls auf von
                    Ihnen geleistete oder erhaltene Zahlungen anfallen, und f&uuml;r die Erhebung, Berichterstattung und
                    Abf&uuml;hrung der korrekten Steuer an die entsprechende Steuerbeh&ouml;rde. Sie erkl&auml;ren sich
                    damit einverstanden, dass wir nicht daf&uuml;r verantwortlich sind, zu bestimmen, ob irgendwelche
                    Steuern auf Ihre Nutzung der DuneEX-Dienste anfallen, oder f&uuml;r die Erhebung, Berichterstattung
                    oder Abf&uuml;hrung von Steuern, die aus einer Transaktion oder Ihrer Nutzung der DuneEX-Dienste
                    entstehen.</p>

                <p>&nbsp;</p>

                <p>Sie erkennen an, dass wir bestimmte Steuerberichte an Steuerbeh&ouml;rden in Bezug auf auf der
                    Plattform durchgef&uuml;hrte Transaktionen vorlegen k&ouml;nnen und dass wir Ihnen nach unserem
                    Ermessen oder gem&auml;&szlig; anwendbarem Recht zus&auml;tzliche Dokumentationen oder
                    Aufzeichnungen zur Verf&uuml;gung stellen k&ouml;nnen, die f&uuml;r die Berechnung etwaiger
                    Steuerpflichten erforderlich sind. Wir k&ouml;nnen auch nach unserem Ermessen
                    gem&auml;&szlig; anwendbarem Recht Steuern einbehalten und abziehen.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>37. Definitionen und Auslegung</p>

                <p>&nbsp;</p>

                <p>In diesen Bedingungen:</p>

                <p>&nbsp;</p>

                <p>a. &Uuml;berschriften und Nummerierungen dienen ausschlie&szlig;lich der Bequemlichkeit und
                    beeinflussen nicht die Bedeutung, Priorit&auml;t oder Auslegung einer Bestimmung oder
                    Unterbestimmung dieser Bedingungen;</p>

                <p>&nbsp;</p>

                <p>b. Die W&ouml;rter &quot;umfasst&quot; oder &quot;einschlie&szlig;lich&quot; bedeuten &quot;umfasst
                    ohne Einschr&auml;nkung&quot; bzw. &quot;einschlie&szlig;lich ohne Einschr&auml;nkung&quot;;</p>

                <p>&nbsp;</p>

                <p>c. Jede Verpflichtung zur Durchf&uuml;hrung oder Unterlassung einer Handlung impliziert die
                    Verpflichtung, die Durchf&uuml;hrung oder Nichtdurchf&uuml;hrung einer solchen Handlung oder Sache
                    in Bezug auf sich selbst oder andere zu verhindern;</p>

                <p>&nbsp;</p>

                <p>d. W&ouml;rter, die die Einzahl bezeichnen, umfassen die Mehrzahl und umgekehrt, und W&ouml;rter, die
                    das Geschlecht bezeichnen, umfassen alle Geschlechter;</p>

                <p>&nbsp;</p>

                <p>e. Jedes hierin erw&auml;hnte Dokument wird periodisch ge&auml;ndert, modifiziert oder erg&auml;nzt,
                    es sei denn, solche &Auml;nderungen w&uuml;rden gegen diese Bedingungen oder die Bedingungen eines
                    solchen Dokuments versto&szlig;en;</p>

                <p>&nbsp;</p>

                <p>f. Im Falle von Abweichungen zwischen diesen Bedingungen (einschlie&szlig;lich aller hierin genannten
                    Dokumente) haben die Bestimmungen und Dokumente in folgender Reihenfolge Vorrang:</p>

                <p>&nbsp;</p>

                <p>I. Produktbedingungen;</p>

                <p>&nbsp;</p>

                <p>II. Datenschutzbestimmungen;</p>

                <p>&nbsp;</p>

                <p>III. diese Bedingungen.</p>

                <p>&nbsp;</p>

                <p>g. Sofern der Kontext nichts anderes erfordert, haben die folgenden Begriffe die folgenden
                    Bedeutungen:</p>

                <p>&nbsp;</p>

                <p>Zugangsdaten bezeichnen Ihre DuneEX-Kontodaten, Benutzernamen, Passw&ouml;rter, pers&ouml;nliche
                    Identifikationsnummern, API-Schl&uuml;ssel, API-Geheimschl&uuml;ssel oder andere Codes oder Formen
                    der Authentifizierung, die Sie zur Anmeldung bei Ihrem DuneEX-Konto oder den DuneEX-Diensten oder
                    zur Erteilung von Anweisungen verwenden.</p>

                <p>&nbsp;</p>

                <p>Konto-Historie bezeichnet schriftliche Aufzeichnungen (einschlie&szlig;lich elektronischer
                    Aufzeichnungen) Ihrer Transaktionen und Ihres DuneEX-Kontos.</p>

                <p>&nbsp;</p>

                <p>API bedeutet Programmierschnittstelle.</p>

                <p>&nbsp;</p>

                <p>Anwendbares Recht bezeichnet alle relevanten oder anwendbaren Gesetze (einschlie&szlig;lich der
                    Grunds&auml;tze des Common Law), Grunds&auml;tze der Gerechtigkeit, Regeln, Vorschriften,
                    regulatorischen Grunds&auml;tze und Anforderungen, Mitteilungen, Anordnungen, Direktiven,
                    Gerichtsbeschl&uuml;sse, untergeordnete Rechtsakte, Beschl&uuml;sse, Direktiven, offizielle
                    Ank&uuml;ndigungen, Rundschreiben, verbindliche Verhaltenskodizes, Leitlinien, Praxisanleitungen und
                    Auslegungen (ob von einer staatlichen, regulatorischen oder anderen Beh&ouml;rde oder einer
                    selbstregulierenden Organisation, deren Mitglied DuneEX ist), die auf die Bereitstellung, den
                    Empfang oder die Nutzung der DuneEX-Dienste oder sonstiger Produkte oder Ergebnisse anwendbar sind,
                    die im Zusammenhang mit den DuneEX-Diensten, diesen Bedingungen oder etwaigen Produktbedingungen
                    bereitgestellt, verwendet oder erhalten werden.</p>

                <p>&nbsp;</p>

                <p>Gesicherte digitale Verm&ouml;genswerte haben die Bedeutung gem&auml;&szlig; Abschnitt 15.3.</p>

                <p>&nbsp;</p>

                <p>DuneEX, wir, unser oder uns bedeutet Nest Services Limited (ein Unternehmen, das gem&auml;&szlig; den
                    Gesetzen der Republik Seychellen unter der Registrierungsnummer 238045 registriert ist und seinen
                    Gesch&auml;ftssitz hat unter: House of Francis, Room 303, Ile Du Port, Mahe, Seychellen).</p>

                <p>&nbsp;</p>

                <p>DuneEX-Konto bedeutet alle Konten (einschlie&szlig;lich Corporate DuneEX-Konten) oder Unterkonten
                    (einschlie&szlig;lich aller DuneEX-Unterkonten), die von DuneEX f&uuml;r Sie zur Erfassung Ihrer
                    Nutzung der DuneEX-Dienste er&ouml;ffnet wurden.</p>

                <p>&nbsp;</p>

                <p>DuneEX-Partner bedeutet in Bezug auf Nest Services Limited jede andere Person, die direkt oder
                    indirekt kontrolliert wird, die Kontrolle aus&uuml;bt oder unter gemeinsamer Kontrolle mit Nest
                    Services Limited steht.</p>

                <p>&nbsp;</p>

                <p>DuneEX-API bedeutet eine API, die Ihnen von einem DuneEX-Partner als Dienstleistung oder von
                    Drittanbieteranwendungen mit einer solchen API zur Verf&uuml;gung gestellt wird.</p>

                <p>&nbsp;</p>

                <p>DuneEX-IP bedeutet geistige Eigentumsrechte, die uns geh&ouml;ren oder die wir auf sublicenzierbarer
                    Basis gem&auml;&szlig; dem Stand dieser Bedingungen lizenziert haben, sowie alle anderen geistigen
                    Eigentumsrechte, die uns geh&ouml;ren, die wir erworben haben oder die wir auf sublicenzierter Basis
                    nach dem Stand dieser Bedingungen lizenziert haben, und die Ihnen im Rahmen der Erbringung der
                    DuneEX-Dienste bereitgestellt werden.</p>

                <p>&nbsp;</p>

                <p>DuneEX-Dienste bezeichnen die von uns Ihnen &uuml;ber die Plattform angebotenen Dienste.</p>

                <p>&nbsp;</p>

                <p>DuneEX-Unterkonto bezeichnet ein innerhalb des Haupt-DuneEX-Kontos erstelltes Unterkonto.</p>

                <p>&nbsp;</p>

                <p>Chat-Service bedeutet den Wert gem&auml;&szlig; Punkt 5.</p>

                <p>&nbsp;</p>

                <p>Anspruch bedeutet jegliche Streitigkeiten, Anspr&uuml;che, Differenzen oder Meinungsverschiedenheiten
                    zwischen Ihnen und DuneEX (und/oder etwaigen DuneEX-Partnern), die sich aus, in Verbindung mit oder
                    in irgendeiner Weise auf Folgendes beziehen:</p>

                <p>&nbsp;</p>

                <p>(a) diese Bedingungen oder etwaige Produktbedingungen, einschlie&szlig;lich der Existenz,
                    G&uuml;ltigkeit, Gegenstand, Auslegung, Erf&uuml;llung, Verletzung, Beendigung, Durchsetzbarkeit
                    oder Folgen der Ung&uuml;ltigkeit solcher Bedingungen;</p>

                <p>&nbsp;</p>

                <p>(b) Ihre Beziehung zu DuneEX (und/oder DuneEX-Partnern) als Benutzer (unabh&auml;ngig davon, ob sie
                    auf Vertrag, Delikt, Gesetz, Betrug, T&auml;uschung oder einer anderen Rechtsnorm beruhen, und
                    unabh&auml;ngig davon, ob Anspr&uuml;che w&auml;hrend oder nach der Beendigung dieser Bedingungen
                    entstehen); oder</p>

                <p>&nbsp;</p>

                <p>(c) alle au&szlig;ervertraglichen Verpflichtungen, die sich aus oder im Zusammenhang mit diesen
                    Bedingungen, etwaigen Produktbedingungen oder Ihrer Beziehung zu DuneEX (und/oder etwaigen
                    DuneEX-Partnern) ergeben.</p>

                <p>&nbsp;</p>

                <p>Kontrolle bedeutet die Befugnis einer Person, die Angelegenheiten einer anderen Person
                    gem&auml;&szlig; den W&uuml;nschen der ersten Person zu f&uuml;hren, sei es durch:</p>

                <p>&nbsp;</p>

                <p>(a) im Fall einer Gesellschaft der wirtschaftlich Berechtigte zu sein, der mehr als f&uuml;nfzig
                    Prozent (50%) des ausgegebenen Aktienkapitals oder der Stimmrechte dieser Gesellschaft besitzt oder
                    das Recht hat, die Mehrheit der Direktoren zu bestellen und abzuberufen oder anderweitig die Stimmen
                    bei Sitzungen des Vorstands dieser Gesellschaft zu kontrollieren aufgrund von Befugnissen, die durch
                    die Organisationsdokumente, Aktion&auml;rsvereinbarung, die Mehrheit der Stimmen des Vorstands oder
                    ein anderes Dokument, das die T&auml;tigkeit dieser Gesellschaft regelt, gew&auml;hrt werden, oder
                    auf andere Weise; oder</p>

                <p>&nbsp;</p>

                <p>(b) im Fall einer Partnerschaft der wirtschaftlich Berechtigte zu sein, der mehr als f&uuml;nfzig
                    Prozent (50%) des Kapitals dieser Partnerschaft besitzt oder das Recht hat, die Zusammensetzung oder
                    Stimmenmehrheit des Managements dieser Partnerschaft zu kontrollieren aufgrund von Befugnissen, die
                    durch die Partnerschaftsvereinbarung oder ein anderes Dokument, das die Angelegenheiten dieser
                    Partnerschaft regelt, gew&auml;hrt werden, oder auf andere Weise.</p>

                <p>&nbsp;</p>

                <p>Corporate DuneEX Account bedeutet ein DuneEX-Konto, das einer K&ouml;rperschaft, juristischen Person
                    oder einer anderen Organisation den Zugang zu DuneEX-Diensten erm&ouml;glicht.</p>

                <p>&nbsp;</p>

                <p>Erstellte IP bedeutet alle geistigen Eigentumsrechte, die von Ihnen gem&auml;&szlig; diesen
                    Bedingungen erstellt wurden, einschlie&szlig;lich Benutzermaterialien, aber unter Ausschluss eines
                    anderen Benutzer-IP.</p>

                <p>&nbsp;</p>

                <p>Digitale Assets bedeuten eine digitale Darstellung von Wert oder Rechten, die elektronisch unter
                    Verwendung der verteilten Hauptbuchtechnologie oder &auml;hnlicher Technologien &uuml;bertragen und
                    gespeichert werden k&ouml;nnen, einschlie&szlig;lich unter anderem Kryptow&auml;hrungen,
                    Stablecoins, nicht fungible Token und tokenisierte Derivate anderer digitaler Assets.</p>

                <p>&nbsp;</p>

                <p>Dominanter Digitaler Verm&ouml;genswert bedeutet ein geforkter digitaler Verm&ouml;genswert,
                    der &uuml;ber einer oder mehreren anderen Versionen des mit dem entsprechenden Fork verbundenen
                    Digitalen Verm&ouml;genswerts dominiert, wie von uns nach unserem Ermessen festgelegt.</p>

                <p>&nbsp;</p>

                <p>E-Mail bedeutet E-Mail(s), die mit Ihrem DuneEX-Konto/-Konten verbunden sind, wie periodisch mit
                    DuneEX vereinbart, gem&auml;&szlig; den von DuneEX definierten Prozessen bei der Nutzung der
                    Plattform.</p>

                <p>&nbsp;</p>

                <p>Fiat-W&auml;hrung bedeutet eine von einer Regierung oder Zentralbank ausgegebene nationale oder
                    supranationale W&auml;hrung oder sonstige monet&auml;re Verpflichtung, die in dieser W&auml;hrung
                    ausgedr&uuml;ckt wird und kein Digitaler Verm&ouml;genswert ist.</p>

                <p>&nbsp;</p>

                <p>H&ouml;here Gewalt bedeutet:</p>

                <p>&nbsp;</p>

                <p>(a) jedes Feuer, Streik, Unruhen, zivile Unruhen, terroristische Handlung, Krieg oder industrielle
                    Konflikte;</p>

                <p>&nbsp;</p>

                <p>(b) Naturkatastrophen wie &Uuml;berschwemmungen, Tornados, Erdbeben und Hurrikane;</p>

                <p>&nbsp;</p>

                <p>(c) jede Epidemie, Pandemie oder gesundheitliche Notlage von nationaler oder internationaler
                    Bedeutung;</p>

                <p>&nbsp;</p>

                <p>(d) jede Handlung oder Verordnung einer Regierung, &uuml;bernationalen Organisation oder Institution,
                    die unserer Meinung nach die Bereitstellung von DuneEX-Diensten auf der Plattform verhindert;</p>

                <p>&nbsp;</p>

                <p>(e) Aussetzung oder Schlie&szlig;ung eines beliebigen DuneEX-Partners;</p>

                <p>&nbsp;</p>

                <p>(f) Verstaatlichung eines beliebigen DuneEX-Partners;</p>

                <p>&nbsp;</p>

                <p>(g) Verh&auml;ngung von Beschr&auml;nkungen oder ungew&ouml;hnlichen Bedingungen durch die Regierung
                    f&uuml;r alle auf unserer Plattform gehandelten Digitalen Assets;</p>

                <p>&nbsp;</p>

                <p>(h) &uuml;berm&auml;&szlig;ige Ver&auml;nderungen im Preis, Angebot oder Nachfrage eines beliebigen
                    Digitalen Verm&ouml;genswerts;</p>

                <p>&nbsp;</p>

                <p>(i) technische Ausf&auml;lle in Informations&uuml;bertragungsmitteln, Kommunikationsmitteln oder
                    Computerger&auml;ten, einschlie&szlig;lich Stromausf&auml;lle und Ausf&auml;lle im Betrieb von
                    Elektronik oder Ausr&uuml;stung;</p>

                <p>&nbsp;</p>

                <p>(j) Unf&auml;higkeit eines beliebigen Liquidit&auml;tsanbieters, Makler-Dealers, Agenten,
                    prim&auml;ren Depotverwahrers, Sub-Depotverwahrers, H&auml;ndlers, B&ouml;rsen, Staking-Plattformen,
                    Liquidit&auml;tspools, Br&uuml;ckenprovider, gesicherten digitalen Verm&ouml;genswerts, Market
                    Makers, Clearingstellen oder Regulierungsorganisationen, ihre Verpflichtungen uns gegen&uuml;ber zu
                    erf&uuml;llen;</p>

                <p>&nbsp;</p>

                <p>(k) Liquidit&auml;tsanbieter, die uns keine Liquidit&auml;t zur Verf&uuml;gung stellen oder nicht
                    k&ouml;nnen;</p>

                <p>&nbsp;</p>

                <p>(l) Arbeits- oder Handelsstreitigkeiten, Streiks, industrielle Konflikte oder Aussperrungen
                    (au&szlig;er wenn diese von DuneEX oder DuneEX-Partnern initiiert werden); und/oder</p>

                <p>&nbsp;</p>

                <p>(m) ein Ereignis, das den Markt f&uuml;r Digitale Assets erheblich beeinflusst,
                    einschlie&szlig;lich &uuml;berm&auml;&szlig;iger Ver&auml;nderungen im Preis, Angebot oder Nachfrage
                    eines Digitalen Verm&ouml;genswerts, sowohl reguliert als auch unreguliert.</p>

                <p>&nbsp;</p>

                <p>&nbsp;</p>

                <p>Fork bedeutet jegliche geplante, ungeplante, pl&ouml;tzliche, festgelegte, erwartete, unerwartete,
                    offengelegte, wenig bekannte, vereinbarte und/oder umstrittene &Auml;nderungen der grundlegenden
                    Betriebsregeln bestimmter Digitaler Verm&ouml;genswerte, die periodisch auf solche Weise auftreten
                    k&ouml;nnen, dass sie zur Schaffung einer oder mehrerer verwandter Versionen des bestehenden
                    Digitalen Verm&ouml;genswerts f&uuml;hren.</p>

                <p>&nbsp;</p>

                <p>Vergangener Fork eines digitalen Verm&ouml;genswerts bedeutet ein Digitaler Verm&ouml;genswert, der
                    als Ergebnis eines Forks entstanden ist.</p>

                <p>&nbsp;</p>

                <p>Anweisung bedeutet jede Anweisung, Anfrage oder Bestellung, die von Ihnen oder einem von DuneEX
                    autorisierten Benutzer im Zusammenhang mit dem Betrieb Ihres DuneEX-Kontos oder zur
                    Durchf&uuml;hrung einer Transaktion &uuml;bermittelt wird, durch ein solches Medium, in einer
                    solchen Form und auf eine solche Weise, wie es von DuneEX erforderlich sein kann.</p>

                <p>&nbsp;</p>

                <p>Geistiges Eigentum umfasst (a) Urheberrechte, Patente, Datenbankrechte und Markenrechte, Designs,
                    Know-how und vertrauliche Informationen (ob registriert oder nicht registriert); (b) Antr&auml;ge
                    auf Registrierung und Rechte zur Einreichung von Antr&auml;gen auf Registrierung f&uuml;r eines der
                    oben genannten Rechte; und (c) alle anderen geistigen Eigentumsrechte und &auml;quivalente
                    oder &auml;hnliche Formen des Schutzes, die an irgendeinem Ort der Welt bestehen.</p>

                <p>&nbsp;</p>

                <p>Lokale Bedingungen beziehen sich auf die Bedingungen, die Ihre Nutzung der von DuneEX in einer
                    bestimmten Gerichtsbarkeit angebotenen Dienste regeln.</p>

                <p>&nbsp;</p>

                <p>Verluste haben die Bedeutung, wie in Abschnitt 29.1 definiert.</p>

                <p>&nbsp;</p>

                <p>Offensichtlicher Fehler bedeutet jeden Fehler, Auslassung oder jede fehlerhafte Zitierung (sei es
                    durch DuneEX oder Dritte), der offensichtlich oder deutlich ist, einschlie&szlig;lich einer
                    fehlerhaften Zitierung durch einen Vertreter von DuneEX unter Ber&uuml;cksichtigung der aktuellen
                    Marktbedingungen oder eines Fehlers in Informationen, Quellen, offiziellen Ergebnissen oder
                    Aussprachen.</p>

                <p>&nbsp;</p>

                <p>Autorisierter Benutzer bezeichnet jede Person, die von Ihnen identifiziert und
                    gem&auml;&szlig; diesen Bedingungen an uns gemeldet wurde und befugt ist, im Namen des Benutzers in
                    Bezug auf ein beliebiges DuneEX Corporate Account zu handeln.</p>

                <p>&nbsp;</p>

                <p>Plattform bezeichnet die digitale Plattform, die wir oder ein DuneEX-Partner &uuml;ber Websites,
                    Mobile App, DuneEX-API oder andere Mittel, die DuneEX-Partner periodisch vorschreiben k&ouml;nnen,
                    zur Nutzung von DuneEX-Diensten bereitstellen.</p>

                <p>&nbsp;</p>

                <p>Datenschutzhinweis bezeichnet den Datenschutzhinweis unter https://www.dune-ex.net/de/privacy.</p>

                <p>&nbsp;</p>

                <p>Produktbedingungen bezeichnen die Bedingungen und Konditionen f&uuml;r ein bestimmtes Produkt, die
                    neben diesen Bedingungen f&uuml;r die Nutzung von DuneEX-Diensten gelten.</p>

                <p>&nbsp;</p>

                <p>Regulierungsbeh&ouml;rde bezeichnet jede ausl&auml;ndische, nationale, staatliche, f&ouml;derale,
                    kantonale, kommunale oder lokale Regierung, Exekutive, Legislative, Judikative, Verwaltungs-,
                    Aufsichts- oder Regulierungsbeh&ouml;rde, Agentur, Quasi-Staatsorgan, Gericht, Kommission,
                    Regierungsorganisation, selbstregulierende Organisation mit Regulierungsbefugnissen, Tribunal,
                    Schiedsgericht, Schiedskommission oder &uuml;bernationale Organisation oder jede ihrer
                    Untergliederungen oder Organe, einschlie&szlig;lich einer Steuerbeh&ouml;rde.</p>

                <p>&nbsp;</p>

                <p>Eingeschr&auml;nkte Person bezeichnet jede nat&uuml;rliche oder juristische Person, die (a) in eine
                    Handelsembargo oder Wirtschaftssanktionen, eine Terroristenliste oder eine Liste korrupter
                    ausl&auml;ndischer Amtstr&auml;ger aufgenommen ist (z. B. UN-Sanktionsliste des Sicherheitsrats,
                    herausgegeben von einer Regierungsbeh&ouml;rde, einschlie&szlig;lich der Liste der besonders
                    ausgewiesenen Personen und gesperrten Personen des Office of Foreign Assets Control (OFAC) des
                    US-Finanzministeriums oder der Liste der gesperrten Personen und Organisationen des
                    US-Handelsministeriums sowie der Listen des Vereinigten K&ouml;nigreichs, der Europ&auml;ischen
                    Union und Kanadas)</p>

                <p>&nbsp;</p>

                <p>Risikowarnung bedeutet eine allgemeine Risikowarnung, die auf der Website ver&ouml;ffentlicht
                    wird.</p>

                <p>&nbsp;</p>

                <p>Sicherheitsverletzung bedeutet</p>

                <p>&nbsp;</p>

                <p>a. das Hacken Ihres DuneEX-Kontos oder Ihrer Zugriffsdaten;</p>

                <p>&nbsp;</p>

                <p>b. Verlust, Diebstahl, unbefugte Nutzung Ihrer Zugriffsdaten oder unbefugter Zugriff und Nutzung
                    Ihres DuneEX-Kontos oder der DuneEX-Dienste in Ihrem Namen; oder</p>

                <p>&nbsp;</p>

                <p>c. jedes andere Sicherheitsereignis (einschlie&szlig;lich eines Cyberangriffs), das Sie und/oder
                    DuneEX betrifft.</p>

                <p>&nbsp;</p>

                <p>Websites bezeichnen unsere Website und alle anderen Websites, Seiten, Funktionen oder Inhalte, die
                    wir besitzen oder betreiben.</p>

                <p>&nbsp;</p>

                <p>Unterst&uuml;tzte digitale Verm&ouml;genswerte bezeichnen diejenigen digitalen Verm&ouml;genswerte,
                    die im Zusammenhang mit Ihrer Nutzung von DuneEX-Diensten verf&uuml;gbar sind.</p>

                <p>&nbsp;</p>

                <p>Bedingungen bezeichnen (bei jeder Erw&auml;hnung sowie bei periodischen &Auml;nderungen oder
                    Erg&auml;nzungen) diese Nutzungsbedingungen sowie alle anderen ausdr&uuml;cklich genannten
                    Dokumente, einschlie&szlig;lich Produktbedingungen.</p>

                <p>&nbsp;</p>

                <p>Marken bezeichnen geistiges Eigentum an Marken, Dienstleistungsmarken und Logos, die auf der
                    Plattform, den Websites und/oder DuneEX-Diensten verwendet und angezeigt werden oder &uuml;ber
                    diese.</p>

                <p>&nbsp;</p>

                <p>Transaktion bedeutet den Verkauf, Kauf oder Abschluss eines beliebigen anderen Typs von Transaktionen
                    oder die Zustimmung zum Verkauf, Kauf oder Abschluss eines beliebigen anderen Typs von Transaktionen
                    mit Digital Asset(s), deren Derivaten, anderen Asset(s) oder Produkt(en), die DuneEX periodisch auf
                    der Plattform durchf&uuml;hren kann, sowie die Einzahlung oder Auszahlung von Digital Assets auf Ihr
                    DuneEX-Konto oder von diesem.</p>

                <p>&nbsp;</p>

                <p>Benutzer-IP bedeutet geistiges Eigentum, das Ihnen geh&ouml;rt oder das Ihnen zum Zeitpunkt dieser
                    Bedingungen lizenziert ist, sowie jedes andere geistige Eigentum, das Ihnen geh&ouml;rt oder das Sie
                    nach dem Datum dieser Bedingungen erworben oder lizenziert haben, mit Ausnahme von DuneEX IP.</p>

                <p>&nbsp;</p>

                <p>Benutzer-Materialien bedeutet geistiges Eigentum in Form von Bewertungen, Ver&ouml;ffentlichungen,
                    Informationen, Daten und Kommentaren, die Sie oder andere Benutzer uns auf den Websites (&uuml;ber
                    unsere &quot;Kontaktieren Sie uns&quot;-Seiten oder anderweitig), der Plattform, durch die Nutzung
                    von DuneEX-Diensten oder anderweitig zur Verf&uuml;gung stellen.</p>

                <p><br/>
                    Website bedeutet die Website unter<a href="http://www.dune-ex.net"> www.dune-ex.net</a>.</p>

                <p>&nbsp;</p>
            </div> : ""}
        </div>
        </div>
    );
};

export default Privacy;