import React, {useEffect, useState} from 'react';
import './landing.css'
import {NavLink} from "react-router-dom";
import phone from '../images/Background.png'
import cards from '../images/Cards.png'
import cardsEn from '../images/CardsEn.png'
import cardsDe from '../images/CardsDe.png'
import safe from '../images/safe.png'
import partner from '../images/partner.png'
import service from '../images/service.png'
import arrowDB from '../images/arrowDB.svg'
import twu from '../images/tradewithustradewithus.png'

import btcicon from '../images/btcicon.png'
import ethicon from '../images/ethicon.png'
import cardanoicon from '../images/cardanoicon.png'
import dashicon from '../images/dashicon.png'
import doticon from '../images/doticon.png'

import axios from "axios";
import {useTranslation} from "react-i18next";

const Landing = () => {
    const { t, i18n} = useTranslation();
    let currentLanguage = i18n.language
    const [emailFromLanding, setEmailFromLanding] = useState("");
    const [emailFromLanding2, setEmailFromLanding2] = useState("");
    const emailFromLandingHandler = e => {
        setEmailFromLanding(e.target.value)
    }
    const emailFromLanding2Handler = e => {
        setEmailFromLanding2(e.target.value)
    }
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const [pair1, setPair1] = useState()
    const [pair2, setPair2] = useState()
    const [pair3, setPair3] = useState()
    const [pair4, setPair4] = useState()
    const [pair5, setPair5] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const requireData = () => {
        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=BTCUSDT`)
            .then(res => {
                setPair1(res.data);
                axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=ETHUSDT`)
                    .then(res => {
                        setPair2(res.data);
                        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=ADAUSDT`)
                            .then(res => {
                                setPair3(res.data);
                                axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=DASHUSDT`)
                                    .then(res => {
                                        setPair4(res.data);
                                        axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=DOTUSDT`)
                                            .then(res => {
                                                setPair5(res.data);
                                                setIsLoaded(true)
                                            })
                                    })

                            })

                    })

            })

    }

    const [selectedItem, setSelectedItem] = useState("1");

    useEffect(() => {
        requireData()
    }, [])
    const isLoggedIn = localStorage.getItem("isLoggedIn")

    function handleClickSelect(s) {
        const selectedElem = document.getElementById(`sel${s}`)
        const sel1 = document.getElementById(`sel1`)
        const sel2 = document.getElementById(`sel2`)
        const sel3 = document.getElementById(`sel3`)
        sel1.classList.remove("l-select-item-selected")
        sel2.classList.remove("l-select-item-selected")
        sel3.classList.remove("l-select-item-selected")
        selectedElem.classList.add("l-select-item-selected")
        setSelectedItem(s)
    }

    return (
        <div className="l-body">
            <div className="l-block1">
                <div className="l-block-1-1-1">
                    <div className="l-block1-1">
                        <h1 className="l-title">{t("main_page.join_trade_earn.join")}</h1>
                        <h1 className="l-title">{t("main_page.join_trade_earn.trade")}</h1>
                        <h1 className="l-title">{t("main_page.join_trade_earn.earn")}</h1>

                        {isLoggedIn ? "" : (
                            <div className="l-block5-form">
                                <input
                                    type="text"
                                    className="land_sinput"
                                    value={emailFromLanding2}
                                    onChange={emailFromLanding2Handler}
                                    placeholder={t("main_page.email")}
                                />
                                <NavLink
                                    to={`/register?emailFromLanding=${emailFromLanding2}`}
                                    className="start-btn2"
                                >
                                    {t("main_page.sign_up_button")}
                                </NavLink>
                            </div>
                        )}
                    </div>
                    <img src={phone} className="phonem" alt=""/>
                </div>
            </div>
            {currentLanguage === "ru" ? <img className="wf75" src={cards} alt=""/>: "" }
            {currentLanguage === "en" ? <img className="wf75" src={cardsEn} alt=""/>: "" }
            {currentLanguage === "de" ? <img className="wf75" src={cardsDe} alt=""/>: "" }

            <div className="l-block6">
                <h1 className="l-block3__title">{t("main_page.markets.title")}</h1>
                {isLoaded ? (
                    <div className="l-graph__container">
                        <div className="l-graph__item border-none">
                            <div className="l-graph__title">{t("main_page.markets.trading_pair")}</div>
                            <div className="l-graph__coin">{t("main_page.markets.price")}</div>
                            <div className={`l-graph__percent `}>{t("main_page.markets.change")}</div>
                            <div className="l-graph__link opacity-0">------------------</div>
                        </div>

                        <div className="l-graph__item">
                            <div className="l-graph__title"><img src={btcicon} alt="" className="crypto-icon"/> Bitcoin
                            </div>
                            <div className="l-graph__coin">${Math.floor10(pair1.lastPrice, -1)}</div>
                            <div
                                className={`l-graph__percent ${pair1.priceChangePercent > 0 ? "text-success" : "text-error"}`}>
                                {Math.floor10(pair1.priceChangePercent, -2)}%
                            </div>
                            <NavLink to="/market?symbol=ADAUSDT" className="l-graph__link">
                                {t("main_page.markets.trade")}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow"
                                          stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                </svg>
                            </NavLink>
                        </div>

                        <div className="l-graph__item">
                            <div className="l-graph__title"><img src={ethicon} alt="" className="crypto-icon"/>Ethereum
                            </div>
                            <div className="l-graph__coin">${Math.floor10(pair2.lastPrice, -2)}</div>
                            <div
                                className={`l-graph__percent ${pair2.priceChangePercent > 0 ? "text-success" : "text-error"}`}>
                                {Math.floor10(pair2.priceChangePercent, -2)}%
                            </div>
                            <NavLink to="/market?symbol=DASHUSDT" className="l-graph__link">
                                {t("main_page.markets.trade")}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow"
                                          stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                </svg>
                            </NavLink>
                        </div>

                        <div className="l-graph__item">
                            <div className="l-graph__title"><img src={cardanoicon} alt="" className="crypto-icon"/>Cardano
                            </div>
                            <div className="l-graph__coin">${Math.floor10(pair3.lastPrice, -4)}</div>
                            <div
                                className={`l-graph__percent ${pair3.priceChangePercent > 0 ? "text-success" : "text-error"}`}>
                                {Math.floor10(pair3.priceChangePercent, -2)}%
                            </div>
                            <NavLink to="/market?symbol=ADAUSDT" className="l-graph__link">
                                {t("main_page.markets.trade")}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow"
                                          stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                </svg>
                            </NavLink>
                        </div>

                        <div className="l-graph__item">
                            <div className="l-graph__title"><img src={dashicon} alt="" className="crypto-icon"/>Dash
                            </div>
                            <div className="l-graph__coin">${Math.floor10(pair4.lastPrice, -3)}</div>
                            <div
                                className={`l-graph__percent ${pair4.priceChangePercent > 0 ? "text-success" : "text-error"}`}>
                                {Math.floor10(pair4.priceChangePercent, -2)}%
                            </div>
                            <NavLink to="/market?symbol=DASHUSDT" className="l-graph__link">
                                {t("main_page.markets.trade")}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow"
                                          stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                </svg>
                            </NavLink>
                        </div>

                        <div className="l-graph__item">
                            <div className="l-graph__title"><img src={doticon} alt="" className="crypto-icon"/>Polkadot
                            </div>
                            <div className="l-graph__coin">${Math.floor10(pair5.lastPrice, -4)}</div>
                            <div
                                className={`l-graph__percent ${pair5.priceChangePercent > 0 ? "text-success" : "text-error"}`}>
                                {Math.floor10(pair5.priceChangePercent, -2)}%
                            </div>
                            <NavLink to="/market?symbol=DOTUSDT" className="l-graph__link">
                                {t("main_page.markets.trade")}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12" className="l-graph-arrow"
                                          stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                </svg>
                            </NavLink>
                        </div>
                    </div>
                ) : ""}
            </div>

            <h1 className="l-dbr-title">{t("main_page.why_choose_dune_ex.title")}</h1>
            <div className="l-dbr-title-bottom"></div>

            <div className="l-default-block">
                <div className="l-default-block-child">
                    <h2 className="db-child-title"><img src={arrowDB}
                                                        alt=""/> {t("main_page.why_choose_dune_ex.secure_platform")}
                    </h2>
                    <p className="db-child-text">{t("main_page.why_choose_dune_ex.secure_description")}</p>
                </div>
                <img src={safe} className="db-image" alt=""/>
            </div>

            <div className="l-default-block">
                <img src={partner} className="db-image" alt=""/>
                <div className="l-default-block-child dbc-sec">
                    <h2 className="db-child-title"><img src={arrowDB}
                                                        alt=""/> {t("main_page.why_choose_dune_ex.strategic_partner")}
                    </h2>
                    <p className="db-child-text">{t("main_page.why_choose_dune_ex.strategic_description")}</p>
                </div>
            </div>

            <div className="l-default-block">
                <div className="l-default-block-child">
                    <h2 className="db-child-title"><img src={arrowDB}
                                                        alt=""/> {t("main_page.why_choose_dune_ex.unified_service")}
                    </h2>
                    <p className="db-child-text">{t("main_page.why_choose_dune_ex.unified_description")}</p>
                </div>
                <img src={service} className="db-image" alt=""/>
            </div>

            <h1 className="l-dbr-title">{t("main_page.why_choose_dune_ex.trade_with_dune_ex")}</h1>
            <div className="l-dbr-title-bottom"></div>

            <div className="l-last-block">
                <img src={twu} className="db-twu-image" alt=""/>
                <div className="l-default-block-child dbc-sec just-last">
                    <p>{t("main_page.why_choose_dune_ex.secure_description")}</p>
                    <p>{t("main_page.why_choose_dune_ex.strategic_description")}</p>
                    <p>{t("main_page.why_choose_dune_ex.unified_description")}</p>
                    <p>{t("main_page.why_choose_dune_ex.trade_description")}</p>
                </div>
            </div>

        </div>

    );
};

export default Landing;