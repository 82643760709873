import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import './logout.sass';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";
import money from './verstka(temp)/img/money.svg'
import logo from '../images/logo.svg'
import LogoutIcon from '@mui/icons-material/Logout';
import {grey} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import LanguageIcon from '@mui/icons-material/Language';
import Popper from '@mui/material/Popper';
import engicon from "../images/engIcon.svg"
import {tr} from "date-fns/locale";
const Header = (props) => {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("selected_lang", lng)
    };

    const pathName =  useLocation().pathname
    console.log(pathName)


    const [marketInfo, setMarketInfo] = useState([]);
    const url1 = new URL(document.location.href);
    let symbolURL1 = (url1.searchParams.get('symbol') || "BTCUSDT");
    let symbolDivided = ""
    const PairMatch = () => {
        let symbolLength = `${symbolURL1}`.length;
        if (symbolLength === 7) {
            symbolDivided = symbolURL1[0] + symbolURL1[1] + symbolURL1[2] + '/USDT'}

        else {
                symbolDivided = symbolURL1[0] + symbolURL1[1] + symbolURL1[2] + symbolURL1[3] + '/USDT';
            }
        }
    if (!symbolURL1) {
    }
    else{
        PairMatch()
    }
    const requireDataMarket = () => axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbolURL1}`)
        .then(res => {
            setMarketInfo(res.data);
            setTimeout(() => requireDataMarket(), 2000)
        })

    useEffect(() => {
        requireDataMarket();
    }, []);
    let priceChangePercent = marketInfo.priceChangePercent;
    function colorChange(priceChangePercent) {
        if (priceChangePercent > 0) {
            return "success-text";
        }

        else {
            return "text-danger";
        }
    }
    const urlId = localStorage.getItem('userLogin');
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const [UserName, setUserName] = useState(localStorage.getItem('userName'))
    const [UserSurname, setUserSurname] = useState(localStorage.getItem('userSurname'))
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail'))

    useEffect(() =>{
        setTimeout(setUserName (localStorage.getItem('userName')), 1000)
        setTimeout(setUserSurname(localStorage.getItem('userSurname')), 1000)
        setTimeout(setUserEmail(localStorage.getItem('userEmail')), 1000)
    },[isLoggedIn])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickLang = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);


    return (<>

        <header className="header">
            <div className="header__container">
                <div className="header__top">
                    <NavLink to="/main"><a href="" className="header__logo">
                        <img src={logo} style={{marginLeft: 20}} alt=""/>
                    </a></NavLink>


                    {isLoggedIn ? (
                            <div className="header-center">
                                <NavLink to="/account/deposit"
                                         className={`header_link ${pathName == "/account/deposit" ? "hl_active" : ""}`}>{t("logged_in_header.deposit")}</NavLink>
                                <NavLink to="/market?symbol=BTCUSDT"
                                         className={`header_link ${pathName === "/market" || pathName === "/market-resources" || pathName === "/market-stocks" ? "hl_active" : ""}`}>{t("logged_in_header.market")}</NavLink>
                                <NavLink to="/account/orders"
                                         className={`header_link ${pathName == "/account/orders" ? "hl_active" : ""}`}>{t("logged_in_header.orders")}</NavLink>
                                <NavLink to="/account"
                                         className={`header_link ${pathName == "/account" ? "hl_active" : ""}`}>{t("logged_in_header.wallet")}</NavLink>
                                <NavLink to="/account/wallet"
                                             className={`header_link ${pathName == "/account/wallet" ? "hl_active" : ""}`}>{t("wallet_page.conversion")}</NavLink>
                            </div>

                        )
                        : ""}
                        <div>
                    {isLoggedIn ? (
                            <div className="header__indi-box">
                                <div className="header__indi">
                                    <NavLink to="/account">
                                        <AccountCircleOutlinedIcon sx={{color: grey[50], width: 25, height: 25}}/>
                                    </NavLink>
                                </div>
                                <div className="header__indi" onClick={props.logOut}>
                                    <a href="">
                                        <LogoutIcon sx={{color: grey[50], width: 25, height: 25}}/>
                                    </a>
                                </div>

                                <button aria-describedby="lang1" onClick={handleClickLang}
                                        className="">
                                    <LanguageIcon sx={{color: grey[50], width: 25, height: 25}}/>
                                </button>

                            </div>
                        )
                        : (
                            <div className="header-right">
                                <NavLink to="/authorization" className="header_link">
                                    {t("header_not_logged_in.sign_in")}
                                </NavLink>

                                <NavLink to="/register" className="header_regisrer_button">
                                    {t("header_not_logged_in.sign_up")}
                                </NavLink>


                                <button aria-describedby="lang1" onClick={handleClickLang}
                                        className="">
                                    <LanguageIcon sx={{color: grey[50], width: 25, height: 25}}/>
                                </button>

                            </div>
                        )}
                            <Popper
                                id="lang1"
                                open={open} anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <div className="popover-lang">
                                    <button className="lang-btn" onClick={() => changeLanguage('en')}>
                                        <img className="lang-icon" src={engicon} alt=""/>
                                        English
                                    </button>
                                    <button className="lang-btn" onClick={() => changeLanguage('ru')}>
                                        <svg width="1000" height="600" viewBox="0 0 1000 600" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect width="1000" height="200" fill="white"/>
                                            <rect y="200" width="1000" height="200" fill="#0031DE"/>
                                            <rect y="400" width="1000" height="200" fill="#DE0000"/>
                                        </svg>

                                        Русский

                                    </button>
                                    <button className="lang-btn" onClick={() => changeLanguage('de')}>
                                        <svg width="1000" height="600" viewBox="0 0 1000 600" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect width="1000" height="200" fill="black"/>
                                            <rect y="200" width="1000" height="200" fill="#DE0000"/>
                                            <rect y="400" width="1000" height="200" fill="#FFCF00"/>
                                        </svg>
                                        Deutsch
                                    </button>
                                </div>
                            </Popper>
                        </div>
                </div>
            </div>
        </header>

        </>
    )
        ;
};

export default Header;
