import React, {useEffect, useState} from 'react';
import selfi from "./img/selfi.png";
import axios from "axios";
import "./css/kyc.css"

import FormData from "form-data"
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {grey, yellow} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
const Kyc = () => {
    const {t} = useTranslation()
    const [selectedOption, setSelectedOption] = useState("Национальный паспорт");
    const [series, setSeries] = useState('');
    const [fullName, setFullName] = useState('');
    const [backDocumentFile, setBackDocumentFile] = useState(null);
    const [frontDocumentFile, setFrontDocumentFile] = useState(null);
    const [selfieFile, setSelfieFile] = useState(null);
    const [placeHolder, setPlaceHolder] = useState("AA 1234567");
    const [kycStatus, setKycStatus] = useState("");




    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );


    const token = localStorage.getItem('token');


    const kysStatus = () => {
        axios.get("https://api.dune-ex.net/api/api/kyc/status", {
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        })
            .then(res => {
                setKycStatus(res.data)
                setTimeout(kysStatus, 5000)
            })
    }

    useEffect(() => {
        kysStatus()
    }, [])

    const form = new FormData();

    if (backDocumentFile) {
        form.append('files', backDocumentFile);
    }

    if (frontDocumentFile) {
        form.append('files', frontDocumentFile);
    }

    if (selfieFile) {
        form.append('files', selfieFile);
    }

// Выполняем POST-запрос с использованием Axios

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSeriesChange = (event) => {
        setSeries(event.target.value);
    };

    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleBackDocumentFileChange = (event) => {
        const file = event.target.files[0];
        setBackDocumentFile(file);
    };

    const handleFrontDocumentFileChange = (event) => {
        const file = event.target.files[0];
        setFrontDocumentFile(file);
    };

    const handleSelfieFileChange = (event) => {
        const file = event.target.files[0];
        setSelfieFile(file);
    };

    const headers = {
        'accept': 'application/json',
        "Authorization" : `Bearer ${token}`,
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: grey[50],
            },
        },
    });
    const handleSubmit = () => {

        axios.post('https://api.dune-ex.net/api/api/kyc/upload', form, {
            headers: headers
        }
        )
            .then(response => {
                axios.post('https://api.dune-ex.net/api/api/kyc/details', {
                    "document_type": selectedOption,
                    "passport_data": series,
                    "patronymic": fullName
                }, {headers: {"Authorization" : `Bearer ${token}`}})
                    .then(() => {

                        enqueueSnackbar(`Заявка отправленна!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                    })
            })
            .catch(error => {
                enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail}`, {action, 'variant': 'error', 'className': "snackerror"});
            });
    };

    useEffect(() => {
        if (selectedOption === "Национальный паспорт"){
            setPlaceHolder("1234 123456")
        }
        else if (selectedOption === "Заграничный паспорт"){
            setPlaceHolder("70 1234567")
        }
        else if (selectedOption === "Водительское удостоверение"){
            setPlaceHolder("70 70 123456")
        }
    }, [selectedOption])

    return (
        <div className="tabs__body">
            <div className={`deposit-popup-window ${kycStatus === "waitingwaiting" ? "" : "hidden"}`}>
                <div className="deposit-popup">
                    <p className="dp-pp-text">{t("kyc_verification_page.waiting_verification")}</p>
                    <ThemeProvider theme={theme}>
                        <CircularProgress/>
                    </ThemeProvider>
                </div>
            </div>
            <div className="account__box">
                <div className="kyc__box">
                    <div className="account__balans">{t("kyc_verification_page.title")}</div>
                    {kycStatus === "accepted" ? (
                        <p className="already">{t("kyc_verification_page.already_verified")}</p>
                    ) : kycStatus === "waiting" ? (
                        <div className="center-do">
                            <p className="dp-pp-text2">{t("kyc_verification_page.documents_sent")}</p>
                            <ThemeProvider theme={theme}>
                                <CircularProgress/>
                            </ThemeProvider>
                        </div>
                    ) : (
                        <>
                            <div className="kyc__tip">
                                <select
                                    name="form[]"
                                    className="select1"
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                >
                                    <option
                                        value="Национальный паспорт">{t("kyc_verification_page.national_id")}</option>
                                    <option
                                        value="Заграничный паспорт">{t("kyc_verification_page.foreign_passport")}</option>
                                    <option
                                        value="Водительское удостоверение">{t("kyc_verification_page.driving_license")}</option>
                                </select>
                            </div>
                            <div className="kyc__inputs">
                                <div className="kyc__item">
                                    <p>{t("kyc_verification_page.series")}</p>
                                    <input
                                        type="text"
                                        placeholder={placeHolder}
                                        value={series}
                                        onChange={handleSeriesChange}
                                    />
                                </div>
                                <div className="kyc__item">
                                    <p>{t("kyc_verification_page.full_name")}</p>
                                    <input
                                        type="text"
                                        placeholder="Иван Иванов"
                                        value={fullName}
                                        onChange={handleFullNameChange}
                                    />
                                </div>
                            </div>
                            <div className="kyc__block">
                                <div className="kyc__title2">{t("kyc_verification_page.upload_id")}</div>
                                <div className="kyc__box2">
                                    <div className="kyc__itemm">
                                        <p className="malina">{t("kyc_verification_page.back_side_document")}</p>
                                        <div className="kyc__b-item">
                                            <input
                                                type="file"
                                                name=""
                                                id=""
                                                onChange={handleBackDocumentFileChange}
                                            />
                                            <p className="kyc__name">
                                                {backDocumentFile ? backDocumentFile.name : t("kyc_verification_page.drag_or_find")}
                                            </p>
                                            <p className="kyc__sub">
                                                {t("kyc_verification_page.max_image_size")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kyc__itemm">
                                        <p className="malina">{t("kyc_verification_page.front_side_document")}</p>
                                        <div className="kyc__b-item">
                                            <input
                                                type="file"
                                                name=""
                                                id=""
                                                onChange={handleFrontDocumentFileChange}
                                            />
                                            <p className="kyc__name">
                                                {frontDocumentFile ? frontDocumentFile.name : t("kyc_verification_page.drag_or_find")}
                                            </p>
                                            <p className="kyc__sub">
                                                {t("kyc_verification_page.max_image_size")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kyc__itemm">
                                        <p className="malina">{t("kyc_verification_page.selfie_with_document")}</p>
                                        <div className="kyc__b-item">
                                            <input
                                                type="file"
                                                name=""
                                                id=""
                                                onChange={handleSelfieFileChange}
                                            />
                                            <img src={selfi} alt=""/>
                                            <p className="kyc__sub">
                                                {t("kyc_verification_page.max_image_size")}
                                            </p>
                                            <p className="kyc__name">
                                                {selfieFile ? selfieFile.name : t("kyc_verification_page.find_button")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lineeee"/>
                            <button className="btn56" onClick={handleSubmit}>
                                {t("kyc_verification_page.submit_button")}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>

    );
};

export default Kyc;
