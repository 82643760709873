import React, { useEffect, useState } from 'react';
import revert from "./img/revert.svg";
import btc from "./img/btc.svg";
import axios from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import Convertation from "../convertation/convertation";
import {useTranslation} from "react-i18next";

const Wallet = () => {
    const {t} = useTranslation()
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon />
        </button>
    );

    (function () {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }

        if (!Math.floor10) {
            Math.floor10 = function (value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();

    const [userData, setUserData] = useState([]);
    const [balance, setBalance] = useState("");
    const [balanceFirst, setBalanceFirst] = useState("");
    const [balanceSecond, setBalanceSecond] = useState("");

    const [valueDirty, setValueDirty] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [networkDirty, setNetworkDirty] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [valueCVError, setValueCVError] = useState(false);
    const [symbolSwap, setSymbolSwap] = useState('usdt');
    const [walletFrom, setWalletFrom] = useState('main');
    const [walletTo, setWalletTo] = useState('futures');
    const [valueConvert, setValueConvert] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);
    const [pair, setPair] = useState("GAZPRUB")
    const [convertData, setConvertData] = useState({})
    const [spotFormAmount, setSpotFormAmount] = useState("")
    const [spotFormTotal, setSpotFormTotal] = useState("")
    const [stockPrice, setStockPrice] = useState(0);
    const [balanceRub, setBalanceRub] = useState();
    const [balanceInv, setBalanceInv] = useState();
    const [isldbi, setisldbi] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [priceChange, setpc] = useState(0)
    const [priceChangePercent, setpcp] = useState(0)
    const [formValid, setFormValid] = useState(false);
    let match = pair.match(/(\w+)(USDT|RUB)/);

    const token = localStorage.getItem('token');

    const requireBalance = () => axios.get(
        `https://api.dune-ex.net/api/api/users/balance`,
        {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(res => {
            setBalance(res.data);
            setBalanceFirst(Math.floor10(res.data.btc, -6))
            setBalanceSecond(Math.floor10(res.data.usdt, -6))
        })
    const requireUserData = () => axios.get(
        `https://api.dune-ex.net/api/api/users/me`,
        {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(res => {
            setUserData(res.data);
        })

    useEffect(() => {
        requireUserData();
        requireBalance()
    }, []);



    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'value':
                setValueDirty(true)
                break
            case 'network':
                setNetworkDirty(true)
                break
            case 'valueConvert':
                setNetworkDirty(true)
                break
        }

    };

    useEffect(() => {
        if (networkError || valueError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [networkError, valueError])

    const getBalance = (walletFromInc) => {
        switch (walletFromInc) {
            case "main":
                axios.get(
                    `https://api.dune-ex.net/api/api/users/balance`,
                    {
                        headers: { "Authorization": `Bearer ${token}` }
                    })
                    .then(res => {
                        setWalletBalance(res.data)
                    })
                break
            case "invest":
                axios.get(
                    `https://api.dune-ex.net/api/api/users/balance/invest`,
                    {
                        headers: { "Authorization": `Bearer ${token}` }
                    })
                    .then(res => {
                        setWalletBalance(res.data)
                    })
                break
            case "futures":
                axios.get(
                    `https://api.dune-ex.net/api/api/users/balance/futures`,
                    {
                        headers: { "Authorization": `Bearer ${token}` }
                    })
                    .then(res => {
                        setWalletBalance(res.data)
                    })
                break
        }
    }

    useEffect(() => {
        getBalance(walletFrom)
    }, [walletFrom]);

    const valueConvertHandler = (e) => {
        setValueConvert(e.target.value)

        if (!e.target.value) {
            setValueCVError('field cannot be empty!')
        } else {
            setValueCVError('')
        }
    };
    const changeValueConvert = event => {
        setValueConvert(event.target.value);
    };
    const changeSymbolSwap = event => {
        setSymbolSwap(event.target.value);
    };
    const handleWalletFrom = event => {
        setWalletFrom(event.target.value);
    };
    const handleWalletTo = event => {
        setWalletTo(event.target.value);
    };
    const swapWallet = () => {
        let wf = walletFrom
        let wt = walletTo
        setWalletFrom(wt)
        setWalletTo(wf)
    }
    const getStockPrice = () => {
        axios.get(`https://api.dune-ex.net/api/api/share/get_avg?symbol=${match[1]}`)
            .then(res => {
                setStockPrice(res.data.lastPrice)
                setSpotFormTotal(spotFormAmount * res.data.lastPrice);
            })
    }
    const handleSpotFormChange = event => {
        setSpotFormAmount(event.target.value);
    };

    useEffect(() => {
        setSpotFormTotal(spotFormAmount * stockPrice);
    }, [spotFormAmount, pair])

    const handlePairChange = event => {
        setPair(event.target.value)
        getStockPrice()
    }
    const getConvertData = () => {
        axios.get(`https://api.dune-ex.net/api/api/shares/convert/data?symbol=${match[1]}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setConvertData(res.data)
            })
    }
    const fetchBalance = async () => {
        try {
            const response = await axios.get('https://api.dune-ex.net/api/api/users/balance/invest', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setBalanceRub(Math.floor10(response.data.rub, -2));
            setBalanceInv(response.data);
            setisldbi(true)

        } catch (error) {
            console.error('Ошибка при получении баланса:', error);
        }
    };

    useEffect(() => {
        fetchBalance()
        getConvertData()
        getStockPrice()
    }, [])

    const handleBuyStocks = () => {
        axios.post("https://api.dune-ex.net/api/api/share/spot", {
            name: match[1],
            coin: match[2],
            amount: spotFormAmount,
            price: stockPrice,
            total: spotFormTotal
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(() => {
                setSpotFormAmount("")
                setSpotFormTotal("")
                fetchBalance()
                getConvertData()
                getStockPrice()
                enqueueSnackbar(`Успешно!`, { action, 'variant': 'success', 'className': "snacksuccess" });
            })
            .catch(error => {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, { action, 'variant': 'error', 'className': "snackerror" });
                    }
                )
            })
    }
    const handleSellStocks = () => {
        axios.post("https://api.dune-ex.net/api/api/share/spot/close", {
            name: match[1],
            amount: spotFormAmount,
            price: stockPrice,
            total: spotFormTotal

        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(() => {
                setSpotFormAmount("")
                setSpotFormTotal("")
                fetchBalance()
                getConvertData()
                getStockPrice()
                enqueueSnackbar(`Успешно!`, { action, 'variant': 'success', 'className': "snacksuccess" });
            })
            .catch(error => {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, { action, 'variant': 'error', 'className': "snackerror" });
                    }
                )
            })
    }
    let stock = match[1].toLowerCase()

    useEffect(() => {
        getStockPrice()
    }, [pair])

    const toggleModal = () => {
        setIsOpen(!isOpen);

    };
    const toggleModalE = event => {
        event.stopPropagation()
        setIsOpen(false);

    };
    const confirmAction = () => {
        handleSellStocks()
        toggleModal(); // Закрываем модальное окно после подтверждения
    };
    const goBack = () => {
        toggleModal(); // Закрываем модальное окно после возврата назад
    };



    const handleWalletConvertation = (event) => {
        let [valueFrom, valueTo, coinFrom, coinTo, balanceFrom, balanceTo] = [valueConvert, valueConvert, symbolSwap, symbolSwap, walletFrom, walletTo]
        event.preventDefault();
        if(balanceTo == "invest" & coinFrom != "usd"){
            axios({
                method: "post",
                url: "https://api.dune-ex.net/api/api/convert",
                data: {type:"main", valueFrom, valueTo, coinFrom, coinTo: "rub", balanceFrom, balanceTo },
                headers: {"Authorization" : `Bearer ${token}`}
            })
                .then(res => {
                    setValueConvert(0)
                    requireBalance()
                    requireUserData()
                    getBalance(walletFrom)
                    setWalletFrom("main")
                    setWalletTo("futures")
                    enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                })
                .catch( error => {
                    error.response.data.detail.map(
                        errormsg => {
                            enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                        }
                    )


                });
        }
        else if(balanceFrom == "invest"){
            axios({
                method: "post",
                url: "https://api.dune-ex.net/api/api/convert",
                data: {type:"main", valueFrom, valueTo, coinFrom: "rub", coinTo: coinTo, balanceFrom, balanceTo },
                headers: {"Authorization" : `Bearer ${token}`}
            })
                .then(res => {
                    setValueConvert(0)
                    requireBalance()
                    requireUserData()
                    getBalance(walletFrom)
                    setWalletFrom("main")
                    setWalletTo("futures")
                    enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                })
                .catch( error => {
                    error.response.data.detail.map(
                        errormsg => {
                            enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                        }
                    )


                });
        }
        else{
            axios({
                method: "post",
                url: "https://api.dune-ex.net/api/api/convert",
                data: {type:"main", valueFrom, valueTo, coinFrom, coinTo, balanceFrom, balanceTo },
                headers: {"Authorization" : `Bearer ${token}`}
            })
                .then(res => {
                    setValueConvert(0)
                    requireBalance()
                    requireUserData()
                    getBalance(walletFrom)
                    setWalletFrom("main")
                    setWalletTo("futures")
                    enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                })
                .catch( error => {
                    error.response.data.detail.map(
                        errormsg => {
                            enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                        }
                    )


                });
        }
    }

    useEffect(() => {
        axios.get(`https://api.dune-ex.net/api/api/share/spot/data?name=${match[1]}&total=${spotFormTotal}`, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                setpc(res.data.priceChange)
                setpcp(res.data.priceChangePercent)
            })
    })
    const [cursCb, setCursCb] = useState(90)
    useEffect(() => {
        axios.get(`https://api.dune-ex.net/api/api/current_coin/ruble`)
            .then(res => {
                setCursCb(res.data)
            })
    }, []);

  return (
      <>
          {isOpen && (
              <div className="deposit-popup-window" onClick={(event) => toggleModalE(event)}>
                  <div className="deposit-popup-window-2" onClick={(event) => event.stopPropagation()}>
                      <h2>{t("wallet_page.current_profit")}</h2>
                      <p
                          className={`pribyl-modal ${
                              priceChange > 0 ? "pi-green" : priceChangePercent === 0 ? "" : "pi-red"
                          }`}
                      >
                          {priceChange} RUB ({priceChangePercent}%)
                      </p>
                      <h2 className="mb-50">{t("wallet_page.confirm_sale")}</h2>
                      <div className="modal-buttons">
                          <button className="modal-button modal-podtv" onClick={confirmAction}>
                              {t("wallet_page.confirm")}
                          </button>
                          <button className="modal-button" onClick={goBack}>
                              {t("wallet_page.go_back")}
                          </button>
                      </div>
                  </div>
              </div>
          )}
          <div className="tabs__body">
              <div className="account__box">
                  <div className="wallet__box">
                      <div className="wallet__item">
                          <div className="wallet__title">{t("wallet_page.transfer_between_accounts")}</div>
                          <div className="wallet__block">
                              <div className="wallet__line">
                                  <div className="wallet__line-left">
                                      <p>{t("wallet_page.from")}</p>
                                      <select className="select" value={walletFrom} onChange={handleWalletFrom}>
                                          <option value="main">{t("wallet_page.deposit_account")}</option>
                                          <option value="invest">{t("wallet_page.investment_account")}</option>
                                          <option value="futures">{t("wallet_page.futures_account")}</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="revert" onClick={swapWallet}>
                                  <img src={revert} alt="" />
                              </div>
                              <div className="wallet__line">
                                  <div className="wallet__line-left">
                                      <p>{t("wallet_page.to")}</p>
                                      <select className="select" value={walletTo} onChange={handleWalletTo}>
                                          <option value="main">{t("wallet_page.deposit_account")}</option>
                                          <option value="invest">{t("wallet_page.investment_account")}</option>
                                          <option value="futures">{t("wallet_page.futures_account")}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div className="wallet__bom">
                              <p className="wallet__txt">
                                  {t("wallet_page.available")}: {walletBalance[`${symbolSwap}`]} {symbolSwap.toUpperCase()} {walletFrom === "invest" && `; 1$ = ${cursCb}₽`}
                              </p>
                              <div className="wallet__bomm">
                                  <div className="wallet__bomm-left">
                                      <p className="wallet__txt2">{t("wallet_page.transfer_amount")}</p>
                                      <input
                                          type="text"
                                          className="wallet__value"
                                          value={valueConvert}
                                          name="value"
                                          onBlur={(e) => blurHandler(e)}
                                          onChange={(e) => valueConvertHandler(e) && changeValueConvert}
                                      />
                                  </div>
                                  <div className="wallet__right">
                                      <select className="select" value={symbolSwap} onChange={changeSymbolSwap}>
                                          <option value="usd">USD</option>
                                          <option value="usdt">USDT</option>
                                          <option value="btc">BTC</option>
                                          <option value="eth">ETH</option>
                                          <option value="sol">SOL</option>
                                          <option value="ada">ADA</option>
                                          <option value="dot">DOT</option>
                                          <option value="trx">TRX</option>
                                          <option value="uni">UNI</option>
                                          {/*<option value="matic">MATIC</option>*/}
                                          <option value="link">LINK</option>
                                          <option value="bnb">BNB</option>
                                          <option value="xrp">XRP</option>
                                          <option value="doge">DOGE</option>
                                          <option value="bch">BCH</option>
                                          <option value="ltc">LTC</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <button type="button" className="button" onClick={handleWalletConvertation}>
                              {t("wallet_page.transfer_button")}
                          </button>
                      </div>

                      <Convertation />
                  </div>

                  <div className="wallet__box">
                      <div className="wallet__item-long">
                          <div className="flex-between">
                              <div className="wallet__title">{t("wallet_page.spot_stock_trading")}</div>
                              <div className="wallet__balance">
                                  {t("wallet_page.balance")}: {balanceRub} {match[2]} | {isldbi ? balanceInv[stock] : ""} {match[1]}
                              </div>
                          </div>
                          <div className="wallet__input_block">
                              <div className="wallet-input">
                                  <select value={pair} onChange={handlePairChange} className="wallet-ri wri-left">
                                      <option value="SBERRUB">SBER/RUB</option>
                                      <option value="LKOHRUB">LKOH/RUB</option>
                                      <option value="ROSNRUB">ROSN/RUB</option>
                                      <option value="WUSHRUB">WUSH/RUB</option>
                                      <option value="MTSSRUB">MTSS/RUB</option>
                                      <option value="SBERPRUB">SBERP/RUB</option>
                                      <option value="TRNFPRUB">TRNF/RUB</option>
                                      <option value="GAZPRUB">GAZP/RUB</option>
                                      <option value="YNDXRUB">YNDX/RUB</option>
                                      <option value="ALRSRUB">ALRS/RUB</option>
                                      <option value="MOEXRUB">MOEX/RUB</option>
                                      <option value="BELURUB">BELU/RUB</option>
                                      <option value="TATNRUB">TATN/RUB</option>
                                  </select>
                              </div>
                              <div className="wallet-input">
                                  <span className="wallet-label">{t("wallet_page.amount")}</span>
                                  <div className="wallet-ri__container">
                                      <input
                                          name="amount"
                                          value={spotFormAmount}
                                          onChange={(event) => handleSpotFormChange(event)}
                                          type="text"
                                          placeholder="0"
                                          className="wallet-ri"
                                      />
                                      <span className="wallet-label">{match[1]}</span>
                                  </div>
                              </div>
                              <div className="wallet-input">
                                  <span className="wallet-label">{t("wallet_page.conversion")}</span>
                                  <div className="wallet-ri__container">
                                      <input disabled name="total" value={spotFormTotal} type="text" placeholder="0" className="wallet-ri" />
                                      <span className="wallet-label">{match[2]}</span>
                                  </div>
                              </div>
                          </div>

                          <div className="apy">
                              {t("wallet_page.expected_apy")}: <span className="apyy">{convertData.apy}%</span>
                          </div>

                          <div className="wallet__input_block">
                              <button className="wi-button bg-buy" onClick={handleBuyStocks}>
                                  {t("wallet_page.buy_button")}
                              </button>
                              <button
                                  className="wi-button bg-sell"
                                  disabled={isldbi ? (balanceInv[stock] ? false : true) : true}
                                  onClick={toggleModal}
                              >
                                  {t("wallet_page.sell_button")}
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>

  );
};

export default Wallet;